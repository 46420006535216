import React, { useState, MouseEvent, ChangeEvent, RefObject } from 'react';
import { Menu, MenuItem, TextField, Chip, Box } from '@mui/material';
import styled from "@emotion/styled";

interface NestedMenuProps {
  data: Record<string, Record<string, Record<string, string[]>>>;
  formik: any;
  anchorEl: RefObject<HTMLDivElement>;
  onClose: () => void;
}

const Nestedmenumultiselect: React.FC<NestedMenuProps> = ({ data, formik, anchorEl, onClose }) => {
  const [subMenuAnchorEl, setSubMenuAnchorEl] = useState<null | HTMLElement>(null);
  const [selectedCountry, setSelectedCountry] = useState<string>('');
  const [selectedState, setSelectedState] = useState<string>('');
  const [selectedCity, setSelectedCity] = useState<string>('');
  const [selectedLocations, setSelectedLocations] = useState<string[]>(formik.values.Locations || []);

  const [countrySearch, setCountrySearch] = useState<string>('');
  const [stateSearch, setStateSearch] = useState<string>('');
  const [citySearch, setCitySearch] = useState<string>('');
  const [zipSearch, setZipSearch] = useState<string>('');

  const handleCountryClick = (event: MouseEvent<HTMLElement>, country: string) => {
    setSelectedCountry(country);
    setSelectedState('');
    setSelectedCity('');
    setSubMenuAnchorEl(event.currentTarget);
  };

  const handleStateClick = (event: MouseEvent<HTMLElement>, state: string) => {
    setSelectedState(state);
    setSelectedCity('');
    setSubMenuAnchorEl(event.currentTarget);
  };

  const handleCityClick = (event: MouseEvent<HTMLElement>, city: string) => {
    setSelectedCity(city);
    setSubMenuAnchorEl(event.currentTarget);
  };

  const handleZipClick = (zip: string) => {
    const locationString = `${selectedCountry}, ${selectedState}, ${selectedCity}, ${zip}`;
    const updatedLocations = selectedLocations.includes(locationString)
      ? selectedLocations.filter(c => c !== locationString)
      : [...selectedLocations, locationString];

    setSelectedLocations(updatedLocations);
    formik.setFieldValue('Locations', updatedLocations);
    onClose();
  };

  const filteredCountries = Object.keys(data).filter(country =>
    country.toLowerCase().includes(countrySearch.toLowerCase())
  );

  const filteredStates = selectedCountry
    ? Object.keys(data[selectedCountry]).filter(state =>
        state.toLowerCase().includes(stateSearch.toLowerCase())
      )
    : [];

  const filteredCities = selectedCountry && selectedState
    ? Object.keys(data[selectedCountry][selectedState]).filter(city =>
        city.toLowerCase().includes(citySearch.toLowerCase())
      )
    : [];

  const filteredZips = selectedCountry && selectedState && selectedCity
    ? data[selectedCountry][selectedState][selectedCity].filter(zip =>
        zip.toLowerCase().includes(zipSearch.toLowerCase())
      )
    : [];

  return (
    <>
      <Menu
        id="country-menu"
        anchorEl={anchorEl.current}
        keepMounted
        open={Boolean(anchorEl.current)}
        onClose={onClose}
      >
        <TextField
        sx={{mariginBottom:"8px"}}
          label="Search Country"
          variant="outlined"
          fullWidth
          margin="normal"
          value={countrySearch}
          onChange={(e: ChangeEvent<HTMLInputElement>) => setCountrySearch(e.target.value)}
        />
        {filteredCountries.length ? (
          filteredCountries.map((country) => (
            <MenuItem key={country} onClick={(e) => handleCountryClick(e, country)}>
              {country}
            </MenuItem>
          ))
        ) : (
          <MenuItem disabled>No Countries Found</MenuItem>
        )}
      </Menu>

      {selectedCountry && (
        <Menu
          id="state-menu"
          anchorEl={subMenuAnchorEl}
          keepMounted
          open={Boolean(subMenuAnchorEl)}
          onClose={() => setSubMenuAnchorEl(null)}
        >
          <TextField
            label="Search State"
            variant="outlined"
            fullWidth
            margin="normal"
            value={stateSearch}
            onChange={(e: ChangeEvent<HTMLInputElement>) => setStateSearch(e.target.value)}
          />
          {filteredStates.length ? (
            filteredStates.map((state) => (
              <MenuItem key={state} onClick={(e) => handleStateClick(e, state)}>
                {state}
              </MenuItem>
            ))
          ) : (
            <MenuItem disabled>No States Found</MenuItem>
          )}
        </Menu>
      )}

      {selectedState && (
        <Menu
          id="city-menu"
          anchorEl={subMenuAnchorEl}
          keepMounted
          open={Boolean(subMenuAnchorEl)}
          onClose={() => setSubMenuAnchorEl(null)}
        >
          <TextField
            label="Search City"
            variant="outlined"
            fullWidth
            margin="normal"
            value={citySearch}
            onChange={(e: ChangeEvent<HTMLInputElement>) => setCitySearch(e.target.value)}
          />
          {filteredCities.length ? (
            filteredCities.map((city) => (
              <MenuItem key={city} onClick={(e) => handleCityClick(e, city)}>
                {city}
              </MenuItem>
            ))
          ) : (
            <MenuItem disabled>No Cities Found</MenuItem>
          )}
        </Menu>
      )}

      {selectedCity && (
        <Menu
          id="zip-menu"
          anchorEl={subMenuAnchorEl}
          keepMounted
          open={Boolean(subMenuAnchorEl)}
          onClose={() => setSubMenuAnchorEl(null)}
        >
          <TextField
            label="Search Zip Code"
            variant="outlined"
            fullWidth
            margin="normal"
            value={zipSearch}
            onChange={(e: ChangeEvent<HTMLInputElement>) => setZipSearch(e.target.value)}
          />
          {filteredZips.length ? (
            filteredZips.map((zip) => (
              <MenuItem key={zip} onClick={() => handleZipClick(zip)}>
                {zip}
              </MenuItem>
            ))
          ) : (
            <MenuItem disabled>No Zip Codes Found</MenuItem>
          )}
        </Menu>
      )}

      <Dropdown_style>
        {selectedLocations.map((location, index) => (
          <Chip
            key={index}
            label={location}
            onDelete={() => {
              const updatedLocations = selectedLocations.filter((c) => c !== location);
              setSelectedLocations(updatedLocations);
              formik.setFieldValue('Locations', updatedLocations);
            }}
          />
        ))}
      </Dropdown_style>
    </>
  );
};

export default Nestedmenumultiselect;


const Dropdown_style = styled(Box)`
  margin-top: 18px;
  .MuiChip-root {
    background: #ced4f0;
    color: #4d6cd9;
    width: 173px;
    border-radius: 5px;
  }
  .MuiChip-deleteIcon {
    color: #4d6cd9 !important;
  }
`;