import styled from '@emotion/styled';
import { Box } from '@mui/material';
import { themeColors } from 'core/Theming/theme';
import React from 'react';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

interface LoaderProps {
    show: boolean;
    children?: React.ReactNode;
}

const TableLoader: React.FC<LoaderProps> = ({ show, children }) => {
    return (
        <LoaderComponent show={show}>
            {show ? (
                <SkeletonTheme baseColor={"#e5e4e2"} highlightColor="#f8f8ff">
                    <Skeleton style={{ marginLeft: '20px' }} width={200} height={25} count={1} />
                    <div>
                        <Skeleton style={{ margin: '5px 0px', width: '100%' }} height={40} count={10} />
                    </div>
                </SkeletonTheme>
            ) : (
                <>{children}</>
            )}
        </LoaderComponent>
    );
};

const LoaderComponent = styled(Box)<{ show: boolean }>(({ show }) => ({
    width: show ? '100vw' : '100%',
    display: 'block',
    overflow: 'hidden'
}))


export default TableLoader;
