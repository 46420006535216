import styled from "@emotion/styled";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { Box, Button, Card, CardContent, IconButton, Modal, Paper, Tooltip, Typography } from "@mui/material";
import { SubmissionDetailsCvList } from "api/resources/modals";
import CustomFileUpload from "components/fileUploadComponent/FileUpload";
import Spinner from "components/spinner/Spinner";
import { themeColors } from "core/Theming/theme";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useCvDetailsNotes, useCvLikeOrDislike, useCvNotes, useCvOnHold, useVendorEditCv } from "pages/hooks";
import React, { useRef, useState } from "react";
import { toast } from "react-toastify";
import { ReactComponent as UnlikeIcon } from "../../../../assets/icons/RfrIcon/Colored Unchecked.svg";
import { ReactComponent as Check } from "../../../../assets/icons/Likeicon.svg";
import { ReactComponent as LikeIcon } from "../../../../assets/icons/LikedCvIcon.svg";
import { ReactComponent as Notcheck } from "../../../../assets/icons/Dislike.svg";
import { ReactComponent as Notes } from "../../../../assets/icons/Notes.svg";
import { ReactComponent as VendorDetails } from "../../../../assets/icons/Vendorcalls.svg";
import { ReactComponent as Downloads } from "../../../../assets/icons/Downloadsicon.svg";
import NotesEditor from "pages/BidMarketPlace/Components/Notes";
import { convertToRaw, EditorState } from "draft-js";
import { Vendordetails } from "pages/BidMarketPlace/Components/Cvdetails";
import { ReactComponent as UnderonReview } from "../../../../assets/requ-submission/UnderReview.svg";

import { ReactComponent as Keeponhold } from "../../../../assets/requ-submission/Keep on hold.svg";
import { SmartMaincontainer, SmartScore } from "pages/BidMarketPlace/SubmissionDetails/Cv";
import { GreenIcons, LowerIcons, MediumIcons, StyledSmartAccept, StyledSmartpending, StyledSmartReject } from "pages/BidMarketPlace/SubmissionDetails/Submissionstable";

interface SubmissionDetailsProps {
    data: SubmissionDetailsCvList[]
    spinner: boolean;
    handleCallCvApi: (callApi: boolean) => void;
    handleBack?: (showCvView: boolean) => void;
    vendorId?: string;
}

const EmployerCardView: React.FC<SubmissionDetailsProps> = ({ data = [], handleCallCvApi, spinner, handleBack, vendorId }) => {
    const [currentPdfIndex, setCurrentPdfIndex] = useState(0);
    const [isRightClicked, setIsRightClicked] = useState(false);
    const [isLeftClicked, setIsLeftClicked] = useState(false);
    const scrollRef = useRef<HTMLDivElement | null>(null);
    const [cvModal, showCvModal] = useState(false);
    const [editCvId, setEditCvId] = useState('');
    const [editCvFile, setEditCvFile] = useState<File | null>(null);
    const [downloadSpinner, showDownloadSpinner] = useState(false)
    const [openNotes, setOpenNotes] = useState(false)
    const [editorState, setEditorState] = useState(EditorState.createEmpty());
    const [selectedImages, setSelectedImages] = React.useState<File[]>([]);

    const [open, setOpen] = useState(false);

    const { updateVendorCv, isLoading, error } = useVendorEditCv({
        onSuccess: () => {
            toast.success("CV Updated Successfully");
        },
        onError: () => {
            toast.error(error?.message);
        },
    });
    const { cvLikeOrDislike } = useCvLikeOrDislike({
        onError: (err) => { toast.error("Error Date requisition:"); console.log(err, 'hello api error') },
        onSuccess: () => {
            toast.success(" update successfully");
            handleCallCvApi(true);
        },
    });

    const { cvOnHold } = useCvOnHold({
        onError: (err) => { toast.error("Error Date requisition:"); console.log(err, 'hello api error') },
        onSuccess: () => {
            toast.success(" update successfully");
            handleCallCvApi(true);
        },
    });

    const Cv = data;

    const handleCardClick = (index: number) => {
        setCurrentPdfIndex(index);
    };
    const { notesCvDetails = [], refetch: cvNotesDetails } = useCvDetailsNotes(
        data[currentPdfIndex]?.cv_id,
        openNotes
    );


    const handleClickLeft = () => {
        if (currentPdfIndex > 0) setCurrentPdfIndex((prevIndex) => prevIndex - 1);
    };

    const handleClickRight = () => {
        if (currentPdfIndex < Cv.length - 1) setCurrentPdfIndex((prevIndex) => prevIndex + 1);
    };

    const handleScrollLeft = () => {
        if (scrollRef.current)
            scrollRef.current.scrollBy({ left: -200, behavior: "smooth" });

        setIsLeftClicked(true);
        setIsRightClicked(false);
    };

    const handleScrollRight = () => {
        if (scrollRef.current)
            scrollRef.current.scrollBy({ left: 200, behavior: "smooth" });

        setIsRightClicked(true);
        setIsLeftClicked(false);
    };

    const getBase64 = (file: File): Promise<string> => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => {
                const base64String = (reader.result as string).split('base64,')[1];
                resolve(base64String || '');
            };
            reader.onerror = (error) => reject(error);
        });
    };

    const handleEditCv = async () => {
        try {
            if (editCvFile) {
                const base64 = await getBase64(editCvFile);
                await updateVendorCv({ cvId: editCvId, base64 });
            }
        } catch (err) {
            toast.error('Something went wrong please try again');
        } finally {
            handleCallCvApi(true);
            showCvModal(false);
            setEditCvFile(null);
            setEditCvId('');
        }
    };

    const handleDownloadCv = () => {
        showDownloadSpinner(true)
        const cvUrl = data[currentPdfIndex]?.cv;
        if (cvUrl) {
            fetch(cvUrl)
                .then(response => response.blob())
                .then(blob => {
                    const url = URL.createObjectURL(blob);
                    const a = document.createElement('a');
                    a.href = url;
                    a.download = `${data[currentPdfIndex]?.cv_name}.pdf`;
                    document.body.appendChild(a);
                    a.click();
                    a.remove();
                    URL.revokeObjectURL(url);
                    showDownloadSpinner(false)
                })
                .catch(() => {
                    toast.error('Failed to download CV');
                    showDownloadSpinner(false)
                });
        }
    };

    const handleShare = () => {
        setOpen(true);
    };

    const handleShareclose = () => {
        setOpen(false);
    };

    const handleopenNotes = () => {
        setOpenNotes(true);
    }

    const handleClose = () => {
        setOpenNotes(false);
    };
    const { cvNotes } = useCvNotes({
        onError: () => toast.error("Error Date requisition:"),
        onSuccess: () => {
            toast.success(" update successfully");
            cvNotesDetails();
        },
    });

    
 const handleFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = Array.from(event.target.files || []);
    toast.success('Image uploaded successfully!');
    setSelectedImages(files);
  };
  
  const convertImageToBase64 = (file: File): Promise<string> => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result as string);
      reader.onerror = (error) => reject(error);
    });
  };
    const handleSubmit = async() => {
        const contentState = editorState.getCurrentContent();
        const rawContent = convertToRaw(contentState);
        const text = contentState.getPlainText();
        const base64Image = selectedImages.length > 0
    ? await convertImageToBase64(selectedImages[0]) 
    : '';

        cvNotes({
            cvId: data[currentPdfIndex].cv_id,
            notes: JSON.stringify(rawContent),
            notesImage:base64Image
        }),
            setEditorState(EditorState.createEmpty());
            setSelectedImages([]);

    };
    const notesCvDetailsArray = Array.isArray(notesCvDetails)
        ? notesCvDetails
        : [];


    const handleCheckClick = async (cvId: string, status: string) => {
        try {
            await cvLikeOrDislike({
                cvID: cvId,
                status: status
            })
        } catch (err) {
            console.log(err, 'hello error')
        } finally {
            handleCallCvApi(true)
        }
    };

    const handleCheckonHold = async (cvId: string, status: string) => {
        try {
            await cvOnHold({
                cvID: cvId,
                status: status
            })
        } catch (err) {
            console.log(err, 'hello error')
        } finally {
            handleCallCvApi(true)
        }
    };

    let Details=10;

    return (
        <Spinner show={spinner}>
            <MainContent>
                {data.length > 0 ? (
                    <MainGridbox>
                        <Headingcontainer>
                            <Headingtesx variant="h4">
                                {handleBack &&
                                    <>

                                        <IconButton onClick={() => handleBack && handleBack(false)}><ArrowBackIcon /></IconButton>
                                        {data[currentPdfIndex]?.cv_name}
                                    </>

                                }

                            </Headingtesx>
                        </Headingcontainer>

                        <Contentcontainer>
                            <LeftscrollPdf>
                                <Button disabled={currentPdfIndex === 0}>
                                    <img src="/Assets/Images/leftArrowBlue.svg" alt="left move" onClick={handleClickLeft} />
                                </Button>
                            </LeftscrollPdf>
                            <PdfContainer>
                                {data.length > 0 && (
                                    <PdfView src={data[currentPdfIndex].cv} type="application/pdf" />
                                )}
                                <Iconcontainer>

                                    {
                                        !handleBack && <ImageContainer>
                                            <ToolsContainer>
                                                <ToolBadge
                                                    color={data[currentPdfIndex].status === 'Disliked' ? themeColors.error : data[currentPdfIndex].status === 'Liked' ? themeColors?.success : themeColors.warning}
                                                    background={data[currentPdfIndex].status === 'Disliked' ? themeColors.badgeError : data[currentPdfIndex].status === 'Liked' ? themeColors?.badgeSuccess : themeColors.badgeWarning}
                                                >
                                                    {data[currentPdfIndex].status === 'Liked' ? <StyledStatus><img src="/Assets/Images/like.svg" alt="like" /></StyledStatus> : data[currentPdfIndex].status === 'Disliked' ? <StyledStatus><img src="/Assets/Images/disLike.svg" alt="disLike" /></StyledStatus> : <StyledStatus><img src="/Assets/Images/cvPending.svg" alt="" /></StyledStatus>}
                                                    {data[currentPdfIndex].status}
                                                </ToolBadge>
                                            </ToolsContainer>
                                        </ImageContainer>
                                    }

                                    {!handleBack ?
                                        <ImageContainer>
                                            <Typography>
                                                <Tooltip arrow title={'Edit Cv'}>
                                                    <img
                                                        src="/Assets/Images/cv.svg"
                                                        alt="Change CV"
                                                        width={35}
                                                        onClick={() => {
                                                            showCvModal(true);
                                                            setEditCvId(data[currentPdfIndex]?.cv_id);
                                                            setEditCvFile(null); // Clear the file state when opening the modal
                                                        }}
                                                        style={{ cursor: 'pointer', marginRight: '8px' }}
                                                    />
                                                </Tooltip>
                                            </Typography>
                                        </ImageContainer> :
                                        <ImageContainer>
                                            {
                                                data.length > 0 && (
                                                    data[currentPdfIndex].status === "Liked" ? (
                                                        <LikeIcon />

                                                    ):  ( <CheckIcon
                                                        onClick={() =>
                                                            handleCheckClick(data[currentPdfIndex]?.cv_id, "Liked")
                                                        }
                                                    />
                                                     ) )
                                            }
                                            {
                                                data.length > 0 && (
                                                    data[currentPdfIndex].status === "Disliked" ? (
                                                        <Uncheckcontainer>
                                                            <ColoredUncheckedIcon />
                                                            DisLike
                                                        </Uncheckcontainer>
                                                    ):(
                                                      <UncheckIcon
                                                        onClick={() => handleCheckClick(data[currentPdfIndex]?.cv_id, "Disliked")}
                                                      />
                                                    ))
                                            }
                                              {
                                                data.length > 0 && (
                                                    data[currentPdfIndex].status === "On hold" ? (
                                                     <KeeponReviewContainer>
                                                          <UnderonReview/>
                                                     </KeeponReviewContainer>

                                                    ) : <KeeponholdContainer>
                                                        <Keeponhold
                                                        onClick={() =>
                                                            handleCheckonHold(data[currentPdfIndex]?.cv_id, "On hold")
                                                        }
                                                    />
                                                    </KeeponholdContainer>
                                                )
                                            }
                                            <NoteIcon onClick={handleopenNotes} />

                                            <VendorcallIcon onClick={handleShare} />
                                            <DownloadIcons onClick={handleDownloadCv} />


                                        </ImageContainer>
                                    }

                                    {
                                        !handleBack &&
                                        <ImageContainer>
                                            <Typography>
                                                <Tooltip arrow title={'Download'}>
                                                    <Spinner show={downloadSpinner}>
                                                        <img
                                                            src="/Assets/Images/downloadPurple.svg"
                                                            alt="Download CV"
                                                            width={35}
                                                            onClick={handleDownloadCv}
                                                            style={{ cursor: 'pointer', marginRight: '8px' }}
                                                        />
                                                    </Spinner>
                                                </Tooltip>
                                            </Typography>
                                        </ImageContainer>
                                    }
                                </Iconcontainer>
                            </PdfContainer>
                            <RightscrollPdf>
                                <img src="/Assets/Images/rightArrowBlue.svg" alt="right move" onClick={handleClickRight} />
                            </RightscrollPdf>

                        </Contentcontainer>
                        <Box>
                        <SmartMaincontainer>
            <SmartScore >
                        <>
            {Details >= 51 ? (
                <StyledSmartAccept>
                <GreenIcons />80% Match
                </StyledSmartAccept>
            ) : Details >= 35 ? (
                <StyledSmartpending>
                <MediumIcons />50% Match
                </StyledSmartpending>
            ) : Details < 35 ? (
                <StyledSmartReject>
                <LowerIcons />35% Match
                </StyledSmartReject>
            ) : (
                " -"
            )}
            </>
            </SmartScore>
            </SmartMaincontainer>
                            <Cardscontained>
                                <MainCardContainer>
                                    <LeftArrow
                                        onClick={handleScrollLeft}
                                        isClicked={isLeftClicked}
                                    />
                                    <ScrollContainer ref={scrollRef}>
                                        {Cv.map((url, index) => (
                                            <Cards
                                                key={index}
                                                onClick={() => handleCardClick(index)}
                                                isSelected={index === currentPdfIndex}
                                            >
                                                <CardContents>
                                                    <Typography variant="h4" color="primary">
                                                        {index + 1}
                                                    </Typography>
                                                </CardContents>
                                            </Cards>
                                        ))}
                                    </ScrollContainer>
                                    <RightArrow
                                        onClick={handleScrollRight}
                                        isClicked={isRightClicked}
                                    />
                                </MainCardContainer>
                            </Cardscontained>
                        </Box>
                        <Box>

                        </Box>
                    </MainGridbox>
                ) : (
                    <Errormessage>No Cvs found</Errormessage>
                )}
                <Modal
                    open={cvModal}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <StyledModalBody>
                        <Box sx={{ ...modalStyle }}>
                            <FileuploadBox>
                                <CustomFileUpload
                                    onFileChange={async (file: File | null) => {
                                        if (file) {
                                            setEditCvFile(file); // Set the file directly
                                        }
                                    }}
                                />
                            </FileuploadBox>
                            {editCvFile === null && <Typography color={themeColors.error}>Required to edit the existing file</Typography>}
                            <Spinner show={isLoading}>
                                <Typography sx={{ marginTop: '10px' }} align={'right'}>
                                    <Button sx={{ marginRight: '10px' }} variant="contained" size="small" disabled={editCvFile === null} onClick={handleEditCv}>Edit Cv</Button>
                                    <Button variant="outlined" size="small" onClick={() => { showCvModal(false) }}>cancel</Button>
                                </Typography>
                            </Spinner>
                        </Box>
                    </StyledModalBody>
                </Modal>

                {openNotes && data &&

                    <NotesEditor
                        open={openNotes}
                        handleClose={handleClose}
                        editorState={editorState}
                        setEditorState={setEditorState}
                        handleSubmit={handleSubmit}
                        cvId={data[currentPdfIndex]?.cv_id}
                        notesCvDetails={notesCvDetailsArray} />
                }


            </MainContent>
            {open &&
                <Vendordetails
                    open={open}
                    handleClose={handleShareclose}
                    cvId={data[currentPdfIndex].cv_id}
                    vendorId={vendorId}
                />
            }


        </Spinner>
    );
};

const KeeponReviewContainer=styled(Box)({
    cursor: "pointer",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
    flexDirection: "column",
    color: themeColors.blackGray,
    fontSize: "12px",
    marginRight:'14px',
    marginBottom:'12px',
  });
  
  
  const KeeponholdContainer=styled(Box)({
      cursor: "pointer",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      textAlign: "center",
      flexDirection: "column",
      color: themeColors.blackGray,
      fontSize: "12px",
      margin: "10px 0px",
      marginRight:'14px',
  });
const LeftscrollPdf = styled(Box)({
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
    marginRight: "30px",
});

const modalStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 600,
    bgcolor: "background.paper",
    boxShadow: 24,
    borderRadius: "15px",
    padding: '5px 20px 20px 20px'
};

const FileuploadBox = styled(Box)({
    marginTop: "16px",
    textAlign: "center",
    borderRadius: "10px",
    border: `1px dashed ${themeColors.emptyStar}`,
});

const ColoredUncheckedIcon = styled(UnlikeIcon)({
    height: "22px",
    width: "41px",
    cursor: 'pointer',
    marginRight: '11px'
})

const StyledModalBody = styled(Box)(({ theme }) => ({
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
}));

const RightscrollPdf = styled(Box)({
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
    marginLeft: "30px",
});

const Errormessage = styled(Typography)({
    alignItems: "center",
    textAlign: "center",
});

const CheckIcon = styled(Check)({
    cursor: "pointer",
    height: "80px",
    width: "69px",
});

const UncheckIcon = styled(Notcheck)({
    cursor: "pointer",
    height: "80px",
    width: "69px",
});

const Uncheckcontainer = styled(Box)({
    cursor: 'pointer',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    flexDirection: 'column',
    color: themeColors.blackGray,
    fontSize: '12px',
    margin: '10px 0px'
})

const Contentcontainer = styled(Box)({
    display: "inline-flex",
    justifyContent: "center",
    padding: "30px",
    width: "100%",

});

const Iconcontainer = styled(Box)({
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    position: "absolute",
    top: "20%",
    right: "0px",
    background: 'rgba(255, 255, 255)',
    width: '80px',
    height: 'auto',
    borderRadius: '12px',
    boxShadow: `0px 0px 4px 0px  ${themeColors.shadowColor}`,
    padding: '10px 0px'
});

const ImageContainer = styled('div')({
    cursor: "pointer",
    paddingTop: "32px",
});

const LeftArrow = styled(KeyboardArrowLeftIcon)<{ isClicked: boolean }>(({ isClicked }) => ({
    cursor: "pointer",
    color: isClicked ? themeColors.primaryDark : themeColors.primaryLight,
    "&.MuiSvgIcon-root": {
        width: "3rem",
        height: "3rem",
    },
}));

const RightArrow = styled(KeyboardArrowRightIcon)<{ isClicked: boolean }>(({ isClicked }) => ({
    cursor: "pointer",
    color: isClicked ? themeColors.primaryDark : themeColors.lightgrey,
    "&.MuiSvgIcon-root": {
        width: "3rem",
        height: "3rem",
    },
}));

const Cardscontained = styled(Box)({
    display: "grid",
    gridAutoFlow: "column",
    gridAutoColumns: "100%",
});

const MainCardContainer = styled(Box)({
    display: "flex",
    alignItems: "center",
    justifyContent: 'center',
    position: "relative",
    width: "100%",
});
const PdfContainer = styled(Box)({
    width: "80%",
    position: "relative",
});



const NoteIcon = styled(Notes)({
    cursor: "pointer",

    height: "80px",
    width: "69px",
});
const VendorcallIcon = styled(VendorDetails)({
    cursor: "pointer",

    height: "80px",
    width: "69px",
});

const DownloadIcons = styled(Downloads)({
    cursor: "pointer",
    height: "80px",
    width: "69px",
});

const CardContents = styled(CardContent)({
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
});

const Cards = styled(Card)<{ isSelected: boolean }>(({ isSelected }) => ({
    width: "110px",
    marginBottom: "8px",
    height: "150px",
    marginRight: "18px",
    display: "flex",
    justifyContent: "center",
    boxShadow: "none",
    backgroundImage: 'url("/Assets/Images/resume1.svg")',
    "&:last-child": {
        marginBottom: 0,
    },
    border: isSelected
        ? `2px solid ${themeColors.primaryDark}`
        : `1px solid ${themeColors.emptyStar}`,
}));

const PdfView = styled(`embed`)({
    width: "100%",
    height: "866px",
    borderRadius: "10px",
});

const MainGridbox = styled(Box)({
    width: "100%",
    border: `1px solid ${themeColors.primaryLight}`,
    boxShadow: `0px 0px 4px 0px  ${themeColors.shadowColor}`,
    borderRadius: "8px",
});
const MainContent = styled(Box)({
    width: "100%",
    display: "flex",
    flexDirection: "row",
    marginTop: "24px",
});
const Headingcontainer = styled(Box)({
    display: "flex",
    justifyContent: "space-between",
    padding: "16px 20px",
    boxShadow: `0px 0px 4px 0px  ${themeColors.shadowColor}`,
});
const Headingtesx = styled(Typography)({
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
});

const ScrollContainer = styled(Box)`
      display: flex;
      overflow-x: auto;
      white-space: nowrap;
      padding: 10px;
      align-items: center;
      justify-content: center;
    
    & > * {
    flex-shrink: 0;
    }
    
    &::-webkit-scrollbar {
    height: 8px;
    }
    
    &::-webkit-scrollbar-thumb {
    background-color: ${themeColors.badgeGray};
    border-radius: 10px;
    }
    
    &::-webkit-scrollbar-thumb:hover {
    background-color: ${themeColors.darkGrey};
    }
    
    &::-webkit-scrollbar-track {
    background-color: ${themeColors.white};
    }
`;

const ToolsContainer = styled(Box)({
    display: "flex",
    flexWrap: "wrap",
    margin: "2px 0",
    alignItems: 'center',
    justifyContent: 'center',
});

const ToolBadge = styled(Paper)<{ background: string, color: string }>(({ background, color }) => ({
    background,
    color,
    textAlign: 'center',
    paddingTop: '2px',
    paddingLeft: '15px',
    paddingRight: '15px',
    paddingBottom: '2px',
    fontWeight: 'bold',
}));

const StyledStatus = styled('span')({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '3px'
})

export default EmployerCardView;