import React, { useEffect, useState } from "react";
import {
  AppBar,
  Tabs,
  Tab,
  Box,
  Button,
  Typography,
  Stack,
  Container,
  Modal,
  TooltipProps,
  Tooltip,
  tooltipClasses,
  InputBase,
  Chip,
} from "@mui/material";
import { generatePath, Link, Routes } from "react-router-dom";
import { ReactComponent as jobsIcon } from "../../assets/icons/All submission icon.svg";
import { styled } from "@mui/system";
import { ReactComponent as DashboardIcon } from "../../assets/icons/Frame 48095432.svg";
import { ReactComponent as DashboardIcontwo } from "../../assets/icons/Frame 48095433.svg";
import { ReactComponent as Filtericon } from "../../assets/icons/Filtericon.svg";
import { ReactComponent as ChatIcon } from "../../assets/images/icons/Chat.svg";
import { ReactComponent as Menuarrow } from "../../assets/icons/Group 33.svg";
import { ReactComponent as Closeiconmodal } from "../../assets/icons/Closed.svg";
import { ReactComponent as Closecheck } from "../../assets/images/icons/Frame 48095805.svg";
import { ReactComponent as AceeptIcon } from "../../assets/images/icons/acceptIcon.svg";
import { ReactComponent as Cvicon } from "../../assets/icons/Colorcv.svg";
import { ReactComponent as Reject } from "../../assets/icons/RfrIcon/UncoloredRejected.svg";
import { ReactComponent as Accepted } from "../../assets/icons/RfrIcon/UncoloredChecked.svg";
import { ReactComponent as ViewIcon } from "../../assets/icons/RfrIcon/Uncolored view.svg";

import {
  UsePostAcceptorRejectSubmission,
  useAllSubmissionList,
} from "pages/hooks";
import { AllSubmissionsListModal } from "api/resources/modals";
import Spinner from "components/spinner/Spinner";
import { themeColors } from "core/Theming/theme";
import { AllSubmissionsModal } from "./Components/Modal";
import SmartFilters from "./Components/Smartfilter";
import SearchIcon from "@mui/icons-material/Search";
import ReusableTable from "components/reusableFIlterTable/ReusableTable";
import { ROUTES } from "core/routes";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { RootState, useAppDispatch, useAppSelector } from "core/store";
import { setView } from "core/store/auth";

interface StatusProps {
  status: string;
}

const Allsubmissions: React.FC = () => {
  const userInfo = useSelector((state: RootState) => state.auth?.userInfo);
  const isView = useSelector((state: RootState) => state.auth?.isView);
  const empId = userInfo?.user_id;
  const dispatch=useAppDispatch()
  const {
    allSubmissionsList = [],
    isLoading,
    refetch: refetchAllList,
  } = useAllSubmissionList(empId as string);

  const { postAcceptOrRejectSubmissionData } = UsePostAcceptorRejectSubmission({
    onSuccess: () => refetchAllList(),
    onError: () => toast.error(" Please select a single row and try again"),
  });

  const [selectedTab, setSelectedTab] = useState(0);

  const [open, setOpen] = useState(false);

  const [reject, setReject] = useState(false);

  const [rfqId, setRfqId] = useState<string | null>(null);

  const [successMessage, setSuccessMessage] = useState("");

  const [selectionModel, setSelectionModel] = useState<
    AllSubmissionsListModal[]
  >([]);

  const [acceptOpen, setAcceptOpen] = useState(false);

  const [multiAccept, setMultiAccept] = useState<string[]>([]);

  const [rejectOpen, setRejectOpen] = useState(false);

  const [multiReject, setMultiReject] = useState<string[]>([]);
  

  const actionsCellWidth =
    selectedTab === 2 || selectedTab === 3 ? "150px" : "200px";

  const filters = [
    {
      label: "By Employer Name",
      options: [
        { label: "Employer 1", value: "employer1" },
        { label: "Employer 2", value: "employer2" },
        { label: "Manas 2", value: "manas2" },
      ],
    },
    {
      label: "By Skills",
      options: [
        { label: "Skill 1", value: "skill1" },
        { label: "Skill 2", value: "skill2" },
      ],
    },
    {
      label: "Job type",
      options: [
        { label: "Frontend Developer", value: "frontend_developer" },
        { label: "Backend Developer", value: "backend_developer" },
      ],
    },
  ];

  useEffect(() => {
    dispatch(setView(false));
    refetchAllList();
  }, [refetchAllList, selectedTab,dispatch]);

  const headers = [
    {
      title: "Submission ID",
      name: "C2C_Vendor_Bid_Id",
      cellWidth: "230px",
      filterType: "text",
      filter: true,

      customComponent: (row: AllSubmissionsListModal) => {
        const submissionDetailsPath = generatePath(ROUTES.submissionDetails, {
          sumission_id: row?.C2C_Vendor_Bid_Id,
        });
        return (
          <Stacks direction="row" spacing={1} mt={0}>
            <StyledText to={submissionDetailsPath}>
              {row.C2C_Vendor_Bid_Id}
            </StyledText>
          </Stacks>
        );
      },
    },
    {
      title: "Job Title",
      name: "job_title",
      cellWidth: "230px",
      filterType: "text",
      filter: true,
      cellAlign: 'left' as "center" | "left" | "right",

      customComponent: (row: AllSubmissionsListModal) => {
        return (
          <Jobtitle>
            <StyledText to={""}>{row.job_title}</StyledText>
          </Jobtitle>
        );
      },
    },
    {
      title: "Vendor ID",
      name: "vendor_id",
      cellWidth: "230px",
      filterType: "text",
      filter: true,

      customComponent: (row: AllSubmissionsListModal) => {
        return (
          <Stacks direction="row" spacing={1} mt={0}>
            <StyledText to={""}>{row.vendor_id}</StyledText>
          </Stacks>
        );
      },
    },
    {
      title: "Vendor Name",
      name: "vandor_name",
      cellWidth: "200px",
      filterType: "text",
      filter: true,
      cellAlign: 'left' as "center" | "left" | "right",

      customComponent: (row: AllSubmissionsListModal) => {
        return (
          <Jobtype direction="row" spacing={1} mt={0}>
            <Vendorname>{row.vandor_name}</Vendorname>
          </Jobtype>
        );
      },
    },
    ...(selectedTab === 0
      ? [
          {
            title: "Status",
            name: "status",
            cellWidth: "200px",
            filterType: "select",
            filter: true,
            options: ["pending", "accepted", "rejected"],

            customComponent: (row: AllSubmissionsListModal) => {
              return (
                <>
                  {row.status === "pending" ? (
                    <StyledButtonPending>Pending</StyledButtonPending>
                  ) : row.status === "rejected" ? (
                    <StyledButtonRejected>Rejected</StyledButtonRejected>
                  ) : row.status === "accepted" ? (
                    <StyledButtonAccept>Accepted</StyledButtonAccept>
                  ) : (
                    " -"
                  )}
                </>
              );
            },
          },
        ]
      : []),
    {
      title: "Submission Sent On",
      name: "created_at",
      cellWidth: "200px",
      filterType: "date",
      filter: true,
      max: new Date().toISOString().split("T")[0],
    },
    {
      title: "Candidate Visa",
      name: "Visa_Type",
      cellWidth: "200px",
      filterType: "text",
      filter: true,

      customComponent: (row: AllSubmissionsListModal) => {
        const maxSkillsToShow = 1;
        const { Visa_Type = [] } = row;
        const visibleSkills = Visa_Type.slice(0, maxSkillsToShow);
        const hiddenSkills = Visa_Type.slice(maxSkillsToShow);
        return (
          <Stacks direction="row" spacing={1} mt={1} ml={1}>
            {visibleSkills.map((skill, index) => (
              <Tooltip key={index} title={skill}>
                <Skil label={skill} />
              </Tooltip>
            ))}

            {hiddenSkills.length > 0 && (
              <Tooltip title={hiddenSkills.join(", ")}>
                <Skil label="..." />
              </Tooltip>
            )}
          </Stacks>
        );
      },
    },
    {
      title: "Employee Level",
      name: "Employer_Level",
      cellWidth: "200px",
      filterType: "text",
      filter: true,

      customComponent: (row: AllSubmissionsListModal) => {
        return (
          <Stacks direction="row" spacing={1} mt={1}>
            {row.Employer_Level}
          </Stacks>
        );
      },
    },
    {
      title: "Rate card",
      name: "Bill_Rate",
      cellWidth: "190px",
      filterType: "text",
      filter: true,

      customComponent: (row: AllSubmissionsListModal) => {
        return (
          <Stacks direction="row" spacing={1} mt={0}>
            <p>
              {!row.Bill_Rate || row.Bill_Rate === "0-0"
                ? "-"
                : `${row.Bill_Rate}`}
            </p>
          </Stacks>
        );
      },
    },
    {
      title: "Commissions asked",
      name: "comission",
      cellWidth: "200px",
      filterType: "text",
      filter: true,

      customComponent: (row: AllSubmissionsListModal) => {
        return (
          <Stacks direction="row" spacing={1} mt={0}>
            <p>${row?.comission?.commission}</p>
          </Stacks>
        );
      },
    },
    {
      title: "CVs mentioned",
      name: "cvs_mentioned",
      cellWidth: "150px",
      filterType: "text",
      filter: true,

      customComponent: (row: AllSubmissionsListModal) => {
        return (
          <Stacks direction="row" spacing={1} mt={0}>
            <p>{row.cvs_mentioned}</p>
          </Stacks>
        );
      },
    },
    ...(selectedTab === 0 || selectedTab === 1 || selectedTab === 2
      ? [
          {
            title: "CVs Submitted",
            name: "cvs_submitted",
            cellWidth: "150px",
            filterType: "text",
            filter: true,

            customComponent: (row: AllSubmissionsListModal) => {
              return (
                <Stacks direction="row" spacing={1} mt={0}>
                  <p>{row.cvs_submitted}</p>
                </Stacks>
              );
            },
          },
        ]
      : []),
  ];

  const actionItmes = [
    {
      type: "button",
      name: "actions",
      handleClick: (action: string, item: AllSubmissionsListModal) => {},
      customActionComponent: (row: AllSubmissionsListModal) => {
        const submissionDetailsPath = generatePath(ROUTES.submissionDetails, {
          sumission_id: row?.C2C_Vendor_Bid_Id,
        });
        return (
          <>
            {selectedTab === 0 ? (
              <>
                {row?.status === "pending" ? (
                  <Iconcontainer>
                    <LightTooltip
                      textColor={themeColors.primaryAlt}
                      title="CV"
                      arrow
                    >
                      <ViewIcon  />
                    </LightTooltip>
                    <LightTooltip
                      textColor={themeColors.primaryAlt}
                      title="Reject"
                      arrow
                    >
                      <Close
                        onClick={() => handleRejectOpen(row.C2C_Vendor_Bid_Id)}
                      />
                    </LightTooltip>
                    <LightTooltip
                      textColor={themeColors.primaryAlt}
                      title="Accept"
                      arrow
                    >
                      <Accept
                        onClick={() => handleClickOpen(row.C2C_Vendor_Bid_Id)}
                      />
                    </LightTooltip>
                  </Iconcontainer>
                ) : row?.status === "accepted" ? (
                  <Iconcontainer>
                    <LightTooltip
                      textColor={themeColors.primaryAlt}
                      title="CV"
                      arrow
                    >
                      <StyledText to={submissionDetailsPath}>
                        <Viewcv onClick={() => dispatch(setView(!isView))} />
                      </StyledText>
                    </LightTooltip>
                    <LightTooltip
                      textColor={themeColors.primaryAlt}
                      title="Reject"
                      arrow
                    >
                      <Close
                        onClick={() => handleRejectOpen(row.C2C_Vendor_Bid_Id)}
                      />
                    </LightTooltip>
                    <LightTooltip
                      textColor={themeColors.primaryAlt}
                      title="Accept"
                      arrow
                    >
                      <Accepted />
                    </LightTooltip>
                  </Iconcontainer>
                ) : (
                  <Iconcontainer>
                    <LightTooltip
                  textColor={themeColors.primaryAlt}
                  title="Cv"
                  arrow
                >
                  <StyledText to={submissionDetailsPath}>
                    <Viewcv onClick={() => dispatch(setView(!isView))} />
                  </StyledText>
                </LightTooltip>
                    <LightTooltip
                      textColor={themeColors.primaryAlt}
                      title="Reject"
                      arrow
                    >
                      <RejectedIcons />
                    </LightTooltip>
                    <LightTooltip
                      textColor={themeColors.primaryAlt}
                      title="Accept"
                      arrow
                    >
                      <Accept
                        onClick={() => handleClickOpen(row.C2C_Vendor_Bid_Id)}
                      />
                    </LightTooltip>
                  </Iconcontainer>
                )}
              </>
            ) : selectedTab === 1 ? (
              <Iconcontainer>
                <LightTooltip
                  textColor={themeColors.primaryAlt}
                  title="Cv"
                  arrow
                >
                  <StyledText to={submissionDetailsPath}>
                    <Viewcv onClick={() => dispatch(setView(!isView))} />
                  </StyledText>
                </LightTooltip>

                <LightTooltip
                  textColor={themeColors.primaryAlt}
                  title="Close"
                  arrow
                >
                  <Close
                    onClick={() => handleRejectOpen(row.C2C_Vendor_Bid_Id)}
                  />
                </LightTooltip>
              </Iconcontainer>
            ) : selectedTab === 2 ? (
              <Iconcontainer>
                <StyledText to={submissionDetailsPath}>
                  <Viewcv onClick={() => dispatch(setView(!isView))} />
                </StyledText>
                <LightTooltip
                  textColor={themeColors.primaryAlt}
                  title="Accept"
                  arrow
                >
                  <AcceptsIcon
                    onClick={() => handleClickOpen(row.C2C_Vendor_Bid_Id)}
                  />
                </LightTooltip>
              </Iconcontainer>
            ) : (
              <Iconcontainer>
                <LightTooltip
                  textColor={themeColors.primaryAlt}
                  title="Reject"
                  arrow
                >
                  <Close
                    onClick={() => handleRejectOpen(row.C2C_Vendor_Bid_Id)}
                  />
                </LightTooltip>
                <LightTooltip
                  textColor={themeColors.primaryAlt}
                  title="Accept"
                  arrow
                >
                  <Accept
                    onClick={() => handleClickOpen(row.C2C_Vendor_Bid_Id)}
                  />
                </LightTooltip>
              </Iconcontainer>
            )}
          </>
        );
      },
    },
  ];

  const handleAcceptClick = () => {
    const Ids = selectionModel.map((id) => id.C2C_Vendor_Bid_Id);
    if (Ids.length > 0) {
      setMultiAccept(Ids);
      setAcceptOpen(true);
    }
  };

  const handleMultiAccept = () => {
    setAcceptOpen(false);
    setMultiAccept([]);
  };

  const handleMultiAcceptRows = () => {
    if (multiAccept.length > 0) {
      multiAccept.forEach((vendor_id) =>
        postAcceptOrRejectSubmissionData({
          submmissionID: vendor_id,
          status: "accepted",
        })
      );

      setSuccessMessage("Accepted Submissions Successfully!");
      handleMultiAccept();
      toast.success("Accepted Submissions Successfully!");
    }
  };

  const handleRejectClick = () => {
    const Ids = selectionModel.map((id) => id.C2C_Vendor_Bid_Id);
    if (Ids.length > 0) {
      setMultiReject(Ids);
      setRejectOpen(true);
    }
  };

  const handleMultiReject = () => {
    setRejectOpen(false);
    setMultiReject([]);
  };

  const handleMultiRejectRows = () => {
    if (multiReject.length > 0) {
      multiReject.forEach((vendor_id) =>
        postAcceptOrRejectSubmissionData({
          submmissionID: vendor_id,
          status: "rejected",
        })
      );
      setSuccessMessage("Rejected Submissions Successfully!");
      handleMultiReject();
      toast.success("Rejected Submissions Successfully!");
    }
  };

  const handleApplyFilters = (
    selectedFilters: Record<string, string | number>
  ) => {
    selectedFilters;
  };

  const handleRejectOpen = (rfqId: string) => {
    setRfqId(rfqId);
    setReject(true);
  };

  const handleReject = () => {
    if (rfqId) {
      postAcceptOrRejectSubmissionData({
        submmissionID: rfqId,
        status: "rejected",
      });

      setSuccessMessage("Rejected Submissions Successfully!");
      handleRejectClose();
      toast.success("Rejected Submissions Successfully!");
    }
  };

  const handleRejectClose = () => {
    setRfqId(null);
    setReject(false);
  };

  const handleClickOpen = (rfqId: string) => {
    setRfqId(rfqId);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setRfqId(null);
  };
  const handleAccept = () => {
    if (rfqId) {
      postAcceptOrRejectSubmissionData({
        submmissionID: rfqId,
        status: "accepted",
      });
      setSuccessMessage("Accepted Submissions Successfully!");
      handleClose();
      toast.success("Accepted Submissions Successfully!");
    }
  };

  const jobsList = selectedTab === 0 ? allSubmissionsList : allSubmissionsList;
  const jobsData: AllSubmissionsListModal[] = Array.isArray(jobsList)
    ? jobsList
    : [];

  const filteredJobs = Array.isArray(jobsList)
    ? jobsList.filter((job: { status: string }) => {
        switch (selectedTab) {
          case 1:
            return job.status === "accepted";
          case 2:
            return job.status === "rejected";
          case 3:
            return job.status === "pending";
          default:
            return true;
        }
      })
    : [];

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setSelectedTab(newValue);
  };

  const handleDoBulk = (selectedItems: AllSubmissionsListModal[]) => {
    setSelectionModel(selectedItems);
  };

  if (isLoading) {
    return <Spinner show={true} />;
  }

  return (
    <MainContent>
      <Headingcontainer>
        <Headingtesx variant="h4">
          <HeadingIcon />
          All Submissions
        </Headingtesx>
      </Headingcontainer>
      <Middlecontainer>
        <MainTabs>
          <MainGridbox>
            <MainAppbar position="static">
              <Tabs
                value={selectedTab}
                onChange={handleChange}
                aria-label="job tabs"
              >
                <Tab label="All Submissions" />
                <Tab label="Accepted Submissions" />
                <Tab label="Rejected Submissions" />
                <Tab label="Pending Submissions" />
              </Tabs>
            </MainAppbar>
            <MainGridcontainer>
              <LeftsiteContainer>
                <Leftsitecontent>
                  <LeftcontentText>
                    {selectionModel.length} of {filteredJobs.length} Selected
                  </LeftcontentText>
                  {selectedTab === 2 ? (
                    ""
                  ) : (
                    <LightTooltip
                      textColor={themeColors.primaryAlt}
                      title="Reject"
                      arrow
                      onClick={handleRejectClick}
                    >
                      <Close />
                    </LightTooltip>
                  )}
                  {selectedTab === 1 ? (
                    ""
                  ) : (
                    <LightTooltip
                      textColor={themeColors.primaryAlt}
                      title="Accept"
                      arrow
                    >
                      <Accept onClick={handleAcceptClick} />
                    </LightTooltip>
                  )}
                </Leftsitecontent>
                <RightSiteICon>
                  {/* <Search id="search">
                    <SearchIconWrapper>
                      <SearchLogo />
                    </SearchIconWrapper>
                    <StyledInputBase
                      placeholder="Search…"
                      inputProps={{ "aria-label": "search" }}
                    />
                  </Search>
                  <SmartFilters
                    filters={filters}
                    buttonLabel="Smart Filters"
                    onApply={handleApplyFilters}
                  />
                  <DashboardsIcon />
                  <Dashboard /> */}
                </RightSiteICon>
              </LeftsiteContainer>
              <Maingridtable>
                <CustomTable>
                  <ReusableTable
                    headers={headers}
                    data={filteredJobs}
                    itemsPerPage={10}
                    spinner={isLoading}
                    handleDoBulk={handleDoBulk}
                    checkBox={true}
                    headerColor={themeColors.primaryLight}
                    actions={actionItmes}
                    actionsCellWidth={actionsCellWidth}
                    inputBorder={themeColors.primaryDark}
                  />
                </CustomTable>
              </Maingridtable>
            </MainGridcontainer>
          </MainGridbox>
        </MainTabs>
      </Middlecontainer>
      <AllSubmissionsModal
        open={acceptOpen}
        handleClose={handleMultiAccept}
        handleConfirmDelete={handleMultiAcceptRows}
        Text={`Do you Want to Accept the Submission  for ${selectionModel.length} `}
        jobTitle={
          jobsData.find((job) => job.C2C_Vendor_Bid_Id === rfqId)?.job_title ||
          ""
        }
      />

      <AllSubmissionsModal
        open={rejectOpen}
        handleClose={handleMultiReject}
        handleConfirmDelete={handleMultiRejectRows}
        Text={`Do you Want to Reject the Submission  for ${selectionModel.length} `}
        jobTitle={
          jobsData.find((job) => job.C2C_Vendor_Bid_Id === rfqId)?.job_title ||
          ""
        }
      />
      <AllSubmissionsModal
        open={open}
        handleClose={handleClose}
        handleConfirmDelete={handleAccept}
        Text={"Do you Want to Accept the Submission  for "}
        jobTitle={
          jobsData.find((job) => job.C2C_Vendor_Bid_Id === rfqId)?.job_title ||
          ""
        }
      />
      <AllSubmissionsModal
        open={reject}
        handleClose={handleRejectClose}
        handleConfirmDelete={handleReject}
        Text={"Do you Want to Reject the Submission  for "}
        jobTitle={
          jobsData.find((job) => job.C2C_Vendor_Bid_Id === rfqId)?.job_title ||
          ""
        }
      />
      {successMessage && (
        <Modal
          open={!!successMessage}
          onClose={() => setSuccessMessage("")}
          aria-labelledby="success-modal-title"
          aria-describedby="success-modal-description"
        >
          <Box sx={{ ...modalStyle }}>
            <Modalheader>
              <Closeicon onClick={() => setSuccessMessage("")} />
            </Modalheader>
            <CenteredColumn>
              <Questionmark>
                <Menu />
              </Questionmark>
              <CenteredTypography id="success-modal-description" variant="h3">
                {successMessage}
              </CenteredTypography>
            </CenteredColumn>
          </Box>
        </Modal>
      )}
    </MainContent>
  );
};

const StatusText = styled(`p`)<StatusProps>`
  text-transform: capitalize;
  color: ${(props: StatusProps) => {
    switch (props.status) {
      case "accepted":
        return `${themeColors.lightGreen}`;
      case "pending":
        return `${themeColors.primaryDark}`;
      case "rejected":
        return `${themeColors.error}`;
      default:
        return "inherit";
    }
  }};
`;
const RejectedIcons = styled(Reject)({
  margin: "0px 16px",
});

const Stacks = styled(Stack)({
  alignItems: "center",
  display: "flex",
  justifyContent: "center",
});

const Jobtype = styled(Stack)({
  alignItems: "center",
  display: "flex",
  justifyContent: "left",
  marginLeft:'15px'
});
const CustomTable = styled(Box)({
  "& .MuiTableBody-root ": {
    "& .MuiTableCell-root": {
      padding: "8px !important",
    },
  },
  marginTop: "10px",
  overflow: "hidden",
});

export const Skil = styled(Chip)({
  background: themeColors.primaryLight,
  color: themeColors.primaryDark,
  fontWeight: "500",
});
const SearchLogo = styled(SearchIcon)({
  color: themeColors.primaryDark,
});

const DashboardsIcon = styled(DashboardIcon)({
  marginTop: "5px",
});

const Dashboard = styled(DashboardIcontwo)({
  marginTop: "5px",
});

export const StyledButtonAccept = styled(Button)({
  color: themeColors.success,
  width: "102px",
  borderRadius: "30px",
  backgroundColor: themeColors.successLight,
  border: "none",
  "&:hover": {
    backgroundColor: themeColors.successLight,
    color: themeColors.success,
    border: "none",
  },
  fontSize: "12px",
  marginLeft: "16px",
});

export const StyledButtonPending = styled(Button)({
  color: themeColors.darkYellow,
  width: "102px",
  borderRadius: "30px",
  backgroundColor: themeColors.lightYellow,
  border: "none",
  "&:hover": {
    backgroundColor: themeColors.lightYellow,
    color: themeColors.darkYellow,
    border: "none",
  },
  fontSize: "12px",
  marginLeft: "16px",
});

export const StyledButtonRejected = styled(Button)({
  color: themeColors.darkRed,
  width: "102px",
  borderRadius: "30px",
  backgroundColor: themeColors.lightRed,
  border: "none",
  "&:hover": {
    backgroundColor: themeColors.lightRed,
    color: themeColors.darkRed,
    border: "none",
  },
  fontSize: "12px",
  marginLeft: "16px",
});

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  color: themeColors.black,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  marginRight: "0px",
  marginLeft: 0,
  width: "100%",
  background: themeColors.white,
  color: themeColors.black,
  marginTop: "10px",

  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(3),
    width: "200px",
    borderRadius: "30px",
    border: `1px solid ${themeColors.primaryLightDark}`,
  },
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1.5, 1, 1, 0),
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      width: "12ch",
      "&:focus": {
        width: "20ch",
      },
    },
  },
}));

const Menu = styled(Menuarrow)({
  height: "80px",
  width: "80px",
  cursor: "pointer",
});

const Accept = styled(AceeptIcon)({
  cursor: "pointer",
});

const AcceptsIcon = styled(AceeptIcon)({
  cursor: "pointer",
  marginLeft: "16px",
});

const Cvs = styled(Cvicon)({
  marginLeft: "16px",
});
const CenteredTypography = styled(Typography)({
  marginTop: "20px",
  alignItems: "center",
  textAlign: "center",
  color: themeColors.grey,
});

const MainTabs = styled(Box)({
  width: "100%",
});

const Questionmark = styled(`div`)({
  display: "flex",
  justifyContent: "center",
});

const Viewcv = styled(Cvicon)({
  cursor: "pointer",
});

const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  bgcolor: "background.paper",
  boxShadow: 24,
  borderRadius: "15px",
  height: "304px",
};

const Modalheader = styled(Container)({
  display: "flex",
  justifyContent: "flex-end",
  backgroundColor: themeColors.primaryLight,
  Padding: "8px",
  borderTopLeftRadius: "14px",
  borderTopRightRadius: "14px",
  borderBottomRadius: "3px",
  padding: "8px",
});

const Iconcontainer = styled(`div`)({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  textAlign: "center",
  padding: "10px",
});

const CenteredColumn = styled(`div`)({
  display: "flex",
  justifyContent: "center",
  flexDirection: "column",
  padding: "30px",
});

const Closeicon = styled(Closeiconmodal)({
  height: "30px",
  width: "30px",
  cursor: "pointer",
});

const Chat = styled(ChatIcon)({
  marginRight: "16px",
  cursor: "pointer",
});
const Filter = styled(Filtericon)({
  marginTop: "14px",
  cursor: "pointer",
});

const Close = styled(Closecheck)({
  margin: "0px 16px",
  cursor: "pointer",
});

export const StyledButtonAccepted = styled(Button)({
  color: themeColors.success,
  padding: "5px 20px",
  borderRadius: "8px",
  backgroundColor: themeColors.successLight,
  textDecoration: "underline",
  border: "none",
  "&:hover": {
    backgroundColor: themeColors.successLight,
    color: themeColors.success,
    border: "none",
  },
  marginLeft: "16px",
});

const Jobtitle = styled(Box)({
  display: "flex",
  justifyContent: "flex-start",
  alignItems: "center",
  textAlign: "center",
  marginLeft:'15px'
});

export const StyledText = styled(Link)({
  color: themeColors.primaryDark,
  textDecoration: "underline",
  cursor: "pointer",
  textTransform: "capitalize",
});

const Vendorname = styled(`p`)({
  textTransform: "capitalize",
});

const LeftcontentText = styled(`p`)({
  marginLeft: "15px",
  color: themeColors.mediumDark,
  marginRight: "15px",
});
const LeftsiteContainer = styled(Box)({
  margin: "0px 7px",
  display: "flex",
  alignItems: "center",
  paddingTop: "6px",
});
const Leftsitecontent = styled(Box)({
  display: "flex",
  justifyContent: "flex-start",
  alignItems: "center",
  width: "100%",
  height: "42px",
});
const RightSiteICon = styled(Box)({
  display: "flex",
  flexDirection: "row",
  justifyContent: "center",
  gap: "15px",
});
const MainGridcontainer = styled(Box)({
  border: `1px solid ${themeColors.primaryLightDark}  !important`,
  borderRadius: "8px",
  borderBottomRightRadius: "1px",
  borderBottomLeftRadius: "1px",
  boxShadow: `0px 0px 4px 0px  ${themeColors.shadowColor}`,
});

const Maingridtable = styled(Box)({
  display: "grid",
  gridAutoFlow: "column",
  gridAutoColumns: "100%",
});

const MainGridbox = styled(Box)({
  width: "100%",
  marginTop: "16px",
});

const MainAppbar = styled(AppBar)({
  background: "none",
  width: "870px",
  boxShadow: "none",
  fontWeight: "400",
  "& .MuiTabs-flexContainer ": {
    display: "flex",
    justifyContent: "flex-start",
  },
  marginLeft: "65px",
});
const Middlecontainer = styled(Box)({
  display: "flex",
  justifyContent: "center",
});
const MainContent = styled(Box)({
  width: "100%",
  padding: "20px",
});
const Headingcontainer = styled(Box)({
  display: "flex",
  justifyContent: "space-between",
  padding: "16px 20px",
  border: `1px solid ${themeColors.emptyStar}`,
  borderRadius: "8px",
  marginBottom: "16px",
  background: themeColors.primaryLight,
});
const Headingtesx = styled(Typography)({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
});
const HeadingIcon = styled(jobsIcon)({
  marginRight: "10px",
  color: themeColors.primary,
});

interface LightTooltipProps extends TooltipProps {
  textColor?: string;
}
const LightTooltip = styled(
  ({
    className,
    textColor = themeColors.grey,
    ...props
  }: LightTooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
  )
)(({ theme, textColor }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    borderRadius: "6px",
    color: textColor,
    boxShadow: `0 0 0.45rem ${themeColors.primaryAlt}`,
    fontSize: 12,
  },
  [`& .MuiTooltip-arrow`]: {
    color: theme.palette.common.white,
  },
}));

export default Allsubmissions;
