import React, { useEffect, useMemo, useRef, useState } from "react";
import { Formik, Field, ErrorMessage, FormikHelpers, Form } from "formik";
import "./customstyles.css";
import {
  TextField,
  Button,
  MenuItem,
  Select,
  FormControl,
  Box,
  Typography,
  Grid,
  Slider,
  RadioGroup,
  FormControlLabel,
  Radio,
  Stack,
  TextareaAutosize,
  FormHelperText,
  Chip,
  Tooltip,
  Autocomplete,
} from "@mui/material";
import { styled } from "@mui/system";
import { EditRFRModal, EditRFRResponseModal } from "api/resources/modals";
import DropdownSelector from "components/Multiselectdropdowns";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs, { Dayjs } from "dayjs";
import { ReactComponent as SaveIcon } from "../../../assets/images/save.svg";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import {
  editRfrallDetails,
  useEditRFR,
  useFetchRFqEmployeeLevel,
  useFetchRFqjobType,
  useFetchRfqpositon,
  useFetchRFQqualification,
  useFetchRFQsecodaryskills,
  useFetchRFRJobCategory,
  useFetchUserLocations,
  useFetchVisaType,
} from "pages/hooks";
import FileUploadComponent from "../Components/UploadFileField/Index";
import { marks, validationSchema } from "./constants";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { RootState, useAppSelector } from "core/store";
import { useNavigate, useParams } from "react-router-dom";
import Nestedmenumultiselect from "components/Multiselectnestedmenudropdowns";
import EditModal from "../Components/Modals/Editrfrdetailsmodal";
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import { themeColors } from "core/Theming/theme";

const EditRFRForm = () => {
  const { isVendor } = useAppSelector((store) => store.auth);
  const navigate = useNavigate();
  const userInfo = useSelector((state: RootState) => state.auth?.userInfo);
  const { "rfq-id": rfqId } = useParams();
  dayjs.extend(utc);
  dayjs.extend(timezone);
  const { editRfrDetails, refetch: refetchEditRFRDetails } = useEditRFR(
    rfqId as string
  ) as unknown as {
    editRfrDetails: EditRFRResponseModal;
    refetch: () => void;
  };

  const userId = userInfo?.user_id ?? "";
  const { levels = [], isLoading: loadingLevels } = useFetchRFqEmployeeLevel();
  const { secondaryskills = [], isLoading: loadingSkills } =
    useFetchRFQsecodaryskills();
  const { positions = [], isLoading: loadingPositions } = useFetchRfqpositon();
  const { qualifications = [], isLoading: loadingQualifications } =
    useFetchRFQqualification();
  const { jobs = [], isLoading: loadingJobs } = useFetchRFqjobType();
  const { jobCategory = [], isLoading: loadingJobCategories } =
    useFetchRFRJobCategory();
  const { visaType = [], isLoading: loadingVistype } = useFetchVisaType();

  const [editRFR, setEditRFR] = useState<boolean>(false);
  const handleEditClose = () => setEditRFR(false);

  const { postEditRfrDetails } = editRfrallDetails({
    onSuccess: () => {
      toast.success("Submitted Successfully");
      setEditRFR(true);
    },
    onError: () => toast.error("Something went wrong, please try again"),
  });

  const { locations = [], isLoading: locationsLoading } =
    useFetchUserLocations();

  const [showMenu, setShowMenu] = useState(false);
  const [showAlert, setShowAlert] = useState(false)

  const textFieldRef = useRef<HTMLDivElement>(null);

  const [initialValues, setInitialValues] = useState<EditRFRModal>({
    rfqId: "",
    userId: "",
    jobTitle: "",
    noOfPositions: 0,
    Locations: [],
    jobCategory: "",
    Qualifications: [],
    primarySkills: [],
    secondarySkills: [],
    rfqClosingDate: null,
    jobType: "",
    visaType: [],
    employeeLevel: "",
    fileName: null,
    quickMessageToVendor: "",
    billRateFrom: 0,
    billRateTo: 0,
    commission: 0,
    chargeType: "",
    hourly: false,
    weekly: false,
    monthly: false,
    initialExperience: 0,
    maxExperience: 5,
    jobDescriptionText: ""
  });
  const [chargeType, setChargeType] = useState({
    type: "",
    hourly: false,
    weekly: false,
    monthly: false,
  });

  const [experienceRange, setExperienceRange] = useState<[number, number]>([
    initialValues.initialExperience,
    initialValues.maxExperience,
  ]);

  const [jdFileName, setFileName] = useState(null);
  useEffect(() => {
    if (editRfrDetails?.result1?.rfq_details) {
      const chargeTypeObject = editRfrDetails.result1.rfq_details.rate_info.chargeType || {};
      const chargeType = chargeTypeObject.hourly
        ? "hourly"
        : chargeTypeObject.weekly
          ? "weekly"
          : chargeTypeObject.monthly
            ? "monthly"
            : "";

      const experienceArray = editRfrDetails.result1.rfq_details.experience || [];
      const [initialExperience = 0, maxExperience = 5] = experienceArray[0]?.split(",").map(Number) || [];

      setInitialValues(prevValues => ({
        ...prevValues,
        userId: editRfrDetails.result1.rfq_details.user_id || "",
        jobTitle: editRfrDetails.result1.rfq_details.job_title || "",
        noOfPositions: editRfrDetails.result1.rfq_details.no_of_positions || 0,
        Locations: editRfrDetails.result1.rfq_details.locations || [],
        jobCategory: editRfrDetails.result1.rfq_details.job_category || "",
        Qualifications: editRfrDetails.result1.rfq_details.qualifications || [],
        primarySkills: editRfrDetails.result1.rfq_details.primary_skills || [],
        secondarySkills: editRfrDetails.result1.rfq_details.secondary_skills || [],
        rfqClosingDate: editRfrDetails.result1.rfq_details.rfq_closing_date || null,
        jobType: editRfrDetails.result1.rfq_details.job_type || "",
        visaType: editRfrDetails.result1.rfq_details.visa_type || "",
        employeeLevel: editRfrDetails.result1.rfq_details.employee_level || "",
        quickMessageToVendor: editRfrDetails.result1.rfq_details.quick_message_to_vendor || "",
        billRateFrom: editRfrDetails.rateInfo?.billRateFrom || 0,
        billRateTo: editRfrDetails.rateInfo?.billRateTo || 0,
        commission: editRfrDetails.result1.rfq_details.rate_info.commission || 0,
        jobDescriptionText: editRfrDetails.result1.rfq_details.jobDescriptionText || "",
        initialExperience,
        maxExperience,
      }));
      setChargeType({
        type: chargeType,
        hourly: chargeTypeObject.hourly || false,
        weekly: chargeTypeObject.weekly || false,
        monthly: chargeTypeObject.monthly || false,
      });

      setExperienceRange([initialExperience, maxExperience]);
      setFileName(editRfrDetails.result1.rfq_details.upload_job_description?.url || null);
    }
  }, [editRfrDetails]);


  const uploadedFile =
    editRfrDetails?.result1?.rfq_details?.upload_job_description?.name;

  const handleditSubmit = (values: EditRFRModal, actions: any) => {
    const payload: EditRFRModal = {
      rfqId: rfqId as string,
      jobTitle: values.jobTitle,
      noOfPositions: values.noOfPositions,
      jobCategory: values.jobCategory,
      Locations: values.Locations,
      rfqClosingDate: values.rfqClosingDate,
      primarySkills: values.primarySkills,
      secondarySkills: values.secondarySkills,
      jobType: values.jobType,
      Qualifications: values.Qualifications,
      visaType: values.visaType,
      quickMessageToVendor: values.quickMessageToVendor,
      userId: userId,
      commission: values.commission,
      chargeType: chargeType.type,
      billRateFrom: values.billRateFrom,
      billRateTo: values.billRateTo,
      hourly: chargeType.hourly,
      weekly: chargeType.weekly,
      monthly: chargeType.monthly,
      initialExperience: values.initialExperience,
      maxExperience: values.maxExperience,
      employeeLevel: values.employeeLevel,
      fileName: values.fileName || jdFileName,
      jobDescriptionText: values.jobDescriptionText
    };
    postEditRfrDetails(payload);

  };

  useEffect(() => { }, [
    locationsLoading,
    loadingSkills,
    loadingPositions,
    loadingQualifications,
    loadingJobs,
    loadingVistype,
    loadingLevels,
    loadingJobCategories,
    rfqId,
  ]);

  useEffect(() => {
    refetchEditRFRDetails();
  }, [rfqId]);

  const nestedLocations = useMemo(() => {
    if (!locations.length) return {};
    const result: Record<string, Record<string, Record<string, string[]>>> = {};
    locations.forEach((location) => {
      const { country, state, city, zipCode } = location;
      if (!result[country]) result[country] = {};
      if (!result[country][state]) result[country][state] = {};
      if (!result[country][state][city]) result[country][state][city] = [];
      result[country][state][city].push(zipCode);
    });
    return result;
  }, [locations]);

  const handleClick = () => setShowMenu(true);
  const handleClosed = () => setShowMenu(false);

  const secondaryData = useMemo(() => {
    return secondaryskills.map((secondaryskills) => secondaryskills.skill);
  }, [secondaryskills]);

  const qualificationsData = useMemo(() => {
    return qualifications.map(
      (qualifications) => qualifications.qualificationLevel
    );
  }, [qualifications]);

  const Visatype = useMemo(() => {
    return visaType.map(
      (visatype) => visatype?.Visa
    );
  }, [visaType])
  const handleModelButton = () => {
    navigate(isVendor ? "/vendor/myrequisitions" : "/employer/myrequisitions");
  };

  const getBase64 = (file: File): Promise<string> => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result as string);
      reader.onerror = (error) => reject(error);
    });
  };
  return (
    <>
      <Formik
        initialValues={initialValues}
        onSubmit={handleditSubmit}
        validationSchema={validationSchema}
        enableReinitialize={true}
      >
        {({
          values,
          errors,
          touched,
          setFieldValue,
          handleSubmit,
          resetForm,
        }) => (
          <StyledForm onSubmit={handleSubmit}>
            <MainBox>
              <Headingcontainer>
                <Headingtesx variant="h4" color="primary">
                  <PreviousIcon
                    onClick={() =>
                      navigate(
                        isVendor
                          ? "/vendor/myrequisitions"
                          : "/employer/myrequisitions"
                      )
                    }
                  />
                  Edit Requisitions
                </Headingtesx>
              </Headingcontainer>
              <FormContent>
                <Box mt={2}>
                  <Grid container spacing={2}>
                    <Grid item md={12}>
                      <UploadContainer>
                        <StyledLabelName>Upload Job Description</StyledLabelName>
                        {/* <Button variant="outlined">Use Role Mapper</Button> */}
                      </UploadContainer>

                      <StyledJdContainer>
                        <FileuploadBox>
                          <FileUploadComponent
                            onFileChange={async (file: File | null) => {
                              if (file) {
                                const base64File = await getBase64(file);
                                setFieldValue("fileName", base64File);
                              }
                            }}
                            disabled={values.jobDescriptionText !== ""}
                            defaultPdfUrl={uploadedFile}
                          />
                        </FileuploadBox>
                        <Typography sx={{ marginRight: "16px", marginLeft: "16px" }}>(or)</Typography>

                        <StyledTextareaAutosize
                          value={values?.jobDescriptionText}
                          disabled={values?.fileName !== null || jdFileName !== null}
                          onChange={(e) => {
                            setFieldValue("jobDescriptionText", e.target.value)
                            setShowAlert(true)
                          }}
                          rows={9}
                        />
                      </StyledJdContainer>
                      <ErrorMessages name="jobDescriptionText" component="div" />
                      {jdFileName === null ? (
                        <>
                          {values.fileName === null ? (
                            <TextErrorp >{!showAlert && !values.jobDescriptionText ? "please upload jd file or Text" : ""}</TextErrorp>
                          ) : (
                            ""
                          )}
                        </>
                      ) : (
                        ""
                      )}
                    </Grid>

                    <Grid item md={6}>
                      <StyledLabelName>Job Title<RedIcon>*</RedIcon></StyledLabelName>
                      <FormControl fullWidth margin="none">
                        <Field
                          as={TextField}
                          name="jobTitle"
                          variant="outlined"
                          error={touched.jobTitle && Boolean(errors.jobTitle)}
                        />

                        <ErrorMessages name="jobTitle" component="div" />
                      </FormControl>
                    </Grid>
                    <Grid item md={6}>
                      <StyledLabelName>Experience<RedIcon>*</RedIcon></StyledLabelName>
                      <StyledStack>
                        <Slider
                          getAriaValueText={(value) => `${value}`}
                          value={experienceRange} // Use 'experienceRange' state
                          valueLabelDisplay="auto"
                          marks={marks}
                          max={20}
                          onChange={(_, value) => {
                            if (Array.isArray(value)) {
                              setExperienceRange(value as [number, number]); // Update local state
                              setFieldValue("initialExperience", value[0]);
                              setFieldValue("maxExperience", value[1]);
                            }
                          }}
                        />
                      </StyledStack>
                    </Grid>

                    <Grid item md={6}>
                      <DropdownSelector
                        name="visaType"
                        label="Visa Type"
                        options={Visatype}
                        placeholder="select or type and enter visaType"
                        required
                      />
                    </Grid>

                    <Grid item md={6}>
                      <StyledLabelName>No of Positions<RedIcon>*</RedIcon></StyledLabelName>
                      <FormControl fullWidth margin="none">
                        <Field
                          as={TextField}
                          type="number"
                          inputProps={{ min: 1 }}
                          name="noOfPositions"
                          variant="outlined"
                          error={touched.noOfPositions && Boolean(errors.noOfPositions)}
                          onInput={(e: { target: { value: number; }; }) => {
                            if (e.target.value < 0) e.target.value = 0;
                          }}
                        />

                        <ErrorMessages name="noOfPositions" component="div" />
                      </FormControl>
                    </Grid>
                    <Grid item md={6}>
                      <DropdownSelector
                        name="Qualifications"
                        label="Qualification(s)"
                        options={qualificationsData}
                        placeholder="select or type and enter Qualifications"
                        required
                      />
                    </Grid>
                    <Grid item md={6}>
                      <FormControl fullWidth margin="none" required>
                        <StyledLabelName>Location<RedIcon>*</RedIcon></StyledLabelName>
                        <TextField
                          fullWidth
                          value={values.Locations.join(", ")}
                          onClick={handleClick}
                          inputRef={textFieldRef}
                          InputProps={{
                            readOnly: true,
                            style: { color: "transparent" },
                          }}
                        />
                        <Dropdownstyle>
                          {values.Locations.map((location, index) => (
                            <Tooltip key={index} title={location}>
                              <Chip
                                key={index}
                                label={location}
                                onDelete={() => {
                                  const updatedLocations =
                                    values.Locations.filter(
                                      (loc) => loc !== location
                                    );
                                  setFieldValue("Locations", updatedLocations);
                                }}
                              />
                            </Tooltip>
                          ))}
                        </Dropdownstyle>
                        {showMenu && (
                          <Nestedmenumultiselect
                            data={nestedLocations}
                            formik={{ values, setFieldValue }}
                            anchorEl={textFieldRef}
                            onClose={handleClosed}
                          />
                        )}
                        <ErrorMessages name="Locations" component="div" />
                      </FormControl>
                    </Grid>
                    <Grid item md={6}>
                      <DropdownSelector
                        name="primarySkills"
                        label="Primary Skill(s)"
                        options={secondaryData}
                        placeholder="select or type and enter Primary Skill(s)"
                        required
                      />
                    </Grid>
                    <Grid item md={6}>
                      <DropdownSelector
                        name="secondarySkills"
                        label="Secondary Skill(s)"
                        options={secondaryData}
                        placeholder="select or type and enter Secondary Skill(s)"
                        required
                      />
                    </Grid>
                    <Grid item md={6}>
                      <Typography>RFR Closing Date<RedIcon>*</RedIcon></Typography>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DemoContainer components={["DatePicker"]}>
                          <DatePicker
                            value={
                              values.rfqClosingDate
                                ? dayjs(values.rfqClosingDate).local()
                                : dayjs().local()
                            }
                            onChange={(newValue: Dayjs | null) =>
                              setFieldValue("rfqClosingDate", newValue ? newValue.local().format('YYYY-MM-DD') : null)
                            }
                          />
                        </DemoContainer>
                      </LocalizationProvider>
                      <FormHelperText error={Boolean(errors.rfqClosingDate)}>
                        <ErrorMessage name="rfqClosingDate" />
                      </FormHelperText>
                    </Grid>

                    <Grid item md={6}>
                      <StyledLabelName>
                        Job Type <RedIcon>*</RedIcon>
                      </StyledLabelName>
                      <FormControl fullWidth margin="none">
                        <Autocomplete
                          freeSolo
                          options={jobs.map((job) => job.contractType)}
                          value={values.jobType}
                          onChange={(event, newValue) => {
                            setFieldValue("jobType", newValue);
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              onChange={(e) => setFieldValue("jobType", e.target.value)}
                              label="Job Type"
                            />
                          )}
                        />
                        <ErrorMessages name="jobType" component="div" />
                      </FormControl>
                    </Grid>

                    <Grid item md={6}>
                      <StyledLabelName>
                        Job Category <RedIcon>*</RedIcon>
                      </StyledLabelName>
                      <FormControl fullWidth margin="none">
                        <Autocomplete
                          freeSolo
                          options={jobCategory.map((job) => job.jobCategory)}
                          value={values.jobCategory}
                          onChange={(event, newValue) => {
                            setFieldValue("jobCategory", newValue);
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              onChange={(e) => setFieldValue("jobCategory", e.target.value)}
                              label="Job Category"
                            />
                          )}
                        />
                        <ErrorMessages name="jobCategory" component="div" />
                      </FormControl>
                    </Grid>

                    <Grid item md={6}>
                      <StyledLabelName>
                        Employee Level <RedIcon>*</RedIcon>
                      </StyledLabelName>
                      <FormControl fullWidth margin="none">
                        <Autocomplete
                          freeSolo
                          options={levels.map((level) => level.level)}
                          value={values.employeeLevel}
                          onChange={(event, newValue) => {
                            setFieldValue("employeeLevel", newValue);
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              onChange={(e) => setFieldValue("employeeLevel", e.target.value)}
                              label="Employee Level"
                            />
                          )}
                        />
                        <ErrorMessages name="employeeLevel" component="div" />
                      </FormControl>
                    </Grid>

                  </Grid>
                  <Grid mt={3.5}>
                    <Typography variant="h6" mb={1}>
                      Rate Info
                    </Typography>
                    <RateInfoGrid container>
                      <Grid item md={12}>
                        <Box display={"flex"}>
                          <StyledLabelName>Charge Type<RedIcon>*</RedIcon>:</StyledLabelName>
                          <StyledFormControl>
                            <RadioGroup
                              row
                              aria-labelledby="demo-row-radio-buttons-group-label"
                              name="row-radio-buttons-group"
                              value={initialValues.chargeType}
                              onChange={(e) => {
                                const selectedValue = e.target.value;
                                setChargeType({
                                  type: selectedValue,
                                  hourly: selectedValue === "hourly",
                                  weekly: selectedValue === "weekly",
                                  monthly: selectedValue === "monthly",
                                });
                              }}
                            >
                              <FormControlLabel
                                value="hourly"
                                control={<Radio checked={chargeType.type === "hourly"} />}
                                label="Hourly Rate"
                              />
                              <FormControlLabel
                                value="weekly"
                                control={<Radio checked={chargeType.type === "weekly"} />}
                                label="Weekly Rate"
                              />
                              <FormControlLabel
                                value="monthly"
                                control={<Radio checked={chargeType.type === "monthly"} />}
                                label="Monthly Rate"
                              />
                            </RadioGroup>
                            {
                              chargeType.type === "" ? <>
                                <TextErrorp>Please select chargeType</TextErrorp>
                              </> : ""
                            }
                          </StyledFormControl>
                        </Box>
                      </Grid>
                      <Grid item md={6}>
                        <FullDiv>
                          <CenteredTypography mb={1}>
                            Bill Rate<RedIcon>*</RedIcon> :{" "}
                          </CenteredTypography>
                          <StyledTextField
                            as={TextField}
                            type="number"
                            variant="outlined"
                            value={values?.billRateFrom}
                            InputProps={{ inputProps: { min: 0 } }}
                            onChange={(e) =>
                              setFieldValue("billRateFrom", e.target.value)
                            }
                          />
                          <CenteredTypography>to</CenteredTypography>

                          <StyledTextField
                            as={TextField}
                            type="number"
                            variant="outlined"
                            value={values?.billRateTo}
                            InputProps={{ inputProps: { min: 0 } }}
                            onChange={(e) =>
                              setFieldValue("billRateTo", e.target.value)
                            }
                          />
                          <CenteredTypography>in USD</CenteredTypography>
                          <FormHelperText
                            error={Boolean(
                              touched.billRateFrom && errors.billRateFrom
                            )}
                          >
                            <ErrorMessage name="billRateFrom" component="div" />
                          </FormHelperText>
                          <FormHelperText
                            error={Boolean(
                              touched.billRateFrom && errors.billRateTo
                            )}
                          >
                            <ErrorMessage name="billRateTo" component="div" />
                          </FormHelperText>
                        </FullDiv>
                      </Grid>
                      <Grid item md={6}>
                        <FullDiv>
                          <CenteredTypography>Commission </CenteredTypography>
                          <StyledTextField
                            type="number"
                            variant="outlined"
                            value={values?.commission}
                            InputProps={{ inputProps: { min: 0 } }}
                            onChange={(e) =>
                              setFieldValue("commission", e.target.value)
                            }
                          />
                          <CenteredTypography>in USD</CenteredTypography>
                        </FullDiv>
                      </Grid>
                    </RateInfoGrid>
                  </Grid>
                  <Grid item md={12}>
                    <Box mt={3.25}>
                      <StyledLabelName>Quick message to vendors</StyledLabelName>
                      <StyledTextareaAutosize
                        value={values?.quickMessageToVendor}
                        onChange={(e) =>
                          setFieldValue("quickMessageToVendor", e.target.value)
                        }
                        rows={4}
                      />
                    </Box>
                  </Grid>
                  <Grid item md={12}>
                    <HorizontalLine />
                  </Grid>

                </Box>
                <MainButtonDiv>
                  <StyledButton
                    type="submit"
                    variant="contained"
                    color="primary"
                    onClick={() => handleditSubmit}
                  >
                    Edit Requisition
                  </StyledButton>
                </MainButtonDiv>
              </FormContent>
            </MainBox>
          </StyledForm>
        )}
      </Formik>
      <EditModal
        open={editRFR}
        onButtonClick={() => handleModelButton()}
        handleEditClose={handleEditClose}
      />
    </>
  );
};

const RedIcon = styled(`span`)({
  color: themeColors.error
})

const TextErrorp = styled(`p`)({
  color: "red"
})
const PreviousIcon = styled(ArrowBackIcon)({
  marginRight: "16px",
  cursor: "pointer",
});
const Headingtesx = styled(Typography)({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
});
const Headingcontainer = styled(Box)({
  display: "flex",
  justifyContent: "space-between",
  padding: "10px 20px",
  border: `1px solid ${themeColors.badgeGray}`,
  borderRadius: "8px",
  marginBottom: "16px",
  background: themeColors.primaryLight,
});

const Dropdownstyle = styled(Box)({
  marginTop: "18px",
  width: "100%",
  "& .MuiChip-root": {
    width: "173px",
    borderRadius: " 5px",
    marginRight: "5px",
  },
});

const TextName = styled(`p`)({
  display: "flex",
  fontWeight: "500",
  color: themeColors.black,
  marginTop: "0px",
});

const StyledButton = styled(Button)({
  width: "auto",
  height: "38px",
  borderRadius: "12px",
});

const MainButtonDiv = styled(Box)({
  display: "flex",
  justifyContent: "center",
  marginTop: "48px",
});

const RateInfoGrid = styled(Grid)({
  backgroundColor: themeColors.white,
  borderRadius: "8px",
  padding: "10px",
});

const StyledForm = styled(Form)({
  borderRadius: "8px",
  boxShadow: "0px 0px 4px 0px rgba(77, 108, 217, 0.75)",
  padding: "16px",
});
const UploadContainer = styled(Box)({
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  width: "100%",
});
const StyledFormControl = styled(FormControl)({
  margin: "none",
  marginLeft: "8px",
  marginTop: "-8px",
  marginBottom: "auto",
});
const StyledStack = styled(Stack)({
  width: 500,
  spacing: 2,
  marginTop: "16px",
  flexDirection: "row",
});
const CenteredTypography = styled(Typography)({
  marginInline: "8px",
  alignSelf: "center",
});
const FullDiv = styled("div")({
  display: "flex",
  width: "100%",
});
const StyledTextField = styled(TextField)({
  marginLeft: "8px",
  backgroundColor: "white",
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: themeColors.badgeGray,
    },
    "&:hover fieldset": {
      borderColor: themeColors.primaryDark,
    },
    "&.Mui-focused fieldset": {
      borderColor: themeColors.primaryDark,
    },
  },
});


const HorizontalLine = styled(Box)({
  width: "100%",
  height: "1px",
  marginTop: "30px",
  marginBottom: "30px",
});

const FileuploadBox = styled(Box)({
  marginTop: "16px",
  textAlign: "center",
  borderRadius: "10px",
  border: "1px dashed rgba(0, 0, 0, 0.50)",
  width: '100%'
});

const MainBox = styled(Box)({
  padding: "38px",
});

const ErrorMessages = styled(ErrorMessage)({
  color: "red",
});

const FormContent = styled(Box)({
  marginBottom: "40px",
});

const StyledLabelName = styled(Typography)({
  color: themeColors.grey,
  fontFeatureSettings: "'clig' off, 'liga' off",
  fontFamily: "Roboto",
  fontSize: "16px",
  fontStyle: "normal",
  fontWeight: 500,
  lineHeight: "150%",
  marginBottom: "8px",
});

const StyledJdContainer = styled(Box)({
  display: 'inline-flex',
  width: '100%',
  borderRadius: '10px',
  backgroundColor: themeColors.badgeGray,
  padding: '16px',
  marginTop: '8px',
  alignItems: 'center',
});
const StyledTextareaAutosize = styled("textarea")<{
  disabled?: boolean;
}>(({ disabled }) => ({
  width: "100%",
  backgroundColor: disabled ? themeColors.lightgrey : themeColors.white,
  borderRadius: "10px",
  resize: "vertical",
  overflow: "auto",
  padding: "8px",
  border: "1px dashed rgba(0, 0, 0, 0.50)",
  outline: "none",
  "&:focus": {
    border: "1px dashed rgba(0, 0, 0, 0.50)",
    outline: "none",
  },
  "&:hover": {
    border: "1px dashed rgba(0, 0, 0, 0.50)",
  },
}));


export default EditRFRForm;
