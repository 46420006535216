import axios, { AxiosError, AxiosResponse } from "axios";

const apiConfig = {
  baseURL: "https://xak9-hweo-h6q9.n7.xano.io/api:ceD068cG:C2C-Backend",
  timeout: 15000,
  validateStatus: (statusCode: number) => statusCode >= 200 && statusCode < 300,
};

const api = axios.create(apiConfig);

api.interceptors.response.use(
  (response) => response,
  (error) => Promise.reject(error)
);

api.interceptors.request.use(async function (config) {
  return config;
});

export { api };

interface ResponseData {
  message: string;
}

export function getBadRequestErrorMessage(error: AxiosError): string {
  if (error.code == "ECONNABORTED")
    return "Oops... Something went wrong. Please try again in a couple of minutes";
  return error.isAxiosError &&
    error.response?.status &&
    error.response?.status >= 400 &&
    error.response?.status <= 500
    ? `${
        (error.response.data as ResponseData)?.message || "Something went wrong"
      }`
    : "Oops... Something went wrong. Please try again in a couple of minutes";
}
