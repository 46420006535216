import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  Container,
  Divider,
  OutlinedInput,
  FormControl,
  InputAdornment,
  Button,
  FormHelperText,
  Theme,
  styled,
} from "@mui/material";
import MailTwoToneIcon from "@mui/icons-material/MailTwoTone";

interface TimeLeft {
  days?: number;
  hours?: number;
  minutes?: number;
  seconds?: number;
}


const StatusComingSoon: React.FC = () => {
  const calculateTimeLeft = (): TimeLeft => {
    const difference = +new Date(`2023`) - +new Date();
    let timeLeft: TimeLeft = {};

    if (difference > 0) {
      timeLeft = {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / 1000 / 60) % 60),
        seconds: Math.floor((difference / 1000) % 60),
      };
    }

    return timeLeft;
  };

  const [timeLeft, setTimeLeft] = useState<TimeLeft>(calculateTimeLeft());

  useEffect(() => {
    const timer = setTimeout(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);
    return () => clearTimeout(timer);
  });

  const timerComponents: any = [];

  Object.keys(timeLeft).forEach((interval) => {
    const key = interval as keyof TimeLeft;
    if (!timeLeft[key]) {
      return;
    }

    timerComponents.push(
      <CenteredBoxWithPadding key={key}>
        <Typography variant="h1">{timeLeft[key]}</Typography>
        <Typography variant="h3">{key}</Typography>
      </CenteredBoxWithPadding>
    );
  });

  return (
    <MainContent>
      <Container maxWidth="md">
        <CenteredBoxWithMargin>
          <Container maxWidth="xs">
            <StyledTypography
              variant="h1"
            >
              {"Coming Soon"}
            </StyledTypography>
            <StyledTypographySub variant="h3">
            
              {
                "We're working on implementing the last features before our launch!"
              }
            </StyledTypographySub>
          </Container>
          <img
            alt="Coming Soon"
            height={200}
            src="/static/images/status/coming-soon.svg"
          />
       </CenteredBoxWithMargin>

        <CenteredFlexBox>
          {timerComponents.length ? timerComponents : <>Time's up!</>}
        </CenteredFlexBox>

        <Container maxWidth="sm">
          <CenteredBox>
            <FormControl variant="outlined" fullWidth>
              <OutlinedInputWrapper
                type="text"
                placeholder={"Enter your email address here..."}
                endAdornment={
                  <InputAdornment position="end">
                    <ButtonNotify variant="contained" size="small">
                      {"Notify Me"}
                    </ButtonNotify>
                  </InputAdornment>
                }
                startAdornment={
                  <InputAdornment position="start">
                    <MailTwoToneIcon />
                  </InputAdornment>
                }
              />
              <FormHelperText>
                {"We'll email you once our website is launched!"}
              </FormHelperText>
            </FormControl>
            <StyledDivider/>
          </CenteredBox>
        </Container>
      </Container>
    </MainContent>
  );
};


const MainContent = styled(Box)({
  height: '100%',
  display: 'flex',
  flex: '1',
  overflow: 'auto',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
});

const OutlinedInputWrapper = styled(OutlinedInput)(
  ({ theme }: { theme: Theme }) => `
    background-color: ${theme.palette.primary.contrastText};`
);

const CenteredBoxWithPadding = styled(Box)({
  textAlign: 'center',
  paddingLeft: '24px',  
  paddingRight: '24px', 
});

const CenteredBoxWithMargin = styled(Box)({
  textAlign: 'center',
  marginBottom: '24px', 
});

const StyledTypography = styled(Typography)({
  marginTop: '32px', 
  marginBottom: '16px', 
});

const StyledTypographySub = styled(Typography)({
  color: 'text.secondary',
  fontWeight: 'normal',
  marginBottom: '32px', 
});

const ButtonNotify = styled(Button)(
  ({ theme }: { theme: Theme }) => `
    margin-right: -${theme.spacing(1)};`
);

const CenteredBox = styled(Box)({
  textAlign: 'center',
  padding: '16px',
});
const StyledDivider = styled(Divider)({
  marginY: '16px'
});
const CenteredFlexBox = styled(Box)({
  display: 'flex',
  justifyContent: 'center',
});

export default StatusComingSoon;
