import React from "react";
import { Modal, Box, Typography, Button } from "@mui/material";
import styled from "@emotion/styled";
import { ReactComponent as CloseIcon } from "../../../../assets/icons/Closed.svg";
import { ReactComponent as Menuarrow } from "../../../../assets/icons/menu-arrow-circle.svg";
import { themeColors } from "core/Theming/theme";

export const Multiselectpublish = ({
  open,
  handleClose,
  handleConfirmDelete,
  jobTitle,
}: {
  open: boolean;
  handleClose: () => void;
  handleConfirmDelete: () => void;
  jobTitle: string;
}) => {
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
    >
      <Box sx={{ ...modalStyle }}>
        <Container>
          <Close onClick={handleClose} />
        </Container>
        <CenteredColumn>
          <Questionmark>
            <Menu />
          </Questionmark>
          <CenteredTypography
            id="modal-description"
            variant="h4"
          >
            Do you really want to publish
          </CenteredTypography>
          <CenteredText
            id="modal-description"
            variant="h4"
            
          >
            {jobTitle} Requisitions
          </CenteredText>
          <StyledContainer>
            <Stylebutton
              variant="contained"
              color="primary"
              onClick={handleConfirmDelete}
            >
              Yes
            </Stylebutton>
            <StylebuttonDelete
              variant="contained"
              onClick={handleClose}
            >
              No
            </StylebuttonDelete>
          </StyledContainer>
        </CenteredColumn>
      </Box>
    </Modal>
  );
};

const CenteredTypography = styled(Typography)({
  marginTop: "20px",
  alignItems: "center",
  textAlign: "center",
   color:themeColors.blackGray
});

const CenteredText = styled(Typography)({
  marginTop: '10px',
  alignItems: "center",
  textAlign: "center",
  color:themeColors.black
});

const StyledContainer = styled(Box)({
  marginTop: "20px",
  display: "flex",
  justifyContent: "center",
});

const Container = styled(`div`)({
  display: "flex",
  justifyContent: "flex-end",
  backgroundColor: themeColors.primaryLight,
  Padding: "8px",
  borderTopLeftRadius: "14px",
  borderTopRightRadius: "14px",
  borderBottomRadius: "3px",
  padding: "8px",
});

const Questionmark = styled(`div`)({
  display: "flex",
  justifyContent: "center",
});

const Menu = styled(Menuarrow)({
  height: "80px",
  width: "80px",
  cursor: "pointer",
});

const CenteredColumn = styled(`div`)({
  display: "flex",
  justifyContent: "center",
  flexDirection: "column",
});

const Close = styled(CloseIcon)({
  height: "30px",
  width: "30px",
  cursor: "pointer",
});

const Stylebutton = styled(Button)({
  backgroundColor: themeColors.success,
  color: themeColors.white,
  width: "100px",
  height: "42px",
  borderRadius: "10px",
  marginRight: "20px",
});

const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  bgcolor: "background.paper",
  boxShadow: 24,
borderRadius: "14px",
  padding:'0px 0px 20px 0px '
};

const StylebuttonDelete = styled(Button)({
  width: "100px",
  height: "42px",
  borderRadius: "10px",
  background: themeColors.error,
  color: themeColors.white,

});
