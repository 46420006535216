import React from "react";
import { Box, Grid, Typography } from "@mui/material";
import JobCard from "./JobCard";
import styled from "@emotion/styled";
import MenuIcon from "@mui/icons-material/Menu";
import { themeColors } from "core/Theming/theme";
import { useGetActiveRequisitions } from "pages/hooks";
import { ActiveRequisitionsModal } from "api/resources/modals";
import { useSelector } from "react-redux";
import { RootState } from "core/store";

const Jobcards: React.FC = () => {
  const userInfo = useSelector((state: RootState) => state.auth?.userInfo);
  const userID =  userInfo?.user_id;
  const { activeRequisitions, isLoading } = useGetActiveRequisitions(userID as string);

  if (!Array.isArray(activeRequisitions)) {
    return <div>Loading...</div>;
  }

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <MainBox>
      <StyledBox>
        <Heading variant="h4" gutterBottom>
          Active Requisitions 
        </Heading>
        {/* <StyledMenuIcon /> */}
      </StyledBox>
      <Cardbox>
        <ScrollContainer>
          <Grid container spacing={1} wrap="nowrap">
          {activeRequisitions?.length ? (
          activeRequisitions.map((result: ActiveRequisitionsModal, index: number) => (
            <Grid item xs={12} sm={6} md={4}  key={index}>
              <JobCard
              title={result?.result?.rfq_details?.job_title}
              experience={result?.result?.rfq_details?.experience}
              locations={result?.result?.rfq_details?.locations}
              skills={result?.result?.rfq_details?.primary_skills}
              daysLeft={result?.result?.rfq_details?.rfq_closing_date}
              newQuotations={result?.result?.ReviewedBids}
              reviewedQuotations={result?.result?.ReviewedBids}
              rfqId={result?.result?.rfq_details?.rfq_id}
              />
            </Grid>
              ))
            ) : (
              <div>No Active Requisitions</div>
            )}
          </Grid>
        </ScrollContainer>
      </Cardbox>
    </MainBox>
  );
};
const Heading = styled(Typography)({
  color: themeColors.darksecondBlue,
  fontSize: '22px',
});

const Cardbox = styled(Box)({
  display:'grid',
  gridAutoFlow:'column',
  gridAutoColumns:'100%'
});
const MainBox = styled(Box)({
  borderRadius: "8px",
  border: `1px solid ${themeColors.emptyStar}`,
  marginTop: "20px",
  boxShadow:'0px 0px 4px 0px rgba(77, 108, 217, 0.75)'
});
const StyledBox = styled(Box)({
  display: "flex",
  justifyContent: "space-between",
  padding: "15px 30px 0px 30px",
});
const ScrollContainer = styled(Box)`
  display: flex;
  overflow-x: auto;
  white-space: nowrap;
  padding: 0px 20px 20px 20px;

  & > * {
    flex-shrink: 0;
  }

  &::-webkit-scrollbar {
    height: 8px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: ${themeColors.badgeGray};
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background-color: ${themeColors.darkGrey};
  }

  &::-webkit-scrollbar-track {
    background-color: ${themeColors.white};
  }
`;
export default Jobcards;
