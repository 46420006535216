import { useParams, useNavigate, generatePath } from "react-router-dom";
import { Box, Button, Container, IconButton, Modal, Popover, Stack, styled, Switch, Tab, Tooltip, Typography, } from "@mui/material";
import { GridCloseIcon } from "@mui/x-data-grid";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Add } from "@mui/icons-material";
import { useAppSelector } from "core/store";
import { themeColors } from "core/Theming/theme";
import { useMemo, useState } from "react";
import {
  useFetchAcceptedRFq,
  useFetchPendingfRFq,
  useFetchAnalysisRFq,
  useFetchInterested,
  useFetchNotInterested,
  useFetchRequestedSubmissionsRFq,
  useFetchRejectedRFq,
  useFetchRequestedCVsRFq,
  useFetchSubmissionDetails,
  UsePostRequestForSubmission,
  useGetRFRDetails,
  UsePostAcceptorRejectSubmission,
  useCvLikeOrDislike,
  useVendorSubmissionDetails,
  useGetVendorSubmissionCvs,
  useGetVendorsSubmissionCvs,
  useDeleteRequisition,
  UsePostNotifyVendor,
  useEmpCvContactDetails,
  useCvOnHold,
} from "pages/hooks";
import Spinner from "components/spinner/Spinner";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { a11yProps, TabPanel, } from "pages/profileScene/components/tabs/Tabspanel";
import JobDetails from "./JobDetails";
import { StyledDiv } from "./Components/InterestedVendorsRfrTab/InterestedVendorsRfrTab";
import { ReactComponent as Uncolor } from "../../../assets/icons/TableviewColorCheckicon.svg";
import { ReactComponent as Check } from "../../../assets/icons/TableviewCheckicon.svg";
import { ReactComponent as Closeiconmodal } from "../../../assets/icons/Closed.svg";
import { ReactComponent as VisaIcon } from "../../../assets/images/visa.svg";
import { ReactComponent as EmployerLevel } from "../../../assets/images/userCheck.svg";
import { ReactComponent as Commission } from "../../../assets/images/commission.svg";
import { ReactComponent as RateInfoicon } from "../../../assets/images/rateinfoNote.svg";
import { ReactComponent as ContactDetailsIcon } from "../../../assets/images/contactDetails.svg"
import { ReactComponent as CalenderStart } from "../../../assets/icons/CalendarStart.svg";
import { ReactComponent as ColorCheck } from "../../../assets/icons/TableviewColorCheckicon.svg";
import { ReactComponent as Download } from "../../../assets/icons/Tableviewdowloadicon.svg";
import { ReactComponent as Eyesvg } from "../../../assets/icons/Eye Open.svg";
import { ReactComponent as Shape } from "../../../assets/icons/TableviewShapeicon.svg";
import { ReactComponent as Colorcheck } from "../../../assets/icons/RfrIcon/Coloredchecked.svg";
import { ReactComponent as Coloruncheck } from "../../../assets/icons/RfrIcon/Colored Unchecked.svg";
import { ReactComponent as Cvicon } from "../../../assets/icons/Colorcv.svg";


import { ReactComponent as Skills } from "../../../assets/images/icons/skills.svg";
import { AcceptTooltip, RejectTooltip, StatusType, ViewCvTooltip, } from "./Components/PendingSubmissions/PendingSubmissions";

import { ReactComponent as AcceptIcon } from "../../../assets/images/icons/acceptIcon.svg";
import { ReactComponent as RejectIcon } from "../../../assets/images/icons/rejectIcon.svg";
import ReusableTable from "components/reusableFIlterTable/ReusableTable";
import { GridRenderCellParams } from "@mui/x-data-grid";
import RatingStars from "./Components/RatingStars";
import { AcceptOrRejectSubmissionModal, AllSubmissionsListModal, CvDetailsModal, CvEmpContactDetailsModal, NotifyAgainModal, RequestForSubmissionModal, SubmissionCvModal, SubmissionDetailCvList, SubmissionDetailsModal, VendorJobSubmission, VendorModal, } from "api/resources/modals";
import { toast } from "react-toastify";
import InitialModal from "./Components/Modals/InitialModal";
import {
  getRfqAcceptedCacheKey,
  getRfqInterestedCacheKey,
  getRfqPendingCacheKey,
  getRfqRejectdCacheKey,
  getRfqRequestedSubmissionsCacheKey,
} from "api/cacheKeys";
import { queryClient } from "api/client";
import { Secondaryskill, Skil, Stacks } from "../Components/RFRlistingpage";
import { SubmissionId } from "./Components/AcceptedSubmissionsTab/AcceptedSubmissionsTab";

import {
  staticAcceptedHeaders,
  staticPendingHeaders,
  staticRejectedHeaders,
  staticRequestedHeaders,
  staticSubmissionsHeaders,
  staticVendorActionsHeaders,
} from "./RfrDetailsConstants";

import {
  ColorcheckIcon,
  ColorUncheckIcon,
  DownloadIcon,
  Iconcontainer,
  LightTooltip,
  Likecontainer,
  UncheckIcon,
  View,
  OnholdDisable,
  OnholdEnable,
  StyledSmartpending,
  MediumIcons,
  StyledSmartReject,
  LowerIcons,
  StyledSmartAccept,
  GreenIcons

} from "pages/BidMarketPlace/SubmissionDetails/Submissionstable";
import { ChartContainer, FullDiv, InterestedBidsTableBox, MainContainer, ScrollableTabs, StyledBox, StyledTypography, TabButtonsContainer } from "./RfrDetailsSub";
import Charts from "./Charts";
import { ReactComponent as Location } from "../../../assets/icons/location (2).svg";
import ViewModal from "./Components/Modals/ViewModal";
import InfoBox from "../Components/InfoBox";
import EmployerCardView from "../Components/EmployerCardView/EmployerCardView";
import { v4 as uuidv4 } from 'uuid';
import ReusableCard from "../Components/ReusableCard";
export function RFRDetails() {
  const { isVendor } = useAppSelector((store) => store.auth);
  const navigate = useNavigate();
  const { "rfq-id": rfqId } = useParams();
  const { rfrDetails } = useGetRFRDetails(rfqId);
  const data = rfrDetails?.rfq_details;
  const [spinner, showSpinner] = useState(false);
  const [value, setValue] = useState<number>(0);
  const [vendorId, setVendorId] = useState<string>("");
  const [viewSubDetailsModal, setViewSubDetailsModal] = useState<boolean>(false);
  const [eyeIconClicked, setEyeIconClicked] = useState<boolean>(false);
  const [cvIdClicked, setCvIdClicked] = useState<boolean>(false);
  const [previewTabSubId, setPreviewTabSubId] = useState<string>("");
  const [subId, setSubId] = useState<string>("");
  const [vendorActions, setVendorActions] = useState("interested");
  const [submissions, setSubmissions] = useState("pending");
  const [selectedSubmissions, setSelectedSubmissions] = useState<VendorModal[]>(
    []
  );
  const [initialModal, setInitialModal] = useState<boolean>(false);
  const [viewCvs, setViewCvs] = useState<boolean>(false);
  const [allCvs, setAllCvs] = useState<SubmissionDetailCvList[]>([]);
  const [multiButtonName, setMultiButtoname] = useState<string>("");
  const [multiModalOpen, setMultiModalOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [emailCopied, setEmailCopied] = useState<boolean>(false);
  const [websiteLinkCopied, setWebsiteLinkCopied] = useState<boolean>(false);
  const [cardView, setCardView] = useState<boolean>(false);
  const { CvEmpContactDetails = {} as CvEmpContactDetailsModal, isLoading: empCvContactDetailsLoading, error } = useEmpCvContactDetails(
    subId,
    vendorId,
  );
  const generalProfile = CvEmpContactDetails?.general_profile || {};
  const { isLoading: loadingAnalytics, refetch: fetchingFunnelAnalytics } =
    useFetchAnalysisRFq(rfqId);
  const {
    requestedSubmissions = [],
    isLoading: loadingRequestedSubmissions,
    refetch: fetchingRequestedSubmissions,
  } = useFetchRequestedSubmissionsRFq(rfqId);
  const {
    interestedVendors = [],
    isLoading: loadingInterested,
    refetch: fetchingInterested,
  } = useFetchInterested(rfqId);
  const {
    notInterestedVendors = [],
    isLoading: loadingNotInterested,
    refetch: fetchingNotInterested,
  } = useFetchNotInterested(rfqId);
  const {
    acceptedVendors = [],
    isLoading: loadingAccepted,
    refetch: fetchingAccepted,
  } = useFetchAcceptedRFq(rfqId);

  const {
    rejectedVendors = [],
    isLoading: loadingRejected,
    refetch: fetchingRejected,
  } = useFetchRejectedRFq(rfqId);
  const {
    pendingVendors = [],
    isLoading: loadingPending,
    refetch: fetchingPending,
  } = useFetchPendingfRFq(rfqId || " ");
  const {
    submissionDetails = [],
    isLoading: isLoadingSubmissionDetails,
    refetch: fetchSubmissionDetails,
  } = useFetchSubmissionDetails(previewTabSubId || "");

  const memoizedSubmissionDetails = useMemo(
    () => submissionDetails,
    [submissionDetails]
  );

  const memoizedRequestedSubmissions = useMemo(
    () => requestedSubmissions,
    [requestedSubmissions]
  );

  const { submissonCvsList = [], refetch: fetchingCvs, isLoading: CvsFetchning } = useGetVendorsSubmissionCvs(subId || '');
  const { vendorMySubmissionDetails = {} as VendorJobSubmission, refetch: fetchVendorDetails, isLoading } = useVendorSubmissionDetails(previewTabSubId || '');




  const { requestedNotifyVendor } = UsePostNotifyVendor({
    onSuccess: () => {
      toast.success("Notified Successfully.");
      setMultiModalOpen(false);
      setSelectedSubmissions([]);
      showSpinner(false);
    },
    onError: () => {
      showSpinner(false);
    },
  });
  const safeJoin = (array: string[] | undefined) => (array ? array.join(', ') : 'N/A');
  const handleBack = async (updatedData: boolean) => {
    setViewCvs(updatedData)
    setPreviewTabSubId('')
  }
  const handleSubmissionDetailsCvBack = async (updatedData: boolean) => {
    setEyeIconClicked(updatedData)
    setCvIdClicked(updatedData)
  }
  const handleCallCvApi = async (callApi: boolean) => {
    if (callApi) await fetchingCvs();

  }

  const handleMultiLikesCallApi = () => {
    const Ids = selectedSubmissions.map((id) => id.cv_id);
    if (Ids.length > 0) {
      Ids.forEach((Ids) =>
        cvLikeOrDislike({
          cvID: Ids,
          status: "Liked"
        })
      );
      toast.success("Liked Cvs Successfully!");
      fetchVendorDetails();
    } else {
      toast.error("Select atleast one Cv.")
    }
  }



  const handleMultiDislikesCallApi = () => {

    const Ids = selectedSubmissions.map((id) => id.cv_id);
    if (Ids.length > 0) {
      Ids.forEach((Ids) =>
        cvLikeOrDislike({
          cvID: Ids,
          status: "Disliked"
        })
      );
      setSelectedSubmissions([]);
      toast.success("Dis Liked Cvs Successfully!");
      fetchVendorDetails();
    } else {
      toast.error("Select atleast one Cv.")
    }
  }
  const handleMultiLikes = () => {
    setMultiButtoname("Like");
    setMultiModalOpen(true);
  }
  const handleMultiDislikes = () => {
    setMultiButtoname("DisLike");
    setMultiModalOpen(true);
  }
  const handleMultidownloadCallAction = () => {
    const cvIds = selectedSubmissions.map((id) => id.cv_id);

    const downloadFile = (cvId: string) => {
      const cvUrl = cvId;
      return fetch(cvUrl)
        .then(response => {
          if (!response.ok) throw new Error('Network response was not ok');
          return response.blob();
        })
        .then(blob => {
          const url = URL.createObjectURL(blob);
          const a = document.createElement('a');
          a.href = url;
          a.download = `${cvId}.pdf`;
          document.body.appendChild(a);
          a.click();
          a.remove();
          URL.revokeObjectURL(url);
        })
        .catch(() => {
          toast.error(`Failed to download CV with ID: ${cvId}`);
        });
    };

    Promise.all(cvIds.map(cvId => downloadFile(cvId)))
      .then(() => {
        setMultiModalOpen(false);
        setSelectedSubmissions([]);
      })
      .catch(() => {
        toast.error('Some downloads failed');
      });
  };
  const handleMultidownload = () => {
    setMultiButtoname("Download");
    setMultiModalOpen(true);
  }



  const handleMultiOpenRfsApiReq = () => {
    const Ids = selectedSubmissions.map((submission) => submission.vendorId);
    if (Ids.length > 0) {
      Ids.forEach((id) => {
        requestedSubmissionData({
          vendorId: id,
          bidRequest: 'Requested',
          rfqId: rfqId,
        });
      });
    } else {
      toast.error("Please select at least one Submission");
    }
  };
  const handleMultiNotifyApiReq = () => {
    const Ids = selectedSubmissions.map((submission) => submission.vendorId);
    if (Ids.length > 0) {
      // Process each ID
      Ids.forEach((id) => {
        requestedNotifyVendor({
          vendorID: id,
          rfrID: rfqId,
        });
      });
    } else {
      toast.error("Please select at least one Submission");
    }
  }
  const handleMultiAcceptCallApi = () => {
    const Ids = selectedSubmissions.map((submission) => submission.C2C_Vendor_Bid_Id);
    if (Ids.length > 0) {
      // Process each ID
      Ids.forEach((id) => {
        postAcceptOrRejectSubmissionData({
          submmissionID: id,
          status: "accepted",
        });
      });

    } else {
      toast.error("Please select at least one Submission");
    }
  }

  const handleMultiRejectCallApi = () => {
    const Ids = selectedSubmissions.map((submission) => submission.C2C_Vendor_Bid_Id);
    if (Ids.length > 0) {
      // Process each ID
      Ids.forEach((id) => {
        postAcceptOrRejectSubmissionData({
          submmissionID: id,
          status: "rejected",
        });
      });

    } else {
      toast.error("Please select at least one Submission");
    }
  }

  const handleMultiNotify = () => {
    setMultiModalOpen(true);
    setMultiButtoname("Notify");
  };
  const handleMultiAccept = () => {
    setMultiModalOpen(true);
    setMultiButtoname("Accept");
  };
  const handleMultiReject = () => {
    setMultiModalOpen(true);
    setMultiButtoname("Reject");
  };
  const handleMultiOpenRfsModal = () => {
    setMultiButtoname("Request for Submissions from")
    setMultiModalOpen(true);
  }
  const handleCloseMultiModal = () => {
    setMultiModalOpen(false);
    setSelectedSubmissions([]);
  }
  const handleToggleChange = () => {
    setCardView((prev) => !prev);
  };

  const handleMultiYesClick = () => {
    switch (multiButtonName) {
      case "Notify":
        handleMultiNotifyApiReq();
        break;
      case "Request for Submissions from":
        handleMultiOpenRfsApiReq();
        break;
      case "Accept":
        handleMultiAcceptCallApi();
        break;
      case "Reject":
        handleMultiRejectCallApi();
        break;
      case "Like":
        handleMultiLikesCallApi();
        break;
      case "DisLike":
        handleMultiDislikesCallApi();
        break;
      case "Download":
        handleMultidownloadCallAction();
        break;
      default:
        console.error("Unknown button action:", multiButtonName);
        break;
    }
  };


  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    if (newValue === 0) {
      fetchingFunnelAnalytics();
    }
    if (newValue === 1) {
      fetchingInterested();
    }
    if (newValue === 2) {
      fetchingNotInterested();
    }
    if (newValue === 3) {
      fetchSubmissionDetails();
    }
    setValue(newValue);
  };
  const handleCloseTab = () => {
    setValue(2);
    setPreviewTabSubId("");
  };
  const memoizedInterestedVendors = useMemo(() => interestedVendors, [interestedVendors]);
  const memoizedNotInterestedVendors = useMemo(() => notInterestedVendors, [notInterestedVendors]);
  const memoizedPendingVendors = useMemo(() => pendingVendors, [pendingVendors]);
  const memoizedAcceptedVendors = useMemo(() => acceptedVendors, [acceptedVendors]);
  const memoizedRejectedVendors = useMemo(() => rejectedVendors, [rejectedVendors]);
  const [acceptorRejectVendor, setAcceptOrRejectVendor] = useState<VendorModal | null>(null);
  const [buttonLabel, setButtonLabel] = useState<string>("")
  const handleOpenAcceptBtnModal = (vendor: VendorModal) => {
    setAcceptOrRejectVendor(vendor);
    setInitialModal(true);
    setButtonLabel("Accept");
  }
  const handleOpenRejectBtnModal = (vendor: VendorModal) => {
    setAcceptOrRejectVendor(vendor);
    setInitialModal(true);
    setButtonLabel("Reject");
  }
  const handleCloseInitialModal = () => {
    setInitialModal(false);
  }
  const { postAcceptOrRejectSubmissionData } = UsePostAcceptorRejectSubmission({
    onSuccess: () => {
      setSelectedSubmissions([]);
      setMultiModalOpen(false);
      setInitialModal(false);
      toast.success("Request made Successfully.");
      fetchingPending();
      fetchingRejected();
      fetchingAccepted();
    },
    onError: () => toast.error(" Please select a single row and try again"),
  });
  const handleinitialModalYesClick = (vendor: VendorModal | null) => {
    setInitialModal(false);
    if (vendor) {
      if (buttonLabel == "Accept") {
        try {
          const payload: AcceptOrRejectSubmissionModal = {
            status: StatusType.Accepted,
            submmissionID: vendor.C2C_Vendor_Bid_Id,
          };
          postAcceptOrRejectSubmissionData(payload);
        } catch (err) {
          toast.error("Something went wrong, please try again");
          showSpinner(false);
        }
      }
      else if (buttonLabel == "Reject") {
        try {
          const payload: AcceptOrRejectSubmissionModal = {
            status: StatusType.Rejected,
            submmissionID: vendor.C2C_Vendor_Bid_Id,
          };
          postAcceptOrRejectSubmissionData(payload);
        }
        catch (err) {
          toast.error("Something went wrong, please try again");
          showSpinner(false);
        }

      };
    }


  }
  const handleSubmissionIdClick = (id: string) => {
    toast.success("Submission Details Tab Added.");
    setValue(3);
    setPreviewTabSubId(id);
  };
  const generateCardData = (vendor: VendorModal) => ({
    header: "Submission ID",
    SubmissionId: vendor.C2C_Vendor_Bid_Id ? vendor.C2C_Vendor_Bid_Id.toString() : "N/A",
    leftKeyValuePairs: [
      { key: "Vendor Name:", value: vendor.name || "N/A" },
      { key: "Vendor ID:", value: vendor.vendorId || "N/A" },
      { key: "Submission Sent On:", value: vendor.startDate || "N/A" },
      { key: "CVs Submitted:", value: vendor.cvs_submitted ? vendor.cvs_submitted.toString() : "0" },
    ],
    rightKeyValuePairs: [
      { key: "Rate Card:", value: vendor.Bill_Rate ? vendor.Bill_Rate.toString() : "N/A" },
      { key: "Commission:", value: vendor.comission ? vendor.comission.toString() : "N/A" },
      { key: "Candidate Visa:", value: vendor.Visa_Type || "N/A" },
      { key: "Employee Level:", value: vendor.Employer_Level || "N/A" },
    ],
    leftButtonLabel: "Accept",
    rightButtonLabel: "Reject",
    onLeftButtonClick: () => handleOpenAcceptBtnModal(vendor),
    onRightButtonClick: () => handleOpenRejectBtnModal(vendor),
    onSubmissionIdClick:()=>handleSubmissionIdClick(vendor.C2C_Vendor_Bid_Id),
  });

  const { requestedSubmissionData } = UsePostRequestForSubmission({
    onSuccess: () => {
      setSelectedSubmissions([]);
      setMultiModalOpen(false);
      toast.success("Submission/s requested Successfully.");
      fetchingInterested();
      fetchingRequestedSubmissions();
    },
    onError: () => toast.error(" Please select a single row and try again"),
  });




  const handleSubmissionClick = (id: string) => {
    toast.success("Submission Details Tab Added.");
    setValue(3);
    setPreviewTabSubId(id);
  };

  const handleDoBulk = (selectedData: any) => {
    setSelectedSubmissions(selectedData);
  };
  const hanleOpenCvModal = (params: SubmissionCvModal) => {
    setViewCvs(true);
    setCvIdClicked(true);
    setSubId(params?.bid_id);
    setVendorId(params?.vendor_id);
  }
  const handleVendorIdClick = (params: VendorModal) => {
  }

  const vendorActionsHeaders = [
    {
      title: "Vendor Id",
      filter: true,
      filterType: "number",
      cellWidth: "200px",
      name: "vendorId",
      customComponent: (params: VendorModal) => (
        <SubmissionId
          onClick={() => handleVendorIdClick(params)}
        >
          {params?.vendorId}
        </SubmissionId>
      ),
    },

    ...staticVendorActionsHeaders,
    {
      title: "Rating",
      filter: true,
      filterType: "number",
      cellWidth: "180px",
      name: "rating",
      customComponent: (params: VendorModal) => {
        return <RatingStars rating={JSON.parse(params.rating)} />;
      },
    },
  ];
  const PendingHeaders = [
    {
      title: "Row Id",
      filter: false,
      cellWidth: "100px",
      name: "id",
      show: false,
    },
    {
      title: "Vendor Id",
      filter: true,
      filterType: "number",
      cellWidth: "200px",
      name: "vendor_id",
      customComponent: (params: VendorModal) => (
        <SubmissionId
          onClick={() => handleVendorIdClick(params)}
        >
          {params?.vendor_id}
        </SubmissionId>
      ),
    },
    {
      title: "Submission Id",
      filter: true,
      filterType: "number",
      cellWidth: "200px",
      name: "C2C_Vendor_Bid_Id",
      customComponent: (params: VendorModal) => (
        <SubmissionId
          onClick={() => handleSubmissionClick(params?.C2C_Vendor_Bid_Id)}
        >
          {params?.C2C_Vendor_Bid_Id}
        </SubmissionId>
      ),
    },
    ...staticPendingHeaders,

  ];
  const AcceptedHeaders = [
    {
      title: "Row Id",
      filter: false,
      cellWidth: "100px",
      name: "id",
      show: false,
    },
    {
      title: "Vendor Id",
      filter: true,
      filterType: "number",
      cellWidth: "220px",
      name: "vendor_id",
      customComponent: (params: VendorModal) => (
        <SubmissionId
          onClick={() => handleVendorIdClick(params)}
        >
          {params?.vendor_id}
        </SubmissionId>
      ),
    },
    {
      title: "Submission Id",
      filter: true,
      filterType: "number",
      cellWidth: "200px",
      name: "C2C_Vendor_Bid_Id",
      customComponent: (params: VendorModal) => (
        <SubmissionId
          onClick={() => handleSubmissionClick(params?.C2C_Vendor_Bid_Id)}
        >
          {params?.C2C_Vendor_Bid_Id}
        </SubmissionId>
      ),
    },
    ...staticAcceptedHeaders,

  ];
  const RejectedHeaders = [
    {
      title: "Row Id",
      filter: false,
      cellWidth: "100px",
      name: "id",
      show: false,
    },
    {
      title: "Submission Id",
      filter: true,
      filterType: "number",
      cellWidth: "200px",
      name: "C2C_Vendor_Bid_Id",
      customComponent: (params: VendorModal) => (
        <SubmissionId
          onClick={() => handleSubmissionClick(params?.C2C_Vendor_Bid_Id)}
        >
          {params?.C2C_Vendor_Bid_Id}
        </SubmissionId>
      ),
    },
    {
      title: "Vendor Id",
      filter: true,
      filterType: "number",
      cellWidth: "200px",
      name: "vendor_id",
      customComponent: (params: VendorModal) => (
        <SubmissionId
          onClick={() => handleVendorIdClick(params)}
        >
          {params?.vendor_id}
        </SubmissionId>
      ),
    },
    ...staticRejectedHeaders,

  ];
  const RequestedHeaders = [
    {
      title: "Row Id",
      filter: false,
      cellWidth: "100px",
      name: "id",
      show: false,
    },
    {
      title: "Vendor Id",
      filter: true,
      filterType: "number",
      cellWidth: "200px",
      name: "vendorId",
      customComponent: (params: VendorModal) => (
        <SubmissionId
          onClick={() => handleVendorIdClick(params)}
        >
          {params?.vendorId}
        </SubmissionId>
      ),
    },
    ...staticRequestedHeaders,
    {
      title: "Rating",
      filter: true,
      filterType: "number",
      cellWidth: "180px",
      name: "rating",
      customComponent: (params: VendorModal) => {
        return <RatingStars rating={JSON.parse(params.rating)} />;
      },
    },
  ];
  const SubmissionsDetailsHeaders = [
    {
      name: "cv_id",
      title: "CV Id",
      filter: true,
      filterType: "number",
      cellWidth: "180px",
      customComponent: (params: SubmissionCvModal) => (
        <CvId
          onClick={() => { hanleOpenCvModal(params) }}
        >
          {params?.cv_id}
        </CvId>
      ),
    },
    ...staticSubmissionsHeaders,

    {
      title: "Smart Score",
      cellWidth: "230px",
      name: "match_score",
      filter: true,
      filterType: "text",
      customComponent: (row: SubmissionCvModal) => {
        return (
          <>
            {
              row?.match_score?(
                <>
                {row?.match_score >= 51 ? (
                    <StyledSmartAccept>
                    <GreenIcons />{Math.round(row.match_score)}% Match
                    </StyledSmartAccept>
                ) : row?.match_score >= 36 ? (
                    <StyledSmartpending>
                    <MediumIcons />{Math.round(row.match_score)}% Match
                    </StyledSmartpending>
                ) : row?.match_score < 36 ? (
                    <StyledSmartReject>
                    <LowerIcons />{Math.round(row.match_score)}% Match
                    </StyledSmartReject>
                ) : (
                    " -"
                )}
                </>
                
              ):("-")
            }
            </>
        );
      },
    },

    {
      name: "prime_skills",
      title: "Primary Skills",
      cellWidth: "320px",
      filterType: "text",
      filter: true,
      customComponent: (row: SubmissionDetailsModal) => {
        const maxSkillsToShow = 1;
        const { prime_skills = [] } = row;
        const visibleSkills = prime_skills.slice(0, maxSkillsToShow);
        const hiddenSkills = prime_skills.slice(maxSkillsToShow);
        return (
          <Stacks direction="row" spacing={1} mt={1} ml={1}>
            {visibleSkills.map((skill, index) => (
              <Tooltip key={index} title={skill}>
                <Skil label={skill} />
              </Tooltip>
            ))}

            {hiddenSkills.length > 0 && (
              <Tooltip title={hiddenSkills.join(", ")}>
                <Skil label="..." />
              </Tooltip>
            )}
          </Stacks>
        );
      },
    },
    {
      name: "second_skills",
      title: "Secondary Skills",
      cellWidth: "300px",
      filterType: "text",
      filter: true,

      customComponent: (row: SubmissionDetailsModal) => {
        const maxSkillsToShow = 1;
        const { second_skills = [] } = row;
        const visibleSkills = second_skills.slice(0, maxSkillsToShow);
        const hiddenSkills = second_skills.slice(maxSkillsToShow);
        return (
          <Stacks direction="row" spacing={1} mt={1} ml={1}>
            {visibleSkills.map((skill, index) => (
              <Tooltip key={index} title={skill}>
                <Secondaryskill label={skill} />
              </Tooltip>
            ))}

            {hiddenSkills.length > 0 && (
              <Tooltip title={hiddenSkills.join(", ")}>
                <Secondaryskill label="..." />
              </Tooltip>
            )}
          </Stacks>
        );
      },
    },
    {
      name: "status",
      title: "Status",
      width: 180,
      customComponent: (params: SubmissionDetailsModal) => {
        const value = params.status;

        let buttonLabel = "";
        let buttonColor: string;
        let labelColor: string;

        switch (value) {
          case "Pending":
            buttonLabel = "Pending";
            buttonColor = themeColors.lightGold;
            labelColor = themeColors.darkCoffee;
            break;
          case "Liked":
            buttonLabel = "Liked";
            buttonColor = themeColors.successLight;
            labelColor = themeColors.lightGreen;
            break;
          case "Disliked":
            buttonLabel = "Disliked";
            buttonColor = themeColors.lightDislike;
            labelColor = themeColors.darkDislike;
            break;
            case "On hold":
              buttonLabel = "On hold";
              buttonColor = themeColors.lightGold;
              labelColor = themeColors.darkCoffee;
              break;
          default:
            buttonLabel = "Unknown";
            buttonColor = themeColors.badgeGray;
            labelColor = themeColors.blackGray;
            break;
        }

        return (
          <Stack direction="row" spacing={1}>
            <Button
              sx={{
                height: "26px",
                width: "102px",
                marginTop: "12px !important",
                backgroundColor: buttonColor,
                color: `${labelColor} !important`,
              }}
            >
              {buttonLabel}
            </Button>
          </Stack>
        );
      },
    },
    
  ];



  const TableActions: React.FC<{ row: VendorModal }> = ({ row }) => {
    const [initialModalOpen, setInitialModalOpen] = useState(false);
    const [selectedRow, setSelectedRow] = useState<VendorModal | null>(null);
    const [spinner, showSpinner] = useState(false);
    const [buttonName, setButtonname] = useState<string>("");

    const { requestedSubmissionData } = UsePostRequestForSubmission({
      onSuccess: () => {
        toast.success("Submission requested Successfully.");
        setInitialModalOpen(false);
        showSpinner(false);
        if (row.status === "interested") {
          queryClient.refetchQueries(getRfqInterestedCacheKey(rfqId));
          queryClient.refetchQueries(getRfqRequestedSubmissionsCacheKey(rfqId))
        }
      },
      onError: () => {
        toast.error("Please select a single row and try again");
        showSpinner(false);
      },
    });
    const { requestedNotifyVendor } = UsePostNotifyVendor({
      onSuccess: () => {
        toast.success("Submission requested Successfully.");
        setInitialModalOpen(false);
        showSpinner(false);
        queryClient.refetchQueries(getRfqRequestedSubmissionsCacheKey(rfqId));
      },
      onError: () => {
        toast.error("Please select a single row and try again");
        showSpinner(false);
      },
    });



    const { postAcceptOrRejectSubmissionData } = UsePostAcceptorRejectSubmission({
      onSuccess: () => {
        toast.success("Request made Successfully.");
        setInitialModalOpen(false);
        showSpinner(false);
        queryClient.refetchQueries(getRfqPendingCacheKey(rfqId));
        queryClient.refetchQueries(getRfqAcceptedCacheKey(rfqId));
        queryClient.refetchQueries(getRfqRejectdCacheKey(rfqId));
      },
      onError: () => toast.error(" Please select a single row and try again"),
    });



    const handleOpenRfsModal = () => {
      setButtonname("Request for Submission from")
      setSelectedRow(row);
      setInitialModalOpen(true);
    };
    const handleOpenRejectModal = () => {
      setButtonname("Reject")
      setSelectedRow(row);
      setInitialModalOpen(true);
    };
    const handleOpenAcceptModal = () => {
      setButtonname("Accept");
      setSelectedRow(row);
      setInitialModalOpen(true);
    };
    const handleNotifyAgain = () => {
      setButtonname("Notify");
      setSelectedRow(row);
      setInitialModalOpen(true);
    };

    const handleCloseModal = () => {
      setInitialModalOpen(false);
    };



    const handleYesClick = () => {

      showSpinner(true);
      if (buttonName === "Notify") {
        try {
          const payload: NotifyAgainModal = {
            vendorID: row.vendorId,
            rfrID: rfqId,
          };
          requestedNotifyVendor(payload);
        } catch (err) {
          toast.error(err as string);
          showSpinner(false);
        }
      }
      if (row.status === "interested") {
        try {
          const payload: RequestForSubmissionModal = {
            vendorId: row.vendorId,
            bidRequest: "Requested",
            rfqId: rfqId,
          };
          requestedSubmissionData(payload);
        } catch (err) {
          toast.error("Something went wrong, please try again");
          showSpinner(false);
        }
      }

      if (buttonName == "Accept") {
        try {
          const payload: AcceptOrRejectSubmissionModal = {
            status: StatusType.Accepted,
            submmissionID: row.C2C_Vendor_Bid_Id,
          };
          postAcceptOrRejectSubmissionData(payload);
        } catch (err) {
          toast.error("Something went wrong, please try again");
          showSpinner(false);
        }
      }
      else if (buttonName == "Reject") {
        try {
          const payload: AcceptOrRejectSubmissionModal = {
            status: StatusType.Rejected,
            submmissionID: row.C2C_Vendor_Bid_Id,
          };
          postAcceptOrRejectSubmissionData(payload);
        }
        catch (err) {
          toast.error("Something went wrong, please try again");
          showSpinner(false);
        }

      };
    }


    return (
      <span >
        {
          row.status === "pending" &&
          <Box display={"inline-flex"} >
            <RejectTooltip title={"Reject"} placement="bottom" arrow>
              <IconButton onClick={() => handleOpenRejectModal()}>
                <RejectIcon />
              </IconButton>
            </RejectTooltip>
            <AcceptTooltip title={"Accept"} placement="bottom" arrow>
              <IconButton onClick={() => handleOpenAcceptModal()}>
                <AcceptIcon />
              </IconButton>
            </AcceptTooltip>
          </Box>
        }
        {
          row.status === "interested" &&
          <Button variant="contained" onClick={() => handleOpenRfsModal()}>
            Request For Submission
          </Button>
        }
        {
          row.status === "accepted" &&
          <RejectTooltip title={"Reject"} placement="bottom" arrow>
            <IconButton onClick={() => handleOpenRejectModal()}>
              <RejectIcon />
            </IconButton>
          </RejectTooltip>

        }
        {
          row.status === "rejected" &&
          <AcceptTooltip title={"Accept"} placement="bottom" arrow>
            <IconButton onClick={() => handleOpenAcceptModal()}>
              <AcceptIcon />
            </IconButton>
          </AcceptTooltip>
        }
        {
          row.status === "Requested" &&
          <Button variant="contained" onClick={() => handleNotifyAgain()}>
            Notify Again
          </Button>
        }
        <Spinner show={spinner}>
          <InitialModal
            btnName={buttonName}
            key={row?.name}
            open={initialModalOpen}
            handleClose={handleCloseModal}
            handleYesClick={handleYesClick}
            jobTitle={data?.job_title}
            status={row?.status}
            vendorName={row?.name}
          />
        </Spinner>
      </span>
    );

  };


  const PendingActions = [
    {
      type: "svg",
      name: "rejectsubmission",
      btnName: "Reject Submission",
      handleClick: (row: string, item: VendorModal) => { },
      customActionComponent: (row: VendorModal) => {
        return <TableActions row={row} />;
      },

    }
  ];
  const InterestedActions = [
    {
      type: "button",
      name: "actions",
      handleClick: (action: string, item: VendorModal) => { },
      customActionComponent: (row: VendorModal) => {
        return <TableActions row={row} />;
      },
    },

  ];
  const RequestedActions = [
    {
      type: "button",
      name: "notify_again",
      btnName: "Notify Again",
      handleClick: (action: string, item: any) => { },
      customActionComponent: (row: VendorModal) => {
        return <TableActions row={row} />
      },
    },
  ];
  const { cvLikeOrDislike } = useCvLikeOrDislike({
    onError: () => toast.error("Error Date requisition:"),
    onSuccess: () => {
      toast.success(" update successfully");
      fetchSubmissionDetails();
      setMultiModalOpen(false);
    },
  });

  const { cvOnHold } = useCvOnHold({
    onError: () => toast.error("Error Date requisition:"),
    onSuccess: () => {
      toast.success(" update successfully");
      fetchSubmissionDetails();
      setMultiModalOpen(false);
    },
  }); 
  const handleCheckClick = (cvId: string, status: string) => {
    cvLikeOrDislike({
      cvID: cvId,
      status: status,
    });
  };
  const handleCvOnHold = (cvId: string, status: string) => {
    cvOnHold({
      cvID: cvId,
      status: status,
    });
  };
  const handleDownloadCv = (cvId: string) => {
    const cvUrl = cvId;
    if (cvUrl) {
      fetch(cvUrl)
        .then(response => response.blob())
        .then(blob => {
          const url = URL.createObjectURL(blob);
          const a = document.createElement('a');
          a.href = url;
          a.download = `${cvId}.pdf`;
          document.body.appendChild(a);
          a.click();
          a.remove();
          URL.revokeObjectURL(url);
        })
        .catch(() => {
          toast.error('Failed to download CV');
        });
    }
  };
  const SubmissionDetailsActions = [
    {
      type: "button",
      name: "actions",
      handleClick: (action: string, item: SubmissionCvModal) => { },
      customActionComponent: (row: SubmissionCvModal) => {
        return (
          <Iconcontainer>
            {row.status === "Liked" ? (
              <Likecontainer>
                <LightTooltip
                  textColor={themeColors.primaryAlt}
                  title="Like"
                  arrow
                >
                  <ColorcheckIcon />
                </LightTooltip>
              </Likecontainer>
            ) : (
              <LightTooltip
                textColor={themeColors.primaryAlt}
                title="Like"
                arrow
              >
                <Check onClick={() => handleCheckClick(row.cv_id, "Liked")} />
              </LightTooltip>
            )}
            {row.status === "Disliked" ? (
              <LightTooltip
                textColor={themeColors.primaryAlt}
                title="DisLike"
                arrow
              >
                <ColorUncheckIcon />
              </LightTooltip>
            ) : (
              <LightTooltip
                textColor={themeColors.primaryAlt}
                title="Dislike
                "
                arrow
              >
                <UncheckIcon
                  onClick={() => handleCheckClick(row.cv_id, "Disliked")}
                />
              </LightTooltip>
            )}
              {row.status === "On hold" ? (
              <LightTooltip
                textColor={themeColors.primaryAlt}
                title="On hold"
                arrow
              >
                <OnholdDisable />
              </LightTooltip>
            ) : (
              <LightTooltip
                textColor={themeColors.primaryAlt}
                title="On hold
                "
                arrow
              >
                <OnholdEnable
                  onClick={() => handleCvOnHold(row.cv_id, "On hold")}
                />
              </LightTooltip>
            )}
            <LightTooltip
              textColor={themeColors.primaryAlt}
              title="Download"
              arrow
            >
              <DownloadIcon onClick={() => handleDownloadCv(row.cv_id)} />
            </LightTooltip>
            <LightTooltip textColor={themeColors.primaryAlt} title="View" arrow>
              <View
                onClick={() => {
                  setEyeIconClicked(true);
                  setSubId(row?.bid_id);
                  setVendorId(row?.vendor_id);
                }}
              />

            </LightTooltip>
          </Iconcontainer>
        );
      },
    },
  ];
  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;


  const handleClose = () => {
    setAnchorEl(null);
  };
  const copyToClipboard = (
    text: string,
    setTooltipOpen: React.Dispatch<React.SetStateAction<boolean>>
  ) => {
    navigator.clipboard.writeText(text).then(() => {
      setTooltipOpen(true);
      setTimeout(() => {
        setTooltipOpen(false);
      }, 1000);
    });
  };

  const AcceptedActions = [
    {
      type: "button",
      name: "reject_submission",
      btnName: "Reject Submission",
      handleClick: (action: string, item: VendorModal) => { },
      customActionComponent: (row: VendorModal) => {
        return <TableActions row={row} />;
      },
    },
    {
      type: "svg",
      name: "viewSubmission",
      btnName: "ViewCv",
      handleClick: (row: string, item: AllSubmissionsListModal) => { },
      customActionComponent: (row: AllSubmissionsListModal) => {
        return (
          <ViewCvTooltip title={"ViewCv"} placement="bottom" arrow>
            <IconButton>
              <Viewcv onClick={() => {
                setViewCvs(true);
                setSubId(row.C2C_Vendor_Bid_Id);
                setVendorId(row.vendor_id);
              }} />
            </IconButton>
          </ViewCvTooltip>
        );
      },
    },
    {
      type: "svg",
      name: "viewContact",
      btnName: "ViewContact",
      handleClick: (row: string, item: AllSubmissionsListModal) => { },
      customActionComponent: (row: AllSubmissionsListModal) => {
        return (
          <>
            <AcceptTooltip title={"View Contact"} placement="bottom" arrow>
              <IconButton onClick={(event) => {
                setSubId(row.C2C_Vendor_Bid_Id);
                setVendorId(row.vendor_id);
                setAnchorEl(event.currentTarget);
              }}>
                <ContactDetailsIcon />
              </IconButton>
            </AcceptTooltip>

            <Popover
              id={id}
              open={open}
              anchorEl={anchorEl}
              onClose={handleClose}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
              disableRestoreFocus
            >
              <Box sx={{ borderRadius: "6px" }}>
                <Box sx={{ backgroundColor: themeColors.primaryLight, height: "32px", padding: "8px" }}> <Typography>Vendor Details</Typography></Box>

                {CvEmpContactDetails && (
                  <Typography sx={{ p: 2 }}>
                    <strong>Vendor ID :</strong> {CvEmpContactDetails?.user_id || ""}<br />
                    <strong>Email ID :</strong> {CvEmpContactDetails?.email || ""} <Tooltip
                      title="Copied to clipboard!"
                      open={emailCopied}
                      disableHoverListener
                      disableFocusListener
                      arrow
                    >
                      <IconButton
                        size="small"
                        onClick={() => copyToClipboard(CvEmpContactDetails?.email, setEmailCopied)}
                        style={{
                          marginLeft: "5px",
                          color: themeColors.DoubleDarkBlue,
                        }}
                      >
                        <ContentCopyIcon fontSize="small" />
                      </IconButton>
                    </Tooltip><br />
                    <strong>Vendor Name :</strong> {generalProfile?.company_name || ""}<br />
                    <strong>Contact Number :</strong> {generalProfile?.phone_number || ""}<br />
                    <strong>Website :</strong> {generalProfile?.website_link || ""} <Tooltip
                      title="Copied to clipboard!"
                      open={websiteLinkCopied}
                      disableHoverListener
                      disableFocusListener
                      arrow
                    >
                      <IconButton
                        size="small"
                        onClick={() => copyToClipboard(generalProfile?.website_link, setWebsiteLinkCopied)}
                        style={{
                          marginLeft: "5px",
                          color: themeColors.DoubleDarkBlue,
                        }}
                      >
                        <ContentCopyIcon fontSize="small" />
                      </IconButton>
                    </Tooltip><br />
                  </Typography>
                )}

              </Box>

            </Popover>
          </>
        );
      },
    },
  ];
  const RejectedActions = [
    {
      type: "button",
      name: "requesr_for_submission",
      btnName: "Request For Submission",
      handleClick: (action: string, item: VendorModal) => { },
      customActionComponent: (row: VendorModal) => {
        return <TableActions row={row} />;
      },
    },
  ];

  return (
    <MainContainer>
      <StyledBox>
        <Box display="flex">
          <IconButton
            aria-label="back"
            size="large"
            sx={{ color: themeColors.primaryDark }}
            onClick={() =>
              navigate(
                isVendor ? "/vendor/myrequisitions" : "/employer/myrequisitions"
              )
            }
          >
            <ArrowBackIcon />
          </IconButton>
          <StyledTypography>
            <u> My Requisitions</u>
          </StyledTypography>
        </Box>
        <Button
          variant="contained"
          endIcon={<Add />}
          onClick={() =>
            navigate(
              isVendor
                ? "/vendor/create-rfr"
                : "/employer/myrequisitions/create-requisition"
            )
          }
        >
          Create a Requisition
        </Button>
      </StyledBox>
      <JobDetails />
      <FullDiv>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <ScrollableTabs
            variant="scrollable"
            value={value}
            onChange={handleTabChange}
            aria-label="scrollable tabs example"
          >
            <Tab label="Analytics" {...a11yProps(0)} value={0} />
            <Tab label="Vendor Actions" {...a11yProps(1)} value={1} />
            <Tab label="Submissions" {...a11yProps(2)} value={2} />
            {(value === 3 || previewTabSubId) && (
              <Tab
                key={previewTabSubId}
                label={
                  <div style={{ display: "flex", alignItems: "center" }}>
                    Submission Id: {previewTabSubId}
                    {value === 3 && (
                      <IconButton
                        size="small"
                        onClick={handleCloseTab}
                        style={{ marginLeft: "8px", color: themeColors.white }}
                      >
                        <GridCloseIcon fontSize="small" />
                      </IconButton>
                    )}
                  </div>
                }
                {...a11yProps(2)}
              />
            )}
          </ScrollableTabs>
        </Box>
        <TabPanel value={value} index={0}>
          <ChartContainer>
            <Charts />
          </ChartContainer>
        </TabPanel>
        <TabPanel value={value} index={1} key={vendorActions}>
          <InterestedBidsTableBox>

            <TabButtonsContainer>
              <Button
                variant={
                  vendorActions === "interested" ? "contained" : "outlined"
                }
                onClick={() => setVendorActions("interested")}
              >
                Interested
              </Button>
              <Button
                sx={{ margin: "0 0px 0 12px" }}
                variant={
                  vendorActions === "not_interested" ? "contained" : "outlined"
                }
                onClick={() => setVendorActions("not_interested")}
              >
                Not Interested
              </Button>
            </TabButtonsContainer>
            {vendorActions === "interested" &&
              <Box display={"inline-flex"} ml={2} >
                <LeftcontentText>
                  {selectedSubmissions.length} of {interestedVendors.length}  Selected
                </LeftcontentText>
                <Button
                  variant="contained"
                  // onClick={handleMultiRfs}
                  onClick={handleMultiOpenRfsModal}
                >
                  Request For Submission
                </Button>
              </Box>

            }
            <ReusableTable
              headers={vendorActionsHeaders}
              data={
                vendorActions === "interested"
                  ? memoizedInterestedVendors
                  : memoizedNotInterestedVendors
              }
              itemsPerPage={10}
              spinner={
                vendorActions === "interested"
                  ? loadingInterested
                  : loadingNotInterested
              }
              handleDoBulk={handleDoBulk}
              checkBox={true}
              headerColor={themeColors.primaryLight}
              inputBorder={themeColors.primaryDark}
              actions={
                vendorActions === "interested" ? InterestedActions : undefined
              }
              actionsCellWidth={"280px"}
            />
            <InitialModal
              btnName={multiButtonName}
              key={uuidv4()}
              open={multiModalOpen}
              handleClose={(handleCloseMultiModal)}
              handleYesClick={handleMultiYesClick}
              jobTitle={data?.job_title}
              multiModal={false}
              // status={selectedSubmissions.status}
              vendorName={"Multiple Vendors"}
            />


          </InterestedBidsTableBox>
        </TabPanel>
        <TabPanel value={value} index={2} key={submissions}>


          <InterestedBidsTableBox>
            <Box display={"inline-flex"} justifyContent={"space-between"} alignItems={"center"}>
              {
                submissions === "pending" && <Box display={"inline-flex"} ml={2}>
                  <Cardview variant="h6" isTableView={cardView}>
                   Table  View
                  </Cardview>
                  <AntSwitch
                    checked={cardView}
                    onChange={() => handleToggleChange()}
                  />
                  <Tableview variant="h6" isTableView={cardView}>
                     Card View
                  </Tableview>
                </Box>
              }
              <TabButtonsContainer>

                <Button
                  sx={{ margin: "0 6px 0 6px" }}
                  variant={submissions === "requested" ? "contained" : "outlined"}
                  onClick={() => setSubmissions("requested")}
                >
                  Requested
                </Button>
                <Button
                  sx={{ margin: "0 6px 0 6px" }}
                  variant={submissions === "pending" ? "contained" : "outlined"}
                  onClick={() => setSubmissions("pending")}
                >
                  Pending
                </Button>
                <Button
                  sx={{ margin: "0 6px 0 6px" }}
                  variant={submissions === "accepted" ? "contained" : "outlined"}
                  onClick={() => setSubmissions("accepted")}
                >
                  Accepted
                </Button>
                <Button
                  sx={{ margin: "0 6px 0 6px" }}
                  variant={submissions === "rejected" ? "contained" : "outlined"}
                  onClick={() => setSubmissions("rejected")}
                >
                  Rejected
                </Button>

              </TabButtonsContainer>
            </Box>
            <>
              {submissions === "requested" &&
                <Box display={"inline-flex"} ml={2} >
                  <LeftcontentText>
                    {selectedSubmissions.length} of {requestedSubmissions.length}  Selected
                  </LeftcontentText>
                  {/* <RejectTooltip title={"Reject"} placement="bottom" arrow>
                    <IconButton onClick={handleMultiReject}>
                      <RejectIcon />
                    </IconButton>
                  </RejectTooltip> */}
                  <Button variant="contained" onClick={handleMultiNotify}>
                    Notify Again
                  </Button>
                </Box>
              }
              {submissions === "pending" &&
                <>

                  <Box display={"inline-flex"} ml={2} >
                    <LeftcontentText>
                      {selectedSubmissions.length} of {pendingVendors.length}  Selected

                    </LeftcontentText>

                    <RejectTooltip title={"Reject"} placement="bottom" arrow>

                      <IconButton onClick={handleMultiReject}>
                        <RejectIcon />
                      </IconButton>
                    </RejectTooltip>
                    <AcceptTooltip title={"Accept"} placement="bottom" arrow>
                      <IconButton onClick={handleMultiAccept}>
                        <AcceptIcon />
                      </IconButton>
                    </AcceptTooltip>
                  </Box>
                </>
              }
              {submissions === "rejected" &&
                <Box display={"inline-flex"} ml={2} >
                  <LeftcontentText>
                    {selectedSubmissions.length} of {rejectedVendors.length}  Selected
                  </LeftcontentText>
                  <AcceptTooltip title={"Accept"} placement="bottom" arrow>
                    <IconButton onClick={handleMultiAccept}>
                      <AcceptIcon />
                    </IconButton>
                  </AcceptTooltip>
                </Box>
              }
              {submissions === "accepted" &&
                <Box display={"inline-flex"} ml={2} >
                  <LeftcontentText>
                    {selectedSubmissions.length} of {acceptedVendors.length}  Selected
                  </LeftcontentText>
                  <RejectTooltip title={"Reject"} placement="bottom" arrow>
                    <IconButton onClick={handleMultiReject}>
                      <RejectIcon />
                    </IconButton>
                  </RejectTooltip>
                </Box>
              }
            </>
            {
              submissions === "pending" && cardView && memoizedPendingVendors.length > 0 ? (
                <>
                  <InitialModal
                    btnName={buttonLabel}
                    key={uuidv4()}
                    open={initialModal}
                    handleClose={handleCloseInitialModal}
                    handleYesClick={() => handleinitialModalYesClick(acceptorRejectVendor)}
                    jobTitle={data?.job_title}
                    multiModal={false}
                    status={"pending submission"}
                    vendorName={acceptorRejectVendor?.name}
                  />

                  {memoizedPendingVendors.map((vendor) => (<ReusableCard {...generateCardData(vendor)} />))}
                </>
              ) : submissions === "accepted" && viewCvs ? (
                <EmployerCardView
                  data={submissonCvsList}
                  spinner={CvsFetchning}
                  handleBack={handleBack}
                  handleCallCvApi={handleCallCvApi}
                  vendorId={vendorId}
                />
              ) : (
                <ReusableTable
                  headers={
                    submissions === "pending"
                      ? PendingHeaders
                      : submissions === "accepted"
                        ? AcceptedHeaders
                        : submissions === "rejected"
                          ? RejectedHeaders
                          : RequestedHeaders
                  }
                  data={
                    submissions === "pending"
                      ? memoizedPendingVendors
                      : submissions === "accepted"
                        ? memoizedAcceptedVendors
                        : submissions === "rejected"
                          ? memoizedRejectedVendors
                          : memoizedRequestedSubmissions
                  }
                  itemsPerPage={10}
                  spinner={
                    submissions === "pending"
                      ? loadingPending
                      : submissions === "accepted"
                        ? loadingAccepted
                        : submissions === "rejected"
                          ? loadingRejected
                          : loadingRequestedSubmissions
                  }
                  actionsCellWidth={
                    submissions === "pending"
                      ? "80px"
                      : submissions === "requested"
                        ? "260px"
                        : "80px"
                  }
                  actions={
                    submissions === "pending"
                      ? PendingActions
                      : submissions === "accepted"
                        ? AcceptedActions
                        : submissions === "rejected"
                          ? RejectedActions
                          : RequestedActions
                  }
                  headerColor={themeColors.primaryLight}
                  checkBox={true}
                  handleDoBulk={handleDoBulk}
                />
              )
            }

            <InitialModal
              btnName={multiButtonName}
              key={uuidv4()}
              open={multiModalOpen}
              handleClose={(handleCloseMultiModal)}
              handleYesClick={handleMultiYesClick}
              jobTitle={data?.job_title}
              multiModal={false}
              // status={selectedSubmissions.status}
              vendorName={"Multiple Vendors"}
            />
          </InterestedBidsTableBox>

        </TabPanel>
        {value === 3 && (
          <TabPanel value={value} index={3} key={previewTabSubId}>
            {(eyeIconClicked || cvIdClicked) ? (
              <EmployerCardView
                data={submissonCvsList}
                spinner={CvsFetchning}
                handleBack={handleSubmissionDetailsCvBack}
                handleCallCvApi={handleCallCvApi}
                vendorId={vendorId}

              />
            ) : (
              <InterestedBidsTableBox>
                <LeftsiteContainer>
                  <Leftsitecontent>
                    <LeftcontentText>
                      {selectedSubmissions.length} of {memoizedSubmissionDetails.length} Selected
                    </LeftcontentText>

                    <LightTooltip
                      textColor={themeColors.primaryAlt}
                      title="Like"
                      arrow
                    >
                      <Check onClick={handleMultiLikes} />
                    </LightTooltip>

                    <LightTooltip
                      textColor={themeColors.primaryAlt}
                      title="Unlike"
                      arrow
                    >
                      <UncheckIcon onClick={handleMultiDislikes} />
                    </LightTooltip>

                    <LightTooltip
                      textColor={themeColors.primaryAlt}
                      title="Download"
                      arrow
                    >
                      <DownloadIcon onClick={handleMultidownload} />
                    </LightTooltip>

                  </Leftsitecontent>
                  <RightSiteICon>
                    {/* <Search id="search">
                    <SearchIconWrapper>
                      <SearchLogo />
                    </SearchIconWrapper>
                    <StyledInputBase
                      placeholder="Search…"
                      inputProps={{ "aria-label": "search" }}
                    />
                  </Search> */}
                    {/* <FunnelIcon onClick={()=>(toast.info("Comming Soon"))} />
                  <StyledButton variant="contained" onClick={()=>(toast.info("Comming Soon"))}>
                    View Submission Details
                  </StyledButton> */}
                  </RightSiteICon>
                </LeftsiteContainer>
                <ViewButton
                  variant="contained"
                  onClick={() => setViewSubDetailsModal(true)}
                >
                  View Submission Details
                </ViewButton>

                <ViewModal
                  SubmissionId={previewTabSubId}
                  openModal={viewSubDetailsModal}
                  title="Submission Id :"
                  onClose={() => setViewSubDetailsModal(false)}
                >
                  {vendorMySubmissionDetails && (
                    <Box sx={{ padding: '16px' }}>
                      <InfoBox
                        icon={VisaIcon}
                        label="Visa Type"
                        value={safeJoin(vendorMySubmissionDetails?.visaTypeWorkPermit)}
                      />
                      <InfoBox
                        icon={Location}
                        label="Location"
                        value={safeJoin(vendorMySubmissionDetails?.location)}
                      />
                      <InfoBox
                        icon={Skills}
                        label="No Of Profiles"
                        value={vendorMySubmissionDetails?.noOfProfiles}
                      />
                      <InfoBox
                        icon={EmployerLevel}
                        label="Employer Level"
                        value={vendorMySubmissionDetails?.employerLevel ?? "N/A"}
                      />
                      <InfoBox
                        icon={Commission}
                        label="Commission"
                        value={vendorMySubmissionDetails?.comission ?? "N/A"}
                      />
                      <InfoBox
                        icon={RateInfoicon}
                        label="Rate Card"
                        value={vendorMySubmissionDetails?.rateInfo ?? "N/A"}
                      />
                      <InfoBox
                        icon={Skills}
                        label="Primary Skills"
                        value={safeJoin(vendorMySubmissionDetails?.primarySkills)}
                      />
                      <InfoBox
                        icon={Skills}
                        label="Secondary Skills"
                        value={safeJoin(vendorMySubmissionDetails?.secondarySkills)}
                      />
                    </Box>
                  )}
                </ViewModal>

                <ReusableTable
                  headers={SubmissionsDetailsHeaders}
                  data={memoizedSubmissionDetails}
                  itemsPerPage={10}
                  actions={SubmissionDetailsActions}
                  spinner={isLoadingSubmissionDetails}
                  actionsCellWidth={"280px"}
                  headerColor={themeColors.primaryLight}
                  checkBox={true}
                  handleDoBulk={handleDoBulk}
                />
                <InitialModal
                  btnName={multiButtonName}
                  key={uuidv4()}
                  open={multiModalOpen}
                  handleClose={(handleCloseMultiModal)}
                  handleYesClick={handleMultiYesClick}
                  jobTitle={data?.job_title}
                  multiModal={false}
                  vendorName={"Multiple CV/s"}
                />

              </InterestedBidsTableBox>
            )}
          </TabPanel>
        )}

      </FullDiv>
    </MainContainer>
  );
}


const Viewcv = styled(Cvicon)({
  cursor: "pointer",
});
const CvId = styled("p")({
  textDecoration: "underline",
  color: themeColors.primaryDark,
})
const ViewButton = styled(Button)({
  width: "240px",
  marginLeft: "auto"
});


const LeftcontentText = styled(`p`)({
  marginLeft: "15px",
  color: themeColors.mediumDark,
  marginTop: "12px",
  marginBottom: 0,
  marginRight: "15px",
});
const LeftsiteContainer = styled(Box)({
  margin: "0px 7px",
  display: "flex",
  alignItems: "center",
  paddingTop: "6px",
});
const Leftsitecontent = styled(Box)({
  display: "flex",
  justifyContent: "flex-start",
  alignItems: "center",
  width: "100%",
  height: "42px",
});
const RightSiteICon = styled(Box)({
  display: "flex",
  flexDirection: "row",
  justifyContent: "center",
  gap: "15px",
});


const Cardview = styled(Typography)<{ isTableView: boolean }>(
  ({ isTableView }) => ({
    color: !isTableView ? themeColors.primaryDark : themeColors.blackGray,
    marginRight: "5px",
  })
);
const AntSwitch = styled(Switch)(({ theme }) => ({
  width: 50,
  height: 20,
  padding: 0,
  display: "flex",
  boxShadow: "10px,0px,0px,10px",
  "&:active": {
    "& .MuiSwitch-thumb": {
      width: 15,
    },
    "& .MuiSwitch-switchBase.Mui-checked": {
      transform: "translateX(32px)",
    },
  },
  "& .MuiSwitch-switchBase": {
    padding: 2,
    "&.Mui-checked": {
      transform: "translateX(30px)",
      "& + .MuiSwitch-track": {
        opacity: 1,
      },
      "& .MuiSwitch-thumb": {
        backgroundColor: `${themeColors.white} !important`,
      },
    },
  },
  "& .MuiSwitch-thumb": {
    boxShadow: "0 2px 4px 0 rgb(0 35 11 / 20%)",
    width: 15,
    height: 16,
    borderRadius: 10,
  },
  "& .MuiSwitch-track": {
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor: `${themeColors.primaryDark}`,
    boxSizing: "border-box",
  },
}));

const Tableview = styled(Typography)<{ isTableView: boolean }>(
  ({ isTableView }) => ({
    color: isTableView ? themeColors.primaryDark : themeColors.blackGray,
    marginLeft: "5px",
  })
);

