import React, { useState } from 'react';
import { Box, Container, IconButton, styled, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import MinimizeIcon from '@mui/icons-material/Minimize';
import {ReactComponent as FullViewicon} from "../../../assets/images/Full.svg";
import { themeColors } from 'core/Theming/theme';
import { VendorJobSubmission } from 'api/resources/modals';


interface ViewModalProps {
    title: string;
    children: React.ReactNode;
    openModal: boolean;
    SubmissionId: string;
    onClose: () => void;
}

const DetailsViewModal: React.FC<ViewModalProps> = ({ title, openModal, onClose, SubmissionId, children }) => {
    const [isMinimized, setIsMinimized] = useState<boolean>(false);

    const toggleMinimize = () => setIsMinimized(!isMinimized);

    return (
        <>
            {openModal && (
                <Box
                    sx={{
                        position: 'fixed',
                        bottom: '0',
                        right: '0', 
                        width: "400px",
                        height: isMinimized ? '50px' : '400px',
                        backgroundColor: '#FFF',
                        boxShadow: '0px 0px 4px 0px rgba(0, 0, 0, 0.25)',
                        borderRadius: isMinimized ? "8px" : "16px",
                        zIndex: 1000,
                        overflow: 'hidden',
                        transform: 'none',
                    }}
                >


                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            borderBottom: '1px solid #ddd',
                        }}
                    >
                        <ModalHeader isMinimized={isMinimized}>
                            <Typography variant="h6" color={themeColors.primaryDark}>{title}{SubmissionId}</Typography>

                            <Box >

                                <IconButton sx={{ color: themeColors.primaryDark }} onClick={toggleMinimize}>
                                    {isMinimized ? <FullViewicon /> : <MinimizeIcon sx={{ marginTop: "-14px" }} />}
                                </IconButton>
                                <IconButton onClick={onClose} sx={{ color: themeColors.primaryDark }}>
                                    <CloseIcon />
                                </IconButton>
                            </Box>
                        </ModalHeader>
                    </Box>


                    {!isMinimized && <Box sx={{ padding: '16px' }}>{children}</Box>}
                </Box>
            )}
        </>
    );
};

const ModalHeader = styled(Box, { shouldForwardProp: (prop) => prop !== 'isMinimized' })<{ isMinimized: boolean }>(
    ({ isMinimized }) => ({
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        backgroundColor: themeColors.primaryLight,
        padding: ' 0px 8px',
        paddingLeft:'16px',
        width:"100%",
        borderTopLeftRadius: isMinimized ? '8px' : '16px',
        borderTopRightRadius: isMinimized ? '8px' : '16px',
        borderBottom: '1px solid #ddd',
    })
);

export default DetailsViewModal;
