// vendorActionsStaticHeaders.ts

export const staticVendorActionsHeaders = [
  {
    title: "Row Id",
    filter: false,
    cellWidth: "100px",
    name: "id",
    show: false,
  },
  
  {
    title: "Vendor Name",
    filter: true,
    cellWidth: "200px",
    filterType: "text",
    name: "vendorName",
  },
  {
    title: "Vendor Performance (Submission)",
    filter: true,
    filterType: "number",
    cellWidth: "320px",
    name: "submissions",
  },
  {
    title: "Vendor Performance(CVs)",
    filter: true,
    filterType: "number",
    name: "cvCount",
    cellWidth: "320px",
  },
];

// pendingStaticHeaders.ts

export const staticPendingHeaders = [
 
  {
    title: "Row Id",
    filter: false,
    cellWidth: "100px",
    name: "id",
    show: false,
  },
  
  {
    title: "Vendor Name",
    filter: true,
    cellWidth: "200px",
    filterType: "text",
    name: "name",
  },
  {
    title: "Rate Card",
    filter: true,
    filterType: "number",
    name: "Bill_Rate",
  },
  {
    title: "Employee Level",
    filter: true,
    filterType: "number",
    name: "Employer_Level",
  },
  {
    title: "Commission Asked",
    filter: true,
    filterType: "number",
    name: "commission",
  },
  {
    title: "Candidate Visa",
    filter: true,
    filterType: "text",
    name: "Visa_Type",
  },
  {
    title: "Cvs Mentioned",
    filter: true,
    filterType: "number",
    name: "cvs_mentioned",
    cellWidth: "200px",
  },
  {
    title: "Cvs Submitted",
    filter: true,
    filterType: "number",
    name: "cvs_submitted",
    cellWidth: "200px",
  },
];

// acceptedStaticHeaders.ts

export const staticAcceptedHeaders = [
  
  {
    title: "Vendor Name",
    filter: true,
    cellWidth: "200px",
    filterType: "text",
    name: "name",
  },
  {
    title: "Rate Card",
    filter: true,
    filterType: "number",
    name: "Bill_Rate",
  },
  {
    title: "Employee Level",
    filter: true,
    filterType: "number",
    name: "Employer_Level",
  },
  {
    title: "Commission Asked",
    filter: true,
    filterType: "number",
    name: "commission",
  },
  {
    title: "Candidate Visa",
    filter: true,
    filterType: "text",
    name: "Visa_Type",
  },
  {
    title: "Cvs Mentioned",
    filter: true,
    filterType: "number",
    name: "cvs_mentioned",
    cellWidth: "200px",
  },
  {
    title: "Cvs Submitted",
    filter: true,
    filterType: "number",
    name: "cvs_submitted",
    cellWidth: "200px",
  },
];

// rejectedStaticHeaders.ts

export const staticRejectedHeaders = [
 
  {
    title: "Vendor Name",
    filter: true,
    cellWidth: "200px",
    filterType: "text",
    name: "name",
  },
  {
    title: "Rate Card",
    filter: true,
    filterType: "number",
    name: "Bill_Rate",
  },
  {
    title: "Employee Level",
    filter: true,
    filterType: "number",
    name: "Employer_Level",
  },
  {
    title: "Commission Asked",
    filter: true,
    filterType: "number",
    name: "commission",
  },
  {
    title: "Candidate Visa",
    filter: true,
    filterType: "text",
    name: "Visa_Type",
  },
  {
    title: "Cvs Mentioned",
    filter: true,
    filterType: "number",
    name: "cvs_mentioned",
    cellWidth: "200px",
  },
  {
    title: "Cvs Submitted",
    filter: true,
    filterType: "number",
    name: "cvs_submitted",
    cellWidth: "200px",
  },
];

// requestedStaticHeaders.ts

export const staticRequestedHeaders = [
  {
    title: "Vendor Name",
    filter: true,
    cellWidth: "200px",
    filterType: "text",
    name: "vendorName",
  },
  {
    title: "Submissions till Date",
    filter: true,
    filterType: "number",
    name: "submissions",
    cellWidth: "200px",
  },
  {
    title: "Cvs Submitted",
    filter: true,
    filterType: "number",
    name: "cvCount",
    cellWidth: "200px",
  },
];

// submissionsStaticHeaders.ts

export const staticSubmissionsHeaders = [
  {
    title: "Row Id",
    filter: false,
    cellWidth: "100px",
    name: "id",
    show: false,
  },
  {
    name: "cv_name",
    title: "CV Name",
    width: 180,
  },
  {
    name: "last_job_title",
    title: "Last Job",
    width: 280,
  },
  {
    name: "higher_qualification",
    title: "Qualification",
    width: 160,
  },
  {
    name: "fullExperience",
    title: "Experience",
    width: 160,
  },
];
