import styled from "@emotion/styled";
import { Box, Button, Switch, Typography } from "@mui/material";
import { themeColors } from "core/Theming/theme";
import { useNavigate, useParams } from "react-router-dom";
import WestIcon from "@mui/icons-material/West";
import Submissionscards from "./Submissionsdetails";
import { ViewCVS } from "./Cv";
import { useEffect, useState } from "react";
import { useGetSubmissionDetails } from "pages/hooks";
import Submissionstable from "./Submissionstable";
import { RootState } from "core/store";
import { useSelector } from "react-redux";

const SubmissionsDetails = () => {
  const userInfo = useSelector((state: RootState) => state.auth?.isView);

  const { sumission_id: submissionId } = useParams();
  const navigate = useNavigate();
  const { submissionsDetails, refetch: refetcSubmissionDetails } =
    useGetSubmissionDetails(submissionId);

  const [isTableView, setTableView] = useState(false);

  const handleToggleChange = () => {
    setTableView((prev) => !prev);
  };

  const [isTable, setTable] = useState(false);

  const handleToggleChangeTable = () => {
    setTable((prev) => !prev);
  };

  useEffect(() => {
    refetcSubmissionDetails();
  }, [submissionId]);

  return (
    <MainContent>
      <Headingcontainer>
        <Headingtesx>
          <HeadingIcon
            onClick={() => navigate("/employer/submission-market-place")}
          />
          All Submissions
        </Headingtesx>
      </Headingcontainer>
      {submissionsDetails && <Submissionscards data={submissionsDetails} />}
      <SwitchContainer>
        {userInfo ? (
          <>
            <Cardview variant="h6" isTableView={isTable}>
              Card View
            </Cardview>
            <AntSwitch
              checked={isTable}
              onChange={() => handleToggleChangeTable()}
            />
            <Tebleview variant="h6" isTableView={isTable}>
              Table View
            </Tebleview>
          </>
        ) : (
          <>
            <Cardview variant="h6" isTableView={isTableView}>
              Table View
            </Cardview>
            <AntSwitch
              checked={isTableView}
              onChange={() => handleToggleChange()}
            />
            <Tebleview variant="h6" isTableView={isTableView}>
              Card View
            </Tebleview>
          </>
        )}
      </SwitchContainer>
      <MiddleContainer>
        <>
          {userInfo ? (
            <>
              {!isTable ? (
                <>
                  <ViewCVS />
                </>
              ) : (
                <>
                  <Submissionstable />
                </>
              )}
            </>
          ) : (
            <>
              {!isTableView ? (
                <>
                  <Submissionstable />
                </>
              ) : (
                <>
                  <ViewCVS />
                </>
              )}
            </>
          )}
        </>
      </MiddleContainer>
    </MainContent>
  );
};
const SwitchContainer = styled(Box)({
  display: "flex",
  justifyContent: "flex-end",
  flexDirection: "row",
  margin: "16px",
  padding: "10px",
});

const Tebleview = styled(Typography)<{ isTableView: boolean }>(
  ({ isTableView }) => ({
    color: isTableView ? themeColors.primaryDark : themeColors.blackGray,
    marginLeft: "5px",
  })
);

const Cardview = styled(Typography)<{ isTableView: boolean }>(
  ({ isTableView }) => ({
    color: !isTableView ? themeColors.primaryDark : themeColors.blackGray,
    marginRight: "5px",
  })
);
const AntSwitch = styled(Switch)(({ theme }) => ({
  width: 50,
  height: 20,
  padding: 0,
  display: "flex",
  boxShadow: "10px,0px,0px,10px",
  "&:active": {
    "& .MuiSwitch-thumb": {
      width: 15,
    },
    "& .MuiSwitch-switchBase.Mui-checked": {
      transform: "translateX(32px)",
    },
  },
  "& .MuiSwitch-switchBase": {
    padding: 2,
    "&.Mui-checked": {
      transform: "translateX(30px)",
      "& + .MuiSwitch-track": {
        opacity: 1,
      },
      "& .MuiSwitch-thumb": {
        backgroundColor: `${themeColors.white} !important`,
      },
    },
  },
  "& .MuiSwitch-thumb": {
    boxShadow: "0 2px 4px 0 rgb(0 35 11 / 20%)",
    width: 15,
    height: 16,
    borderRadius: 10,
  },
  "& .MuiSwitch-track": {
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor: `${themeColors.primaryDark}`,
    boxSizing: "border-box",
  },
}));

const MainContent = styled(Box)({
  width: "100%",
  padding: "20px",
  overflow: "hidden",
});

const Headingcontainer = styled(Box)({
  display: "flex",
  justifyContent: "space-between",
  padding: "16px 20px",
  border: `1px solid ${themeColors.emptyStar}`,
  borderRadius: "8px",
  marginBottom: "16px",
  background: themeColors.primaryLight,
});

const Headingtesx = styled(Typography)({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  color: themeColors.primaryDark,
  fontSize: "24px",
  fontWeight: "600",
});

const HeadingIcon = styled(WestIcon)({
  marginRight: "10px",
  color: themeColors.primary,
  cursor: "pointer",
});
const MiddleContainer = styled(Box)({
  marginTop: "16px",
});
export default SubmissionsDetails;
