import React, { useEffect, useMemo, useState } from 'react';
import { AppBar, Toolbar, IconButton, InputBase, Switch, Typography, Box, Avatar, Badge, Divider as MuiDivider, CircularProgress, Card, Popover, Tooltip } from '@mui/material';
import { styled, alpha } from '@mui/material/styles';
import SearchIcon from '@mui/icons-material/Search';
import NotificationsIcon from '@mui/icons-material/Notifications';
import logo from '../assets/images/logo 2.svg';
import { useAppDispatch, useAppSelector } from 'core/store';
import { checkUserType } from 'core/store/auth';
import { User, VendorNotifacation } from 'api/resources/modals';
import { useNavigate } from 'react-router-dom';
import { themeColors } from 'core/Theming/theme';
import { useGetVendorNotifications, UsepostVendorReadNotification } from 'pages/hooks';
import { toast } from 'react-toastify';

interface HeaderProps {
  completionPercentage?: number;
}
interface StyledProps {
  isRecruiter: boolean;
}
export const Header: React.FC<HeaderProps> = ({ completionPercentage }) => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch();
  const { isVendor } = useAppSelector(store => store.auth);
  const [isRecruiter, setIsRecruiter] = useState<boolean>(false);
  const userInfo: User | null = useAppSelector((state) => state.auth?.userInfo);
  const [vendorNotification, setVendorNotification] = useState<VendorNotifacation[]>([])
  const { vendorNotificatios = [], refetch: reFetchVendorNotifications, isLoading: CvsFetchning } = useGetVendorNotifications(userInfo?.user_id || '');
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const { vendorReadNotification, isLoading, error } = UsepostVendorReadNotification({
    onError: (error) => {
      console.error("Error reading vendor notification:", error); // Error handling
    },
    onSuccess: (data) => {
      console.log("Successfully read vendor notification:", data); // Success handling
    },
  });

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  useMemo(async () => {
    if (isRecruiter && isVendor && userInfo?.user_id) {
      let data = await reFetchVendorNotifications()
      setVendorNotification(data?.data || [])
    }
  }, [isRecruiter, isVendor, userInfo?.user_id, open])

  useEffect(() => {
    if (isVendor !== null) {
      setTimeout(() => {
        setIsRecruiter(isVendor);
        const path = (isVendor ? `/vendor${window.location.pathname.split('/vendor')[1] || '/dashboard'}` : `/employer${window.location.pathname.split('/employer')[1] || '/dashboard'}`)
        navigate(path)
      }, 100);
    }
  }, [isVendor]);

  useEffect(() => {
    if (isVendor !== null) {
      dispatch(checkUserType(isRecruiter));
      const path = (isVendor ? `/vendor${window.location.pathname.split('/vendor')[1] || '/dashboard'}` : `/employer${window.location.pathname.split('/employer')[1] || '/dashboard'}`)
      navigate(path)
    }
  }, [isRecruiter]);

  const handleToggleChange = () => {
    setIsRecruiter(prev => !prev);
  };

  const handleReadNotification = async (data: VendorNotifacation) => {
    try {
      if (data?.result?.rfq_id) {
        await vendorReadNotification({ uniqueID: data?.result?.uniqueID, read: true })
        navigate(`/vendor/rmp/${data?.result?.rfq_id}/submission-details`); handleClose();
      }
    } catch (err) {
      toast.error("can't open the notification please try again")
    }
  }

  const formatDate = (timestamp: number) => {
    try {
      const date = new Date(timestamp);
      const day = String(date.getDate()).padStart(2, '0');
      const month = String(date.getMonth() + 1).padStart(2, '0');
      const year = date.getFullYear();
      const timeString = date.toLocaleTimeString('en-GB', { hour: '2-digit', minute: '2-digit' });
      const dateString = `${day}-${month}-${year}`;
      return `${dateString}, ${timeString}`;
    } catch (err) {
      return '';
    }
  };

  return (
    <MainContainer isVendor={isRecruiter}>
      <Toolbar>
        <Logo src={logo} />
        {/* <Search id='search'>
          <SearchIconWrapper>
            <SearchIcon />
          </SearchIconWrapper>
          <StyledInputBase
            placeholder='Search…'
            inputProps={{ 'aria-label': 'search' }}
          />
        </Search> */}
        <Box sx={{ flexGrow: 1 }} />
        <React.Fragment>
          {isRecruiter && (
            <div>
              <IconButton sx={{ color: "#3D42DF" }} color="inherit" onClick={handleClick}>
                <Badge
                  badgeContent={vendorNotification?.length}
                  color="error"
                  overlap="circular"
                  anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                >
                  <StyledNotificationsIcon isRecruiter={isRecruiter} />
                </Badge>
              </IconButton>

              <Popover open={open} anchorEl={anchorEl} onClose={handleClose} anchorOrigin={{ vertical: 'bottom', horizontal: 'right', }} transformOrigin={{ vertical: 'top', horizontal: 'right', }}>
                <Card sx={{ width: '250px', maxHeight: '300px', overflowY: 'auto', boxShadow: 3 }}>
                  {vendorNotification?.length > 0 ? (
                    vendorNotification.map((notify, index) => {
                      return (
                        <StyledNotificationBox onClick={() => handleReadNotification(notify)} key={index}>
                          <Typography sx={{ padding: '0px 10px', cursor: 'pointer' }}>
                            <b>Job Title:</b> {notify?.result?.rfq_details?.job_title || 'Unknown'}
                          </Typography>
                          <Typography sx={{ padding: '10px 10px', cursor: 'pointer' }}>
                            <Tooltip title={notify?.result?.notification?.length > 30 ? notify?.result?.notification : ''} arrow>
                              <span>
                                <b>Notification:</b> {notify?.result?.notification?.length > 30 ? notify?.result?.notification?.slice(0, 30) + '...' : notify?.result?.notification}
                              </span>
                            </Tooltip>
                          </Typography>
                          <Typography sx={{ padding: '0 10px 10px 10px', cursor: 'pointer' }}>
                            <b>Time:</b> {formatDate(notify?.result?.created_at)}
                          </Typography>
                          <MuiDivider />
                        </StyledNotificationBox>
                      );
                    })
                  ) : (
                    <Typography sx={{ padding: '10px' }}>No notifications</Typography>
                  )}
                </Card>
              </Popover>
            </div>
          )}
        </React.Fragment>

        <StyledDivider orientation='vertical' variant='middle' flexItem />
        <ToggleButtonboxmodule>
          <AntSwitch checked={isRecruiter} onChange={() => handleToggleChange()} />
          <ToggleButtonmaincontent>
            <StyledTypographyEmployer isRecruiter={isRecruiter} variant='subtitle1' color={isRecruiter ? themeColors.black : themeColors?.primaryDark}>
              Employer
            </StyledTypographyEmployer>
            <StyledTypographyVendor isRecruiter={isRecruiter} variant='subtitle1' color={!isRecruiter ? themeColors.black : themeColors?.purpleDark}>
              Vendor
            </StyledTypographyVendor>
          </ToggleButtonmaincontent>
        </ToggleButtonboxmodule>
        <StyledDivider orientation='vertical' variant='middle' flexItem />
        <Logininfo>
          <LoginInfomainContent onClick={() => navigate(!isVendor ? '/employer/profile' : '/vendor/profile')}>
            <CircularProgress variant='determinate' value={completionPercentage} />
            <Profilecontent>
              <Typography variant='caption' component='div' color='textSecondary'>
                <StyledAvatar alt='Profile Picture' />
              </Typography>
            </Profilecontent>
          </LoginInfomainContent>
          <Username variant='h4'>{`${userInfo?.general_profile?.first_name} ${userInfo?.general_profile?.last_name}`}</Username>
        </Logininfo>
      </Toolbar>
    </MainContainer >
  );
};

const StyledNotificationBox = styled(Box)({
  '&:hover': {
    background: themeColors?.purpleLight
  }
})

const Search = styled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.black, 0.05),
  '&:hover': {
    backgroundColor: alpha(theme.palette.common.black, 0.1),
  },
  marginRight: theme.spacing(2),
  marginLeft: 0,
  width: '100%',
  background: themeColors.white,
  color: themeColors.black,

  [theme.breakpoints.up('sm')]: {
    marginLeft: theme.spacing(3),
    width: '388px',
    borderRadius: '30px',
  },
}));

const Username = styled(Typography)({
  marginRight: '10px',
})
const SearchIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  color: '#202224',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const MainContainer = styled(AppBar)<{ isVendor: boolean }>(({ isVendor }) => ({
  backgroundColor: isVendor ? themeColors.purpleLight : themeColors.primaryLight,
  position: 'static',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: 'inherit',
  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 1, 1, 0),
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: '12ch',
      '&:focus': {
        width: '20ch',
      },
    },
  },
}));

const Logo = styled(`img`)({
  marginLeft: '10px',
  marginRight: '25px',
})

const AntSwitch = styled(Switch)(({ theme }) => ({
  width: 50,
  height: 20,
  padding: 0,
  display: 'flex',
  boxShadow: '10px,0px,0px,10px',
  '&:active': {
    '& .MuiSwitch-thumb': {
      width: 15,
    },
    '& .MuiSwitch-switchBase.Mui-checked': {
      transform: 'translateX(32px)',
    },
  },
  '& .MuiSwitch-switchBase': {
    padding: 2,
    '&.Mui-checked': {
      transform: 'translateX(30px)',
      '& + .MuiSwitch-track': {
        opacity: 1,
        backgroundColor: '#ddcaf9',
      },
      '& .MuiSwitch-thumb': {
        backgroundColor: `${themeColors.primaryColorDark} !important`,
      },
    },
  },
  '& .MuiSwitch-thumb': {
    boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
    width: 15,
    height: 16,
    backgroundColor: '#2E5BCD !important',
    borderRadius: 10,
    transition: theme.transitions.create(['width'], {
      duration: 200,
    }),
  },
  '& .MuiSwitch-track': {
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor: '#B6C6ED',
    boxSizing: 'border-box',
  },
}));

const ToggleButtonboxmodule = styled(Box)`
  display: flex;
  align-items: center;
  margin-left: 18px;
  margin-right: 18px;
  flex-direction: column;
  min-width: 116px;
`;

const ToggleButtonmaincontent = styled(Box)`
  display: flex;
`;

const StyledDivider = styled(MuiDivider)`
  margin: 10px 16px;
  background: ${themeColors.emptyStar};
`;

const Logininfo = styled(Box)`
  display: flex;
  align-items: center;
  gap: 5px
`;
const LoginInfomainContent = styled(Box)`
  position: relative;
  display: inline-flex;
   margin-top: 20px;
   margin-bottom: 20px;
    cursor:pointer;
`;
const Profilecontent = styled(Box)`
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
`;
const StyledAvatar = styled(Avatar)({
  color: themeColors.white,
});

const StyledTypographyVendor = styled(Typography) <StyledProps>`
  margin-left: 1rem;
`;

const StyledTypographyEmployer = styled(Typography) <StyledProps>`
  margin-left: 1rem;
`;

const StyledNotificationsIcon = styled(NotificationsIcon) <StyledProps>`
color: ${({ isRecruiter }) => isRecruiter ? themeColors.purpleDark : themeColors.primaryDark}
`;
