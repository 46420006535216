export const VendorSideNavData = [
  {
    component: "Dashboard",
    icon: "/Assets/sidenavIcons/dashboard.svg",
    selectedIcon: "/Assets/sidenavIcons/ColoredIcons/dashboardPurple.svg",
    url: "/vendor/dashboard",
  },
  {
    component: "All Requisitions",
    icon: "/Assets/sidenavIcons/My RFRs.svg",
    selectedIcon: "/Assets/sidenavIcons/ColoredIcons/RmpPurple.svg",
    url: "/vendor/rmp",
  },
  {
    component: "My Network",
    icon: "/Assets/sidenavIcons/My Network.svg",
    selectedIcon: "/Assets/sidenavIcons/ColoredIcons/MyNetworkPurple.svg",
    url: "/vendor/my-network",
  },
  {
    component: "My Submissions",
    icon: "/Assets/sidenavIcons/fire.svg",
    selectedIcon: "/Assets/sidenavIcons/ColoredIcons/firePurple.svg",
    url: "/vendor/my-submissions",
  },
  {
    component: "My CVs",
    icon: "/Assets/sidenavIcons/cvs.svg",
    selectedIcon: "/Assets/sidenavIcons/ColoredIcons/cvsPurple.svg",
    url: "/vendor/my-cv",
  },
  {
    component: "Analytics",
    icon: "/Assets/sidenavIcons/Analytics.svg",
    selectedIcon: "/Assets/sidenavIcons/ColoredIcons/AnalyticsPurple.svg",
    url: "/vendor/analytics",
  },
  {
    component: "Chats",
    icon: "/Assets/sidenavIcons/Chats.svg",
    selectedIcon: "/Assets/sidenavIcons/ColoredIcons/ChatsPurple.svg",
    url: "/vendor/chats",
  },
  {
    component: "Profile",
    icon: "/Assets/sidenavIcons/Profile.svg",
    selectedIcon: "/Assets/sidenavIcons/ColoredIcons/ProfilePurple.svg",
    url: "/vendor/profile",
  },
  {
    component: "Settings",
    icon: "/Assets/sidenavIcons/Settings.svg",
    selectedIcon: "/Assets/sidenavIcons/ColoredIcons/SettingPurple.svg",
    url: "/vendor/settings",
  },
];
