import { themeColors } from "core/Theming/theme"

export const BadgeBackgrounds = {
    'pending': themeColors.badgeWarning,
    'accepted': themeColors.badgeSuccess,
    'rejected': themeColors.badgeError,
    'interested': themeColors.purpleLight,
    'not-interested': themeColors.badgeGray,
}

export const BadgeColors = {
    'pending': themeColors.warning,
    'accepted': themeColors.success,
    'rejected': themeColors.error,
    'interested': themeColors.purpleDark,
    'not-interested': themeColors.grayBlack
}
