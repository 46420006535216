import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { Button, Grid } from '@mui/material';
import styled from '@emotion/styled';
import { ButtonProps } from '@mui/material/Button';
import { useState } from 'react';
import Image1 from '../../assets/images/LandingPage/Group 48095453.svg'
import { useNavigate } from 'react-router-dom'; 


export default function Page2() {

    const [activeButton, setActiveButton] = useState<string | null>(null);
    const navigate = useNavigate(); 


    const handleClick = (buttonName: string) => {
        setActiveButton(buttonName);
        navigate('/signup'); 

    };

    const handleSignupClick = () => {
        navigate('/signup'); 
    };

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClose = () => {
        setAnchorEl(null);
    };
    return (
        <div className='page2background'>
            <React.Fragment>
                <Box sx={{ display: 'flex', alignItems: 'center', textAlign: 'center' }} className="Menubar">
                <MenuButtons
                        isActive={activeButton === 'C2C Requirements'}
                        onClick={() => handleClick('C2C Requirements')}
                    >C2C Requirements</MenuButtons>
                    <MenuButtons
                        isActive={activeButton === 'Post Hotlist'}
                        onClick={() => handleClick('Post Hotlist')}
                    >Post Hotlist</MenuButtons>
                    <MenuButtons
                        isActive={activeButton === 'Post Requisition'}
                        onClick={() => handleClick('Post Requisition')}
                    >
                            Post Requisition</MenuButtons>
                            <MenuButtons
                        isActive={activeButton === 'Start Submission'}
                        onClick={() => handleClick('Start Submission')}
                    >Start Submission</MenuButtons>

                </Box>
                <Grid  className='gridcontent'>
                <Box className='imageContainer'
                            component="img"
                            alt="Page2Image"
                            src={Image1}
                        />
                </Grid>
                <Grid container spacing={2} className='maincontent'>
                    <Grid item xs={5} className="aboutContent">

                        <Typography variant="h1">About <span className='talentcvhange'>Talent Xchange </span></Typography>
                        <Typography variant="body1" className='description'>
                            Talent Xchange is a dynamic Corp-to-Corp marketplace that helps employers by connecting with multiple vendors.
                            Our platform offers an all-in-one solution for job listings and candidate submissions, enabling organizations
                            to fulfill their C2C needs with ease and efficiency.
                        </Typography>

                    </Grid>

                    <Grid item xs={4} className='signincontent'>
                    <StartSubmit onClick={handleSignupClick}>Sign Up and Start your Free Trial Today!</StartSubmit>

                    </Grid>
                </Grid>

            </React.Fragment >



        </div >
    )
}
const MenuButtons = styled(Typography)<{ isActive?: boolean }>(({ isActive }) => ({
    minWidth: '100px',
    padding: '8px',
    display: 'flex',
    justifyContent: 'center',
    borderRadius: '54px',
    width: '150px',
    height: '40px',
    textAlign: 'center',
    color: '#FFFFFF',
    background: isActive
        ? 'var(--Active-Gradient, linear-gradient(113deg, rgba(0, 255, 0, 0.47) 0%, rgba(0, 255, 0, 0.00) 110.84%))'
        : 'var(--Main-Gradient, linear-gradient(113deg, rgba(255, 255, 255, 0.47) 0%, rgba(255, 255, 255, 0.00) 110.84%))',
    backdropFilter: isActive ? 'blur(12.5px)' : 'blur(18.7px)',
    boxShadow: isActive
        ? `0px 39px 56px -36px rgba(255, 255, 255, 0.50) inset, 
           0px 7px 11px -4px #FFF inset, 
           0px -82px 68px -64px rgba(15, 240, 109, 0.30) inset, 
           0px 98px 100px -48px rgba(157, 66, 241, 0.30) inset, 
           0px 4px 18px 0px rgba(122, 51, 187, 0.30) inset, 
           0px 1px 40px 0px rgba(69, 242, 141, 0.40) inset`
        : 'none',
    cursor: 'pointer',
    '&:hover': {
        background: 'var(--Active-Gradient, linear-gradient(113deg, rgba(0, 255, 0, 0.47) 0%, rgba(0, 255, 0, 0.00) 110.84%))',
        boxShadow: `0px 39px 56px -36px rgba(255, 255, 255, 0.50) inset, 
                    0px 7px 11px -4px #FFF inset, 
                    0px -82px 68px -64px rgba(15, 240, 109, 0.30) inset, 
                    0px 98px 100px -48px rgba(157, 66, 241, 0.30) inset, 
                    0px 4px 18px 0px rgba(122, 51, 187, 0.30) inset, 
                    0px 1px 40px 0px rgba(69, 242, 141, 0.40) inset`,
      },
}));

const StartSubmit = styled(Button)<ButtonProps>(() => ({
    display: 'flex',
    justifyContent: 'center',
    background: 'rgba(255, 255, 255, 0.01)',
    boxShadow: `0px 39px 56px -36px rgba(255, 255, 255, 0.50) inset, 
                0px 7px 11px -4px #FFF inset, 
                0px -82px 68px -64px rgba(15, 240, 109, 0.30) inset, 
                0px 98px 100px -48px rgba(157, 66, 241, 0.30) inset, 
                0px 4px 18px 0px rgba(122, 51, 187, 0.30) inset, 
                0px 1px 40px 0px rgba(69, 242, 141, 0.40) inset`,
    backdropFilter: 'blur(12.5px)',
    borderRadius: '60px',
    color: '#FFFFFF',
}));