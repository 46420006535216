import React, { useEffect, useMemo, useRef, useState } from "react";
import {
  Formik,
  Field,
  Form,
  ErrorMessage,
  FormikHelpers,
  FormikProps,
} from "formik";
import * as Yup from "yup";
import {
  TextField,
  Button,
  MenuItem,
  Select,
  FormControl,
  Box,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
  Grid,
  Chip,
  Tooltip,
} from "@mui/material";
import { styled } from "@mui/system";
import MenuIcon from "@mui/icons-material/Menu";
import { QuickRFRformModal, User } from "api/resources/modals";
import {
  useFetchUserLocations,
  useQuickRfqSkills,
  useQuickRfqVisatype,
} from "pages/hooks";
import DropdownSelector from "components/Multiselectdropdowns";
import Nestedmenumultiselect from "components/Multiselectnestedmenudropdowns";
import store, { useAppSelector } from "core/store";
import { themeColors } from "core/Theming/theme";
interface QuickRFRformProps {
  handleQuickRfrsubmit: (
    values: QuickRFRformModal,
    actions: FormikHelpers<QuickRFRformModal>,
    resetForm: () => void
  ) => void;
}

interface DropdownstyleProps {
  isVendor: boolean | null
}

const initialValues: QuickRFRformModal = {
  jobTitle: "",
  Locations: [],
  skills: [],
  visaType: [],
  billRateFrom: 0,
  billRateTo: 0,
  userId: "",
  Status: "",
  hourly: false,
  weekly: false,
  monthly: false,

};

const validationSchema = Yup.object({
  jobTitle: Yup.string()
    .required("Required")
    .matches(/^[A-Za-z\s]+$/, "Job title must contain only letters"),
  Locations: Yup.array()
    .of(Yup.string())
    .min(1, "Required")
    .required("Required"),
  billRateTo: Yup.number().required("Required").min(1, "billRateTo must > 0"),
  visaType: Yup.array().of(Yup.string()).min(1, "Required").required("Required"),
  billRateFrom: Yup.number().required("Required").min(1, "billRateFrom must > 0"),
  skills: Yup.array().of(Yup.string()).min(1, "Required").required("Required"),
  Status: Yup.string()
    .required("Required")
});

const QuickRFRForm: React.FC<QuickRFRformProps> = ({
  handleQuickRfrsubmit,
}) => {
  const { isVendor } = useAppSelector(store => store.auth)
  const { locations = [], isLoading: locationsLoading } = useFetchUserLocations();

  const [showMenu, setShowMenu] = useState(false);

  const textFieldRef = useRef<HTMLDivElement>(null);

  const { Skills = [], isLoading: skillsLoading } = useQuickRfqSkills();

  const { Visa = [], isLoading: visaLoading } = useQuickRfqVisatype();

  const nestedLocations = useMemo(() => {
    if (!locations.length) return {};
    const result: Record<string, Record<string, Record<string, string[]>>> = {};
    locations.forEach((location) => {
      const { country, state, city, zipCode } = location;
      if (!result[country]) result[country] = {};
      if (!result[country][state]) result[country][state] = {};
      if (!result[country][state][city]) result[country][state][city] = [];
      result[country][state][city].push(zipCode);
    });
    return result;
  }, [locations]);

  const handleClick = () => setShowMenu(true);
  const handleClose = () => setShowMenu(false);



  const skills = useMemo(() => {
    if (!Skills.length) {
      return ["No Locations Available"];
    }

    return Skills.map((skills) => skills.skill);
  }, [Skills]);

  const Visatype = useMemo(() => {
    return Visa.map(
      (Visa) => Visa?.Visa
    );
  }, [Visa])

  useEffect(() => { }, [visaLoading, skillsLoading, locationsLoading]);

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={(values, actions) => {
        handleQuickRfrsubmit(values, actions, actions.resetForm);
        actions.setSubmitting(false);
      }}
    >
      {({ values, setFieldValue, handleChange, handleSubmit }) => (
        <Form
          onSubmit={(e) => {
            e.preventDefault();
            handleSubmit(e);
          }}
        >
          <MainBox>
            <StyledBox>
              <Heading variant="h4">
                Quick Requisition
              </Heading>
              <StyledMenuIcon />
            </StyledBox>
            <Maincontent>
              <FormContent>
                <Box mt={2}>
                  <Grid container spacing={2}>
                    <Grid item md={6}>
                      <LabelName>
                        Job Title<RedIcon>*</RedIcon>
                      </LabelName>
                      <FormControl fullWidth margin="normal">
                        <Field
                          as={TextField}
                          name="jobTitle"
                          label="Job Title"
                          variant="outlined"
                        />
                        <ErrorMessages name="jobTitle" component="div" />
                      </FormControl>
                    </Grid>

                    <Grid item md={6}>
                      <TextName>
                        Location<RedIcon>*</RedIcon>
                      </TextName>
                      <Grid mt={0.8}>
                        <TextField
                          label="Locations"
                          fullWidth
                          value={values.Locations.join(", ")}
                          onClick={handleClick}
                          inputRef={textFieldRef}
                          InputProps={{
                            readOnly: true,
                            style: { color: 'transparent' },
                          }}
                        />
                        <Dropdownstyle isVendor={isVendor}>
                          {values.Locations.map((location, index) => (
                            <Tooltip key={index} title={location}>
                              <Chip
                                key={index}
                                label={location}
                                onDelete={() => {
                                  const updatedLocations = values.Locations.filter(
                                    (loc) => loc !== location
                                  );
                                  setFieldValue("Locations", updatedLocations);
                                }}
                              />
                            </Tooltip>
                          ))}
                        </Dropdownstyle>
                        {showMenu && (
                          <Nestedmenumultiselect
                            data={nestedLocations}
                            formik={{ values, setFieldValue }}
                            anchorEl={textFieldRef}
                            onClose={handleClose}
                          />
                        )}
                        <ErrorMessages name="Locations" component="div" />
                      </Grid>
                    </Grid>
                    <Grid item md={6}>
                      <DropdownSelector
                        name="skills"
                        label="Skills"
                        options={skills}
                        placeholder="select or type and enter Skills"
                        required
                      />
                    </Grid>
                    <Grid item md={6}>
                      <DropdownSelector
                        name="visaType"
                        label="VisaType"
                        options={Visatype}
                        placeholder="select or type and enter visaType"
                        required
                      />
                    </Grid>
                    <Grid item md={6}>
                      <Typography variant="h6" color={themeColors.grey}>Rate Info <RedIcon>*</RedIcon></Typography>
                      <RateinfoBox>
                        <RangeName>Range:</RangeName>
                        <Rangefrom>
                          <Field
                            as={TextField}
                            name="billRateFrom"
                            label="Range (min)"
                            variant="outlined"
                            type="number"
                          />
                          <ErrorMessages name="billRateFrom" component="div" />
                        </Rangefrom>
                        <p>to</p>
                        <Rangefrom>
                          <Field
                            as={TextField}
                            name="billRateTo"
                            label="Range (max)"
                            variant="outlined"
                            type="number"
                          />
                          <ErrorMessages name="billRateTo" component="div" />
                        </Rangefrom>
                        <StyledLabelName mt={2}>USD</StyledLabelName>
                      </RateinfoBox>
                    </Grid>
                    <Grid item md={6}>
                      <RadioButtoncontent>
                        <FormControl component="fieldset">
                          <RadioGroup
                            row
                            aria-label="rateType"
                            name="rateType"
                            value={values.Status}
                            onChange={(e) => {
                              const selectedValue = e.target.value;
                              setFieldValue("Status", selectedValue);
                              setFieldValue(
                                "hourly",
                                selectedValue === "hourly"
                              );
                              setFieldValue(
                                "weekly",
                                selectedValue === "weekly"
                              );
                              setFieldValue(
                                "monthly",
                                selectedValue === "monthly"
                              );
                            }}
                          >
                            <FormControlLabel
                              value="hourly"
                              control={<Radio />}
                              label="Hourly rate"
                            />
                            <FormControlLabel
                              value="weekly"
                              control={<Radio />}
                              label="Weekly rate"
                            />
                            <FormControlLabel
                              value="monthly"
                              control={<Radio />}
                              label="Monthly rate"
                            />
                          </RadioGroup>
                        </FormControl>
                      </RadioButtoncontent>
                      <ErrorMessages name="Status" component="div" />
                    </Grid>
                  </Grid>
                </Box>
              </FormContent>
              <Mainbuttondiv>
                <StyleButtom type="submit" variant="contained" color="primary">
                  Create a Quick Requisition
                </StyleButtom>
              </Mainbuttondiv>
            </Maincontent>
          </MainBox>
        </Form>
      )}
    </Formik>
  );
};

const Heading = styled(Typography)({
  color: themeColors.darksecondBlue,
  fontSize: '22px',
});

const MiddleGrid = styled(Grid)({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
});
const RateinfoBox = styled(Box)({
  display: "flex",
  marginTop: "25px",
  gap: "10px",
});
const RedIcon = styled(`span`)({
  color: themeColors.error,
});

const Dropdownstyle = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'isVendor',
})<DropdownstyleProps>(({ isVendor }) => {
  const colorClip = isVendor ? themeColors.purpleDark : themeColors.primaryDark
  const backgroundClip = isVendor ? themeColors.purpleLight : themeColors.primaryLight
  return {
    marginTop: '18px',
    width: '100%',
    '& .MuiChip-root': {
      background: backgroundClip,
      width: '173px',
      borderRadius: '5px',
      marginRight: '5px',
    },
    '& .MuiChip-deleteIcon': {
      color: colorClip,
    },
  };
});

const StyledLabelName = styled(Typography)({
  fontSize: "14px",
  fontWeight: 500,
  marginBottom: "20px",
});

const VisaStyle = styled(Typography)({
  fontSize: "14px",
  fontWeight: 500,
  marginBottom: "10px",
});

const RadioButtoncontent = styled(Box)({
  display: "flex",
  justifyContent: "flex-start",
  gap: 2,
  marginTop: '52px'
});


const Rangefrom = styled(FormControl)({
  width: "120px",
  height: "32px",
  borderRadius: "8px",
});

const RangeName = styled(`p`)({
  display: "flex",
  fontWeight: "500",
  color: themeColors.grey,
  marginTop: "10px",
});
const Currency = styled(FormControl)({
  width: "222px",
  height: "53px",
  borderRadius: "8px",
});
const MainBox = styled(Box)({
  borderRadius: "8px",
  border: `1px solid ${themeColors.emptyStar}`,
});
const StyleButtom = styled(Button)({
  width: "257px",
  height: "38px",
  borderRadius: "4px",
});
const Mainbuttondiv = styled(Box)({
  display: "flex",
  justifyContent: "center",
});
const StyledBox = styled(Box)({
  display: "flex",
  justifyContent: "space-between",
  padding: "15px 30px",
  boxShadow:
    "0px 9px 16px rgba(159, 162, 191, .18), 0px 2px 2px rgba(159, 162, 191, 0.32)",
});

const StyledMenuIcon = styled(MenuIcon)({
  color: themeColors.primaryDark,
});

const ErrorMessages = styled(ErrorMessage)({
  color: "red",
});
const Maincontent = styled(Box)({
  padding: "20px",
});

const FormContent = styled(Box)({
  marginBottom: "40px",
});

const LabelName = styled(`p`)({
  display: "flex",
  fontWeight: "500",
  color: themeColors.grey,
  margin: "0px",
});

const TextName = styled(`p`)({
  display: "flex",
  fontWeight: "500",
  color: themeColors.grey,
  marginTop: '0px'
});

export default QuickRFRForm;
