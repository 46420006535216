import React from "react";
import { styled } from "@mui/system";
import { Button, Paper, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { themeColors } from "core/Theming/theme";

interface QuickActionsCardProps {
  icon: string;
  text: string;
  url: string;
  theme: boolean | null;
}

const QuickActionsCard: React.FC<QuickActionsCardProps> = ({ icon, text, url, theme }) => {
  const navigate = useNavigate();
  const handleClick = () => {
    navigate(url)
  }

  return (
    <Container>
      <ClickableCard baColor={theme} onClick={() => handleClick()}>
        <Icon>
          <img src={icon} alt={text} />
        </Icon>
        <StyledTypography textColor={theme} variant="subtitle1">{text}</StyledTypography>
      </ClickableCard>
    </Container>
  )
};

const Container = styled('div')`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 2px;
  margin-left: 8px;
    margin-right: 8px;
    height:100%;
    padding-bottom:12px;

      @media (min-height: 1315px) {
    height: 125%;
}
`;

const ClickableCard = styled(Button) <{ baColor: boolean | null }>`
  text-align: start;
  align-items: center;
  display: flex;
  justify-content: flex-start;
  cursor: pointer;
  transition: box-shadow 0.3s ease;
  width: auto;
  margin: 10px 10px;
  background-color: ${(props) => props?.baColor ? themeColors?.purpleLight : themeColors?.primaryLight};

  &:hover {
    box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.3);
  }

  &:active {
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.5);
  }
`;

const Icon = styled(`div`)`
margin-top: 10px;
    margin-right: 16px;
`;

const StyledTypography = styled(Typography) <{ textColor?: boolean | null }>`
  font-size: 16px;
  font-weight: 500;
  line-height: 100%;
  color:${(props) => props?.textColor ? themeColors?.purpleDark : themeColors?.darksecondBlue}
`;



export default QuickActionsCard;
