import styled from "@emotion/styled";
import { Box } from "@mui/material";
import Spinner from "components/spinner/Spinner";
import { themeColors } from "core/Theming/theme";
import { useEffect, useState } from "react";
import { Outlet, useNavigate, useLocation } from "react-router-dom";

const UserLayout = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [spinner, showSpinner] = useState(true);

    useEffect(() => {
        setTimeout(() => {
            if (location.pathname === '/') {
                navigate('/signin');
                showSpinner(false);
            } else {
                showSpinner(false);
            }
        }, 100)
    }, [navigate, location.pathname]);

    return (
        <section>
            <StyledBox component="main">
                <Spinner show={spinner}>
                    <Maincontent>
                        <Outlet />
                    </Maincontent>
                </Spinner>
            </StyledBox>
        </section>
    );
};

const StyledBox = styled(Box)({
    flexGrow: 1,
    backgroundColor: themeColors.white,
});

const Maincontent = styled(Box)({
    display: "flex",
    position: "relative",
});

export default UserLayout;
