import styled from "@emotion/styled";
import { Box, Button, Card, CardContent, Typography } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { themeColors } from "core/Theming/theme";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import { ReactComponent as DownloadIcon } from "../../../assets/icons/Download.svg";
import { SubmissionDetailsModal } from "api/resources/modals";
import Spinner from "components/spinner/Spinner";
import { ReactComponent as Check } from "../../../assets/icons/Likeicon.svg";
import { ReactComponent as Notcheck } from "../../../assets/icons/Dislike.svg";
import { ReactComponent as Notes } from "../../../assets/icons/Notes.svg";
import { ReactComponent as VendorDetails } from "../../../assets/icons/Vendorcalls.svg";
import { ReactComponent as More } from "../../../assets/icons/Submissionsmoreicon.svg";
import { ReactComponent as Downloads } from "../../../assets/icons/Downloadsicon.svg";
import { ReactComponent as LeftarrowPdf } from "../../../assets/icons/Rightarrowpdf.svg";
import { ReactComponent as Rightarrowpdf } from "../../../assets/icons/Leftarrowpdf.svg";
import { ReactComponent as LikeIcon } from "../../../assets/icons/LikedCvIcon.svg";
import { ReactComponent as UnlikeIcon } from "../../../assets/icons/RfrIcon/Colored Unchecked.svg";
import { ReactComponent as Keeponhold } from "../../../assets/requ-submission/Keep on hold.svg";
import { ReactComponent as UnderonReview } from "../../../assets/requ-submission/UnderReview.svg";
import WestIcon from "@mui/icons-material/West";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { toast } from "react-toastify";
import {
  useCvDetailsNotes,
  useCvLikeOrDislike,
  useCvNotes,
  useCvOnHold,
  useGetSubmissionDetails,
} from "pages/hooks";
import { useNavigate, useParams } from "react-router-dom";
import NotesEditor from "../Components/Notes";
import { convertToRaw, EditorState } from "draft-js";
import { Vendordetails } from "../Components/Cvdetails";
import { ROUTES } from "core/routes";
import { GreenIcons, LowerIcons, MediumIcons, StyledSmartAccept, StyledSmartpending, StyledSmartReject } from "./Submissionstable";

export const ViewCVS = ({
  onHide,
  cvID,
}: {
  onHide?: () => void;
  cvID?: string | undefined;
}) => {

  const navigate = useNavigate();
  const CvID = cvID;

  const { sumission_id: submissionId } = useParams();

  const {
    submissionsDetails,
    refetch: refetcSubmissionDetails,
    isLoading,
  } = useGetSubmissionDetails(submissionId);

  const cvToDisplay = submissionsDetails?.cv?.find(
    (cv) => cv.id.toString() === CvID
  );

  const cvCardDisplay = submissionsDetails?.cv?.filter(
    (cv) => cv.id.toString() === CvID
  );

  const [currentPdfIndex, setCurrentPdfIndex] = useState(0);

  const [isRightClicked, setIsRightClicked] = useState(false);

  const [isLeftClicked, setIsLeftClicked] = useState(false);

  const [openNotes, setOpenNotes] = useState<boolean>(false);

  const [editorState, setEditorState] = useState(EditorState.createEmpty());

  const scrollRef = useRef<HTMLDivElement | null>(null);

  const Cv = submissionsDetails?.cv?.map((cv) => cv) || [];

  const [open, setOpen] = useState(false);

  const [selectedImages, setSelectedImages] = React.useState<File[]>([]);

  const cvId = Cv[currentPdfIndex] ? Cv[currentPdfIndex].id.toString() : "";

  const { cvLikeOrDislike } = useCvLikeOrDislike({
    onError: () => toast.error("Error Date requisition:"),
    onSuccess: () => {
      toast.success(" update successfully");
      refetcSubmissionDetails();
    },
  });

  const { cvOnHold } = useCvOnHold({
    onError: () => toast.error("Error Date requisition:"),
    onSuccess: () => {
      refetcSubmissionDetails();
    },
  });
  const { notesCvDetails = [], refetch: cvNotesDetails } = useCvDetailsNotes(
    cvId,
    openNotes
  );

  const { cvNotes } = useCvNotes({
    onError: () => toast.error("Error Date requisition:"),
    onSuccess: () => {
      toast.success(" update successfully");
      cvNotesDetails();
    },
  });

  useEffect(() => {
    refetcSubmissionDetails();
  }, [submissionId]);

  const notesCvDetailsArray = Array.isArray(notesCvDetails)
    ? notesCvDetails
    : [];

  const handleShare = () => {
    setOpen(true);
  };

  const handleShareclose = () => {
    setOpen(false);
  };

  const handleCardClick = (index: number) => {
    setCurrentPdfIndex(index);
  };

  const handleopenNotes = () => {
    setOpenNotes(true);
  };

  const handleClickLeft = () => {
    if (currentPdfIndex > 0) setCurrentPdfIndex((prevIndex) => prevIndex - 1);
    setOpenNotes(false)
  };

  const handleClickRight = () => {
    if (currentPdfIndex < Cv?.length - 1)
      setCurrentPdfIndex((prevIndex) => prevIndex + 1);
    setOpenNotes(false)
  };

  const handleScrollLeft = () => {
    if (scrollRef.current)
      scrollRef.current.scrollBy({ left: -200, behavior: "smooth" });

    setIsLeftClicked(true);
    setIsRightClicked(false);
  };

  const handleScrollRight = () => {
    if (scrollRef.current)
      scrollRef.current.scrollBy({ left: 200, behavior: "smooth" });

    setIsRightClicked(true);
    setIsLeftClicked(false);
  };

  const handleCheckClick = (cvId: string, status: string) => {
    cvLikeOrDislike({
      cvID: cvId,
      status: status,
    });
  };

  const handleCvOnHold=(cvId: string, status: string)=>{
    cvOnHold({
      cvID: cvId,
      status: status,
    });
    toast.success(`You have kept CV ID${cvId}  on hold for Review!`);
  }
  const handleFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = Array.from(event.target.files || []);
    toast.success('Image uploaded successfully!');
    setSelectedImages(files);
  };
  
  const convertImageToBase64 = (file: File): Promise<string> => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result as string);
      reader.onerror = (error) => reject(error);
    });
  };
  const handleSubmit = async() => {
    
    const contentState = editorState.getCurrentContent();
    const rawContent = convertToRaw(contentState);
    const base64Image = selectedImages.length > 0
    ? await convertImageToBase64(selectedImages[0]) 
    : '';
    cvNotes({
      cvId: cvId,
      notes: JSON.stringify(rawContent),
      notesImage:base64Image
    }),
      setEditorState(EditorState.createEmpty());
      setSelectedImages([]);
  };
  

  const handleDownloadCv = () => {
    const cvUrl = cvId;
    if (cvUrl) {
        fetch(cvUrl)
            .then(response => response.blob())
            .then(blob => {
                const url = URL.createObjectURL(blob);
                const a = document.createElement('a');
                a.href = url;
                a.download = `${cvId}.pdf`;
                document.body.appendChild(a);
                a.click();
                a.remove();
                URL.revokeObjectURL(url);
            })
            .catch(() => {
                toast.error('Failed to download CV');
            });
    }
};

const downloadAllCv=()=>{
  const cvList=Cv
  cvList.forEach(item => {
    const cvUrl = item.cv;
    if (cvUrl) {
        fetch(cvUrl)
            .then(response => response.blob())
            .then(blob => {
                const url = URL.createObjectURL(blob);
                const a = document.createElement('a');
                a.href = url;
                a.download = `${item.id}.pdf`; 
                document.body.appendChild(a);
                a.click();
                a.remove();
                URL.revokeObjectURL(url);
            })
            .catch(() => {
                toast.error(`Failed to download CV with ID: ${item.id}`);
            });
    }
});
}

  const handleClose = () => {
    setOpenNotes(false);
  };

  if (isLoading) {
    return <Spinner show={true} />;
  }
  let Details=60

  return (
    <MainContent>
      {submissionsDetails && submissionsDetails?.cv?.length > 0 ? (
        <MainGridbox>
          <Headingcontainer>
            <Headingtesx variant="h4">
              {CvID ? <Box><HeadingIcon onClick={onHide} /></Box> : <Box><HeadingIcon onClick={() => navigate(ROUTES.bidMarketPlace)} /></Box>}
              {submissionsDetails?.jobTitle}
            </Headingtesx>
            <HeaderLeft>
              <StyledButton variant="contained" onClick={downloadAllCv} >
                <Download />
                Downloads All
              </StyledButton>
              {/* <Mail onClick={()=>(toast.info("Comming Soon"))} /> */}
            </HeaderLeft>
          </Headingcontainer>

          <Contentcontainer>
            <LeftscrollPdf>
              <LeftarrowPdf onClick={handleClickLeft} />
            </LeftscrollPdf>
            <PdfContainer>
              {/* {cvToDisplay ? (
                <PdfView src={cvToDisplay.cv} type="application/pdf" />
              ) : (
                <PdfView
                  src={submissionsDetails?.cv[currentPdfIndex].cv}
                  type="application/pdf"
                />
              )} */}


                {cvToDisplay ? (
                  cvToDisplay.cv?.endsWith('.pdf') ? (
                    <PdfView src={cvToDisplay.cv} type="application/pdf" />
                  ) : cvToDisplay.cv.endsWith('.docx') ? (
                    <iframe
                      src={`https://docs.google.com/gview?url=${cvToDisplay.cv}&embedded=true`}
                      title="Document Viewer"
                      width="100%"
                      height="866px"
                    />
                  ) : null // handle other cases if needed
                ) : submissionsDetails?.cv[currentPdfIndex]?.cv ? (
                  submissionsDetails.cv[currentPdfIndex].cv.endsWith('.pdf') ? (
                    <PdfView
                      src={submissionsDetails.cv[currentPdfIndex].cv}
                      type="application/pdf"
                    />
                  ) : submissionsDetails.cv[currentPdfIndex].cv.endsWith('.docx') ? (
                    <iframe
                      src={`https://docs.google.com/gview?url=${submissionsDetails.cv[currentPdfIndex].cv}&embedded=true`}
                      title="Document Viewer"
                      width="100%"
                      height="866px"
                    />
                  ) : submissionsDetails.cv[currentPdfIndex].cv.endsWith('.doc') ? (
                    <iframe
                      src={`https://docs.google.com/gview?url=${submissionsDetails.cv[currentPdfIndex].cv}&embedded=true`}
                      title="Document Viewer"
                      width="100%"
                      height="866px"
                    />
                  ) : null // handle other cases if needed
                ) : null}


              {
                cvToDisplay ? (<>
                  <Iconcontainer>
                    {cvToDisplay &&
                      (cvToDisplay.status ===
                        "Liked" ? (
                        <div>
                          <LikeIcon />
                        </div>
                      ): (
                        <CheckIcon
                          onClick={() => handleCheckClick(cvToDisplay.id.toString(), "Liked")}
                        />
                      ))}
                    {cvToDisplay &&
                      (cvToDisplay.status ===
                        "Disliked" ? (
                        <Uncheckcontainer>
                          <ColoredUncheckedIcon />
                          DisLike
                        </Uncheckcontainer>
                      ):  (
                        <UncheckIcon
                          onClick={() => handleCheckClick(cvToDisplay.id.toString(), "Disliked")}
                        />
                      ))}

                      {cvToDisplay &&
                      (cvToDisplay.status ===
                        "On hold" ? (
                        <KeeponReviewContainer>
                          <UnderonReview />
                        </KeeponReviewContainer>
                      ) : (
                        <KeeponholdContainer>
                        <Keeponhold
                          onClick={() => handleCvOnHold(cvToDisplay.id.toString(), "On hold")}
                        />
                        </KeeponholdContainer>
                      ))}
                    <NoteIcon onClick={handleopenNotes} />
                    <VendorcallIcon onClick={handleShare} />
                    <DownloadIcons onClick={handleDownloadCv}  />
                    {/* <MoreIcon /> */}
                  </Iconcontainer>

                </>) : (
                  <Iconcontainer>
                    {submissionsDetails?.cv.length > 0 &&
                      (submissionsDetails?.cv[currentPdfIndex].status ===
                        "Liked" ? (
                        <div>
                          <LikeIcon />
                        </div>
                      ) :(<>
                      <CheckIcon
                            onClick={() => handleCheckClick(cvId, "Liked")}
                          />
                        </>
                      ))}
                    {submissionsDetails?.cv.length > 0 &&
                      (submissionsDetails?.cv[currentPdfIndex].status ===
                        "Disliked" ? (
                        <Uncheckcontainer>
                          <ColoredUncheckedIcon />
                          DisLike
                        </Uncheckcontainer>
                      ) : (
                        <>
                        <UncheckIcon
                            onClick={() => handleCheckClick(cvId, "Disliked")}
                          />
                        </>
                      ))}
                         {submissionsDetails?.cv.length > 0 &&
                      (submissionsDetails?.cv[currentPdfIndex].status ===
                        "On hold" ? (
                          <KeeponReviewContainer>
                          <UnderonReview />
                        </KeeponReviewContainer>
                      ) : (
                        <KeeponholdContainer>
                        <Keeponhold
                          onClick={() => handleCvOnHold(cvId, "On hold")}
                        />
                        </KeeponholdContainer>
                      ))}

                    <NoteIcon onClick={handleopenNotes} />
                    <VendorcallIcon onClick={handleShare} />
                    <DownloadIcons  onClick={handleDownloadCv}  />
                    {/* <MoreIcon /> */}
                  </Iconcontainer>
                )
              }
            </PdfContainer>
            {openNotes === true ? (
              <>
                <NotesEditor
                  open={openNotes}
                  handleClose={handleClose}
                  editorState={editorState}
                  setEditorState={setEditorState}
                  handleSubmit={handleSubmit}
                  handleFileUpload={handleFileUpload}
                  cvId={cvId}
                  notesCvDetails={notesCvDetailsArray}
                />
              </>
            ) : (
              ""
            )}
            <RightscrollPdf>
              <Rightarrowpdf onClick={handleClickRight} />
            </RightscrollPdf>
          </Contentcontainer>
          <Box>
            <SmartMaincontainer>
            <SmartScore >
            <>
            {Details >= 51 ? (
                <StyledSmartAccept>
                <GreenIcons />80% Match
                </StyledSmartAccept>
            ) : Details >= 35 ? (
                <StyledSmartpending>
                <MediumIcons />50% Match
                </StyledSmartpending>
            ) : Details < 35 ? (
                <StyledSmartReject>
                <LowerIcons />35% Match
                </StyledSmartReject>
            ) : (
                " -"
            )}
            </>
            </SmartScore>
            </SmartMaincontainer>
            <Cardscontained>
              <MainCardContainer>
                <LeftArrow
                  onClick={handleScrollLeft}
                  isClicked={isLeftClicked}
                />
                <ScrollContainer ref={scrollRef}>
                  {CvID && cvCardDisplay ? (
                    <>
                      {cvCardDisplay?.map((url, index) => (
                        <Cards
                          key={index}
                          onClick={() => handleCardClick(index)}
                          isSelected={index === currentPdfIndex}
                        >
                          <CardContents>
                            <Typography variant="h4" color="primary">
                              {index + 1}
                            </Typography>
                          </CardContents>
                        </Cards>
                      ))}
                    </>
                  ) : (
                    <>
                      {Cv.map((url, index) => (
                        <Cards
                          key={index}
                          onClick={() => handleCardClick(index)}
                          isSelected={index === currentPdfIndex}
                        >
                          <CardContents>
                            <Typography variant="h4" color="primary">
                              {index + 1}
                            </Typography>
                          </CardContents>
                        </Cards>
                      ))}
                    </>
                  )}
                </ScrollContainer>
                <RightArrow
                  onClick={handleScrollRight}
                  isClicked={isRightClicked}
                />
              </MainCardContainer>
            </Cardscontained>
          </Box>
          <Vendordetails
            open={open}
            handleClose={handleShareclose}
            cvId={cvId}
            vendorId={submissionsDetails?.vendor_id}
          />
        </MainGridbox>
      ) : (
        <Errormessage>No Cvs found</Errormessage>
      )}
    </MainContent>
  );
};

const LeftscrollPdf = styled(Box)({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  cursor: "pointer",
  marginRight: "30px",
});

const Uncheckcontainer = styled(Box)({
  cursor: "pointer",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  textAlign: "center",
  flexDirection: "column",
  color: themeColors.blackGray,
  fontSize: "12px",
  margin: "10px 0px",
});

export const SmartScore=styled(Box)({
    display: 'flex',
    justifyContent:'center',
    boxShadow: '0px 4px 4px 0px #00000040',
    width: '260px',
    height: '37px',
  borderRadius: '8px',
  border:'1px #DADADA'
})
const KeeponholdContainer=styled(Box)({
    cursor: "pointer",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
    flexDirection: "column",
    color: themeColors.blackGray,
    fontSize: "12px",
    margin: "10px 0px",
    marginRight:'14px',
});

export const SmartMaincontainer=styled(Box)({
  display:'flex',
  justifyContent:'center'
})

const KeeponReviewContainer=styled(Box)({
  cursor: "pointer",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  textAlign: "center",
  flexDirection: "column",
  color: themeColors.blackGray,
  fontSize: "12px",
  marginRight:'14px',
  marginBottom:'12px',
});
const ColoredUncheckedIcon = styled(UnlikeIcon)({
  height: "22px",
  width: "41px",
  cursor: "pointer",
  marginRight: "11px",
});
const RightscrollPdf = styled(Box)({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  cursor: "pointer",
  marginLeft: "30px",
});
const Errormessage = styled(Typography)({
  alignItems: "center",
  textAlign: "center",
});
const Contentcontainer = styled(Box)({
  display: "flex",
  justifyContent: "center",
  padding: "30px",
});

const Iconcontainer = styled(Box)({
  display: "flex",
  flexDirection: "column",
  marginLeft: "16px",
  justifyContent: "center",
  position: "absolute",
  top: "224px",
  right: "0px",
  background: "#fff",
  width: "80px",
  height: "419px",
  borderRadius: "12px",
  boxShadow: `0px 0px 4px 0px  ${themeColors.shadowColor}`,
  padding: "10px 0px",
});
const CheckIcon = styled(Check)({
  cursor: "pointer",
  height: "80px",
  width: "69px",
});
const UncheckIcon = styled(Notcheck)({
  cursor: "pointer",
  marginBottom: "16px",
  height: "80px",
  width: "69px",
  marginTop: "16px",
});
const VendorcallIcon = styled(VendorDetails)({
  cursor: "pointer",
  marginBottom: "16px",
  height: "80px",
  width: "69px",
});
const MoreIcon = styled(More)({
  cursor: "pointer",
  marginBottom: "16px",
  height: "80px",
  width: "69px",
});
const DownloadIcons = styled(Downloads)({
  cursor: "pointer",
  marginBottom: "16px",
  height: "80px",
  width: "69px",
});

const NoteIcon = styled(Notes)({
  cursor: "pointer",
  marginBottom: "16px",
  height: "80px",
  width: "69px",
});

const LeftArrow = styled(KeyboardArrowLeftIcon)<{ isClicked: boolean }>(
  ({ theme, isClicked }) => ({
    cursor: "pointer",
    color: isClicked ? themeColors.primaryDark : themeColors.lightgrey,
    "&.MuiSvgIcon-root": {
      width: "3rem",
      height: "3rem",
    },
  })
);
const RightArrow = styled(KeyboardArrowRightIcon)<{ isClicked: boolean }>(
  ({ theme, isClicked }) => ({
    cursor: "pointer",
    color: isClicked ? themeColors.primaryDark : themeColors.lightgrey,
    "&.MuiSvgIcon-root": {
      width: "3rem",
      height: "3rem",
    },
  })
);
const Cardscontained = styled(Box)({
  display: "grid",
  gridAutoFlow: "column",
  gridAutoColumns: "100%",
});

const HeadingIcon = styled(WestIcon)({
  marginRight: "10px",
  color: themeColors.primary,
  cursor: "pointer",
});

const Download = styled(DownloadIcon)({
  marginRight: "5px",
});
const MainCardContainer = styled(Box)({
  display: "flex",
  alignItems: "center",
  position: "relative",
  width: "100%",
});
const PdfContainer = styled(Box)({
  width: "80%",
  position: "relative",
});

const HeaderLeft = styled(Box)({
  alignItems: "center",
  display: "flex",
  justifyContent: "center",
});

const Mail = styled(MailOutlineIcon)({
  alignItems: "center",
  color: `${themeColors.primaryDark}`,
  marginLeft: "15px",
});

const StyledButton = styled(Button)({
  borderRadius: "5px",
  width: "161px",
  height: "36px",
  fontSize: "12px",
});
const CardContents = styled(CardContent)({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  cursor: "pointer",
});

const Cards = styled(Card)<{ isSelected: boolean }>(({ isSelected }) => ({
  width: "110px",
  marginBottom: "8px",
  height: "150px",
  marginRight: "18px",
  display: "flex",
  justifyContent: "center",
  boxShadow: "none",
  backgroundImage: 'url("/Assets/Images/resume1.svg")',
  "&:last-child": {
    marginBottom: 0,
  },
  border: isSelected
    ? `2px solid ${themeColors.primaryDark}`
    : `1px solid ${themeColors.emptyStar}`,
}));

const Footer = styled(Box)({
  display: "flex",
  justifyContent: "center",
});

const PdfView = styled(`embed`)({
  width: "100%",
  height: "866px",
  borderRadius: "10px",
});

const StyledButtons = styled(Button)({
  borderRadius: "5px",
  width: "224px",
  height: "39px",
  fontSize: "12px",
  marginTop: "15px",
  marginRight: "10px",
  marginBottom: "16px",
});

const MainGridbox = styled(Box)({
  width: "100%",
  border: `1px solid ${themeColors.primaryLightDark}`,
  boxShadow: `0px 0px 4px 0px  ${themeColors.shadowColor}`,
  borderRadius: "8px",
});
const MainContent = styled(Box)({
  width: "100%",
});
const Headingcontainer = styled(Box)({
  display: "flex",
  justifyContent: "space-between",
  padding: "16px 20px",
  boxShadow: `0px 0px 4px 0px  ${themeColors.shadowColor}`,
});
const Headingtesx = styled(Typography)({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
});

const ScrollContainer = styled(Box)`
  display: flex;
  overflow-x: auto;
  white-space: nowrap;
  padding: 10px;

  & > * {
    flex-shrink: 0;
  }

  &::-webkit-scrollbar {
    height: 8px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: ${themeColors.badgeGray};
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background-color: ${themeColors.darkGrey};
  }

  &::-webkit-scrollbar-track {
    background-color: ${themeColors.white};
  }
`;