import styled from "@emotion/styled";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Chip,
  Tooltip,
  Typography,
} from "@mui/material";
import { themeColors } from "core/Theming/theme";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { SubmissionDetailsModal } from "api/resources/modals";
import Spinner from "components/spinner/Spinner";

interface Detailsprops {
  data: SubmissionDetailsModal | undefined;
}

const Submissionscards = ({ data }: Detailsprops) => {
  if (!data) {
    return <Spinner show={true} />;
  }

  const maxSkillsToShow = 1;
  const visibleSkills = data?.Visa_Type.slice(0, maxSkillsToShow);
  const hiddenSkills = data?.Visa_Type.slice(maxSkillsToShow);

  return (
    <>
      <AccordiansContent>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpantIcon />}
            aria-controls="panel1-content"
            id="panel1-header"
          >
            <Accordiansheadercontent>
              <HeaderName>Submission ID: </HeaderName>
              <StyledText>{data?.C2C_Vendor_Bid_Id}</StyledText>
              {data?.status === "pending" ? (
                <StyledButtonPending>{data?.status}</StyledButtonPending>
              ) : data?.status === "rejected" ? (
                <StyledButtonRejected>{data?.status}</StyledButtonRejected>
              ) : (
                <StyledButtonAccepted>{data?.status}</StyledButtonAccepted>
              )}
            </Accordiansheadercontent>
          </AccordionSummary>
          <AccordionDetails>
            <MainContainer>
              <Container>
                <Details>
                  <Heading>Job Title:</Heading>
                  <Text>{data?.jobTitle}</Text>
                </Details>
                <Details>
                  <Heading>Vendor ID: </Heading>
                  <Text>{data?.vendor_id}</Text>
                </Details>
                <Details>
                  <Heading>Candidate Visa: </Heading>
                  <Text>
                    {" "}
                    {visibleSkills.map((skill, index) => (
                      <Tooltip key={index} title={skill}>
                        <Skil label={skill} />
                      </Tooltip>
                    ))}
                    {hiddenSkills.length > 0 && (
                      <Tooltip title={hiddenSkills.join(", ")}>
                        <Skil label="..." />
                      </Tooltip>
                    )}
                  </Text>
                </Details>
                <Details>
                  <Heading>CVs Mentioned: </Heading>
                  <Text>{data?.cvsMentioned}</Text>
                </Details>
              </Container>
              <Container>
                <Details>
                  <Heading>Vendor Name:</Heading>
                  <Text>{data?.vendorName}</Text>
                </Details>
                <Details>
                  <Heading>Employee Level:</Heading>
                  <Text>{data?.Employer_Level}</Text>
                </Details>
                <Details>
                  <Heading>CVs Submitted: </Heading>
                  <Text>{data?.cvsSubmitted}</Text>
                </Details>
                <Details>
                  <Heading>Likes CVs:</Heading>
                  <Text>{data?.acceptedCVs}</Text>
                </Details>
              </Container>
              <Container>
                <Details>
                  <Heading>Submission Sent On:</Heading>
                  <Text>{data?.startDate}</Text>
                </Details>
                <Details>
                  <Heading>Rate card: </Heading>
                  <Text>{data?.billRateStatus}</Text>
                </Details>
                <Details>
                  <Heading>Commission:</Heading>
                  <Text>${data?.commission}</Text>
                </Details>
                <Details>
                  <Heading>Pending CVs:</Heading>
                  <Text>{data?.pendingCVs}</Text>
                </Details>
              </Container>
            </MainContainer>
          </AccordionDetails>
        </Accordion>
      </AccordiansContent>
    </>
  );
};

export const Skil = styled(Chip)({
  background: 'none',
  color: themeColors.grey,
  fontSize:'19px'
});
const MainContainer = styled(Box)({
  display: "flex",
  justifyContent: "space-between",
});

const Container = styled(Box)({
  display: "flex",
  flexDirection: "column",
});

const ExpantIcon = styled(ExpandMoreIcon)({
  color: themeColors.primaryDark,
});

const Details = styled(Box)({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  textAlign: "center",
  marginBottom: "16px",
});
const Heading = styled(Typography)({
  color: themeColors.primaryDark,
  textTransform: "capitalize",
  fontSize: "18px",
  fontWeight: "600",
});

const Text = styled(Typography)({
  color: themeColors.grey,
  marginLeft: "6px",
  fontSize: "18px",
  fontWeight: "400",
  textTransform: "capitalize",
});
const Accordiansheadercontent = styled(Box)({
  display: "flex",
  justifyContent: "flex-start",
  alignItems: "center",
  textAlign: "center",
  flexDirection: "row",
});
export const StyledButtonAccepted = styled(Button)({
  color: themeColors.success,
  textTransform: "capitalize",
  borderRadius: "30px",
  backgroundColor: themeColors.successLight,
  border: "none",
  "&:hover": {
    backgroundColor: themeColors.successLight,
    color: themeColors.success,
    border: "none",
  },
  fontSize: "12px",
  marginLeft: "16px",
});

export const StyledButtonPending = styled(Button)({
  color: themeColors.darkYellow,
  textTransform: "capitalize",
  borderRadius: "30px",
  backgroundColor: themeColors.lightYellow,
  border: "none",
  "&:hover": {
    backgroundColor: themeColors.lightYellow,
    color: themeColors.darkYellow,
    border: "none",
  },
  fontSize: "12px",
  marginLeft: "16px",
});

export const StyledButtonRejected = styled(Button)({
  color: themeColors.darkRed,
  textTransform: "capitalize",
  borderRadius: "30px",
  backgroundColor: themeColors.lightRed,
  border: "none",
  "&:hover": {
    backgroundColor: themeColors.lightRed,
    color: themeColors.darkRed,
    border: "none",
  },
  fontSize: "12px",
  marginLeft: "16px",
});

const StyledText = styled(Typography)({
  color: themeColors.primaryDark,
  fontSize: "24px",
  fontWeight: "600",
});
const HeaderName = styled(Typography)({
  color: themeColors.black,
  marginRight: "5px",
  fontSize: "24px",
  fontWeight: "600",
});

const AccordiansContent = styled(Box)({
  border: `1px solid ${themeColors.primaryLightDark}`,
  boxShadow: `0px 0px 4px 0px  ${themeColors.shadowColor}`,
  borderRadius: "10px",
});
export default Submissionscards;
