import React, { useState, ChangeEvent, useRef, useEffect } from "react";
import { Box, Typography, IconButton, LinearProgress, styled, } from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";
import { ReactComponent as PdfIcon } from "../../assets/images/pdfIcon.svg";
import ArticleIcon from '@mui/icons-material/Article';

interface FileUploadProps {
    onFileChange: (file: File | null) => void;
    defaultPdfUrl?: string;
    resetKey?: any;
}

const CustomFileUpload: React.FC<FileUploadProps> = ({ onFileChange, defaultPdfUrl, resetKey }) => {
    const [file, setFile] = useState<File | null>(null);
    const [error, setError] = useState<string | null>(null);
    const [uploadProgress, setUploadProgress] = useState<number>(0);
    const [uploading, setUploading] = useState<boolean>(false);
    const fileInputRef = useRef<HTMLInputElement | null>(null);

    useEffect(() => {
        if (resetKey) {
            setFile(null);
            setUploadProgress(0);
            setUploading(false);
            setError(null);

            if (fileInputRef.current) {
                fileInputRef.current.value = '';
            }
        }
    }, [resetKey]);

    const simulateUploadProgress = () => {
        setUploading(true);
        let progress = 0;
        const interval = setInterval(() => {
            progress += 10;
            setUploadProgress(progress);
            if (progress >= 100) {
                clearInterval(interval);
                setUploading(false);
            }
        }, 500);
    };

    const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
        const selectedFile = event.target.files?.[0];
        if (selectedFile) {
            // Check if the file type is allowed
            const allowedTypes = [
                "application/pdf",
                "application/msword",
                "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
            ];

            if (!allowedTypes.includes(selectedFile.type)) {
                setError("Please upload a PDF or Word document (DOC or DOCX).");
                return;
            }
            setFile(selectedFile);
            setError(null);
            onFileChange(selectedFile);
            simulateUploadProgress();
        } else {
            setFile(null);
        }
    };


    const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
        event.preventDefault();
        const droppedFile = event.dataTransfer.files?.[0];
        if (droppedFile) {
            if (droppedFile.type !== "application/pdf") {
                setError("Please upload PDF or Doc files only.");
                return;
            }
            setFile(droppedFile);
            setError(null);
            onFileChange(droppedFile);
            simulateUploadProgress();
        }
    };

    const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
        event.preventDefault();
    };

    const handleRemoveFile = () => {
        setFile(null);
        setUploadProgress(0);
        setUploading(false);
        onFileChange(null);

        // Reset file input
        if (fileInputRef.current) {
            fileInputRef.current.value = '';
        }
    };

    return (
        <MainBox onDrop={handleDrop} onDragOver={handleDragOver}>
            {!file && !uploading ? (
                <>
                    <Typography variant="body1" mb={2}>
                        <input
                            type="file"
                            accept="application/pdf,application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                            onChange={handleFileChange}
                            style={{ display: "none" }}
                            id="file-upload"
                            ref={fileInputRef}
                        />
                        <label htmlFor="file-upload">
                            <IconButton component="span" color="primary">
                                <img src="/Assets/Images/fileUpload.svg" alt="Upload" />
                            </IconButton>
                        </label>
                        <Box>
                            <Typography variant="h3">No CV Uploaded</Typography>
                            <Typography>Supported format: PDF or Docs of size less than or equal to 2MB</Typography>
                        </Box>
                    </Typography>
                </>
            ) : (
                <>
                    {uploading ? (
                        <UploadingBox>
                            <UploadingSubBox>
                                {file?.type === "application/pdf" ? <PdfIcon /> : <ArticleIcon color="primary" />}
                                <Typography variant="body2" ml={1}>
                                    {file?.name}
                                </Typography>
                            </UploadingSubBox>
                            <Box flexGrow={1} ml={2}>
                                <LinearProgress
                                    variant="determinate"
                                    value={uploadProgress}
                                />
                            </Box>
                        </UploadingBox>
                    ) : (
                        <UploadedBox>
                            <PdfIcon />
                            <Typography variant="body1" ml={1}>
                                <a href={file?.name ? URL.createObjectURL(file) : ''} target="_blank" rel="noopener noreferrer">
                                    {file?.name}
                                </a>
                            </Typography>
                            <IconButton
                                color="error"
                                onClick={handleRemoveFile}
                                sx={{ ml: 1 }}
                            >
                                <CancelIcon />
                            </IconButton>
                        </UploadedBox>
                    )}
                </>
            )}
            {error && (
                <Typography color="error" variant="body2" mt={2}>
                    {error}
                </Typography>
            )}
        </MainBox>
    );
};

const MainBox = styled(Box)({
    border: `1px solid grey.400`,
    borderRadius: "8px",
    padding: "16px",
    textAlign: "center",
});

const UploadingBox = styled(Box)({
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
});

const UploadingSubBox = styled(Box)({
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
});

const UploadedBox = styled(Box)({
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
});

export default CustomFileUpload;