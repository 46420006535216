import React, { useEffect, useState } from "react";
import { Box, Button, Card, Grid, Tooltip, Typography } from "@mui/material";
import styled from "@emotion/styled";
import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh';
import { themeColors } from "core/Theming/theme";
import { useVendorDashboardSubmissions } from "pages/hooks";
import { useNavigate } from "react-router-dom";

interface CountdowncardsProps {
    userId: string
}

interface TimeRemaining {
    days: number;
    hours: number;
    minutes: number;
    seconds: number;
}

const VendorQuickSubmission: React.FC<CountdowncardsProps> = ({ userId }) => {
    const navigate = useNavigate();
    const { vendorDashboardSubmissions, error, refetch, isLoading } = useVendorDashboardSubmissions(userId ? userId : '');

    const handleSendSubmission = (id: string) => {
        navigate(`/vendor/rmp/${id}/submission-details`);
    };

    const CountdownTimer: React.FC<{ expiryDate: string }> = ({ expiryDate }) => {
        const [timeRemaining, setTimeRemaining] = useState<TimeRemaining>(calculateTimeRemaining(expiryDate));

        useEffect(() => {
            const interval = setInterval(() => {
                setTimeRemaining(calculateTimeRemaining(expiryDate));
            }, 1000);

            return () => clearInterval(interval);
        }, [expiryDate]);

        return (
            <StyledTime>
                <StyledCounterBox>
                    <StyledSpan>{timeRemaining.days.toString().padStart(2, '0')}</StyledSpan>
                    <Typography>DAYS</Typography>
                </StyledCounterBox>
                <StyledCounterBox>
                    <StyledSpan>{timeRemaining.hours.toString().padStart(2, '0')}</StyledSpan>
                    <Typography>HOURS</Typography>
                </StyledCounterBox>
                <StyledCounterBox>
                    <StyledSpan>{timeRemaining.minutes.toString().padStart(2, '0')}</StyledSpan>
                    <Typography>MINS</Typography>
                </StyledCounterBox>
                <StyledCounterBox>
                    <StyledSpan>{timeRemaining.seconds.toString().padStart(2, '0')}</StyledSpan>
                    <Typography>SECS</Typography>
                </StyledCounterBox>
            </StyledTime>
        );
    };

    const calculateTimeRemaining = (expiryDate: string | number): TimeRemaining => {
        const now = new Date();
        let endDate = new Date(expiryDate);
        const isSameDay = now.toDateString() === endDate.toDateString();
        if (isSameDay) {
            endDate.setHours(23, 59, 59, 999); // Set endDate to today's end (23:59:59)
        }

        const timeDiff = endDate.getTime() - now.getTime();

        if (timeDiff <= 0) {
            return { days: 0, hours: 0, minutes: 0, seconds: 0 };
        }

        const days = Math.floor(timeDiff / (1000 * 60 * 60 * 24));
        const hours = Math.floor((timeDiff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        const minutes = Math.floor((timeDiff % (1000 * 60 * 60)) / (1000 * 60));
        const seconds = Math.floor((timeDiff % (1000 * 60)) / 1000);

        return { days, hours, minutes, seconds };
    };


    return (
        <MainBox>
            <StyledVendorBox>
                <Typography variant="h3" color={themeColors?.purpleDark}>Create a Quick Submission</Typography>
                <Typography sx={{ cursor: 'pointer' }} color={themeColors?.purpleDark} onClick={() => navigate('/vendor/rmp')}><b><u>View more</u></b></Typography>
            </StyledVendorBox>
            <Cardbox>
                <ScrollContainer>
                    <Grid container spacing={2} wrap="nowrap">
                        {isLoading ? (
                            <React.Fragment><Typography variant="h3">Loading</Typography></React.Fragment>
                        ) : (
                            <React.Fragment>
                                {vendorDashboardSubmissions?.map((item, index) => (
                                    <Grid item xs={12} sm={6} md={4} key={index}>
                                        <StyledCard>
                                            <Typography variant="h3">
                                                <Tooltip arrow title={item?.rfq_details?.job_title}>
                                                    <span style={{ cursor: 'pointer' }}>{item?.rfq_details?.job_title?.length > 20 ? item?.rfq_details?.job_title?.slice(0, 20) + '...' : item?.rfq_details?.job_title}</span>
                                                </Tooltip>
                                            </Typography>
                                            <CountdownTimer expiryDate={item?.rfq_details?.rfq_closing_date || ''} /><br />
                                            <Typography sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '5px' }} align="center">
                                                <Typography sx={{ fontSize: '36px', color: themeColors?.purpleDark }}>{item?.submissions_count}</Typography><Typography> Submissions received <br /> till date</Typography>
                                            </Typography>
                                            <StyledButton variant="contained" onClick={() => handleSendSubmission(item?.rfq_details?.rfq_id || '')}>
                                                <AutoFixHighIcon /> Smart Fill the Submission
                                            </StyledButton>
                                        </StyledCard>
                                    </Grid>
                                ))}
                            </React.Fragment>
                        )}
                    </Grid>
                </ScrollContainer>
            </Cardbox>
        </MainBox>
    );
};

const StyledCounterBox = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    boxShadow: `0 0 0 0.2px ${themeColors?.purpleDark}`,
    padding: '5px',
    color: themeColors?.purpleDark,
    borderRadius: '5px'
})

const Cardbox = styled(Box)({
    display: 'grid',
    gridAutoFlow: 'column',
    gridAutoColumns: '100%',
});

const MainBox = styled(Card)({
    borderRadius: "8px",
    marginTop: "20px",
});

const StyledVendorBox = styled(Box)({
    padding: '15px 30px 0 30px',
    display: 'flex',
    justifyContent: 'space-between'
})

const ScrollContainer = styled(Box)`
  display: flex;
  overflow-x: auto;
  white-space: nowrap;
  padding: 20px;

  & > * {
    flex-shrink: 0;
  }

  &::-webkit-scrollbar {
    height: 8px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: ${themeColors.badgeGray};
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background-color: ${themeColors.grey};
  }

  &::-webkit-scrollbar-track {
    background-color: ${themeColors.white};
  }
`;

const CenterTypography = styled(Typography)`
    text-align: center
`

const StyledSpan = styled(Typography)`
  font-size: 24px;
`


const StyledButton = styled(Button)`
  margin-top: auto;
`;

const StyledCard = styled(Card)`
  margin: 10px;
  padding-left: 15px;
  padding-right: 16px;
  padding-top: 15px;
  padding-bottom: 20px;
  width: 300px;
  height: 300px;
  border-radius: 10px;
  background-color: white;
  border: 1px solid ${themeColors.purpleLight};
  display: flex;
  flex-direction: column;
  overflow: hidden;
  text-align: center;
`;

const StyledTime = styled(Box)`
    margin-top: 16px;
    margin-right: 16px;
    display: flex;
    align-items: center;
    background-color: ${themeColors.purpleLight};
    border-radius: 8px;
    justify-content: center;
    padding: 10px;
    height: auto; 
    font-size: 16px;
    width: 100%;
    gap: 20px;
    box-sizing: border-box; 
`;

const ContentParagraph = styled('p')`
  margin-top: 15px; 
  padding: 0; 
  overflow: hidden; 
  word-wrap: break-word; 
  white-space: normal; 
  line-height: 1.5;
`;

export default VendorQuickSubmission;
