import {
  VendorMysubmitionsEmployer,
  VendorMysubmitionsEmployerTable,
  VendorSubmissionsTableModel,
} from "api/resources/modals";
import { useAppSelector } from "core/store";
import { UseGetVendorEmployerRequests } from "pages/hooks";

import {
  FilterIcon,
  LeftcontentText,
  LeftsiteContainer,
  Leftsitecontent,
  MainGridContainer,
  Maingridtable,
  RightSiteICon,
} from "pages/Vendor/rmp";
import { useEffect, useState } from "react";
import styled from "@emotion/styled";
import { Link, useNavigate } from "react-router-dom";
import { Stack, TableContainer, Tooltip, Typography } from "@mui/material";
import ReusableTable from "components/reusableFIlterTable/ReusableTable";
import { themeColors } from "core/Theming/theme";

interface Props {
  selectedTab: number;
  data: VendorMysubmitionsEmployer[];
  spinner: boolean
}

const EmployerRequestTab = ({ selectedTab, data, spinner }: Props) => {
  const navigate = useNavigate();
  const userId = useAppSelector((store) => store.auth.userInfo?.user_id);
  const [isLoading, setLoading] = useState(false);
  const [selectedSubmissions, setSelectedSubmissions] = useState<string[]>([]);
  const [employerRequestData, setEmployerRequestData] = useState<VendorMysubmitionsEmployerTable[]>([]);

  const headers = [
    {
      title: "Employer Name",
      name: "employerName",
      cellWidth: "200px",
      filterType: "text",
      filter: true,
      customComponent: (row: VendorMysubmitionsEmployerTable) => {
        return (
          <Stack direction="row" spacing={1} mt={0}>
            {row.employerName}
          </Stack>
        );
      },
    },
    {
      title: "Employer ID",
      name: "employerId",
      cellWidth: "200px",
      filterType: "text",
      filter: true,
      customComponent: (row: VendorMysubmitionsEmployerTable) => {
        return (
          <>
            <Stack direction="row" spacing={1} mt={0}>
              {row.employerId}
            </Stack>
          </>
        );
      },
    },
    {
      title: "Requested Date",
      name: "created_at",
      cellWidth: "200px",
      filterType: "date",
      filter: true,
    },
    {
      title: "Requisition Details",
      name: "requisitionDetails",
      cellWidth: "200px",
      filterType: "text",
      filter: true,
      customComponent: (row: VendorMysubmitionsEmployerTable) => {
        return (
          <>
            <Stack direction="column" spacing={1} mt={0}>
              <p>
                <Link to={`#`}>{row.requisitionDetails} </Link>
              </p>
              <p>{row.rfqId}</p>
            </Stack>
          </>
        );
      },
    },
    {
      title: "Visa Type",
      name: "visaType",
      cellWidth: "150px",
      filterType: "text",
      filter: true,
      customComponent: (row: VendorSubmissionsTableModel) => {
        return (
          <Tooltip title={row?.visaType} arrow>
            <Typography variant="body2" noWrap>
              {row?.visaType}
            </Typography>
          </Tooltip>
        );
      },
    },
    {
      title: "Employee Level",
      name: "employeeLevel",
      cellWidth: "200px",
      filterType: "text",
      filter: true,
      customComponent: (row: VendorMysubmitionsEmployerTable) => {
        return (
          <Stack justifyContent={'center'} direction="row" spacing={1} mt={0}>
            <p>{row.employeeLevel}</p>
          </Stack>
        );
      },
    },
  ];

  const actionItmes = [
    {
      type: "button",
      name: "actions",
      handleClick: (action: string, item: VendorSubmissionsTableModel) => { },
      customActionComponent: (row: VendorSubmissionsTableModel) => {
        return (
          <ActinItemDiv>
            <CreateSubmitionAction
              onClick={(e: React.MouseEvent) => {
                navigate(`/vendor/rmp/${row.rfqId}/submission-details`);
              }}
            >
              Create Submission
            </CreateSubmitionAction>
          </ActinItemDiv>
        );
      },
    },
  ];

  const transformData = (
    data: VendorMysubmitionsEmployer[] | []
  ): VendorMysubmitionsEmployerTable[] | [] => {
    const transformedData: VendorMysubmitionsEmployerTable[] | [] = data
      .filter(
        (item: VendorMysubmitionsEmployer) => Object.keys(item)?.length !== 0
      )
      .map((item: VendorMysubmitionsEmployer, index) => ({
        id: item?.user_id || "",
        employerName: item?.general_profile?.company_name || "-",
        employerId: item?.user_id || "-",
        created_at: item?.bidStatus?.created_at || 0,
        requisitionDetails: item.rfq_details.job_title || "-",
        visaType: item?.rfq_details?.visa_type?.join(', ') || "",
        employeeLevel: item?.rfq_details?.employee_level || "-",
        rfqId: item?.rfq_details?.rfq_id || "-",
      }));
    return transformedData;
  };

  const setRmpDataForTable = async () => {
    setLoading(true);
    switch (selectedTab) {
      case 1:
        setEmployerRequestData(transformData(data));
        break;
      default:
        setEmployerRequestData(transformData(data));
    }
    setLoading(false);
  };

  const handleDoBulk = () => { };

  useEffect(() => {
    setRmpDataForTable();
  }, [selectedTab]);

  return (
    <MainGridContainer>
      <LeftsiteContainer>
        <Leftsitecontent>
          <LeftcontentText>
            {`${selectedSubmissions?.length || 0} of ${employerRequestData?.length || 0
              } Selected`}
          </LeftcontentText>
        </Leftsitecontent>
        <RightSiteICon>
          <FilterIcon />
        </RightSiteICon>
      </LeftsiteContainer>
      <Maingridtable>
        <TableContainer>
          <ReusableTable
            key={selectedSubmissions?.length}
            headers={headers}
            data={employerRequestData}
            itemsPerPage={10}
            spinner={spinner}
            handleDoBulk={handleDoBulk}
            checkBox={true}
            headerColor={themeColors.purpleLight}
            inputBorder={themeColors.purpleDark}
            actions={actionItmes}
            actionsCellWidth={"200px"}
          />
        </TableContainer>
      </Maingridtable>
    </MainGridContainer>
  );
};

const CreateSubmitionAction = styled(`div`)({
  width: "160px",
  height: "35px",
  backgroundColor: themeColors.purpleDark,
  color: themeColors.white,
  justifyContent: "center",
  alignItems: "center",
  display: "flex",
  borderRadius: "30px",
  fontWeight: "600",
  fontSize: "14px",
});

const ActinItemDiv = styled(`div`)({
  margin: "11px 0px",
  display: "flex",
  gap: "8px",
  alignItems: "center",
});

export default EmployerRequestTab;
