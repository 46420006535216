import { AppBar, styled, Tab, Tabs } from "@mui/material";
import React from "react";

interface Props {
  selectedTab: number;
  changeTabs: (event: React.SyntheticEvent, newValue: number) => void;
  labels: string[];
}

export const CustomTabPanel = ({ selectedTab, changeTabs, labels }: Props) => {
  return (
    <MainAppbar position="static">
      <Tabs value={selectedTab} onChange={changeTabs}>
        {labels.map((eachTab, index) => (
          <Tab key={index} label={eachTab} />
        ))}
      </Tabs>
    </MainAppbar>
  );
};

const MainAppbar = styled(AppBar)({
  background: "none",
  width: "774px",
  boxShadow: "none",
  fontWeight: "400",
  "& .MuiTabs-flexContainer ": {
    display: "flex",
    justifyContent: "flex-start",
  },
  "& .MuiTabs-scroller": {
    paddingLeft: "16px",
  },
  marginLeft: "15px",
});
