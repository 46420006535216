import React, {
  useRef,
  useCallback,
  useMemo,
  useEffect,
  useState,
} from "react";
import {
  Button,
  TextField,
  MenuItem,
  FormControl,
  Select,
  ButtonGroup,
  CircularProgress,
  Grid,
  Box,
  Typography,
  Autocomplete,
} from "@mui/material";
import styled from "@emotion/styled";
import EditIcon from "@mui/icons-material/Edit";
import MuiPhoneNumber from "material-ui-phone-number";
import {
  useFetchUserLocations,
  useGetUserIDDetails,
  usePostSignData,
} from "pages/hooks";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import * as Yup from "yup";
import { PostUserIDdetailsModal } from "api/resources/modals";
import { ReactComponent as Upload } from "../../../../assets/images/Group 1.svg";
import NestedMenu from "components/Singleselectnestedmenudropdown";
import { themeColors } from "core/Theming/theme";
import Spinner from "components/spinner/Spinner";
const maxFileSizeMB = 0.5;

const validationSchema = Yup.object().shape({
  firstName: Yup.string()
    .matches(/^[A-Za-z\s]+$/, "First Name cannot contain numbers")
    .required("First Name is required"),
  lastName: Yup.string()
    .matches(/^[A-Za-z\s]+$/, "Last Name cannot contain numbers")
    .required("Last Name is required"),
  companyName: Yup.string()
    .matches(/^[A-Za-z\s]+$/, "Company Name cannot contain numbers")
    .required("Company Name is required"),
  phone: Yup.string()
    .matches(
      /^(\+?\d{1,3}[-.\s]?)?\d{10}$/,
      "Phone Number must be a valid number"
    )
    .required("Phone Number is required"),
  location: Yup.string().required("Location is required"),
  websiteLink: Yup.string().url("Invalid URL"),
  linkedin: Yup.string().url("Invalid URL"),
  role: Yup.string().required("You must select either Employer or Recruiter"),
});

export const Generalprofile: React.FC = () => {
  const storedUser = localStorage.getItem("user");
  const user = storedUser ? JSON.parse(storedUser) : null;
  const userId = user?.user_id;

  const { userdata, isLoading } = useGetUserIDDetails(userId);
  const [isEditable, setIsEditable] = useState(false);

  const { postSignData, isSigningIn } = usePostSignData({
    onSuccess: () => {
      toast.success("Submitted Successfully");
    },
    onError: () => toast.error("Something went wrong, please try again"),
  });
  const { locations = [], isLoading: locationsLoading } =
    useFetchUserLocations();

  const fileInputRef = useRef<HTMLInputElement>(null);
  const logoInputRef = useRef<HTMLInputElement>(null);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const textFieldRef = useRef<HTMLDivElement>(null);
  const [showMenu, setShowMenu] = useState(false);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
    setShowMenu(true);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setShowMenu(false);
  };

  const handleImageUpload = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>, setFieldValue: any) => {
      const file = event.target.files?.[0];
      if (file) {
        if (!file?.type.startsWith("image/")) {
          toast.error("Only image files are allowed.");
          return;
        }
        if (file.size > 1024 * 1024) {
          toast.error("Image size is too big. Maximum size is 1 MB.");
          return;
        }
        const reader = new FileReader();
        reader.onloadend = () => {
          setFieldValue("imageSrc", reader.result);
        };
        reader.readAsDataURL(file);
      }
    },
    []
  );

  const handleLogoFileChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>, setFieldValue: any) => {
      const selectedFile = event.target.files ? event.target.files[0] : null;
      if (selectedFile) {
        if (!selectedFile.type.startsWith("image/")) {
          toast.error("Only image files are allowed.");
          setFieldValue("error", "Only image files are allowed.");
          setFieldValue("logoSrc", null);
          setFieldValue("logoImage", "");
          setFieldValue("logoFile", null);
          return;
        }
        if (selectedFile.size > maxFileSizeMB * 1024 * 1024) {
          toast.error(`Error File size exceeds ${maxFileSizeMB}MB`);
          setFieldValue("error", `File size exceeds ${maxFileSizeMB}MB`);
          setFieldValue("logoSrc", null);
          setFieldValue("logoImage", "");
          setFieldValue("logoFile", null);
        } else {
          const reader = new FileReader();
          reader.onloadend = () => {
            setFieldValue("error", "");
            setFieldValue("logoSrc", reader.result);
            setFieldValue("logoImage", URL.createObjectURL(selectedFile));
            setFieldValue("logoFile", selectedFile);
          };
          reader.readAsDataURL(selectedFile);
        }
      }
    },
    []
  );

  const handleDivClick = useCallback(() => {
    fileInputRef.current?.click();
  }, []);

  const handleLogoButtonClick = useCallback(() => {
    logoInputRef.current?.click();
  }, []);

  const nestedLocations = useMemo(() => {
    if (!locations.length) {
      return {};
    }
  
    const result: Record<string, Record<string, { city: string; zipCode: string }[]>> = {};
  
    locations.forEach((location) => {
      const { country, state, city, zipCode } = location;
  
      if (!result[country]) {
        result[country] = {};
      }
  
      if (!result[country][state]) {
        result[country][state] = [];
      }
  
      result[country][state].push({ city, zipCode });
    });
  
    return result;
  }, [locations]);
  

  const formik = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      companyName: "",
      phone: "",
      location: "",
      websiteLink: "",
      linkedin: "",
      isEmployer: false,
      isRecruiter: false,
      role: "",
      imageSrc: null as string | ArrayBuffer | null,
      logoSrc: null as string | ArrayBuffer | null,
      logoFile: null as File | null,
      logoImage: "",
      error: "",
    },
    validationSchema,
    onSubmit: (values) => {
      const payload: PostUserIDdetailsModal = {
        fName: values.firstName,
        lName: values.lastName,
        phNo: values.phone,
        location: values.location,
        employer: values.isEmployer,
        recruiter: values.isRecruiter,
        companyName: values.companyName,
        userID: userId,
        websiteName: values.websiteLink,
        linkedin: values.linkedin,
        profileImage:
          values.imageSrc ||
          userdata?.general_profile?.profile_picture?.url ||
          null,
        companyLogo:
          values.logoSrc ||
          userdata?.general_profile?.company_logo?.url ||
          null,
      };

      postSignData(payload);
      setIsEditable(!isEditable);
    },
  });

  useEffect(() => {
    if (userdata) {
      formik.setValues({
        firstName: userdata?.general_profile?.first_name || "",
        lastName: userdata?.general_profile?.last_name || "",
        companyName: userdata?.general_profile?.company_name || "",
        phone: userdata?.general_profile?.phone_number || "",
        location: userdata?.general_profile?.location || "",
        websiteLink: userdata?.general_profile?.website_link || "",
        linkedin: userdata?.general_profile?.linkedIn_profile || "",
        isEmployer: userdata?.general_profile?.employer || false,
        isRecruiter: userdata?.general_profile?.recruiter || false,
        role: userdata?.general_profile?.employer
          ? "Employer"
          : userdata?.general_profile?.recruiter
          ? "Recruiter"
          : "",
        imageSrc: userdata?.general_profile?.profile_picture?.url || null,
        logoSrc: userdata?.general_profile?.company_logo?.url || null,
        logoFile: null,
        logoImage: userdata?.general_profile?.company_logo?.path || "",
        error: "",
      });
    }
  }, [userdata]);

  const toggleEditable = () => {
    setIsEditable((prevIsEditable) => !prevIsEditable);
  };

  if (isLoading || locationsLoading || isSigningIn || !userdata)
    return (
      <Grid
        display="flex"
        height={"80vh"}
        justifyContent="center"
        alignItems="center"
      >
        <Spinner show={true} />
      </Grid>
    );
  return (
    <DetailsPage>
      <FormContainer key={userdata?.id}>
        <form onSubmit={formik.handleSubmit}>
          <StyledBox>
            <StyledBox2
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <Typography variant="h4">General Information </Typography>
            </StyledBox2>
            <Button
              startIcon={<EditIcon />}
              variant="contained"
              onClick={toggleEditable}
            >
              Edit
            </Button>
          </StyledBox>
          <UploadImage onClick={handleDivClick}>
            <input
              disabled={!isEditable}
              type="file"
              accept="image/*"
              onChange={(e) => handleImageUpload(e, formik.setFieldValue)}
              ref={fileInputRef}
              style={{ display: "none" }}
            />
            {formik.values.imageSrc ? (
              <ProfileImage
                src={formik.values.imageSrc as string}
                alt="Uploaded Logo"
              />
            ) : userdata?.general_profile?.profile_picture?.path ? (
              <ProfileImage
                src={`https://xak9-hweo-h6q9.n7.xano.io${userdata?.general_profile?.profile_picture?.path}`}
                alt="Profile Image"
              />
            ) : (
              <UploadIcon />
            )}
          </UploadImage>
          <Formdiv>
            <Leftsite>
              <TextFieldContainer>
                <LabelName>
                  First Name <RedIcon>*</RedIcon>
                </LabelName>
                <TextField
                  label="First Name"
                  name="firstName"
                  value={formik.values.firstName}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  disabled={!isEditable}
                  variant="outlined"
                  fullWidth
                />
                {formik.touched.firstName && formik.errors.firstName && (
                  <StyledErrorMessage>
                    {formik.errors.firstName}
                  </StyledErrorMessage>
                )}
              </TextFieldContainer>
              <LeftstyleP>
                Phone Number<RedIcon>*</RedIcon>
              </LeftstyleP>
              <PhoneContainer>
                <MuiPhoneNumber
                  disabled={!isEditable}
                  defaultCountry={"us"}
                  onChange={(value) => formik.setFieldValue("phone", value)}
                  value={formik.values.phone}
                  fullWidth
                  variant="outlined"
                  label="Phone Number"
                  autoFormat={false}
                  countryCodeEditable={false}
                />
                {formik.touched.phone && formik.errors.phone && (
                  <StyledErrorMessage>{formik.errors.phone}</StyledErrorMessage>
                )}
              </PhoneContainer>
              <DefaultRole>
                <LabelName>What is your default role</LabelName>
                <CustomButtonGroup
                  variant="contained"
                  aria-label="contained primary button group"
                >
                  <EmployerButton
                    disabled={!isEditable}
                    onClick={() => {
                      formik.setFieldValue("isEmployer", true);
                      formik.setFieldValue("isRecruiter", false);
                      formik.setFieldValue("role", "Employer");
                    }}
                    selected={formik.values.isEmployer}
                  >
                    Employer
                  </EmployerButton>
                  <Recruiter
                    disabled={!isEditable}
                    onClick={() => {
                      formik.setFieldValue("isEmployer", false);
                      formik.setFieldValue("isRecruiter", true);
                      formik.setFieldValue("role", "Recruiter");
                    }}
                    selected={formik.values.isRecruiter}
                  >
                    Vendor
                  </Recruiter>
                </CustomButtonGroup>
                {formik.touched.role && formik.errors.role && (
                  <StyledErrorMessage>{formik.errors.role}</StyledErrorMessage>
                )}
              </DefaultRole>
              <div>
                <LabelName>Upload Company Logo</LabelName>
                <MainContent>
                  <Mainuploadlogo>
                    <Uploadlogodiv>
                      {formik.values.logoFile ? (
                        <Imagelogo
                          src={URL.createObjectURL(formik.values.logoFile)}
                          alt="Logo"
                        />
                      ) : (
                        <Imagelogo
                          src={`https://xak9-hweo-h6q9.n7.xano.io${userdata?.general_profile?.company_logo?.path}`}
                          alt="Logo"
                        />
                      )}
                    </Uploadlogodiv>
                    <div>
                      <text>
                        Please upload Company logo, size less than 0.5MB
                      </text>
                      <Logo>
                        <input
                          type="file"
                          accept="image/*"
                          ref={logoInputRef}
                          disabled={!isEditable}
                          style={{ display: "none" }}
                          onChange={(e) =>
                            handleLogoFileChange(e, formik.setFieldValue)
                          }
                        />
                        <ChooseFileButton
                          onClick={handleLogoButtonClick}
                          variant="outlined"
                        >
                          Choose File
                        </ChooseFileButton>
                        {formik.values.logoFile ? (
                          <Imagetext>{formik.values.logoFile?.name}</Imagetext>
                        ) : (
                          <Imagetext>No File Chosen</Imagetext>
                        )}
                      </Logo>
                    </div>
                  </Mainuploadlogo>
                </MainContent>
              </div>
            </Leftsite>
            <Rightsite>
              <TextFieldContainer>
                <LabelName>
                  Last Name<RedIcon>*</RedIcon>
                </LabelName>
                <TextField
                  disabled={!isEditable}
                  label="Last Name"
                  name="lastName"
                  value={formik.values.lastName}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  variant="outlined"
                  fullWidth
                />
                {formik.touched.lastName && formik.errors.lastName && (
                  <StyledErrorMessage>
                    {formik.errors.lastName}
                  </StyledErrorMessage>
                )}
              </TextFieldContainer>
              <TextFieldContainer>
                <LabelName>
                  Your Location<RedIcon>*</RedIcon>
                </LabelName>
                <FormControl fullWidth>
                  <TextField
                    disabled={!isEditable}
                    name="location"
                    label="Selected Location"
                    variant="outlined"
                    value={formik.values.location}
                    onClick={handleClick}
                    onChange={(event) =>
                      formik.setFieldValue("location", event.target.value)
                    }
                    error={
                      formik.touched.location && Boolean(formik.errors.location)
                    }
                    helperText={
                      formik.touched.location && formik.errors.location
                    }
                    ref={textFieldRef}
                  />
                  {showMenu && (
                    <NestedMenu
                      data={nestedLocations} 
                      formik={formik}
                      anchorEl={textFieldRef}
                      onClose={handleClose}
                    />
                  )}
                </FormControl>
              </TextFieldContainer>

              <TextFieldContainer>
                <LabelName>Website Link</LabelName>
                <TextField
                  disabled={!isEditable}
                  label="Website Link"
                  name="websiteLink"
                  value={formik.values.websiteLink}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.websiteLink &&
                    Boolean(formik.errors.websiteLink)
                  }
                  helperText={
                    formik.touched.websiteLink && formik.errors.websiteLink
                  }
                  variant="outlined"
                  fullWidth
                />
              </TextFieldContainer>
              <TextFieldContainer>
                <LabelName>
                  Company Name<RedIcon>*</RedIcon>
                </LabelName>
                <TextField
                  label="Company Name"
                  name="companyName"
                  disabled={!isEditable}
                  value={formik.values.companyName}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  variant="outlined"
                  fullWidth
                />
                {formik.touched.companyName && formik.errors.companyName && (
                  <StyledErrorMessage>
                    {formik.errors.companyName}
                  </StyledErrorMessage>
                )}
              </TextFieldContainer>
              <TextFieldContainer>
                <LabelName>LinkedIn Profile</LabelName>
                <TextField
                  label="LinkedIn Profile"
                  name="linkedin"
                  disabled={!isEditable}
                  value={formik.values.linkedin}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.linkedin && Boolean(formik.errors.linkedin)
                  }
                  helperText={formik.touched.linkedin && formik.errors.linkedin}
                  variant="outlined"
                  fullWidth
                />
              </TextFieldContainer>
            </Rightsite>
          </Formdiv>
          <SaveButtonFooter>
            <SaveNextButton
              disabled={!isEditable}
              variant="contained"
              color="primary"
              type="submit"
            >
              Submit
            </SaveNextButton>
          </SaveButtonFooter>
        </form>
      </FormContainer>
    </DetailsPage>
  );
};

const RedIcon = styled.span`
  color: ${themeColors.error};
`;

const Formdiv = styled.div`
  margin-top: 50px;
  display: flex;
`;

const Leftsite = styled.div`
  margin-right: 16px;
  width: 100%;
`;

const LeftstyleP = styled.p`
  font-weight: 500;
  color: ${themeColors.black};
`;

const UploadIcon = styled(Upload)`
  margin-top: 30px;
  cursor: pointer;
  width: 210px;
  height: 210px;
`;

const DefaultRole = styled.div`
  display: flex;
  flex-direction: column;
`;

const Mainuploadlogo = styled.div`
  display: flex;
`;

const Uploadlogodiv = styled.div`
  margin-right: 30px;
`;

const Logo = styled.div`
  display: flex;
  margin-top: 10px;
  align-items: center;
`;

const Imagelogo = styled.img`
  width: 81px;
  height: 81px;
  object-fit: cover;
  border-radius: 8px;
`;

const Imagetext = styled.p`
  margin: 0px;
  font-weight: 400;
  font-size: 14.35px;
  align-items: center;
`;

const Rightsite = styled.div`
  width: 100%;
`;

const SaveButtonFooter = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 15px;
`;

const ProfileImage = styled.img`
  margin-top: 30px;
  cursor: pointer;
  width: 210px;
  height: 210px;
`;

const StyledErrorMessage = styled.div`
  color: red;
  font-size: 0.8em;
`;

const DetailsPage = styled.div`
  align-items: center;
  padding: 0px 0px;
  padding-bottom: 70px;
  background-size: cover;
  background-repeat: no-repeat;
`;

const Title = styled.h1`
  font-weight: 700;
  margin: 0px;
  text-align: center;
`;

const FormContainer = styled.div`
  background: ${themeColors.white};
  box-shadow: 0 0.25rem 1rem rgba(206, 212, 240, 0.8);
  border-radius: 1rem;
  margin-top: 20px;
  padding: 0px 20px;
  padding-bottom: 20px;
  display: flex;
  justify-content: center;
  flex-direction: column;
`;

const UploadImage = styled.div`
  align-items: center;
  text-align: center;
`;

const TextFieldContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  max-width: 495px;
  width: 100%;

  @media only screen and (max-width: 1049px) {
    max-width: 425px;
    width: 100%;
  }
`;

const LabelName = styled.p`
  display: flex;
  font-weight: 500;
  color: ${themeColors.black};
`;

const CustomButtonGroup = styled(ButtonGroup)`
  box-shadow: none;
  width: 155px;
  height: 27px;
  border-radius: 10px;
  border: 1px solid rgba(0, 0, 0, 0.23);
  &.MuiButtonGroup-root {
    .MuiButtonGroup-firstButton,
    .MuiButtonGroup-middleButton {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
      border-right: 1px solid rgba(0, 0, 0, 0.23);
    }
  }
`;

const SaveNextButton = styled(Button)`
  margin-top: 20px;
  width: 172px;
  height: 55px;
  color: ${themeColors.white};
  border-radius: 10px;
  margin-top: 10px !important;
`;

const PhoneContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  max-width: 495px;
  width: 100%;

  @media only screen and (max-width: 1049px) {
    max-width: 425px;
    width: 100%;
  }
`;

const MuiPhoneNumber_1 = styled(MuiPhoneNumber)`
  .MuiOutlinedInput-root {
    padding: 0px;
    width: auto;
  }
`;

const MainContent = styled.div`
  padding: 20px;
  border: 5px dotted ${themeColors.badgeGray};
  border-radius: 10px;
`;

const ChooseFileButton = styled(Button)`
  width: 107px;
  height: 34px;
  font-size: 10px;
  margin-right: 10px;
  margin-left: 10px;
`;

const EmployerButton = styled(Button) <{ selected: boolean }>`
  && {
    background-color: ${(props) => (props.selected ? themeColors.primaryDark : themeColors.white)};
    color: ${(props) => (props.selected ? themeColors.white : themeColors.black)};
    font-size: 10px;
  }
`;

const Recruiter = styled(Button)<{ selected: boolean }>`
  && {
    background-color: ${(props) => (props.selected ? themeColors.purpleDark : themeColors.white)};
    color: ${(props) => (props.selected ? themeColors.white : themeColors.black)};
    font-size: 10px;
  }
`;

const StyledBox = styled(`div`)`
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
`;

const StyledBox2 = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export default Generalprofile;
