import { Box, styled, Typography } from "@mui/material";
import { themeColors } from "core/Theming/theme";

export interface InfoBoxProps {
  icon: React.ComponentType;
  label: string;
  value?: React.ReactNode;
}

export const InfoBox: React.FC<InfoBoxProps> = ({
  icon: Icon,
  label,
  value,
}) => (
  <MainDiv>
    <Icon />
    <CardKey>{label}:</CardKey>
    <CardValue>{value}</CardValue>
  </MainDiv>
);

const CardKey = styled(Typography)({
  marginLeft: "8px",
  color: themeColors.primaryDark,
  fontSize: "18px",
  fontStyle: "normal",
  fontWeight: 600,
});

const CardValue = styled(Typography)({
  marginLeft: "8px",
  color: themeColors.black,
  fontSize: "18px",
  fontStyle: "normal",
  fontWeight: 400,
});

const MainDiv = styled(Box)({
  display: "flex",
  alignItems: "center",
  marginBottom: 8,
});

export default InfoBox;
