import { useParams } from "react-router-dom";
import Navbar from "./header";
import Details from "./Signupdetails";

const Postsignup = () => {
  const params = useParams();
  const rfqId = params.id

  return (
    <div>
      <Navbar />
      <Details rfqId={rfqId} />
    </div>
  );
};

export default Postsignup;
