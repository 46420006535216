import ErrorBoundary from "core/ErrorBoundary";
import { Provider } from "react-redux";
import store from "core/store";
import { Main } from "./Main";
import EmotionTheme from "core/Theming/EmotionTheme";
import { QueryClientProvider } from "react-query";
import { queryClient } from "api/client";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";

function App() {
  return (
    <Provider store={store}>
      <EmotionTheme>
        <QueryClientProvider client={queryClient}>
          <ErrorBoundary>
            <ToastContainer />
            <Main />
          </ErrorBoundary>
        </QueryClientProvider>
      </EmotionTheme>
    </Provider>
  );
}

export default App;
