import React from "react";
import { Chart, registerables, ChartOptions } from "chart.js";
import { FunnelController, TrapezoidElement } from "chartjs-chart-funnel";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { Chart as ReactChart } from "react-chartjs-2";

Chart.register(
  ...registerables,
  FunnelController,
  TrapezoidElement,
  ChartDataLabels
);

export interface FunnelChartProps {
  data: {
    labels: string[];
    datasets: {
      data: number[];
      backgroundColor: string[];
      textColor: string[];
    }[];
  };
  options?: ChartOptions<"funnel">;
}

const FunnelChart: React.FC<FunnelChartProps> = ({ data, options }) => {
  const defaultOptions: ChartOptions<"funnel"> = {
    indexAxis: "y",
    scales: {
      x: {
        display: false,
      },
    },
    plugins: {
      legend: {
        display: false,
      },
      datalabels: {
        color: (context) => {
          return data.datasets[0].textColor[context.dataIndex];
        },
        font: {
          size: 12,
          weight: "bold",
        },
        formatter: (context) => {
          const labels = context.chart.data.labels;
          if (labels && context.dataIndex != null) {
            const label = labels[context.dataIndex];
            return label ? `${label}` : "";
          }
          return "";
        },
        align: "center",
        anchor: "center",
        clamp: true,
        offset: 0,
        display: (context) => {
          const labels = context.chart.data.labels;
          const dataIndex = context.dataIndex;
          return !!(
            labels &&
            dataIndex != null &&
            typeof labels[dataIndex] === "string"
          );
        },
      },
    },
  };

  return (
    <div
      style={{
        width: "600px",
        height: "400px",
        marginLeft: "auto",
        marginRight: "auto",
        padding: "32px",
      }}
    >
      <ReactChart type="funnel" data={data} options={options || defaultOptions} />
    </div>
  );
};

export default FunnelChart;
