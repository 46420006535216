import React, { useState, ChangeEvent, useRef, useEffect } from "react";
import {
  Box,
  Typography,
  IconButton,
  LinearProgress,
  styled,
} from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";
import { ReactComponent as FileUploadIcon } from "../../../../assets/images/Combined Folder Icon.svg";
import { ReactComponent as PdfIcon } from "../../../../assets/images/pdfIcon.svg";
import { ReactComponent as DocxIcon } from "../../../../assets/images/Doc Icon.svg";
import { themeColors } from "core/Theming/theme";

interface FileUploadProps {
  onFileChange: (file: File | null) => void;
  defaultPdfUrl?: string;
  disabled?: boolean;
  removeFile?: () => void;
}

const FileUpload: React.FC<FileUploadProps> = ({
  onFileChange,
  defaultPdfUrl,
  disabled,
  removeFile,
}) => {
  const [file, setFile] = useState<File | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [uploadProgress, setUploadProgress] = useState<number>(0);
  const [uploading, setUploading] = useState<boolean>(false);
  const uploadTimeoutRef = useRef<NodeJS.Timeout | null>(null);

  const simulateUploadProgress = () => {
    uploadTimeoutRef.current = setInterval(() => {
      setUploadProgress((prev) => {
        if (prev >= 100) {
          clearInterval(uploadTimeoutRef.current!);
          setUploading(false);
          return 100;
        }
        return prev + 10;
      });
    }, 500);
  };

  useEffect(() => {
    if (defaultPdfUrl) {
      // If there's a default PDF URL, set the file state to null so it doesn't overlap with the new file state
    }
  }, [defaultPdfUrl]);

  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (disabled) return;
    const selectedFile = event.target.files?.[0];
    if (selectedFile) {
      if (!["application/pdf", "application/vnd.openxmlformats-officedocument.wordprocessingml.document"].includes(selectedFile.type)) {
        setError("Please upload a PDF or DOCX file.");
        return;
      }
      setFile(selectedFile);
      setError(null);
      onFileChange(selectedFile);
      setUploading(true);
      simulateUploadProgress();
    }
  };

  const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
    if (disabled) return;
    event.preventDefault();
    const droppedFile = event.dataTransfer.files?.[0];
    if (droppedFile) {
      if (!["application/pdf", "application/vnd.openxmlformats-officedocument.wordprocessingml.document"].includes(droppedFile.type)) {
        setError("Only PDF or DOCX files are allowed.");
        return;
      }
      setFile(droppedFile);
      setError(null);
      onFileChange(droppedFile);
      setUploading(true);
      simulateUploadProgress();
    }
  };

  const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
    if (disabled) return;
    event.preventDefault();
  };

  const handleRemoveFile = () => {
    if (disabled) return;
    setFile(null);
    setUploadProgress(0);
    setUploading(false);
    onFileChange(null);
    if (uploadTimeoutRef.current) {
      clearInterval(uploadTimeoutRef.current);
    }
    removeFile?.();
  };

  return (
    <MainBox sx={{ backgroundColor: disabled ? themeColors.badgeGray : themeColors.white, cursor: disabled ? "no-drop" : "ponter", } }>
      <Box onDrop={handleDrop} onDragOver={handleDragOver}>
        {!file && !uploading ? (
          <>
            <Typography variant="body1" mb={2}>
              {!defaultPdfUrl ? "Drag & Drop PDF or DOCX file or" : ""}
              <input
                type="file"
                accept=".pdf, .docx"
                onChange={handleFileChange}
                style={{ display: "none" }}
                id="file-upload"
                disabled={disabled}
              />
              <label htmlFor="file-upload">
                <IconButton component="span" color="primary" disabled={disabled}>
                  <FileUploadIcon />
                </IconButton>
                {!defaultPdfUrl
                  ? "Browse & Upload file here "
                  : defaultPdfUrl}
              </label>
            </Typography>
          </>
        ) : (
          <>
            {uploading ? (
              <>
                <UploadingBox>
                  <UploadingSubBox>
                    {file?.type === "application/pdf" ? <PdfIcon /> : <DocxIcon />}
                    <Typography variant="body2" ml={1}>
                      {file?.name}
                    </Typography>
                  </UploadingSubBox>
                  <Box flexGrow={1} ml={2}>
                    <LinearProgress
                      variant="determinate"
                      value={uploadProgress}
                    />
                  </Box>
                </UploadingBox>
              </>
            ) : (
              <UploadedBox>
                {file?.type === "application/pdf" ? <PdfIcon /> : <DocxIcon />}
                <Typography variant="body2" ml={1}>
                  {file?.name}
                </Typography>
                <Typography variant="body2" ml={1}>
                  <a
                    href={file?.name ? URL.createObjectURL(file) : ""}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Preview
                  </a>
                  
                </Typography>
                <IconButton
                  color="error"
                  onClick={handleRemoveFile}
                  sx={{ ml: 1 }}
                  disabled={disabled}
                >
                  <CancelIcon />
                </IconButton>
              </UploadedBox>
            )}
          </>
        )}
      </Box>
      {error && (
        <Typography color="error" variant="body2" mt={2}>
          {error}
        </Typography>
      )}
    </MainBox>
  );
};

const MainBox = styled(Box)({
  border: `1px solid grey.400`,
  borderRadius: "8px",
  padding: "16px",
  textAlign: "center",

});
const UploadingBox = styled(Box)(({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "space-between",
}));
const UploadingSubBox = styled(Box)(({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
}));
const UploadedBox = styled(Box)(({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "center",
}));

export default FileUpload;