import React, { useRef } from 'react';
import { Box, IconButton, Typography, Button } from '@mui/material';

import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import WestIcon from '@mui/icons-material/West';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import styled from '@emotion/styled';
import { ReactComponent as JobsIcon } from "../../assets/Settings.svg";
import { ReactComponent as Glass } from "../../assets/glass.svg";
import Cards from './requisitionpage';
import Boxs from './cards';
const CarouselContainer = styled(Box)`
  display: flex;
  align-items: center;
  overflow: hidden;
  position: relative;
  margin-top:10px;
  background: #160B30;
`;

const CarouselWrapper = styled(Box)`
  display: flex;
  overflow-x: scroll;
  scroll-behavior: smooth;
  &::-webkit-scrollbar {
    display: none; /* Hide scrollbar */
  }
`;

const CardContainer = styled(Box)`
//   min-width: 300px;
//   height: 200px;
//   margin: 0 10px;
//   background-color: #3b3b98;
//   border-radius: 10px;
//   box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
//   border: 2px solid rgba(255, 255, 255, 0.3); /* Outer Border */
//   padding: 20px;
//   color: white;
  position: relative;
`;

const OuterBorder = styled(Box)`
  position: absolute;
  top: -10px;
  bottom: -10px;
  left: -10px;
  right: -10px;
  border: 2px solid rgba(255, 255, 255, 0.5);
  border-radius: 12px;
`;

const Tag = styled(Box)`
  background-color: #5555aa;
  border-radius: 20px;
  padding: 5px 10px;
  margin-right: 5px;
`;

const ArrowButton = styled(IconButton)`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;
  background-color: #ffffff;
`;

const Settings: React.FC = () => {
 
  return (
    <Box sx={{backgroundColor:'#160B30',backgroundSize:'cover',backgroundRepeat:'no-repeat'}}>
        <Box sx={{display:'flex',alignItems:'center',justifyContent:'center'}}>
        <Typography color="#446DFC" fontSize='50px' variant='h1' sx={{marginTop:'62px'}}>Requisitions</Typography>
        </Box>
        <Box sx={{display:'flex',justifyContent:'flex-end',marginRight:'16px'}}>
        <StyledButton  variant="contained">View More</StyledButton>
        </Box>
        <Boxs/>
        <Cards/>

    </Box>
  );
};


const StyledButton = styled(Button)({
    borderRadius: "32px",
    width: "177px",
    height: "34px",
    fontSize: "12px",
    backgroundColor:'#7A33BB4D',
    color:'#FFF',
    boxShadow:'#7A33BB4Dinset'

  });
export default Settings;

