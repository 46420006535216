import {
  VendorCvsDatabaseApiModel,
  VendorCvsDatabaseTableModel,
} from "api/resources/modals";
import ReusableTable from "components/reusableFIlterTable/ReusableTable";
import { themeColors } from "core/Theming/theme";
import {
  ActinItemDiv,
  FilterIcon,
  LeftcontentText,
  LeftsiteContainer,
  Leftsitecontent,
  LightToolTip,
  MainGridContainer,
  Maingridtable,
  RightSiteICon,
  TableContainer,
} from "pages/Vendor/rmp";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { ReactComponent as viewCVIcon } from "../../../../assets/icons/vendorViewCvIcon.svg";
import { ReactComponent as downloadCVIcon } from "../../../../assets/icons/vendorDownloadCvIcon.svg";
import { ReactComponent as resendMailIcon } from "../../../../assets/icons/vendorResendMailIcon.svg";
import { ReactComponent as resendMailDisableIcon } from "../../../../assets/icons/vendorResendMailDisableIcon.svg";
import styled from "@emotion/styled";
import {
  StyledButtonAccept,
  StyledButtonPending,
  StyledButtonRejected,
} from "pages/BidMarketPlace/Allsubmissions";
import { useResendMail } from "pages/hooks";
import { toast } from "react-toastify";
import Tooltip from "@mui/material/Tooltip";

interface Props {
  selectedTab: number;
  data: VendorCvsDatabaseApiModel[];
  spinner: boolean;
  refechData:()=>{}
}

const CvDataBase = ({ selectedTab, data, refechData }: Props) => {
  const [isLoading, setLoading] = useState(false);
  const [cvData, setCvData] = useState<VendorCvsDatabaseTableModel[]>([]);
  const [selectedCvs, setSelectedCvs] = useState<VendorCvsDatabaseTableModel[]>(
    []
  );

  const resendMailApiCall = useResendMail({
    onError: () => {},
    onSuccess: (message) => {
      refechData();
      if (message == "Email Sent Successfully") toast.success(message);
      else toast.error(message);
    },
  });

  const resendMail = async (cvId: string) => {
    setLoading(true); 
    await resendMailApiCall.mutateAsync(cvId);
    setLoading(false);
  };

  const transformData = (
    apiData: VendorCvsDatabaseApiModel[]
  ): VendorCvsDatabaseTableModel[] => {
    const transformedData: VendorCvsDatabaseTableModel[] | [] = apiData
      ?.filter(
        (item: VendorCvsDatabaseApiModel) => Object.keys(item)?.length !== 0
      )
      ?.map((item: VendorCvsDatabaseApiModel, index: number) => ({
        id: index,
        cvId: item?.cvId || "-",
        jobSeekerName: item?.jobSeekerName || "-",
        rightToRepresent: item?.rightToRepresent || "-",
        noOfRequisitions: item?.noOfRequisitions || "-",
        phone: item?.phone || "-",
        email: item?.email || "-",
        lastJobTitle: item?.lastJobTitle || "-",
        qualification: item?.qualification || "-",
        smartScore: item?.smartScore || "-",
      }));
    return transformedData;
  };

  const headers = [
    {
      title: "id",
      name: "id",
      cellWidth: "230px",
      show: false,
    },
    {
      title: "CV ID",
      name: "cvId",
      cellWidth: "200px",
      filterType: "text",
      filter: true,
      customComponent: (row: VendorCvsDatabaseTableModel) => {
        return <Link to={`${row.cvId}/cv-details`}>{row.cvId}</Link>;
      },
    },
    {
      title: "Job Seeker Name",
      name: "jobSeekerName",
      cellWidth: "200px",
      filterType: "text",
      filter: true,
    },
    {
      title: "Right To Represent",
      name: "rightToRepresent",
      cellWidth: "250px",
      filterType: "select",
      options: ["pending", "accepted", "rejected"],
      filter: true,
      customComponent: (row: VendorCvsDatabaseTableModel) => {
        return (
          <RightToRepresentDiv>
            {row.rightToRepresent == "pending" ? (
              <StyledButtonPending>
                {row.rightToRepresent.charAt(0).toUpperCase() +
                  row.rightToRepresent.slice(1)}
              </StyledButtonPending>
            ) : row.rightToRepresent == "accepted" ? (
              <StyledButtonAccept>
                {row.rightToRepresent.charAt(0).toUpperCase() +
                  row.rightToRepresent.slice(1)}
              </StyledButtonAccept>
            ) : (
              <StyledButtonRejected>
                {row.rightToRepresent.charAt(0).toUpperCase() +
                  row.rightToRepresent.slice(1)}
              </StyledButtonRejected>
            )}
            {row.rightToRepresent != "accepted" ? (
              <LightToolTip
                textColor={themeColors.black}
                title="Resend Email To Candidate"
                arrow
              >
                {row.email ? (
                  <ResendMailIcon onClick={() => resendMail(row.cvId)} />
                ) : (
                  <ResendMailDisableIcon />
                )}
              </LightToolTip>
            ) : (
              ""
            )}
          </RightToRepresentDiv>
        );
      },
    },
    {
      title: "No Of Requisitions",
      name: "noOfRequisitions",
      cellWidth: "200px",
      filterType: "number",
      filter: true,
      customComponent: (row: VendorCvsDatabaseTableModel) => {
        return (
          <Link to={`${row.noOfRequisitions}/cv-details`}>
            {row.noOfRequisitions}
          </Link>
        );
      },
    },
    {
      title: "Phone",
      name: "phone",
      cellWidth: "200px",
      filterType: "text",
      filter: true,
    },
    {
      title: "Email",
      name: "email",
      cellWidth: "150px",
      filterType: "text",
      filter: true,
      customComponent: (row: VendorCvsDatabaseTableModel) => {
        const maxLength = 18;
        const truncatedJobTitle =
          row.email.length > maxLength
            ? `${row.email.substring(0, maxLength)}...`
            : row.email;
        return (
          <Tooltip title={row.email} arrow>
            <div>{truncatedJobTitle}</div>
          </Tooltip>
        );
      },
    },
    {
      title: "Last Job title",
      name: "lastJobTitle",
      cellWidth: "200px",
      filterType: "text",
      filter: true,
    },
    {
      title: "Qualification",
      name: "qualification",
      cellWidth: "250px",
      filterType: "text",
      filter: true,
    },
  ];

  const actionItmes = [
    {
      type: "button",
      name: "actions",
      handleClick: (action: string, item: VendorCvsDatabaseTableModel) => {},
      customActionComponent: (row: VendorCvsDatabaseTableModel) => {
        return (
          <ActinItemDiv>
            <LightToolTip textColor={themeColors.black} title="View CV" arrow>
              <ViewCvIcon onClick={() => {}} />
            </LightToolTip>
            <LightToolTip
              textColor={themeColors.black}
              title="Download CV"
              arrow
            >
              <DownloadCvIcon onClick={() => {}} />
            </LightToolTip>
          </ActinItemDiv>
        );
      },
    },
  ];

  const handleDoBulk = (selectedItems: VendorCvsDatabaseTableModel[]) => {
    setSelectedCvs(selectedItems);
  };

  useEffect(() => {
    setLoading(true);
    setCvData(transformData(data));
    setLoading(false);
  }, [data, selectedTab]);

  return (
    <MainGridContainer>
      <LeftsiteContainer>
        <Leftsitecontent>
          <LeftcontentText>
            {`${selectedCvs?.length || 0} of ${cvData?.length || 0} Selected`}
          </LeftcontentText>
        </Leftsitecontent>
        <RightSiteICon>
          <FilterIcon />
        </RightSiteICon>
      </LeftsiteContainer>
      <Maingridtable>
        <TableContainer>
          <ReusableTable
            key={cvData?.length}
            headers={headers}
            data={cvData}
            itemsPerPage={10}
            spinner={isLoading}
            handleDoBulk={handleDoBulk}
            checkBox={true}
            headerColor={themeColors.purpleLight}
            inputBorder={themeColors.purpleDark}
            actions={actionItmes}
            actionsCellWidth={"150px"}
          />
        </TableContainer>
      </Maingridtable>
    </MainGridContainer>
  );
};

const ActionItemsIconCommonStyles = {
  marginTop: "8px",
  cursor: "pointer",
  width: "24px",
  height: "24px",
};

export const ViewCvIcon = styled(viewCVIcon)(ActionItemsIconCommonStyles);

export const DownloadCvIcon = styled(downloadCVIcon)(
  ActionItemsIconCommonStyles
);

export const ResendMailIcon = styled(resendMailIcon)(
  ActionItemsIconCommonStyles
);

export const ResendMailDisableIcon = styled(resendMailDisableIcon)({
  ...ActionItemsIconCommonStyles,
  cursor: "not-allowed",
});

export const RightToRepresentDiv = styled(`div`)({
  display: "flex",
  gap: "8px",
});

export default CvDataBase;
