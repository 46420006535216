import React from "react";
import { Box, Grid, Typography } from "@mui/material";
import styled from "@emotion/styled";
import MenuIcon from "@mui/icons-material/Menu";
import CountdownCard from "./CountdownCard";
import { Link, useNavigate } from "react-router-dom";
import { themeColors } from "core/Theming/theme";
import { useRequisitionsAboutToExpire } from "pages/hooks";
import { RequisitionAboutToExpireModal } from "api/resources/modals";
import { useSelector } from "react-redux";
import { RootState } from "core/store";
import Spinner from "components/spinner/Spinner";


const Countdowncards: React.FC = () => {
  const userInfo = useSelector((state: RootState) => state.auth?.userInfo);
  const userID =  userInfo?.user_id;
  const { aboutExpireData, isLoading } = useRequisitionsAboutToExpire(userID as string);

  const navigate=useNavigate()


  if (isLoading) {
    return <Spinner show={true} />;
  }

  return (
    <MainBox>
      <StyledBox>
        <Heading variant="h4" gutterBottom>
          Requisitions about to expire
        </Heading>
        <StyledMenuIcon />
      </StyledBox>
      <Linkdiv onClick={()=>navigate("/employer/myrequisitions")}>
      <Typography variant="h4"> <u>ViewAll</u></Typography>
      </Linkdiv>
      <Cardbox>
        <ScrollContainer>
          <Grid container spacing={1} wrap="nowrap">
          {aboutExpireData?.map((result: RequisitionAboutToExpireModal, index: number) => {
            
            return (
              <Grid item xs={12} sm={6} md={4} key={index}>
                <CountdownCard
                  title={result.result?.rfq_details?.job_title}
                  expiryDate={result.result?.rfq_details?.rfq_closing_date}
                  bids={result.result?.Total_total_bid}
                  rfq_id={result.result?.rfq_details?.rfq_id}
                />
              </Grid>
              );
            })}
          </Grid>
        </ScrollContainer>
      </Cardbox>
    </MainBox>
  );
};
const StyledMenuIcon = styled(MenuIcon)({
  color: themeColors.primaryDark,
});

const Heading = styled(Typography)({
  color: themeColors.darksecondBlue,
  fontSize: '22px',
});

const Cardbox = styled(Box)({
  display: "grid",
  gridAutoFlow: "column",
  gridAutoColumns: "100%",
});

const MainBox = styled(Box)({
  borderRadius: "8px",
  border: `1px solid ${themeColors.emptyStar}`,
  marginTop: "20px",
});

const StyledBox = styled(Box)({
  display: "flex",
  justifyContent: "space-between",
  padding: "15px 30px",
  boxShadow:
    "0px 9px 16px rgba(159, 162, 191, .18), 0px 2px 2px rgba(159, 162, 191, 0.32)",
});

const ScrollContainer = styled(Box)`
  display: flex;
  overflow-x: auto;
  white-space: nowrap;
  padding: 20px;

  & > * {
    flex-shrink: 0;
  }

  &::-webkit-scrollbar {
    height: 8px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #ccc;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background-color: #888;
  }

  &::-webkit-scrollbar-track {
    background-color: ${themeColors.white};
  }
`;

const Linkdiv=styled(Box)({
  display:'flex',
  justifyContent:"flex-end",
  marginRight:"28px",
  marginTop:"10px",
  cursor:'pointer'
})

export default Countdowncards;