import React, { useState } from "react";
import {
  Card,
  CardContent,
  CardActions,
  Button,
  Typography,
  IconButton,
  Box,
  Stack,
  Tooltip,
  Chip,
  Modal,
  Container,
} from "@mui/material";
import { styled } from "@mui/system";
import VisibilityIcon from "@mui/icons-material/Visibility";
import ChatIcon from "@mui/icons-material/Chat";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import { themeColors } from "core/Theming/theme";
import { toast } from "react-toastify";
import { UsePostAcceptorRejectSubmission, useRecentSubmissions } from "pages/hooks";
import { RootState } from "core/store";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Spinner from "components/spinner/Spinner";
import { AllSubmissionsModal } from "pages/BidMarketPlace/Components/Modal";
import { ReactComponent as Menuarrow } from "../../../../../assets/icons/Group 33.svg";
import { ReactComponent as CloseIcons } from "../../../../../assets/icons/Closed.svg";  

type BidCardProps = {
  name: string;
  bidId: string;
  jobTitle: string;
  visaCategory: string[];
  rate: string;
  commission: string;
  rfq_id:string;
  status:string;
};

const BidCard: React.FC<BidCardProps> = ({
  name,
  bidId,
  jobTitle,
  visaCategory,
  rate,
  commission,
  rfq_id,
  status
}) => {

  const navigate=useNavigate()
  const userInfo = useSelector((state: RootState) => state.auth?.userInfo);
  const userID =  userInfo?.user_id;
  const {refetch,isLoading } = useRecentSubmissions(userID as string);

  const { postAcceptOrRejectSubmissionData } = UsePostAcceptorRejectSubmission({
    onSuccess: () => refetch(),
    onError: () => toast.error(" Please select a single row and try again"),
  });

  const jobTitleParts = jobTitle?.split(' ');


  const capitalizeFirstLetter = (str: string) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
  };
  
  const capitalizeEachWord = (str: string) => {
    return str.split(' ').map(capitalizeFirstLetter).join(' ');
  };

  const firstPart = jobTitleParts?.length > 0 ? capitalizeEachWord(jobTitleParts[0]) : '';

  const lastPart = jobTitleParts?.length > 1 ? capitalizeEachWord(jobTitleParts[jobTitleParts.length - 1]) : '';

  const maxSkillsToShow = 1;
  const visibleSkills = visaCategory.slice(0, maxSkillsToShow);
  const hiddenSkills = visaCategory.slice(maxSkillsToShow);

  const [open, setOpen] = useState(false);

  const [reject, setReject] = useState(false);

  const [successMessage, setSuccessMessage] = useState("");  
  
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleAccept = () => {
    if (bidId) {
      postAcceptOrRejectSubmissionData({
        submmissionID: bidId,
        status: "accepted",
      });
      setSuccessMessage("Accepted Submissions Successfully!");
      handleClose();
      toast.success("Accepted Submissions Successfully!");
    }
  };


  const handleRejectOpen = () => {
    setReject(true);
  };

  const handleReject = () => {
    if (bidId) {
      postAcceptOrRejectSubmissionData({
        submmissionID: bidId,
        status: "rejected",
      });

       setSuccessMessage("Rejected Submissions Successfully!");
      handleRejectClose();
      toast.success("Rejected Submissions Successfully!");
    }
  };

  const handleRejectClose = () => {
    setReject(false);
  };

  
  if (isLoading) {
    return <Spinner show={true} />;
  }
  let Fullname=capitalizeEachWord(name)
  return (
    <StyledCard>
      <CardHeader>
      <Tooltip arrow title={capitalizeEachWord(name)}>
      <span style={{ cursor: 'pointer' }}> <Tilte variant="h4">{Fullname.length > 20 ? Fullname?.slice(0, 20) + '...' : Fullname}</Tilte></span>
      </Tooltip>
        <IconButton   onClick={() => navigate(`/employer/myrequisitions/${rfq_id}`)}>
          <Iconvisibility />
        </IconButton>
      </CardHeader>
      <Cardcontent>
        <CardItems>
          <TqlText color="textSecondary" variant="h6">
            {bidId}
          </TqlText>
          <Typography variant="h6" color={themeColors.grey}>
            Submission raised against: <strong>{firstPart}</strong>
          </Typography>
          <Typography variant="body2" color={themeColors.grey}>
            <strong>{lastPart}</strong>
          </Typography>
        </CardItems>
        <CardDetails>
          <Typography variant="body2" color={themeColors.grey}>
            <strong>Visa Category:</strong>
      {visibleSkills.map((skill, index) => (
        <Tooltip key={index} title={skill}>
          <Skil label={skill} />
        </Tooltip>
      ))}

      {hiddenSkills.length > 0 && (
        <Tooltip title={hiddenSkills.join(", ")}>
          <Skil label="..." />
        </Tooltip>
      )}
          </Typography>
          <Typography variant="body2" color={themeColors.grey}>
            <RateText>Charge/Rate:</RateText> {rate}
          </Typography>
          <Typography variant="body2" color={themeColors.grey}>
            <VendorText>Commission to Vendor:</VendorText>
            {commission}
          </Typography>
        </CardDetails>
      </Cardcontent>
      <CardFooter>
        {
          status==="rejected"?"":<ActionButton color="error" variant="outlined" onClick={handleRejectOpen}>
          <CloseIcon />
          Decline
        </ActionButton>
        }
        
        {
          status==="accepted"?"":<ActionButton color="success" variant="outlined" onClick={handleClickOpen}>
          <CheckIcon />
          Accept
        </ActionButton>
        }
        
      </CardFooter>
      <AllSubmissionsModal
        open={open}
        handleClose={handleClose}
        handleConfirmDelete={handleAccept}
        Text={"Do you Want to Accept the Submission  for "}
        jobTitle={jobTitle}
      />

    <AllSubmissionsModal
        open={reject}
        handleClose={handleRejectClose}
        handleConfirmDelete={handleReject}
        Text={"Do you Want to Reject the Submission  for "}
        jobTitle={jobTitle}
      />

         {successMessage && (
          <Modal
            open={!!successMessage}
            onClose={() => setSuccessMessage("")}
            aria-labelledby="success-modal-title"
            aria-describedby="success-modal-description"
          >
            <Box sx={{ ...modalStyle }}>
            <Modalheader>
              <Close onClick={() => setSuccessMessage("")} />
            </Modalheader>
              <CenteredColumn>
                <Questionmark>
                  <Menu />
                </Questionmark>
                <CenteredTypography id="success-modal-description" variant="h3">
                  {successMessage}
                </CenteredTypography>
              </CenteredColumn>
            </Box>
          </Modal>
        )}

    </StyledCard>
  );
};

export const Skil = styled(Chip)({
  background: themeColors.primaryLight,
  color: themeColors.grey,
  fontWeight: "500",
});
const Tilte=styled(Typography)({
  color:themeColors.grey,
  fontSize:'20px',
});
const CardHeader = styled("div")({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  color: themeColors.grey,
});

const CardDetails = styled("div")({
  backgroundColor: themeColors.primaryLight,
  padding: "16px",
  borderRadius: "8px",
  marginTop: "8px",
  display: "flex",
  justifyContent: "flex-start",
  alignItems: "flex-start",
  flexDirection: "column",
});

const CardFooter = styled(CardActions)({
  display: "flex",
  justifyContent: "flex-start",
  padding: "0px",
});

const ActionButton = styled(Button)({
  margin: "10px 5px",
  borderRadius: "30px",
  minWidth: "100px",
  height: "35px",
});
const Iconvisibility = styled(VisibilityIcon)({
  color: themeColors.grayBlack,
});

const CardItems = styled(Box)({
  display: "flex",
  justifyContent: "flex-start",
  flexDirection: "column",
  padding: "0px",
  alignItems: "flex-start",
});
const Cardcontent = styled(CardContent)({
  padding: "0px",
});
const RateText = styled("strong")({
  marginRight: "15px",
});

const VendorText = styled("strong")({
  marginRight: "8px",
});

const TqlText = styled(Typography)({
  fontSize: "10px",
});

const StyledCard = styled(Card)`
  margin: 10px;
  padding: 16px 20px 30px 20px;
  width: 310px;
  height: 290px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  border: 1px solid ${themeColors.primaryDark};
  alignitem: center;
  text-align: center;
`;

const CenteredColumn = styled(`div`)({
  display: "flex",
  justifyContent: "center",
  flexDirection: "column",
});
const Modalheader = styled(Container)({
  display: "flex",
  justifyContent: "flex-end",
  backgroundColor: themeColors.primaryLight,
  Padding: "8px",
  borderTopLeftRadius: "14px",
  borderTopRightRadius: "14px",
  borderBottomRadius: "3px",
  padding: "8px",
});


const Close = styled(CloseIcons)({
  height: "30px",
  width: "30px",
  cursor: "pointer",
});

const Questionmark = styled(`div`)({
  display: "flex",
  justifyContent: "center",
  marginTop:'16px'
});


const Menu = styled(Menuarrow)({
  height: "80px",
  width: "80px",
  cursor: "pointer",
});

const CenteredTypography = styled(Typography)({
  marginTop: "20px",
  alignItems: "center",
  textAlign: "center",
  color: themeColors.black,
});

const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  bgcolor: "background.paper",
  boxShadow: 24,
  borderRadius: "15px",
  height: "304px",
};

export default BidCard;
