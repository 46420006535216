import React, { useState } from "react";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import {
  GridColDef,
  GridRowSelectionModel,
  GridRowsProp,
} from "@mui/x-data-grid";
import { Button } from "@mui/material";
import { ReactComponent as Trash } from "../../../../assets/icons/trash.svg";
import { ReactComponent as Share } from "../../../../assets/icons/Share.svg";
import { ReactComponent as RejectIcon } from "../../../../assets/images/icons/rejectIcon.svg";
import { ReactComponent as AcceptIcon } from "../../../../assets/images/icons/acceptIcon.svg";

import styled from "@emotion/styled";
import { DataGrid, GridPaginationModel } from "@mui/x-data-grid";
import { themeColors } from "core/Theming/theme";
import Spinner from "components/spinner/Spinner";
import { renderPendingActionItems } from "./Columns";
interface CustomTabPanelProps {
  value: number;
  index: number;
  isLoading: boolean;
  selectionModel: GridRowSelectionModel;
  dataRows: GridRowsProp;
  columns: GridColDef[];
  tabLabel?: string;
  onSelectionChange: (newSelectionModel: GridRowSelectionModel) => void;
  onActionButtonClick: () => void;
  onSecondaryButtonClick?: () => void;
}

const CustomTabPanel: React.FC<CustomTabPanelProps> = ({
  value,
  index,
  isLoading,
  selectionModel,
  dataRows,
  columns,
  tabLabel,
  onSelectionChange,
  onActionButtonClick,
  onSecondaryButtonClick,
}) => {
  const [paginationModel, setPaginationModel] = useState<GridPaginationModel>({
    page: 0,
    pageSize: 10,
  });


  if (
    !Array.isArray(dataRows) ||
    !dataRows.every((item) => typeof item === "object" && item !== null)
  ) {
    return (
      <TabBox
        role="tabpanel"
        hidden={value !== index}
        id={`tab-panel-${index}`}
        aria-labelledby={`tab-${index}`}
      >
        <Typography color="error">
          There was an error loading the data. Please try again later.
        </Typography>
      </TabBox>
    );
  }


  const handleSelectionChange = (newSelectionModel: GridRowSelectionModel) => {
    const { page, pageSize } = paginationModel;
    const visibleRows = dataRows.slice(page * pageSize, (page + 1) * pageSize);
    const selectedVisibleRows = visibleRows.map((row) => row.id);

    const newSelectedRows = newSelectionModel.filter((id) =>
      selectedVisibleRows.includes(id)
    );

    onSelectionChange(newSelectedRows);
  };

  const handleActionButtonClick = (event: React.MouseEvent) => {
    event.stopPropagation();
    onActionButtonClick();
  };

  const handleSecondaryButtonClick = (event: React.MouseEvent) => {
    event.stopPropagation();
    onSecondaryButtonClick?.();
  };

  return (
    <TabBox
      role="tabpanel"
      hidden={value !== index}
      id={`tab-panel-${index}`}
      aria-labelledby={`tab-${index}`}
    >
      <LeftsiteContainer>
        <Leftsitecontent>
          <LeftcontentText>
            {selectionModel.length} of {dataRows.length} Selected
          </LeftcontentText>
          <IconButton sx={{ mr: "8px" }} onClick={(e) => e.stopPropagation()}>
            <TrashIcon />
          </IconButton>
          <IconButton sx={{ mr: "8px" }} onClick={(e) => e.stopPropagation()}>
            <Share />
          </IconButton>
          {value === 4 && (
            <>
              <RejectIcon
                style={{ marginRight: "8px" }}
                onClick={(e) => e.stopPropagation()}
              />
              {/* <StyledButton
                variant="outlined"
                onClick={handleActionButtonClick}
              >
                {tabLabel}
              </StyledButton> */}
            </>
          )}

          {(value === 1 || value === 5) && (
            <StyledButton variant="outlined" onClick={handleActionButtonClick}>
              {tabLabel}
            </StyledButton>
          )}
          {value === 3 && <StyledDiv>{renderPendingActionItems()}</StyledDiv>}

          {onSecondaryButtonClick && (
            <StyledButton onClick={handleSecondaryButtonClick}>
              Notify Again
            </StyledButton>
          )}
        </Leftsitecontent>
        <RightSiteICon>{/* Add your right-side icons here */}</RightSiteICon>
      </LeftsiteContainer>

      <Spinner show={isLoading} />

      <StyledDataGrid
        rows={dataRows}
        columns={columns}
        checkboxSelection
        pagination
        pageSizeOptions={[5, 10, 20]}
        paginationModel={paginationModel}
        onPaginationModelChange={setPaginationModel}
        onRowSelectionModelChange={handleSelectionChange}
        rowSelectionModel={selectionModel}
        getRowId={(row) => row.id}
      />
    </TabBox>
  );
};



const StyledDataGrid = styled(DataGrid)({
  minHeight: 300, 
});
const StyledDiv = styled("div")({
  display: "inline-flex",
  alignItems: "center",
  verticalAlign: "middle",
});
const TabBox = styled(Box)({
  width: "100%",
  borderRadius: "8px",
  padding: "24px",
  background: themeColors.white,
  boxShadow: "0px 0px 4px 0px rgba(77, 108, 217, 0.75)",
});
const LeftsiteContainer = styled(Box)({
  margin: "0px 8px",
  display: "flex",
  alignItems: "center",
});
const Leftsitecontent = styled(Box)({
  display: "flex",
  justifyContent: "flex-start",
  alignItems: "center",
  width: "100%",
  height: "42px",
  marginBottom:"8px"
});
const RightSiteICon = styled(Box)({
  display: "flex",
  flexDirection: "row",
});
const LeftcontentText = styled(Typography)({
  marginLeft: "15px",
  color: themeColors.disabedDark,
  marginRight: "15px",
});
const StyledButton = styled(Button)({
  display: "inline-flex",
  alignItems: "center",
  borderRadius: "8px",
  padding: "0px 20px",
  height: "32px",
  textDecoration: "underline",
});
const TrashIcon = styled(Trash)({
  marginRight: "15px",
});

export default CustomTabPanel;
