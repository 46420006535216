import React from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import ImageP3 from "../../assets/images/LandingPage/Image1.svg"
import imag1 from '../../assets/icons/Landing Page/Frame 48096421.svg'
import imag2 from '../../assets/icons/Landing Page/Frame 48096422.svg'
import imag3 from '../../assets/icons/Landing Page/Frame 48096423.svg'
import imag4 from '../../assets/icons/Landing Page/Frame 48096420.svg'
import imag5 from '../../assets/icons/Landing Page/Frame 48096421 (1).svg'
import Image2P3 from "../../assets/images/LandingPage/Image2.svg"


export default function Page3() {
    return (
        <div className='page2background container-page3'>
            <Box sx={{ flexGrow: 1 }}>
                <Typography
                    variant="h1"
                    component="h2"
                    className="mainp3"
                    style={{marginTop:'49px'}}
                >
                    Achieve Exceptional Results with AI/ML Powered Smart Matching
                </Typography>
                {/* grdi1  */}
                <Grid container spacing={2} className='maincontent-page3'>
                    {/* leftcolumn */}
                    <Grid item xs={4}>
                        <Box
                            component="img"
                            sx={{
                                height: 400,
                                width: 500,
                            }}
                            alt="Page3Image"
                            src={ImageP3}
                            className='image1p3'
                        />

                    </Grid>
                    {/* rightsidecolumn */}
                    <Grid item xs={8}>
                        <Typography
                            variant="h3"
                            component="h2"
                            className="page3Heading"
                        >
                            Create a Vendor network based on..
                        </Typography>
                        {/* subcolumns */}
                        <Grid container spacing={2}>
                            <Grid item xs={1} className="imagegrid">
                                <img src={imag3} alt="MatchingCV" style={{ width: '80px', height: '80px', marginBottom: '10px' }}></img>
                                <img src={imag2} alt="MatchingSub" style={{ width: '80px', height: '80px', marginBottom: '10px' }}></img>
                                <img src={imag1} alt="MatchingVH" style={{ width: '80px', height: '80px', marginBottom: '10px' }}></img>

                            </Grid>
                            <Grid item xs={6}>
                                <div style={{padding:'10px'}}>
                                    <Typography
                                        variant="h4"
                                        component="h2"
                                        className="rightHeading"
                                    >
                                        Matching CVs
                                    </Typography>
                                    <p>Instantly Align Your Job Description with All Active CVs in Our Database for Your Requisition</p>
                                </div>
                                <div style={{padding:'10px'}}>

                                    <Typography
                                        variant="h4"
                                        component="h2"
                                        className="rightHeading"
                                    >
                                        Matching Submissions

                                    </Typography>
                                    <p>Receive Recommendations for Similar Submissions Across Comparable JDs</p>

                                </div>
                                <div style={{padding:'10px'}}>


                                    <Typography
                                        variant="h4"
                                        component="h2"
                                        className="rightHeading"
                                    >
                                        Matching Vendor History
                                    </Typography>
                                    <p>Get Suggestions for Vendors with a Track Record of Similar Submissions or Those Who Typically Handle JDs Like Yours</p>
                                </div>

                            </Grid>
                        </Grid>

                        <Grid>

                        </Grid>

                    </Grid>
                </Grid>
                {/* grid2 */}
                <Grid container spacing={2} className='maincontent-page3'>
                    {/* leftcolumn  content*/}

                    <Grid item xs={8}>
                        <Typography
                            variant="h3"
                            component="h2"
                            className="page3Heading left"
                        >
                            Create a Employer connection based on...
                        </Typography>
                        {/* subcolumns */}
                        <Grid container spacing={2}>
                            <Grid item xs={1} className="imagegrid">
                                <img src={imag4} alt="MatchingCV" style={{ width: '80px', height: '80px', marginBottom: '10px' }}></img>
                                <img src={imag5} alt="MatchingSub" style={{ width: '80px', height: '80px', marginBottom: '10px' }}></img>

                            </Grid>
                            <Grid item xs={6}>
                                <div style={{padding:'10px'}}>
                                    <Typography
                                        variant="h4"
                                        component="h2"
                                        className="rightHeading"
                                    >
                                        Matching Requestions
                                    </Typography>
                                    <p>Receive suggestions for Employer Requestions based on your CV and submission Database</p>
                                </div>
                                <div style={{padding:'10px'}}>

                                    <Typography
                                        variant="h4"
                                        component="h2"
                                        className="rightHeading"
                                    >
                                        Matching Employer History

                                    </Typography>
                                    <p>Receive Suggestions for Employers with Similar Requisitions or Those Who Typically Request CVs Like Yours</p>

                                </div>

                            </Grid>
                        </Grid>

                        <Grid>

                        </Grid>

                        {/* Additional content here */}
                    </Grid>
                    <Grid item xs={4}>
                        <Box
                            component="img"
                            alt="Page3Image2"
                            src={Image2P3} className='image2p3'
                        />

                    </Grid>
                </Grid>
            </Box>
        </div>
    );
}
