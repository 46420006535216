import React, { useState, MouseEvent, ChangeEvent, RefObject } from 'react';
import { Menu, MenuItem, TextField } from '@mui/material';
import { useFormik } from 'formik';

interface Location {
  country: string;
  state: string;
  city: string;
  zipCode: string;
}

interface NestedMenuProps {
  data: Record<string, Record<string, { city: string; zipCode: string }[]>>;
  formik: any;
  anchorEl: RefObject<HTMLDivElement>;
  onClose: () => void;
}

const NestedMenu: React.FC<NestedMenuProps> = ({ data, formik, anchorEl, onClose }) => {
  const [subMenuAnchorEl, setSubMenuAnchorEl] = useState<null | HTMLElement>(null);
  const [selectedCountry, setSelectedCountry] = useState<string | null>(null);
  const [selectedState, setSelectedState] = useState<string | null>(null);
  const [selectedCity, setSelectedCity] = useState<string | null>(null);

  const [countrySearch, setCountrySearch] = useState<string>('');
  const [stateSearch, setStateSearch] = useState<string>('');
  const [citySearch, setCitySearch] = useState<string>('');

  const handleCountryClick = (event: MouseEvent<HTMLElement>, country: string) => {
    setSelectedCountry(country);
    setSelectedState(null); // Clear state and city selection when country changes
    setCitySearch(''); // Clear city search
    setSubMenuAnchorEl(event.currentTarget);
  };

  const handleStateClick = (event: MouseEvent<HTMLElement>, state: string) => {
    setSelectedState(state);
    setSelectedCity(null); // Clear city selection when state changes
    setCitySearch(''); // Clear city search
    setSubMenuAnchorEl(event.currentTarget);
  };

  const handleCityClick = (city: string, zipCode: string) => {
    formik.setFieldValue('location', `${city}, ${selectedState}, ${selectedCountry}, ${zipCode}`);
    onClose(); // Close all menus after selection
  };

  // Filter countries based on search input
  const filteredCountries = Object.keys(data).filter(country =>
    country.toLowerCase().includes(countrySearch.toLowerCase())
  );

  // Filter states based on search input and selected country
  const filteredStates = selectedCountry
    ? Object.keys(data[selectedCountry]).filter(state =>
        state.toLowerCase().includes(stateSearch.toLowerCase())
      )
    : [];

  // Filter cities based on search input, selected country, and selected state
  const filteredCities = selectedCountry && selectedState
    ? data[selectedCountry][selectedState].filter(location =>
        location.city.toLowerCase().includes(citySearch.toLowerCase())
      )
    : [];

  return (
    <>
      <Menu
        id="country-menu"
        anchorEl={anchorEl.current}
        keepMounted
        open={Boolean(anchorEl.current)}
        onClose={onClose}
      >
        <TextField
          label="Search Country"
          variant="outlined"
          fullWidth
          margin="normal"
          value={countrySearch}
          onChange={(e: ChangeEvent<HTMLInputElement>) => setCountrySearch(e.target.value)}
        />
        {filteredCountries.length ? (
          filteredCountries.map((country) => (
            <MenuItem key={country} onClick={(e) => handleCountryClick(e, country)}>
              {country}
            </MenuItem>
          ))
        ) : (
          <MenuItem disabled>No Countries Found</MenuItem>
        )}
      </Menu>

      {selectedCountry && (
        <Menu
          id="state-menu"
          anchorEl={subMenuAnchorEl}
          keepMounted
          open={Boolean(subMenuAnchorEl) && Boolean(selectedCountry)}
          onClose={() => setSubMenuAnchorEl(null)}
        >
          <TextField
            label="Search State"
            variant="outlined"
            fullWidth
            margin="normal"
            value={stateSearch}
            onChange={(e: ChangeEvent<HTMLInputElement>) => setStateSearch(e.target.value)}
          />
          {filteredStates.length ? (
            filteredStates.map((state) => (
              <MenuItem key={state} onClick={(e) => handleStateClick(e, state)}>
                {state}
              </MenuItem>
            ))
          ) : (
            <MenuItem disabled>No States Found</MenuItem>
          )}
        </Menu>
      )}

      {selectedState && selectedCountry && (
        <Menu
          id="city-menu"
          anchorEl={subMenuAnchorEl}
          keepMounted
          open={Boolean(subMenuAnchorEl) && Boolean(selectedState)}
          onClose={() => setSubMenuAnchorEl(null)}
        >
          <TextField
            label="Search City"
            variant="outlined"
            fullWidth
            margin="normal"
            value={citySearch}
            onChange={(e: ChangeEvent<HTMLInputElement>) => setCitySearch(e.target.value)}
          />
          {filteredCities.length ? (
            filteredCities.map((location) => (
              <MenuItem 
                key={location.city} 
                onClick={() => handleCityClick(location.city, location.zipCode)}
              >
                {location.city} ({location.zipCode})
              </MenuItem>
            ))
          ) : (
            <MenuItem disabled>No Cities Found</MenuItem>
          )}
        </Menu>
      )}
    </>
  );
};

export default NestedMenu;
