import React, { useMemo, useState, useRef, useEffect } from 'react';
import { Autocomplete, Box, Button, Card, Checkbox, Chip, FormControl, FormControlLabel, FormHelperText, Grid, MenuItem, Paper, Radio, RadioGroup, Select, Stack, TextField, Tooltip, Typography } from "@mui/material";
import * as Yup from 'yup';
import { ErrorMessage, Formik, FormikHelpers, FormikProps } from "formik";
import Nestedmenumultiselect from "components/Multiselectnestedmenudropdowns";
import { useCreateVendorSubmission, useFetchRFqEmployeeLevel, useFetchUserLocations, useQuickRfqVisatype } from "pages/hooks";
import styled from '@emotion/styled';
import { useAppSelector } from 'core/store';
import Spinner from 'components/spinner/Spinner';
import { toast } from 'react-toastify';
import { themeColors } from 'core/Theming/theme';
import MultipleFileUpload from 'components/multipleFileUploadComponent/MultipleFileUpload';
import DropdownSelector from 'components/Multiselectdropdowns';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

export interface SubmissionFormValues {
    visa_type: string[];
    Locations: string[];
    employeeLevel: string;
    profiles: number | null;
    rateType: string;
    commissionPreposedByEmployer: 'Yes' | 'No' | null;
    commission: number | '';
    ratePerHour: number | null;
    fileName?: string[] | null;
    declaration?: boolean
}

interface DropdownstyleProps {
    isVendor: boolean | null;
    marginBoolean?: boolean | null;
}

interface SubmissionFormProps {
    rfqId?: string;
    userId?: string;
    existingCommission: number | null | undefined;
    fullName: string | null | undefined;
    actualCommission?: number | string;
    formDetails?: SubmissionFormValues;
}

const SubmissionForm: React.FC<SubmissionFormProps> = ({ rfqId, userId, existingCommission, fullName, actualCommission, formDetails }) => {
    const { isVendor } = useAppSelector(store => store.auth);
    const [spinner, showSpinner] = useState(false);
    const [resetKey, setResetKey] = useState<string>('');
    const textFieldRef = useRef<HTMLDivElement>(null);
    const [showMenu, setShowMenu] = useState(false);
    const { locations = [], isLoading: locationsLoading } = useFetchUserLocations();
    const { Visa = [], isLoading: visaLoading } = useQuickRfqVisatype();
    const visaData = useMemo(() => {
        return Visa.map((visa) => visa.Visa);
    }, [Visa]);
    const { levels = [], isLoading: loadingLevels } = useFetchRFqEmployeeLevel();
    const formData: SubmissionFormValues = {
        visa_type: [],
        Locations: [],
        employeeLevel: '',
        profiles: 0,
        rateType: '',
        commissionPreposedByEmployer: null,
        commission: '',
        ratePerHour: 0,
        fileName: null,
        declaration: false
    };
    const { createVendorSubmission, isLoading, error } = useCreateVendorSubmission({
        onSuccess: () => {
            toast.success("Submission created successfully!");
        },
        onError: () => {
            toast.error(error?.message);
        },
    });

    const formSchema = Yup.object({
        Locations: Yup.array().of(Yup.string()).required('Required').min(1, 'At least one location is required').ensure(),
        visa_type: Yup.array().of(Yup.string()).required('Required').min(1, 'At least one Visa Type is required').ensure(),
        employeeLevel: Yup.string().required('Required'),
        profiles: Yup.number().typeError('Must be a number').positive('Must be greater than 0').required('Required'),
        rateType: Yup.string().required('Required'),
        commissionPreposedByEmployer: Yup.string().required('Required'),
        ratePerHour: Yup.number().positive('Must be greater than 0').required('Required'),
        commission: Yup.number().typeError('Must be a number')
            .test(
                'required-if-no',
                'Commission is required if proposed by employer is No and must be greater than 0',
                function (value) {
                    const { commissionPreposedByEmployer } = this.parent;
                    if (commissionPreposedByEmployer === 'No') {
                        return value !== undefined && value !== null && value > 0;
                    }
                    return true;
                }
            ),
        fileName: Yup.array().of(Yup.string()).required('Required').min(1, 'At least one CV is required').max(10, ' you can upload 10 files in a single stretch').ensure(),
        declaration: Yup.boolean()
            .test(
                'required-if-files',
                'Please check the box to confirm you have rights to the resume',
                function (value) {
                    const { fileName } = this.parent;
                    if (fileName?.length > 0) {
                        return value === true;
                    }
                    return true;
                }
            ),
    });

    const nestedLocations = useMemo(() => {
        if (!locations.length) return {};
        const result: Record<string, Record<string, Record<string, string[]>>> = {};
        locations.forEach((location) => {
            const { country, state, city, zipCode } = location;
            if (!result[country]) result[country] = {};
            if (!result[country][state]) result[country][state] = {};
            if (!result[country][state][city]) result[country][state][city] = [];
            result[country][state][city].push(zipCode);
        });
        return result;
    }, [locations]);

    const handleSubmitVendorForm = async (values: SubmissionFormValues, { resetForm, setFieldValue }: FormikHelpers<SubmissionFormValues>) => {
        showSpinner(true);
        try {
            if (userId && rfqId) {
                const payload = {
                    "visaType": values.visa_type,
                    "employeeLevel": values.employeeLevel,
                    "noOfProfiles": values.profiles,
                    "billRate": values.ratePerHour,
                    "location": values.Locations,
                    "rfq_id": rfqId,
                    "weekly": values.rateType === 'Weekly',
                    "hourly": values.rateType === 'Hourly',
                    "monthly": values.rateType === 'Monthly',
                    "userID": userId,
                    "commissionAcceptance": values.commissionPreposedByEmployer || 'Yes',
                    "crossOffer": values.commissionPreposedByEmployer === 'Yes' ? existingCommission : values.commission,
                    "cv_File": values?.fileName
                };
                await createVendorSubmission(payload);

                resetForm();
                setFieldValue("fileName", null);
                setResetKey(Date.now().toString());
            } else {
                toast.error('Something went wrong please try again after re-login');
            }
        } catch (error: any) {
            toast.error('Something went wrong please try again', error)
        } finally {
            showSpinner(false);
        }
    };

    const handleClick = () => setShowMenu(true);
    const handleClose = () => setShowMenu(false);

    return (
        <StyledBox display="flex" flexDirection="row" gap={3}>
            <div>
                <Formik initialValues={formData} validationSchema={formSchema} onSubmit={handleSubmitVendorForm} validateOnChange={false} validateOnBlur={false}>
                    {({ handleSubmit, handleChange, values, errors, touched, setFieldValue }: FormikProps<SubmissionFormValues>) => {
                        useEffect(() => {
                            if (formDetails) {
                                Object.keys(formDetails).forEach(key => {
                                    const value = formDetails[key as keyof SubmissionFormValues];
                                    setFieldValue(key, value);
                                });
                            }
                        }, [formDetails])

                        return (
                            <React.Fragment>
                                <Grid container spacing={2}>
                                    <Grid item md={8}>
                                        <form key={values?.visa_type[0]} noValidate onSubmit={handleSubmit}>
                                            <FormControl fullWidth>
                                                <Box display="flex" flexDirection="row" gap={2}>
                                                    <Grid container spacing={2}>
                                                        <Grid item md={6}>
                                                            <DropdownSelector
                                                                required
                                                                name="visa_type"
                                                                label="Visa Type"
                                                                options={visaData}
                                                                placeholder='select or type and enter VisaType'
                                                            />
                                                        </Grid>

                                                        <Grid item xs={12} md={6}>
                                                            <TextName>
                                                                {`Location `} <RedIcon>*</RedIcon>
                                                            </TextName>
                                                            <Grid mt={0.8}>
                                                                {locationsLoading ? 'Loading...' :
                                                                    <TextField
                                                                        fullWidth
                                                                        name='locations'
                                                                        placeholder='search location'
                                                                        value={values.Locations.join(", ")}
                                                                        onClick={handleClick}
                                                                        inputRef={textFieldRef}
                                                                        InputProps={{
                                                                            readOnly: true,
                                                                            style: { color: 'transparent' },
                                                                        }}
                                                                    />
                                                                }
                                                                <Dropdownstyle isVendor={isVendor} marginBoolean={!errors.Locations}>
                                                                    {values?.Locations?.map((location, index) => (
                                                                        <Tooltip PopperProps={{ style: { zIndex: 0 } }} key={index} title={location} arrow>
                                                                            <Chip
                                                                                key={index}
                                                                                label={location}
                                                                                onDelete={() => {
                                                                                    const updatedLocations = values.Locations.filter(
                                                                                        (loc) => loc !== location
                                                                                    );
                                                                                    setFieldValue("Locations", updatedLocations);
                                                                                }}
                                                                            />
                                                                        </Tooltip>
                                                                    ))}
                                                                </Dropdownstyle>
                                                                {showMenu && (
                                                                    <Nestedmenumultiselect
                                                                        data={nestedLocations}
                                                                        formik={{ values, setFieldValue }}
                                                                        anchorEl={textFieldRef}
                                                                        onClose={handleClose}
                                                                    />
                                                                )}
                                                                <ErrorMessages name="Locations" component="div" />
                                                            </Grid>
                                                        </Grid>
                                                        <Grid item md={6}>
                                                            <StyledLabelName>
                                                                Employee Level <RedIcon>*</RedIcon>
                                                            </StyledLabelName>
                                                            <FormControl fullWidth margin="none">
                                                                {loadingLevels ? (
                                                                    'Loading...'
                                                                ) : (
                                                                    <Autocomplete
                                                                        freeSolo
                                                                        options={levels.map((level) => level.level)}
                                                                        value={values.employeeLevel}
                                                                        onChange={(event, newValue) => {
                                                                            setFieldValue("employeeLevel", newValue);
                                                                        }}
                                                                        renderInput={(params) => (
                                                                            <TextField
                                                                                {...params}
                                                                                name="employeeLevel"
                                                                                placeholder="Select or type Employee Level"
                                                                                onChange={(e) => setFieldValue("employeeLevel", e.target.value)}
                                                                                error={Boolean(errors.employeeLevel && touched.employeeLevel)}
                                                                                label="Employee Level"
                                                                            />
                                                                        )}
                                                                    />
                                                                )}
                                                                <ErrorMessages name="employeeLevel" component="div" />
                                                            </FormControl>
                                                        </Grid>

                                                        <Grid item xs={12} md={6}>
                                                            <StyledLabelName>No of Profiles <RedIcon>*</RedIcon></StyledLabelName>
                                                            <TextField
                                                                value={values.profiles}
                                                                type='number'
                                                                onChange={handleChange}
                                                                name="profiles"
                                                                variant="outlined"
                                                                fullWidth
                                                                error={Boolean(errors.profiles && touched.profiles)}
                                                            />
                                                            <ErrorMessages name="profiles" component="div" />
                                                        </Grid>

                                                        <Grid item xs={12} md={6}>
                                                            <StyledLabelName>Bill Rate $ (per hour) <RedIcon>*</RedIcon></StyledLabelName>
                                                            <TextField
                                                                value={values.ratePerHour}
                                                                type='number'
                                                                onChange={handleChange}
                                                                name="ratePerHour"
                                                                variant="outlined"
                                                                fullWidth
                                                                error={Boolean(errors.ratePerHour && touched.ratePerHour)}
                                                            />
                                                            <ErrorMessages name="ratePerHour" component="div" />
                                                        </Grid>

                                                        <Grid item xs={12} md={6}>
                                                            <StyledLabelName>Charge Type <RedIcon>*</RedIcon></StyledLabelName>
                                                            <StyledChargeType fullWidth>
                                                                <RadioGroup
                                                                    row
                                                                    name="rateType"
                                                                    value={values.rateType}
                                                                    onChange={handleChange}
                                                                >
                                                                    <FormControlLabel
                                                                        value="Hourly"
                                                                        control={<Radio />}
                                                                        label="Hourly"
                                                                    />
                                                                    <FormControlLabel
                                                                        value="Weekly"
                                                                        control={<Radio />}
                                                                        label="Weekly"
                                                                    />
                                                                    <FormControlLabel
                                                                        value="Monthly"
                                                                        control={<Radio />}
                                                                        label="Monthly"
                                                                    />
                                                                </RadioGroup>
                                                            </StyledChargeType>
                                                            <ErrorMessages name="rateType" component="div" />
                                                        </Grid>

                                                        <Grid item xs={12} md={6}>
                                                            <StyledLabelName>Are you happy by commission Proposed by Employer? <RedIcon>*</RedIcon></StyledLabelName>
                                                            <FormControl fullWidth>
                                                                <RadioGroup row name="commissionPreposedByEmployer" value={values.commissionPreposedByEmployer} onChange={handleChange} aria-labelledby="charge-type-group">
                                                                    <FormControlLabel value="Yes" control={<Radio />} label="Yes" />
                                                                    <FormControlLabel value="No" control={<Radio />} label="No" />
                                                                </RadioGroup>
                                                                <ErrorMessages name="commissionPreposedByEmployer" component="div" />
                                                            </FormControl>
                                                        </Grid>

                                                        {values?.commissionPreposedByEmployer === 'No' &&
                                                            <Grid item xs={12} md={6}>
                                                                <StyledLabelName>If "No" mention your Cross offer in $. <RedIcon>*</RedIcon></StyledLabelName>
                                                                <TextField
                                                                    value={values.commission}
                                                                    onChange={handleChange}
                                                                    name="commission"
                                                                    variant="outlined"
                                                                    fullWidth
                                                                    error={Boolean(errors.commission && touched.commission)}
                                                                />
                                                                <ErrorMessages name="commission" component="div" />
                                                            </Grid>
                                                        }
                                                        <Grid item md={12}>
                                                            <StyledLabelName>
                                                                <StyledUploadFile>
                                                                    Upload file here
                                                                    <Tooltip arrow title='you can upload Minimum 1 file Maximum 10 files in a single stretch'>
                                                                        <StyledInfoOutlinedIcon />
                                                                    </Tooltip>
                                                                    <RedIcon> *</RedIcon>
                                                                </StyledUploadFile>
                                                            </StyledLabelName>
                                                            <FileuploadBox>
                                                                <MultipleFileUpload
                                                                    noUploadName='No File Uploaded'
                                                                    onFileChange={async (fileBase64Array: string[] | null) => {
                                                                        if (fileBase64Array) {
                                                                            setFieldValue("fileName", fileBase64Array);
                                                                        }
                                                                        setFieldValue('declaration', false)
                                                                    }}
                                                                    resetKey={resetKey}
                                                                />
                                                            </FileuploadBox>
                                                            <FormHelperText error={Boolean(touched.fileName && errors.fileName)}>
                                                                <ErrorMessage name="fileName" />
                                                            </FormHelperText>
                                                            {Array.isArray(values?.fileName) && values.fileName.length > 0 && (
                                                                <Grid item md={12}>
                                                                    <Checkbox
                                                                        checked={Boolean(values?.declaration)} // Ensure it's a boolean value
                                                                        onChange={() => setFieldValue('declaration', !values?.declaration)} // Toggle between true and false
                                                                        name='declaration'
                                                                    />
                                                                    I declare that I have full rights and ownership over the resumes submitted above
                                                                    <FormHelperText error={Boolean(touched.declaration && errors.declaration)}>
                                                                        <ErrorMessage name="declaration" />
                                                                    </FormHelperText>
                                                                </Grid>
                                                            )}
                                                        </Grid>
                                                    </Grid>
                                                </Box>
                                            </FormControl>
                                            <StyledSubmitBox>
                                                <Spinner show={isLoading}>
                                                    <Button type="submit" variant="contained">Create Submission</Button>
                                                </Spinner>
                                            </StyledSubmitBox>
                                        </form>
                                    </Grid>
                                    <Grid item md={4}>
                                        <StyledCard>
                                            <Typography variant="h3" align='center' gutterBottom>Submission Preview</Typography>
                                            <FlexSpan>
                                                <div>
                                                    <StyledTypography>Vendor Name</StyledTypography>
                                                    <StyledTypography>Submission Date</StyledTypography>
                                                    <StyledTypography>Visa Status</StyledTypography>
                                                    <StyledTypography>Rate info</StyledTypography>
                                                    <StyledTypography>Commission</StyledTypography>
                                                    <StyledTypography>Employee Level</StyledTypography>
                                                    <StyledTypography>Number of profiles</StyledTypography>
                                                </div>
                                                <div>
                                                    <SpanTolBadge>{fullName}</SpanTolBadge>
                                                    <SpanTolBadge>{new Date().toLocaleDateString()}</SpanTolBadge>
                                                    <SpanTolBadge>{values.visa_type?.length > 0 ? values.visa_type?.join(', ') : '--'}</SpanTolBadge>
                                                    <SpanTolBadge>{values.ratePerHour ? '$ ' + values.ratePerHour : '--'}</SpanTolBadge>
                                                    <SpanTolBadge>{(values?.commissionPreposedByEmployer === 'No' && values.commission) ? '$ ' + values.commission : '$ ' + actualCommission}</SpanTolBadge>
                                                    <SpanTolBadge>{values.employeeLevel ? values.employeeLevel : '--'}</SpanTolBadge>
                                                    <SpanTolBadge>{values.profiles ? values.profiles : '--'}</SpanTolBadge>
                                                </div>
                                            </FlexSpan>
                                        </StyledCard>
                                    </Grid>
                                </Grid>
                            </React.Fragment>
                        );
                    }}
                </Formik>
            </div>
        </StyledBox>
    );
};

const StyledSubmitBox = styled(Box)({
    textAlign: 'center',
    marginTop: '15px',

})

const FlexSpan = styled('span')({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: '10px'
})

const SpanTolBadge = styled(Paper)({
    backgroundColor: themeColors.badgeGray,
    color: "black",
    boxShadow: "none",
    marginTop: '5px',
    marginBottom: '5px',
    paddingTop: '8px',
    paddingBottom: '8px',
    paddingLeft: '10px',
    paddingRight: '100px'
})

const StyledCard = styled(Card)({
    padding: '20px',
    marginTop: '30px'
})

const StyledTypography = styled(Typography)({
    marginTop: '20px',
    marginBottom: '20px'
})

const StyledBox = styled(Box)({
    display: 'flex',
    justifyContent: 'space-between',
    gap: '10px',
    paddingLeft: '20px',
    paddingRight: '20px',
    marginBottom: '10px'
})

const StyledUploadFile = styled('div')({
    display: 'flex',
    alignItems: 'center'
})

const StyledChargeType = styled(FormControl)({
    border: '1px solid #e5e5e5',
    borderRadius: '8px',
    paddingLeft: '15px',
    paddingTop: '5px',
    paddingBottom: '5px',
    maxWidth: '98%'
})

const ErrorMessages = styled(ErrorMessage)({
    color: themeColors.error,
});

const StyledLabelName = styled(Typography)({
    fontSize: "14px",
    fontWeight: 500,
    marginBottom: "15px",
});

const Dropdownstyle = styled(Box, {
    shouldForwardProp: (prop) => prop !== 'isVendor',
})<DropdownstyleProps>(({ isVendor, marginBoolean }) => {
    const colorClip = isVendor ? themeColors.purpleDark : themeColors.primaryDark;
    const backgroundClip = isVendor ? themeColors.purpleLight : themeColors.primaryLight;
    return {
        marginTop: marginBoolean ? '18px' : 0,
        width: '100%',
        '& .MuiChip-root': {
            background: backgroundClip,
            width: '173px',
            borderRadius: '5px',
            marginRight: '5px',
        },
        '& .MuiChip-deleteIcon': {
            color: colorClip,
        },
    };
});

const TextName = styled('p')({
    display: "flex",
    fontWeight: "500",
    color: themeColors.grey,
    marginTop: '0px'
});

const RedIcon = styled('span')({
    color: themeColors.error,
});

const FileuploadBox = styled(Box)({
    marginTop: "16px",
    textAlign: "center",
    borderRadius: "10px",
    border: `1px dashed ${themeColors.emptyStar}`,
});

const StyledInfoOutlinedIcon = styled(InfoOutlinedIcon)({
    color: themeColors?.grayBlack,
    cursor: 'pointer',
    height: '18px'
})

export default SubmissionForm;
