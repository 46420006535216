import React, { useEffect } from "react";
import {
  Box,
  IconButton,
  Paper,
  Toolbar,
  Input,
  Typography,
  InputBase,
} from "@mui/material";
import FormatBoldIcon from "@mui/icons-material/FormatBold";
import FormatItalicIcon from "@mui/icons-material/FormatItalic";
import FormatUnderlinedIcon from "@mui/icons-material/FormatUnderlined";
import ImageIcon from "@mui/icons-material/Image";
import { convertFromRaw, convertToRaw, Editor, EditorState, RichUtils } from "draft-js";
import "draft-js/dist/Draft.css";
import { themeColors } from "core/Theming/theme";
import AddIcon from "@mui/icons-material/Add";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import CloseIcon from "@mui/icons-material/Close";
import { FormatAlignCenterOutlined } from "@mui/icons-material";
import SearchIcon from "@mui/icons-material/Search";
import { styled } from "@mui/system";
import { ReactComponent as Cvfilter } from "../../../assets/icons/RfrIcon/CvFilter.svg";
import { ReactComponent as Cvfunnel } from "../../../assets/icons/RfrIcon/Cvfunel.svg";
import { ReactComponent as Addicon } from "../../../assets/icons/RfrIcon/AddIcons.svg";
import { CvNotesDetails } from "api/resources/modals";



const NotesEditor = ({
  open,
  handleClose,
  handleSubmit,
  editorState,
  setEditorState,
  notesCvDetails = [],
  handleFileUpload
}: {
  open: boolean;
  handleClose: () => void;
  editorState: EditorState;
  setEditorState: React.Dispatch<React.SetStateAction<EditorState>>;
  handleSubmit: () => void;
  handleFileUpload?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  cvId: string;
  notesCvDetails?: CvNotesDetails[];
}) => {
  const [searchTerm, setSearchTerm] = React.useState<string>("");

  const handleKeyCommand = (command: string) => {
    const newState = RichUtils.handleKeyCommand(editorState, command);
    if (newState) {
      setEditorState(newState);
      return "handled";
    }
    return "not-handled";
  };

  const toggleInlineStyle = (style: string) => {
    setEditorState(RichUtils.toggleInlineStyle(editorState, style));
  };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
  };

  const filteredNotes = notesCvDetails.filter((note) =>
    note.notes.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const renderNoteContent = (note: CvNotesDetails) => {
    try {
      const rawContent = JSON.parse(note.notes);
      const contentState = convertFromRaw(rawContent);
      const noteEditorState = EditorState.createWithContent(contentState);

      return (
        <Editor
        editorState={noteEditorState}
        readOnly={true}
        onChange={() => {}} 
        />
      );
    } catch (error) {
      return <StyleText>{note?.notes}</StyleText>;
    }
  };

  return (
    <OuterBox>
      {open && (
        <>
          <StyledBox>
            <HeaderBox>
              <Box>
                <AddIcons onClick={handleSubmit} />
              </Box>
              <Box>
                <MoreHorizIcons />
                <ClosedICon onClick={handleClose} />
              </Box>
            </HeaderBox>
            <PaperContainer>
              <Editor
                editorState={editorState}
                handleKeyCommand={handleKeyCommand}
                onChange={setEditorState}
                placeholder="Type here..."
              />
            </PaperContainer>
            <HR />
            <Paper elevation={0} sx={{ mb: 0 }}>
              <ToolbarContainer>
                <IconButton onClick={() => toggleInlineStyle("BOLD")}>
                  <FormatBoldIcons />
                </IconButton>
                <IconButton onClick={() => toggleInlineStyle("ITALIC")}>
                  <FormatItalicIcons />
                </IconButton>
                <IconButton onClick={() => toggleInlineStyle("UNDERLINE")}>
                  <FormatUnderlinedIcons />
                </IconButton>
                <IconButton onClick={() => toggleInlineStyle("center")}>
                  <FormatAlignCenterOutlineds />
                </IconButton>
                <IconButton component="label">
                  <ImageIcons />
                  <InputHidden type="file" 
                      id="imageUpload"
                      hidden
                      inputProps={{ accept: 'image/png, image/jpeg, image/jpg' }}
                      onChange={handleFileUpload}  />
                </IconButton>
                <IconButton>
                  <AddIcons onClick={handleSubmit} />
                </IconButton>
              </ToolbarContainer>
            </Paper>
          </StyledBox>
          {filteredNotes.length > 0 && (
            <SearchBox>
              <HeaderSearchBox>
                <Search id="search">
                  <SearchIconWrapper>
                    <SearchLogo />
                  </SearchIconWrapper>
                  <StyledInputBase
                    placeholder="Search…"
                    inputProps={{ "aria-label": "search" }}
                    value={searchTerm}
                    onChange={handleSearchChange}
                  />
                </Search>
                <Box>
                  <Funnel />
                  <Filter />
                </Box>
              </HeaderSearchBox>
              <ScrollableBox>
                {filteredNotes.length > 0 ? (
                  filteredNotes.map((note: CvNotesDetails) => (
                    <ContentBox key={note.id}>
                      <hr />
                      <Container>
                        <StylesText variant="h5">General Remarks</StylesText>
                        <StyleText>{note?.notes_date}</StyleText>
                      </Container>
                      <Box>{renderNoteContent(note)}</Box>
                      {note?.notes_img?<img width={'241px'} height={'144px'} src={note?.notes_img}/>:""}
                    </ContentBox>
                  ))
                ) : (
                  <p>No notes available.</p>
                )}
              </ScrollableBox>
            </SearchBox>
          )}
        </>
      )}
    </OuterBox>
  );
};

const Container=styled(Box)({
  display:'flex',
  justifyContent:'space-between',
})

const StyleText=styled(Typography)({
  color:themeColors.darkGrey
})
const HR = styled(`hr`)({
  color: themeColors.primaryLightDark,
});

const StylesText = styled(Typography)({
  color: themeColors.grey,
});
const AddIcons = styled(Addicon)({
  cursor: "pointer",
  color: themeColors.darkGrey,
  height:'20px',
  width:'20px'
});
const MoreHorizIcons = styled(MoreHorizIcon)({
  cursor: "pointer",
  color: themeColors.darkGrey,
});

const ClosedICon = styled(CloseIcon)({
  cursor: "pointer",
  color: themeColors.darkGrey,
});

const ImageIcons = styled(ImageIcon)({
  cursor: "pointer",
  color: themeColors.darkGrey,
});
const FormatAlignCenterOutlineds = styled(FormatAlignCenterOutlined)({
  cursor: "pointer",
  color: themeColors.darkGrey,
});

const FormatUnderlinedIcons = styled(FormatUnderlinedIcon)({
  cursor: "pointer",
  color: themeColors.darkGrey,
});
const FormatItalicIcons = styled(FormatItalicIcon)({
  cursor: "pointer",
  color: themeColors.darkGrey,
});
const FormatBoldIcons = styled(FormatBoldIcon)({
  cursor: "pointer",
  color: themeColors.darkGrey,
});

const Funnel = styled(Cvfunnel)({
  width: "20px",
  height: "20px",
  marginRight: "16px",
  marginTop: "5px",
  cursor: "pointer",
});

const Filter = styled(Cvfilter)({
  width: "18px",
  height: "18px",
  marginTop: "5px",
  cursor: "pointer",
  marginRight: "10px",
});
const SearchLogo = styled(SearchIcon)({
  color: themeColors.darkGrey,
});
const SearchIconWrapper = styled("div")(({ theme }) => ({
  paddingRight: "10px",
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  color: themeColors.black,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  marginRight: theme.spacing(2),
  marginLeft: 0,
  width: "100%",
  background: themeColors.white,
  color: themeColors.black,

  [theme.breakpoints.up("sm")]: {
    width: "100px",
  },
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    paddingLeft: "24px",
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      width: "12ch",
      "&:focus": {
        width: "20ch",
      },
    },
  },
}));

const OuterBox = styled(Box)`
  display: flex;
  flex-direction: column;
  background:"blue";
`;

const StyledBox = styled(Box)`
  width: 270px;
  border: 1px solid ${themeColors.primaryLightDark};
  margin-left: 15px;
  height: 257px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  box-shadow: 0px 0px 4px 0px ${themeColors.shadowColor};
   overflow-y: auto;
`;

const HeaderBox = styled(Box)`
  display: flex;
  justify-content: space-between;
  padding: 5px;
  background-color: ${themeColors.lightWhite};
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
`;

const PaperContainer = styled(Paper)`
  min-height: 150px;
  box-shadow: none;
  padding: 2px;

`;

const ToolbarContainer = styled(Toolbar)`
  display: flex;
  justify-content: space-between;
`;

const InputHidden = styled(Input)`
  display: none;
`;

const SearchBox = styled(Box)`
  width: 270px;
  margin-top: 16px;
  border: 1px solid ${themeColors.primaryLightDark};
  margin-left: 15px;
  height: 597px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  box-shadow: 0px 0px 4px 0px ${themeColors.shadowColor};
`;

const HeaderSearchBox = styled(Box)`
  display: flex;
  justify-content: space-between;
  padding: 5px 5px 0px 5px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
`;

const ScrollableBox = styled(Box)`
  height: 500px;
  overflow-y: auto;
`;

const ContentBox = styled(Box)`
  padding: 0px 5px 5px 5px;
`;

export default NotesEditor;
