import React from "react";
import { Modal, Box, Typography, Button } from "@mui/material";
import styled from "@emotion/styled";
import { ReactComponent as CloseActionIcon } from "../../../assets/icons/Closed.svg";
import { ReactComponent as Menuarrow } from "../../../assets/icons/menu-arrow-circle.svg";
import { themeColors } from "core/Theming/theme";
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';

export const AllSubmissionsModal = ({
  open,
  handleClose,
  handleConfirmDelete,
  jobTitle,
  Text
}: {
  open: boolean;
  handleClose: () => void;
  handleConfirmDelete: () => void;
  jobTitle: string;
  Text:string;
}) => {
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
    >
      <Box sx={{ ...modalStyle }}>
        <Modalheader>
          <Close onClick={handleClose} />
        </Modalheader>
        <CenteredColumn>
          <Questionmark>
            <Menu />
          </Questionmark>
          <CenteredTypography
            id="modal-description"
            variant="h4"
          >
           {Text}<CenteredText  variant="h4">{jobTitle}</CenteredText>
          </CenteredTypography>
          <StyledContainer>
            <Stylebutton
              variant="contained"
              color="primary"
              onClick={handleConfirmDelete}
            >
              Yes<Checked/>
            </Stylebutton>
            <StylebuttonDelete
              variant="contained"
              color="secondary"
              onClick={handleClose}
            >
              No<Closed/>
            </StylebuttonDelete>
          </StyledContainer>
        </CenteredColumn>
      </Box>
    </Modal>
  );
};

const Checked=styled(CheckIcon)({
  marginLeft:"5px"
})

const Closed=styled(CloseIcon)({
  marginLeft:"5px"
})

const CenteredTypography = styled(Typography)({
  marginTop: "20px",
  alignItems: "center",
  textAlign: "center",
   color:themeColors.darkGrey
});
const CenteredText = styled(Typography)({
  marginTop: '10px',
  alignItems: "center",
  textAlign: "center",
  color:themeColors.grey
});
const StyledContainer = styled(Box)({
  marginTop: "20px",
  display: "flex",
  justifyContent: "center",
});

const Container = styled(`div`)({
  display: "flex",
  justifyContent: "flex-end",
});

const Questionmark = styled(`div`)({
  display: "flex",
  justifyContent: "center",
});

const Menu = styled(Menuarrow)({
  height: "80px",
  width: "80px",
  cursor: "pointer",
});

const CenteredColumn = styled(`div`)({
  display: "flex",
  justifyContent: "center",
  flexDirection: "column",
  padding:'30px'
});

const Close = styled(CloseActionIcon)({
  height: "30px",
  width: "30px",
  cursor: "pointer",
});
const Stylebutton = styled(Button)({
  backgroundColor: themeColors.lightGreen,
  color: themeColors.white,
  width: "100px",
  height: "42px",
  borderRadius: "10px",
  marginRight: "20px",
});
const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  bgcolor: "background.paper",
  boxShadow: 24,
  borderRadius: "15px",
};

const Modalheader=styled(Container)({
    display:'flex',
    justifyContent:'flex-end',
    backgroundColor:themeColors.primaryLight,
    Padding:'8px',
    borderTopLeftRadius:"14px",
    borderTopRightRadius:"14px",
    borderBottomRadius:"3px",
    padding: "8px",
  })

const StylebuttonDelete = styled(Button)({
  backgroundColor: themeColors.redColor,
  color:themeColors.white,
  width: "100px",
  height: "42px",
  borderRadius: "10px",
});
