import {
  GridColDef,
  GridCellParams,
  GridRenderCellParams,
} from "@mui/x-data-grid";
import RatingStars from "./RatingStars";
import { ReactComponent as CloseIcon } from "../../../../assets/images/icons/Frame 48095805.svg";
import { ReactComponent as AceeptIcon } from "../../../../assets/images/icons/acceptIcon.svg";
import { ReactComponent as RejectIcon } from "../../../../assets/images/icons/rejectIcon.svg";
import styled from "@emotion/styled";
import { Button, Stack, Typography } from "@mui/material";
import { useState } from "react";
import { themeColors } from "core/Theming/theme";
import { VendorModal } from "api/resources/modals";
import { Link } from "react-router-dom";
import { Skil } from "pages/MyRFRs/Components/RFRlistingpage";

export const renderActionItems = () => (
  <StyledDiv>
 
    <StyledButton href="#">Request for a Submission</StyledButton>
  </StyledDiv>
);
export const renderActionItemsUnInterested = () => (
  <StyledDiv>
    <StyledButton href="#">Notify Again</StyledButton>
  </StyledDiv>
);
const renderRejectedActionItems = () => (
  <StyledDiv>
    <AceeptIcon />
  </StyledDiv>
);

const renderCvsActionItems = (params: GridCellParams) => {
  const [requestAgain, setRequestAgain] = useState(false);
  const handleRequestClick = () => {
    setRequestAgain(true);
  };
  const cvsSubmitted = params.row.cvs_submitted;

  return (
    <StyledDiv>   
      {params.value === 4 && <CloseIcon style={{ marginRight: "8px" }} />}

      {cvsSubmitted === 0 ? (
        requestAgain ? (
          <StyledButton href="#" onClick={handleRequestClick}>
            Request Again
          </StyledButton>
        ) : (
          <></>
          // <StyledButtonAccepted href="#" onClick={handleRequestClick}>
          //   Request for CV
          // </StyledButtonAccepted>
        )
      ) : (
        <></>
        // <DisabledButton>Request for CV</DisabledButton>
      )}
    </StyledDiv>
  );
};
export const renderPendingActionItems = () => (
  <StyledDiv>
    <AceeptIcon style={{ marginRight: "8px" }} />
    <RejectIcon />
  </StyledDiv>
);

export const InterestedColumns: GridColDef[] = [
  {
    field: "actionItems",
    headerName: "Actions",
    width: 280,
    renderCell: renderActionItems,
  },
  { field: "vendor_id", headerName: "Vendor Id", width: 120 },

  { field: "name", headerName: "Vendor Name", sortable: true, width: 160 },
  {
    field: "rating",
    headerName: "Rating",
    width: 150,
    renderCell: (params: GridCellParams) => (
      <RatingStars rating={params.value as number} />
    ),
  },
  {
    field: "submitted_bids",
    headerName: "Submissions till date",
    type: "number",
    width: 220,
  },
  {
    field: "cvs_submitted",
    headerName: "CVs Submitted till date",
    type: "number",
    width: 200,
  },
];

export const requestedSubmissionsColumns: GridColDef[] = [
  { field: "vendor_id", headerName: "Vendor Id", width: 120 },
  {
    field: "actionItems",
    headerName: "Actions",
    width: 280,
    renderCell: (params) => renderRejectedActionItems(),
  },
  { field: "name", headerName: "Vendor Name", sortable: true, width: 160 },
  {
    field: "startDate",
    headerName: "requestSentOn",
    width: 150,
    renderCell: (params: GridRenderCellParams<VendorModal>) => {
      const cellValue = params.value;
      return (
        <>
          <Stack direction="row" spacing={1} mt={0}>
            <p>{cellValue}</p>
          </Stack>
          ;
        </>
      );
    },
  },
  {
    field: "submissionsTillDate",
    headerName: "Submissions till date",
    type: "number",
    width: 220,
  },
  {
    field: "cvs_submitted",
    headerName: "CVs Submitted till date",
    type: "number",
    width: 200,
  },
];

export const requestedCvsColumns: GridColDef[] = [
  { field: "vendor_id", headerName: "Vendor Id", width: 180 },

  {
    field: "actionItems",
    headerName: "Actions",
    width: 240,
    renderCell: renderCvsActionItems,
  },

  { field: "name", headerName: "Vendor Name", sortable: true, width: 160 },
  {
    field: "startDate",
    headerName: "Submission Date",
    width: 150,
    renderCell: (params: GridRenderCellParams<VendorModal>) => {
      const cellValue = params.value;
      return (
        <>
          <Stack direction="row" spacing={1} mt={0}>
            <p>{cellValue}</p>
          </Stack>
          ;
        </>
      );
    },
  },
  {
    field: "Visa_Type",
    headerName: "Candidate Visa",
    sortable: true,
    width: 160,
    renderCell: (params: GridRenderCellParams<string[]>) => (
      <Stack direction="row" spacing={1} mt={1}>
        {Array.isArray(params.value) && params.value.length > 0
          ? params.value.map((Visa: string) => <Skil key={Visa} label={Visa} />)
          : null}
      </Stack>
    ),
  },
  {
    field: "Bill_Rate",
    headerName: "Rate info",
    width: 150,
  },
  {
    field: "Employer_Level",
    headerName: "Employee Level",
    width: 200,
  },
  {
    field: "commission",
    headerName: "Commissions asked",
    width: 180,
  },
  {
    field: "cvs_mentioned",
    headerName: "CVs mentioned",
    type: "number",
    width: 180,
  },
  {
    field: "cvs_submitted",
    headerName: "CVs Submitted",
    type: "number",
    width: 180,
  },
];

export const notInterestedColumns: GridColDef[] = [
  { field: "vendor_id", headerName: "Vendor Id", width: 120 },
  { field: "name", headerName: "Vendor Name", sortable: true, width: 160 },
  {
    field: "rating",
    headerName: "Rating",
    width: 150,
    renderCell: (params: GridCellParams) => (
      <RatingStars rating={params.value as number} />
    ),
  },
  {
    field: "submitted_bids",
    headerName: "Submissions till date",
    type: "number",
    width: 220,
  },
  {
    field: "cvs_submitted",
    headerName: "CVs Submitted till date",
    type: "number",
    width: 220,
  },
];

const StyledDiv = styled("div")({
  display: "inline-flex",
  alignItems: "center",
  verticalAlign: "middle",
});

const StyledButton = styled(Button)({
  padding: "5px 20px",
  borderRadius: "8px",
  backgroundColor: themeColors.primaryLight,
  color: themeColors.primaryDark,
  textDecoration: "underline",
});
const DisabledButton = styled(Button)({
  padding: "5px 20px",
  borderRadius: "8px",
  backgroundColor: themeColors.disabled,
  color: themeColors.disabedDark,
  textDecoration: "underline",
});
export const StyledButtonAccepted = styled(Button)({
  color: themeColors.success,
  padding: "5px 20px",
  borderRadius: "8px",
  backgroundColor: themeColors.successLight,
  textDecoration: "underline",
  border: "none",
  "&:hover": {
    backgroundColor: themeColors.successLight,
    color: themeColors.success,
    border: "none",
  },
});
