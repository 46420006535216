import { ReactElement } from "react";
import { ThemeProvider as MuiThemeProvider } from "@mui/material/styles";
import { CssBaseline } from "@mui/material";
import { muiTheme } from "./theme";
import { useAppSelector } from "core/store";

interface Props {
  children: ReactElement;
}

export default function EmotionTheme({ children }: Props): ReactElement {
  const {isVendor} = useAppSelector(store => store.auth);
  return (
    <MuiThemeProvider theme={muiTheme(isVendor)}>
      <CssBaseline />
      {children}
    </MuiThemeProvider>
  );
}
