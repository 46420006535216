import { ReactComponent as create } from "../../../assets/icons/create.svg";
import { ReactComponent as recieve } from "../../../assets/icons/recieve.svg";
import { ReactComponent as reject } from "../../../assets/icons/reject.svg";
import { ReactComponent as review } from "../../../assets/icons/review.svg";
import { ReactComponent as accept } from "../../../assets/icons/accept.svg";

export const employeeWidgetOptions = [
  { label: "Quick Requisitions", value: "option1" },
  // { label: "Your Journey so far", value: "option2" },
  { label: "Recent Submissions", value: "option3" },
  { label: "Requisitions about to expire", value: "option5" }
];


export const bidData = [
  {
    name: "Suresh",
    bidId: "TQ1234",
    jobTitle: "WordPress",
    visaCategory: "H1B",
    rate: "$500",
    commission: "15%",
  },
  {
    name: "Suresh",
    bidId: "TQ1234",
    jobTitle: "WordPress",
    visaCategory: "H1B",
    rate: "$500",
    commission: "15%",
  },
  {
    name: "Suresh",
    bidId: "TQ1234",
    jobTitle: "WordPress",
    visaCategory: "H1B",
    rate: "$500",
    commission: "15%",
  },
  {
    name: "Suresh",
    bidId: "TQ1234",
    jobTitle: "WordPress",
    visaCategory: "H1B",
    rate: "$500",
    commission: "15%",
  },
];

export const employeeActions = [
  {
      "icon": "/Assets/icons/employer/Add.svg",
      "text": "Create a Requisition",
      "url": "/employer/dashboard/create-requisition"
  },
  {
    "icon": "/Assets/icons/employer/Group.svg",
    "text": "Review Submissions",
    "url": "/employer/submission-market-place"
},
  {
      "icon": "/Assets/icons/employer/Star.svg",
      "text": "See all Vendors",
      "url": "/employer/my-network"
  },
  {
      "icon": "/Assets/icons/employer/Chats.svg",
      "text": "Chat",
      "url": "/employer/chats"
  },
]

export const jobData = [
  {
    title: "Product Analyst",
    experience: "1 to 3 yrs experience",
    locations: "Chennai, Bangalore, Hyderabad",
    skills: "Design skills, Figma",
    daysLeft: 14,
    newQuotations: 12,
    reviewedQuotations: 22,
    chatsInitiated: 8,
  },
  {
    title: "UI Designer",
    experience: "1 to 3 yrs experience",
    locations: "Chennai, Bangalore, Hyderabad",
    skills: "Design skills, Figma",
    daysLeft: 14,
    newQuotations: 12,
    reviewedQuotations: 22,
    chatsInitiated: 8,
  },
  {
    title: "UI Designer",
    experience: "1 to 3 yrs experience",
    locations: "Chennai, Bangalore, Hyderabad",
    skills: "Design skills, Figma",
    daysLeft: 14,
    newQuotations: 12,
    reviewedQuotations: 22,
    chatsInitiated: 8,
  },
  {
    title: "UI Designer",
    experience: "1 to 3 yrs experience",
    locations: "Chennai, Bangalore, Hyderabad",
    skills: "Design skills, Figma",
    daysLeft: 14,
    newQuotations: 12,
    reviewedQuotations: 22,
    chatsInitiated: 8,
  },
  {
    title: "UI Designer",
    experience: "1 to 3 yrs experience",
    locations: "Chennai, Bangalore, Hyderabad",
    skills: "Design skills, Figma",
    daysLeft: 14,
    newQuotations: 12,
    reviewedQuotations: 22,
    chatsInitiated: 8,
  },
];

export const journeyCards = [
  { icon: create, text: "Requisitions created so far", numberText: "200" },
  { icon: recieve, text: "Submissions Received", numberText: "200" },
  { icon: review, text: "Submissions Not Reviewed", numberText: "200" },
  { icon: accept, text: "Submissions Accepted", numberText: "200" },
  { icon: reject, text: "Submissions Rejected", numberText: "200" },
];

export const EmployerActions = [
  {
    icon: "/Assets/icons/employer/Add.svg",
    text: "Create a Requisition",
    url: "/employer/dashboard/create-requisition",
  },
  {
    icon: "/Assets/icons/employer/Group.svg",
    text: "See all Vendors",
    url: "",
  },
  {
    icon: "/Assets/icons/employer/Star.svg",
    text: "Review submissions",
    url: "",
  },
  {
    icon: "/Assets/icons/employer/Chats.svg",
    text: "Chat",
    url: "",
  },
  {
    icon: "/Assets/icons/employer/Simplification.svg",
    text: "Manage your Team",
    url: "",
  },
];

export const pdfUrls = [
  "https://xak9-hweo-h6q9.n7.xano.io/vault/wZb2lGyv/ZnftgD-RQAnSdVTwPZJ80XMgczU/8hmc6A../dummy-pdf_2%25252B%252525281%25252529.pdf",
  "https://xak9-hweo-h6q9.n7.xano.io/vault/wZb2lGyv/WmB2-BMMlZfPftDPi4lYkd8dzNU/r5vMCA../file-af8578.pdf",
  "https://xak9-hweo-h6q9.n7.xano.io/vault/wZb2lGyv/ZnftgD-RQAnSdVTwPZJ80XMgczU/8hmc6A../dummy-pdf_2%25252B%252525281%25252529.pdf",
  "https://xak9-hweo-h6q9.n7.xano.io/vault/wZb2lGyv/WmB2-BMMlZfPftDPi4lYkd8dzNU/r5vMCA../file-af8578.pdf",
  "https://xak9-hweo-h6q9.n7.xano.io/vault/wZb2lGyv/ZnftgD-RQAnSdVTwPZJ80XMgczU/8hmc6A../dummy-pdf_2%25252B%252525281%25252529.pdf",
  "https://xak9-hweo-h6q9.n7.xano.io/vault/wZb2lGyv/WmB2-BMMlZfPftDPi4lYkd8dzNU/r5vMCA../file-af8578.pdf",
  "https://xak9-hweo-h6q9.n7.xano.io/vault/wZb2lGyv/ZnftgD-RQAnSdVTwPZJ80XMgczU/8hmc6A../dummy-pdf_2%25252B%252525281%25252529.pdf",
  "https://xak9-hweo-h6q9.n7.xano.io/vault/wZb2lGyv/WmB2-BMMlZfPftDPi4lYkd8dzNU/r5vMCA../file-af8578.pdf",
];