import React from 'react';
import { Pie } from 'react-chartjs-2';
import { Chart as ChartJS, Tooltip, Legend, ArcElement } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import styled from '@emotion/styled';

ChartJS.register(ArcElement, Tooltip, Legend, ChartDataLabels);

interface PieChartProps {
  data: {
    labels: string[];
    datasets: {
      data: (string | number)[];
      backgroundColor: string[];
    }[];
  };
  options?: any;
}

const PieChart: React.FC<PieChartProps> = ({ data, options }) => {
  return (
    <PieDiv>
      <Pie data={data} options={options} />
    </PieDiv>
  );
};

const PieDiv = styled.div`
  width: 600px;
  height: 600px;
`;

export default PieChart;
