import styled from '@emotion/styled';
import { ReactComponent as GoldStar } from "../../../../assets/images/icons/Star.svg";
import { ReactComponent as EmptyStar } from "../../../../assets/images/icons/Vector.svg";
import { themeColors } from 'core/Theming/theme';


interface RatingStarsProps {
  rating: number | null;
  goldStarColor?: string;
  starSize?: string;
}


const RatingStars: React.FC<RatingStarsProps> = ({
  rating = null,
  goldStarColor = themeColors.goldStar,
  starSize = '20px',
}) => {
  const validRating = rating === null ? 0 : Math.max(0, Math.min(5, rating));

  return (
    <Container starSize={starSize}>
      {Array.from({ length: 5 }, (_, index) =>
        index < validRating ? (
          <Star
            key={index}
            as={GoldStar}
            size={starSize}
            fillcolor={goldStarColor}
          />
        ) : (
          <Star
            key={index}
            as={EmptyStar}
            size={starSize}
            fillcolor="transparent" 
            strokeWidth="1px" 
            strokeColor={themeColors.emptyStar}
          />
        )
      )}
    </Container>
  );
};

const Container = styled.div<{ starSize: string }>`
  display: inline-flex;
  align-items: center;
  font-size: ${(props) => props.starSize};
`;

const Star = styled.svg<{ size: string; fillcolor: string; strokeWidth?: string; strokeColor?: string }>`
  width: ${(props) => props.size};
  height: ${(props) => props.size};
  fill: ${(props) => props.fillcolor};
  stroke-width: ${(props) => props.strokeWidth || '0'};
  stroke: ${(props) => props.strokeColor || 'none'};
`;

export default RatingStars;
