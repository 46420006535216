import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  FormControl,
  Grid,
  Paper,
  TextField,
  Typography,
  Divider as MuiDivider,
  Slider,
  CircularProgress,
} from "@mui/material";
import * as Yup from "yup";
import styled from "@emotion/styled";
import { Formik, Form, Field, ErrorMessage, FormikHelpers } from "formik";
import { CompanyInfomDataModal, User } from "api/resources/modals";
import { themeColors } from "core/Theming/theme";
import Spinner from "components/spinner/Spinner";

interface AboutCompanyProps {
  userdata?: User;
  handleSave: (
    values: CompanyInfomDataModal,
    actions: FormikHelpers<CompanyInfomDataModal>
  ) => void;
}
export const AboutCompany: React.FC<AboutCompanyProps> = ({
  userdata,
  handleSave,
}) => {

  const [sizeRange, setSizeRange] = useState<number[]>([1, 1000]);

  const validationSchema = Yup.object({
    headquarters: Yup.string().matches(
      /^[A-Za-z\s]+$/,
      "Headquarters cannot contain numbers"
    ).required("Company Headquarters is required"),
    size: Yup.string()
      .matches(/^[\d-]+$/, "Size must be a number")
      .required("Company Size is required"),
  });

  if (!userdata)
    return (
      <Grid
        display="flex"
        height={"80vh"}
        justifyContent="center"
        alignItems="center"
      >
      <Spinner show={true} />
      </Grid>
    );

  return (
    <Formik
      initialValues={{
        headquarters: userdata?.about_company?.company_headquarters || "",
        size: userdata?.about_company?.company_size || '1-1000',
        revenue: userdata?.about_company?.company_revenue || "",
        description: userdata?.about_company?.company_description || "",
      }}
      validationSchema={validationSchema}
      onSubmit={handleSave}
    >
      {({ setFieldValue, values }) => {
        useEffect(() => {
          if (typeof values.size === 'string') {
            const [min, max] = values.size.split('-').map(Number);
            setSizeRange([min || 1, max || 1000]);
          }
        }, [values.size]);

        const handleSliderChange = (event: Event, newValue: number | number[]) => {
          if (Array.isArray(newValue)) {
            setSizeRange(newValue);
            setFieldValue('size', `${newValue[0]}-${newValue[1]}`);
          }
        };

        return (
          <Form>
            <Box>
              <StyledPaper variant="outlined">
                <StyledBox>
                  <Typography variant="h4">About Company</Typography>
                </StyledBox>
                <Box mt={2}>
                  <Grid container spacing={2}>
                    <Grid item md={6}>
                      <LabelName>Company Headquarters</LabelName>
                      <FormControl fullWidth margin="normal">
                        <Field
                          as={TextField}
                          name="headquarters"
                          label="Company Headquarters"
                          variant="outlined"
                        />
                        <ErrorMessage
                          name="headquarters"
                          component={StyledErrorMessage}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item md={6}>
                      <LabelName>Company Size</LabelName>
                      <FormControl fullWidth margin="normal">
                      <Slider
                          value={sizeRange}
                          onChange={handleSliderChange}
                          valueLabelDisplay="auto"
                          min={1}
                          max={1000}
                          step={1}
                          marks={[
                            { value: 1, label: '1' },
                            { value: 200, label: '200' },
                            { value: 400, label: '400' },
                            { value: 600, label: '600' },
                            { value: 800, label: '800' },
                            { value: 1000, label: '1000' },
                          ]}
                        />
                        <ErrorMessage
                          name="size"
                          component={StyledErrorMessage}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item md={6}>
                      <LabelName>Company Revenue</LabelName>
                      <FormControl fullWidth margin="normal">
                        <Field
                          as={TextField}
                          name="revenue"
                          label="Company Revenue"
                          variant="outlined"
                        />
                      </FormControl>
                    </Grid>
                    <Grid item md={12}>
                      <LabelName>Description</LabelName>
                      <Field
                        as={TextField}
                        name="description"
                        label="Description"
                        multiline
                        rows={4}
                        fullWidth
                        variant="outlined"
                        margin="normal"
                      />
                    </Grid>
                  </Grid>
                </Box>
                <StyledDivider />
                <Boxmodule>
                  <Button variant="contained" color="primary" type="submit">
                    Save
                  </Button>
                </Boxmodule>
              </StyledPaper>
            </Box>
          </Form>
        );
      }}
    </Formik>
  );
};

const StyledBox = styled(Box)({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
});

const StyledPaper = styled(Paper)({
  padding: "24px",
  marginBottom: "16px",
});

const StyledErrorMessage = styled.div`
  color: red;
  font-size: 0.8em;
`;

const LabelName = styled.p`
  display: flex;
  font-weight: 500;
  color: ${themeColors.grey};
  margin: 0px;
`;

const StyledDivider = styled(MuiDivider)`
  margin: 20px 0px;
  background: ${themeColors.badgeGray};
`;

const Boxmodule = styled(Box)`
  display: flex;
  justify-content: flex-start;
`;
