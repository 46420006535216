import React, { useEffect, useState } from "react";
import {
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Divider,
  IconButton,
  styled,
  Box,
  Typography,
  Button,
} from "@mui/material";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { SideNavbarMenuItemModal } from "api/resources/modals";
import { useLocation, useNavigate } from "react-router-dom";
import { logout } from "core/Auth";
import { useAppSelector } from "core/store";
import { VendorSideNavData } from "./data/vendorSideNavData";
import { EmployerSideNavData } from "./data/employerSideNavData";
import { themeColors } from "core/Theming/theme";

interface SelectedProps {
  selected?: boolean;
  isVendor?: boolean | null;
}

const Sidenavbar = () => {
  const { isVendor } = useAppSelector((store) => store.auth);
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = `${location.pathname}${location.search}`;
  const [open, setOpen] = useState(false);
  const [menuItems, setMenuItems] = useState<SideNavbarMenuItemModal[] | []>(
    []
  );

  useEffect(() => {
    if (isVendor) {
      setMenuItems(VendorSideNavData);
    } else {
      setMenuItems(EmployerSideNavData);
    }
  }, [isVendor]);

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleSelect = (url: string) => {
    navigate(url);
  };

  const drawerWidth = 240;

  return (
    <StyledDrawer variant="permanent" open={open}>
      <StyledLogoBox>
        <StyledIconButton
          isVendor={isVendor}
          onClick={open ? handleDrawerClose : handleDrawerOpen}
        >
          {open ? <ChevronLeftIcon /> : <ChevronRightIcon />}
        </StyledIconButton>
      </StyledLogoBox>
      <List>
        {menuItems?.map((item) => (
          <React.Fragment key={item?.icon}>
            {item?.component === "Settings" && <Divider />}
            <StyledListItemButton
              selected={searchParams.includes(item?.url)}
              onClick={() => handleSelect(item?.url)}
              style={{ width: open ? drawerWidth : 85 }}
              isVendor={isVendor}
            >
              <ListItemIcon>
                <img
                  src={
                    searchParams.includes(item?.url)
                      ? item?.selectedIcon
                      : item?.icon
                  }
                  alt=""
                />
              </ListItemIcon>
              {open && <ListItemText primary={item?.component} />}
            </StyledListItemButton>
          </React.Fragment>
        ))}
        <React.Fragment>
          <StyledListItemButton isVendor={isVendor} onClick={() => logout()}>
            <StyledLogout>
              <img src={"/Assets/sidenavIcons/Logout.svg"} alt="icon" />
            </StyledLogout>
            {open && <ListItemText primary={"Logout"} />}
          </StyledListItemButton>
        </React.Fragment>
      </List>
      {open &&
      <>{
        isVendor?(
          <VendorsContainers>
          <Typography color={themeColors.blackGray}>Your current plan ends on</Typography>
          <Typography color={themeColors.blackGray}>  <RedSpan>22/09/24</RedSpan> (5 more days)</Typography>
          <StyleButton
          aria-controls="multi-select-menu"
          aria-haspopup="true"
          variant={isVendor ? 'contained' : 'contained'}
        >
        Extend Membership
        </StyleButton>
        </VendorsContainers>

        ):(
          <Containers>
          <Typography color={themeColors.blackGray}>Your current plan ends on</Typography>
          <Typography color={themeColors.blackGray}>  <RedSpan>22/09/24</RedSpan> (5 more days)</Typography>
          <StyleButton
          aria-controls="multi-select-menu"
          aria-haspopup="true"
          variant={isVendor ? 'contained' : 'contained'}
        >
        Extend Membership
        </StyleButton>
        </Containers>
        )
      }
      </>
}
    </StyledDrawer>
  );
};

const RedSpan = styled('span')({
  color: '#FF0000',
});
const Containers = styled(Box)({
    width: '205px',
    padding: '10px',
    textAlign: 'center',
    backgroundColor:themeColors.primaryLight,
    marginLeft:'16px',
    borderRadius:'10px',
  });

  const StyleButton=styled(Button)({
    marginTop:'16px',
    borderRadius:'4px',
    marginBottom:'10px',
  })

  const VendorsContainers = styled(Box)({
    width: '205px',
    padding: '10px',
    textAlign: 'center',
    backgroundColor:themeColors.purpleLight ,
    marginLeft:'16px',
    borderRadius:'10px',
  });


const StyledLogout = styled(ListItemIcon)`
  filter: "invert(20%) sepia(70%) saturate(6000%) hue-rotate(0deg) brightness(85%) contrast(100%)"
  color: "red"
`;

const StyledDrawer = styled(Drawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ open }: { open: boolean }) => ({
  width: open ? 240 : 85,
  position: 'sticky',
  overflow: "hidden",
  flexShrink: 0,
  borderRadius: "0px 8px 8px 0px",
  overflowX: "hidden",
  marginTop: "20px",
  "& .MuiDrawer-paper": {
    overflowY: "hidden",
    position: "fixed",
    top: "100px",
    bottom: "0",
    width: open ? 240 : 85,
    border: `1px solid ${themeColors.emptyStar}`,
    borderRadius: "0px 8px 8px 0px",
    boxShadow: "0px 0px 4px 0px rgba(0, 0, 0, 0.25)",
    overflowX: "hidden",
  },
}));

const StyledLogoBox = styled("div")({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
});

const StyledListItemButton = styled(ListItem) <SelectedProps>`
  ${({ selected, isVendor }) =>
    selected &&
    `
    border-right: none;
    border-radius: 8px;
    color: ${isVendor ? themeColors.purpleDark : themeColors.primaryDark} !important;
    background-color: ${isVendor ? themeColors.purpleLight : themeColors.primaryLight} !important;
  `}
  &:hover {
    color: ${({ isVendor }) => (isVendor ? themeColors.purpleDark : themeColors.primaryDark)} !important;
    background-color: ${({ isVendor }) =>
    isVendor ? themeColors.purpleLight : themeColors.primaryLight} !important;
    cursor: pointer;
  }
  width: 100%;
  display: flex;
  align-items: center;
`;

const StyledIconButton = styled(IconButton) <{ isVendor: boolean | null }>`
  background-color: ${({ isVendor }) => (isVendor ? themeColors.purpleLight : themeColors.primaryLight)};
  color: ${({ isVendor }) => (isVendor ? themeColors.purpleDark : themeColors.primaryDark)};

  &:hover {
    background-color: ${({ isVendor }) => (isVendor ? themeColors.purpleLight : themeColors.primaryLight)};
    color: ${({ isVendor }) => (isVendor ? themeColors.purpleDark : themeColors.primaryDark)};
  }
`;

export default Sidenavbar;
