import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary/AccordionSummary";
import { useGetRFRDetails, usePostJobDescription } from "pages/hooks";
import { ChangeEvent, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { ReactComponent as CalenderStart } from "../../../assets/icons/CalendarStart.svg";
import { ReactComponent as CalenderExpired } from "../../../assets/icons/CalendarExpired.svg";
import { ReactComponent as Certificate } from "../../../assets/icons/Certificate.svg";
import { ReactComponent as Dollar } from "../../../assets/icons/Dollar.svg";
import { ReactComponent as Experience } from "../../../assets/icons/Experience.svg";
import { ReactComponent as Location } from "../../../assets/icons/Location.svg";
import { ReactComponent as Job } from "../../../assets/images/icons/Job.svg";
import { ReactComponent as Visa } from "../../../assets/images/icons/Passport.svg";
import { ReactComponent as Skills } from "../../../assets/images/icons/skills.svg";
import { ReactComponent as PdfIcon } from "../../../assets/images/pdfIcon.svg";
import { ReactComponent as EditIcon } from "../../../assets/icons/Edit-Post-Comment.svg";
import { ReactComponent as ChevronIcon } from "../../../assets/icons/Chevron.svg";
import styled from "@emotion/styled";
import { themeColors } from "core/Theming/theme";
import { Box, Card, Chip, Grid, IconButton, Typography } from "@mui/material";
import InfoBox from "../Components/InfoBox";
import Spinner from "components/spinner/Spinner";



function JobDetails() {
  const { postJDDetails, error } = usePostJobDescription({
    onSuccess: () => {
      toast.success("Submitted Successfully");
      refetchRFRDetails();
    },
    onError: () => toast.success("Something went wrong, please try again"),
  });
  const { "rfq-id": rfqId } = useParams<{ "rfq-id"?: string }>();

  const {
    rfrDetails,
    isLoading,
    refetch: refetchRFRDetails,
  } = useGetRFRDetails(rfqId);
  const data = rfrDetails?.rfq_details;
  const [fileName, setFileName] = useState<string | null>(
    data?.upload_job_description?.name || null
  );

  useEffect(() => {
    refetchRFRDetails();
  }, [rfqId]);
  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();
    const file = event.target.files?.[0];

    if (!file) return;
    if (file.type !== "application/pdf") {
      toast.error("Please select a PDF file.");
      return;
    }
    if (!rfqId) {
      toast.error("RFQ ID is missing.");
      return;
    }
    const formData = new FormData();
    formData.append("rfq_id", rfqId);
    formData.append("ChangeJD", file);

    postJDDetails(formData)
      .then(() => {
        setFileName(file.name);
        toast.success("File submitted successfully");
      })
      .catch(() => {
        toast.error("Error submitting file");
      });
  };
  const handleEditClick = () => {
    const fileInput = document.getElementById("file-input") as HTMLInputElement;
    if (fileInput) {
      fileInput.click();
    } else {
      console.error("File input not found");
    }
  };
  if (isLoading) {
    return <Spinner show={true} />;
  }
  return (
    <Box mt={4}>
      <StyledAccordion key={data?.start_date}>
        <StyledAccordionSummary
          expandIcon={<ChevronIcon />}
          aria-controls="panel-content"
          id="panel-header"
        >
          <CardHeader>{data?.job_title || "No Job Title"}</CardHeader>
        </StyledAccordionSummary>
        <AccordionDetails>
          <StyledCardBox>
            <Box display={"inline-flex"}>
              <InterestedBox>
                Interests Received: {data?.interested_count || "0"}
              </InterestedBox>
              <DeclinedBox>Declines Received: {data?.not_interested_count || "0"}</DeclinedBox>
            </Box>
            <StyledJDBox>
              <InfoTypography> Uploaded JD: </InfoTypography>
              <PdfIcon height={"24px"} />
              <JdTypography>
                {fileName ||
                  data?.upload_job_description?.name ||
                  "No JD Available"}
              </JdTypography>
              <IconButton onClick={handleEditClick}>
                <EditIcon />
              </IconButton>
              <HiddenFileInput
                type="file"
                id="file-input"
                accept=".pdf"
                onChange={handleFileChange}
              />
            </StyledJDBox>
          </StyledCardBox>
          <StyledCardBottomBox>
            <Grid item md={6}>
              <InfoBox
                icon={CalenderStart}
                label={"Requisition Created On"}
                value={data?.start_date || "start_date"}
              />
              <InfoBox
                icon={CalenderExpired}
                label={"Requisition Expires On"}
                value={data?.rfq_closing_date || "No End Date Provided."}
              />
              <InfoBox
                icon={Experience}
                label={"Experience"}
                value={data?.experience.join(", ") || "Not Provided."}
              />

              <InfoBox
                icon={Visa}
                label={"Visa Type"}
                value={
                  (
                    <div>
                      {data?.visa_type.map((skill) => (
                        <Skil key={skill} label={skill} />
                      ))}
                    </div>
                  ) || "N/A"
                }
              />
              <InfoBox
                icon={Job}
                label={"Job Type"}
                value={data?.job_type || "Not Provided."}
              />
              <InfoBox
                icon={Job}
                label={"Job Category"}
                value={data?.job_category || "Not Provided."}
              />
            </Grid>
            <Grid
              item
              md={6}
              container
              direction="column"
              alignItems="flex-start"
            >
              <InfoBox
                icon={Location}
                label={"Locations"}
                value={data?.locations.join(", ") || "No locations"}
              />
              <InfoBox
                icon={Certificate}
                label={"Qualifications"}
                value={data?.qualifications.join(", ") || "No qualifications"}
              />
              <InfoBox
                icon={Dollar}
                label={"Rate Info"}
                value={`${data?.rate_info.bill_rate}` || "N/A"}
              />

              <InfoBox
                icon={Skills}
                label={"Primary Skills"}
                value={
                  (
                    <div>
                      {data?.primary_skills.map((skill) => (
                        <Skil key={skill} label={skill} />
                      ))}
                    </div>
                  ) || "N/A"
                }
              />
              <InfoBox
                icon={Skills}
                label="Secondary Skills"
                value={
                  (
                    <div>
                      {data?.secondary_skills?.map((skill) => (
                        <Skil key={skill} label={skill} />
                      ))}
                    </div>
                  ) || "N/A"
                }
              />
            </Grid>
          </StyledCardBottomBox>
        </AccordionDetails>
      </StyledAccordion>
    </Box>
  );
}


const StyledAccordion = styled(Accordion)({
  borderRadius: '8px',
  boxShadow: '0px 0px 4px 0px rgba(77, 108, 217, 0.75)',
});
const StyledAccordionSummary = styled(AccordionSummary)({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
});
const HiddenFileInput = styled("input")({
  display: "none",
});
const JdTypography = styled(Typography)({
  color: themeColors.primaryDark,
  fontSize: "16px",
  fontStyle: "normal",
  fontWeight: 400,
  textDecoration: "underline",
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
});
const StyledJDBox = styled(Box)({
  display: "flex",
  width: "50%",
  alignItems: "center",
  height: "44px",
  float: "left",
  verticalAlign: "middle",
  borderRadius: "8px",
  background: themeColors.primaryLight,
  boxShadow: "0px 0px 4px 0px rgba(0, 0, 0, 0.08)",
  padding: "10px 20px",
});
const StyledCardBox = styled(Box)({
  display: "flex",
  justifyContent: "space-between",
  borderRadius: "8px",
});
const InfoTypography = styled(Typography)({
  marginLeft: "8px",
  color: themeColors.blackGray,
  fontSize: "18px",
  fontStyle: "normal",
  fontWeight: 600,
  width: "120px"
});
const InterestedBox = styled(Box)({
  width: "208px",
  height: "30px",
  display: "flex",
  padding: "7px 20px",
  marginRight: "8px",
  justifyContent: "center",
  alignItems: "center",
  borderRadius: "30px",
  backgroundColor: themeColors.successLight,
  color: themeColors.success,
  textAlign: "center",
  fontFamily: "Roboto",
  fontSize: "16px",
  fontStyle: "normal",
  fontWeight: 500,
});

const DeclinedBox = styled(Box)({
  width: "236px",
  height: "30px",
  display: "flex",
  padding: "7px 20px",
  justifyContent: "center",
  alignItems: "center",
  borderRadius: "30px",
  backgroundColor: themeColors.badgeError,
  color: themeColors.error,
  textAlign: "center",
  fontSize: "16px",
  fontStyle: "normal",
  fontWeight: 500,
});
const Skil = styled(Chip)({
  background: themeColors.primaryLight,
  color: themeColors.primaryDark,
  fontWeight: "500",
  marginRight: "2px"
});
const StyledCardBottomBox = styled(Box)({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  width: "100%",
  borderRadius: "8px",
  marginTop: "32px",
});
const CardHeader = styled(Typography)({
  color: themeColors.headerBlack,
  fontSize: '32px',
  fontStyle: 'normal',
  fontWeight: 600,
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
});


export default JobDetails;
