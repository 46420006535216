import styled from "@emotion/styled";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { Box, Button, Card, CardContent, Modal, Paper, Tooltip, Typography } from "@mui/material";
import { SubmissionDetailCvList } from "api/resources/modals";
import CustomFileUpload from "components/fileUploadComponent/FileUpload";
import Spinner from "components/spinner/Spinner";
import { themeColors } from "core/Theming/theme";
import { useVendorEditCv } from "pages/hooks";
import React, { useRef, useState } from "react";
import { toast } from "react-toastify";

interface SubmissionDetailsProps {
    data: SubmissionDetailCvList['allCvs']
    spinner: boolean;
    handleCallCvApi: (callApi: boolean) => void;
}

const VendorSubmissionCardView: React.FC<SubmissionDetailsProps> = ({ data = [], handleCallCvApi, spinner }) => {
    const [currentPdfIndex, setCurrentPdfIndex] = useState(0);
    const [isRightClicked, setIsRightClicked] = useState(false);
    const [isLeftClicked, setIsLeftClicked] = useState(false);
    const scrollRef = useRef<HTMLDivElement | null>(null);
    const [cvModal, showCvModal] = useState(false);
    const [editCvId, setEditCvId] = useState('');
    const [editCvFile, setEditCvFile] = useState<File | null>(null);
    const [downloadSpinner, showDownloadSpinner] = useState(false)
    const { updateVendorCv, isLoading, error } = useVendorEditCv({
        onSuccess: () => {
            toast.success("CV Updated Successfully");
        },
        onError: () => {
            toast.error(error?.message);
        },
    });

    const Cv = data;

    const handleCardClick = (index: number) => {
        setCurrentPdfIndex(index);
    };

    const handleClickLeft = () => {
        if (currentPdfIndex > 0) setCurrentPdfIndex((prevIndex) => prevIndex - 1);
    };

    const handleClickRight = () => {
        if (currentPdfIndex < Cv.length - 1) setCurrentPdfIndex((prevIndex) => prevIndex + 1);
    };

    const handleScrollLeft = () => {
        if (scrollRef.current)
            scrollRef.current.scrollBy({ left: -200, behavior: "smooth" });

        setIsLeftClicked(true);
        setIsRightClicked(false);
    };

    const handleScrollRight = () => {
        if (scrollRef.current)
            scrollRef.current.scrollBy({ left: 200, behavior: "smooth" });

        setIsRightClicked(true);
        setIsLeftClicked(false);
    };

    const getBase64 = (file: File): Promise<string> => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => {
                const base64String = (reader.result as string).split('base64,')[1];
                resolve(base64String || '');
            };
            reader.onerror = (error) => reject(error);
        });
    };

    const handleEditCv = async () => {
        try {
            if (editCvFile) {
                const base64 = await getBase64(editCvFile);
                await updateVendorCv({ cvId: editCvId, base64 });
            }
        } catch (err) {
            toast.error('Something went wrong please try again');
        } finally {
            handleCallCvApi(true);
            showCvModal(false);
            setEditCvFile(null);
            setEditCvId('');
        }
    };

    const logDownloadEvent = () => {
        const eventPayload = {
            name: "Office.Word.Online.UserAction.DownloadACopy",
            time: new Date().toISOString(),
            ver: "4.0",
            iKey: "your-ikey",
            data: {
                // Populate with required fields from the payload
                "UserAction": {
                    "Id": 3555692408,
                    "Name": "DownloadACopy"
                },
                "Document": {
                    "Url": data[currentPdfIndex]?.cv
                },
                "User": {
                    "PrimaryIdentitySpace": "unknown",
                    "IsAnonymous": true
                }
            }
        };

        fetch('https://your-logging-endpoint.example.com', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(eventPayload)
        })
            .catch(error => console.error('Error logging download event:', error));
    };

    const handleDownloadCv = () => {
        logDownloadEvent(); // Log the download event

        const cvUrl = data[currentPdfIndex]?.cv;
        const fileName = data[currentPdfIndex]?.cv_name || 'document';
        const fileExtension = cvUrl.includes('.docx') ? '.docx' : '.pdf';

        fetch(cvUrl)
            .then(response => {
                if (!response.ok) {
                    throw new Error(`Network response was not ok: ${response.statusText}`);
                }
                return response.blob();
            })
            .then(blob => {
                const url = URL.createObjectURL(blob);
                const a = document.createElement('a');
                a.href = url;
                a.download = `${fileName}${fileExtension}`;
                document.body.appendChild(a);
                a.click();
                a.remove();
                URL.revokeObjectURL(url);
            })
            .catch(error => {
                console.error('Error downloading CV:', error);
                toast.error('Failed to download CV');
            });
    };

    return (
        <Spinner show={spinner}>
            <MainContent>
                {data.length > 0 ? (
                    <MainGridbox>
                        <Headingcontainer>
                            <Headingtesx variant="h4">
                                {data[currentPdfIndex]?.cv_name}
                            </Headingtesx>
                        </Headingcontainer>

                        <Contentcontainer>
                            <LeftscrollPdf>
                                <Button disabled={currentPdfIndex === 0}>
                                    <img src="/Assets/Images/leftArrowPurple.svg" alt="left move" onClick={handleClickLeft} />
                                </Button>
                            </LeftscrollPdf>
                            <PdfContainer>
                                {data.length > 0 &&
                                    <React.Fragment>
                                        {data[currentPdfIndex].cv?.slice(-5) === '.docx' ? (
                                            <iframe
                                                src={`https://docs.google.com/gview?url=${data[currentPdfIndex].cv}&embedded=true`}
                                                title="Document Viewer"
                                                width="100%"
                                                height="866px"
                                            />) :
                                            <PdfView src={data[currentPdfIndex].cv} type="application/pdf" />
                                        }
                                    </React.Fragment>
                                }
                                <Iconcontainer>
                                    <ImageContainer>
                                        <ToolsContainer>
                                            <ToolBadge
                                                color={data[currentPdfIndex].status === 'Disliked' ? themeColors.error : data[currentPdfIndex].status === 'Liked' ? themeColors?.success : data[currentPdfIndex].status === 'On hold' ? themeColors?.purpleDark : themeColors.warning}
                                                background={data[currentPdfIndex].status === 'Disliked' ? themeColors.badgeError : data[currentPdfIndex].status === 'Liked' ? themeColors?.badgeSuccess : data[currentPdfIndex].status === 'On hold' ? themeColors?.purpleLight : themeColors.badgeWarning}
                                            >
                                                {data[currentPdfIndex].status === 'Liked' ? <StyledStatus><img src="/Assets/Images/like.svg" alt="like" /></StyledStatus> : data[currentPdfIndex].status === 'Disliked' ? <StyledStatus><img src="/Assets/Images/disLike.svg" alt="disLike" /></StyledStatus> : <StyledStatus><img src="/Assets/Images/cvPending.svg" alt="" /></StyledStatus>}
                                                {data[currentPdfIndex].status}
                                            </ToolBadge>
                                        </ToolsContainer>
                                    </ImageContainer>
                                    <ImageContainer>
                                        <Typography>
                                            <Tooltip arrow title={'Edit Cv'}>
                                                <img
                                                    src="/Assets/Images/cv.svg"
                                                    alt="Change CV"
                                                    width={35}
                                                    onClick={() => {
                                                        showCvModal(true);
                                                        setEditCvId(data[currentPdfIndex]?.cv_id);
                                                        setEditCvFile(null); // Clear the file state when opening the modal
                                                    }}
                                                    style={{ cursor: 'pointer', marginRight: '8px' }}
                                                />
                                            </Tooltip>
                                        </Typography>
                                    </ImageContainer>
                                    <ImageContainer>
                                        <Typography>
                                            <Tooltip arrow title={'Download'}>
                                                <Spinner show={downloadSpinner}>
                                                    {data[currentPdfIndex].cv?.slice(-5) === '.docx' ?
                                                        <a href={data[currentPdfIndex].cv} target="_blank">
                                                            <img
                                                                src="/Assets/Images/downloadPurple.svg"
                                                                alt="Download CV"
                                                                width={35}
                                                                style={{ cursor: 'pointer', marginRight: '8px' }}
                                                            />
                                                        </a> :
                                                        <img
                                                            src="/Assets/Images/downloadPurple.svg"
                                                            alt="Download CV"
                                                            width={35}
                                                            onClick={handleDownloadCv}
                                                            style={{ cursor: 'pointer', marginRight: '8px' }}
                                                        />
                                                    }
                                                </Spinner>
                                            </Tooltip>
                                        </Typography>
                                    </ImageContainer>
                                </Iconcontainer>
                            </PdfContainer>
                            <RightscrollPdf>
                                <img src="/Assets/Images/rightArrowPurple.svg" alt="right move" onClick={handleClickRight} />
                            </RightscrollPdf>
                        </Contentcontainer>
                        <Box>
                            <Cardscontained>
                                <MainCardContainer>
                                    <LeftArrow
                                        onClick={handleScrollLeft}
                                        isClicked={isLeftClicked}
                                    />
                                    <ScrollContainer ref={scrollRef}>
                                        {Cv.map((url, index) => (
                                            <Cards
                                                key={index}
                                                onClick={() => handleCardClick(index)}
                                                isSelected={index === currentPdfIndex}
                                            >
                                                <CardContents>
                                                    <Typography variant="h4" color="primary">
                                                        {index + 1}
                                                    </Typography>
                                                </CardContents>
                                            </Cards>
                                        ))}
                                    </ScrollContainer>
                                    <RightArrow
                                        onClick={handleScrollRight}
                                        isClicked={isRightClicked}
                                    />
                                </MainCardContainer>
                            </Cardscontained>
                        </Box>
                    </MainGridbox>
                ) : (
                    <Errormessage>No Cvs found</Errormessage>
                )}
                <Modal
                    open={cvModal}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <StyledModalBody>
                        <Box sx={{ ...modalStyle }}>
                            <FileuploadBox>
                                <CustomFileUpload
                                    onFileChange={async (file: File | null) => {
                                        if (file) {
                                            setEditCvFile(file); // Set the file directly
                                        }
                                    }}
                                />
                            </FileuploadBox>
                            {editCvFile === null && <Typography color={themeColors.error}>Required to edit the existing file</Typography>}
                            <Spinner show={isLoading}>
                                <Typography sx={{ marginTop: '10px' }} align={'right'}>
                                    <Button sx={{ marginRight: '10px' }} variant="contained" size="small" disabled={editCvFile === null} onClick={handleEditCv}>Edit Cv</Button>
                                    <Button variant="outlined" size="small" onClick={() => { showCvModal(false) }}>cancel</Button>
                                </Typography>
                            </Spinner>
                        </Box>
                    </StyledModalBody>
                </Modal>
            </MainContent>
        </Spinner>
    );
};


const LeftscrollPdf = styled(Box)({
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
    marginRight: "30px",
});

const modalStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 600,
    bgcolor: "background.paper",
    boxShadow: 24,
    borderRadius: "15px",
    padding: '5px 20px 20px 20px'
};

const FileuploadBox = styled(Box)({
    marginTop: "16px",
    textAlign: "center",
    borderRadius: "10px",
    border: `1px dashed ${themeColors.emptyStar}`,
});

const StyledModalBody = styled(Box)(({ theme }) => ({
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
}));

const RightscrollPdf = styled(Box)({
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
    marginLeft: "30px",
});
const Errormessage = styled(Typography)({
    alignItems: "center",
    textAlign: "center",
});
const Contentcontainer = styled(Box)({
    display: "flex",
    justifyContent: "center",
    padding: "30px",
});

const Iconcontainer = styled(Box)({
    display: "flex",
    flexDirection: "column",
    marginLeft: "16px",
    justifyContent: "center",
    position: "absolute",
    top: "531px",
    right: "0px",
    background: 'linear-gradient(rgba(255, 255, 255, 0.8), rgba(255, 255, 255, 0.8))',
    width: '80px',
    height: '319px',
    borderRadius: '12px',
    boxShadow: `0px 0px 4px 0px  ${themeColors.shadowColor}`,
    padding: '10px 0px'
});

const ImageContainer = styled('div')({
    cursor: "pointer",
    margin: "16px 20px",

});

const LeftArrow = styled(KeyboardArrowLeftIcon)<{ isClicked: boolean }>(({ isClicked }) => ({
    cursor: "pointer",
    color: isClicked ? themeColors.purpleDark : themeColors.purpleLight,
    "&.MuiSvgIcon-root": {
        width: "3rem",
        height: "3rem",
    },
}));

const RightArrow = styled(KeyboardArrowRightIcon)<{ isClicked: boolean }>(({ isClicked }) => ({
    cursor: "pointer",
    color: isClicked ? themeColors.purpleDark : themeColors.lightgrey,
    "&.MuiSvgIcon-root": {
        width: "3rem",
        height: "3rem",
    },
}));

const Cardscontained = styled(Box)({
    display: "grid",
    gridAutoFlow: "column",
    gridAutoColumns: "100%",
});

const MainCardContainer = styled(Box)({
    display: "flex",
    alignItems: "center",
    justifyContent: 'center',
    position: "relative",
    width: "100%",
});
const PdfContainer = styled(Box)({
    width: "80%",
    position: "relative",
});



const CardContents = styled(CardContent)({
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
});

const Cards = styled(Card)<{ isSelected: boolean }>(({ isSelected }) => ({
    width: "110px",
    marginBottom: "8px",
    height: "150px",
    marginRight: "18px",
    display: "flex",
    justifyContent: "center",
    boxShadow: "none",
    backgroundImage: 'url("/Assets/Images/resume1.svg")',
    "&:last-child": {
        marginBottom: 0,
    },
    border: isSelected
        ? `2px solid ${themeColors.purpleDark}`
        : `1px solid ${themeColors.emptyStar}`,
}));

const PdfView = styled(`embed`)({
    width: "100%",
    height: "866px",
    borderRadius: "10px",
});

const MainGridbox = styled(Box)({
    width: "100%",
    border: `1px solid ${themeColors.purpleLight}`,
    boxShadow: `0px 0px 4px 0px  ${themeColors.shadowColor}`,
    borderRadius: "8px",
});
const MainContent = styled(Box)({
    width: "100%",
});
const Headingcontainer = styled(Box)({
    display: "flex",
    justifyContent: "space-between",
    padding: "16px 20px",
    boxShadow: `0px 0px 4px 0px  ${themeColors.shadowColor}`,
});
const Headingtesx = styled(Typography)({
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
});

const ScrollContainer = styled(Box)`
      display: flex;
      overflow-x: auto;
      white-space: nowrap;
      padding: 10px;
      align-items: center;
      justify-content: center;
    
    & > * {
    flex-shrink: 0;
    }
    
    &::-webkit-scrollbar {
    height: 8px;
    }
    
    &::-webkit-scrollbar-thumb {
    background-color: ${themeColors.badgeGray};
    border-radius: 10px;
    }
    
    &::-webkit-scrollbar-thumb:hover {
    background-color: ${themeColors.darkGrey};
    }
    
    &::-webkit-scrollbar-track {
    background-color: ${themeColors.white};
    }
`;

const ToolsContainer = styled(Box)({
    display: "flex",
    flexWrap: "wrap",
    margin: "2px 0",
    alignItems: 'center',
    justifyContent: 'center',
});

const ToolBadge = styled(Paper)<{ background: string, color: string }>(({ background, color }) => ({
    background,
    color,
    textAlign: 'center',
    paddingTop: '2px',
    paddingLeft: '15px',
    paddingRight: '15px',
    paddingBottom: '2px',
    fontWeight: 'bold',
}));

const StyledStatus = styled('span')({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '3px'
})

export default VendorSubmissionCardView