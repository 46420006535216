import * as Yup from "yup";

export const marks = [
  {
    value: 0,
    label: "0",
  },
  {
    value: 5,
    label: "5",
  },
  {
    value: 10,
    label: "10",
  },
  {
    value: 15,
    label: "15",
  },
  {
    value: 20,
    label: "20+",
  },
];

export const validationSchema = Yup.object({
  jobTitle: Yup.string()
    .matches(
      /^[a-zA-Z0-9\s-]*$/,
      "Job Title can only contain letters, numbers, spaces, and hyphens"
    )
    .required("Job Title is required"),
  initialExperience: Yup.number()
    .min(0, "Experience cannot be less than 0")
    .required("Experience is required"),
  maxExperience: Yup.number()
    .min(
      Yup.ref("initialExperience"),
      "Max Experience must be greater than Initial Experience"
    )
    .required("Experience is required"),

  noOfPositions: Yup.number()
    .required("Required")
    .min(1, "Value must be at least 1"),
  Qualifications: Yup.array().min(1, "At least one qualification is required"),
  visaType: Yup.array().min(1, "VISA Type is required"),
  // Locations: Yup.array().min(1, "At least one location is required"),
  primarySkills: Yup.array().min(1, "At least one primary skill is required"),
  secondarySkills: Yup.array().min(
    1,
    "At least one secondary skill is required"
  ),
  rfqClosingDate: Yup.date()
    .min(new Date().toISOString().split("T")[0], "Date cannot be in the past")
    .required("Required"),

  jobType: Yup.string().required("Job Type is required"),
  jobCategory: Yup.string().required("Job Category is required"),
  employeeLevel: Yup.string().required("Employee Level is required"),
  chargeType: Yup.string().required("Charge Type is required"),
  billRateFrom: Yup.number()
    .min(1, "Bill rate must be greater than 0")
    .required("Bill rate is required"),
  billRateTo: Yup.number()
    .min(Yup.ref("billRateFrom"), "Bill rate 'to' must be greater than 'from'")
    .required("Bill rate is required"),
  
  commission: Yup.number()
    .nullable()
    .min(0, "Commission must be greater than 0")
    .notRequired(),

 fileName: Yup.mixed<File>()
  .nullable()
  .test("fileType", "Only PDF and DOCX files are allowed", (value) => {
    if (value && value instanceof File) {
      return (
        value.type === "application/pdf" || 
        value.type === "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
      );
    }
    return true;
  }),
  zipcode: Yup.string().required("zipcode is required"),
  state: Yup.string().required("state is required"),
  city: Yup.string().required("city is required"),

});


