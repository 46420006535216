import styled from "@emotion/styled";
import {
  Box,
  Button,
  Card,
  Checkbox,
  Modal,
  Paper,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import { SubmissionDetailCvList } from "api/resources/modals";
import CustomFileUpload from "components/fileUploadComponent/FileUpload";
import ReusableTable from "components/reusableFIlterTable/ReusableTable";
import Spinner from "components/spinner/Spinner";
import { themeColors } from "core/Theming/theme";
import { useVendorAddMultipleCv, useVendorEditCv } from "pages/hooks";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CloseIcon from "@mui/icons-material/Close";
import RemoveIcon from "@mui/icons-material/Remove";
import MultipleFileUpload from "components/multipleFileUploadComponent/MultipleFileUpload";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

import { CircularProgressBar } from "pages/Vendor/CommonComponents/CircleProgress";
import { SmartScoreCard, SmartScoreIcon } from "pages/Vendor/myCvs/SmartScoreTab/SmartScoreTab";

interface SubmissionDetailsProps {
  submissionCvData?: SubmissionDetailCvList["allCvs"];
  submissionDetails?: SubmissionDetailCvList["Details"];
  spinner: boolean;
  handleCallCvApi: (callApi: boolean) => void;
}

const SubmissionDetails: React.FC<SubmissionDetailsProps> = ({
  submissionCvData,
  spinner,
  handleCallCvApi,
  submissionDetails,
}) => {
  const [sortedCvs, setSortedCvs] = useState<SubmissionDetailCvList["allCvs"]>(
    []
  );
  const [cvDetails, setCvDetails] = useState<
    SubmissionDetailCvList["allCvs"][number] | null
  >(null);
  const [modal, showModal] = useState(false);
  const [editCvFile, setEditCvFile] = useState<string>("");
  const [cvModal, showCvModal] = useState(false);
  const [editCvId, setEditCvId] = useState<string>("");
  const [tableSpinner, showTableSpinner] = useState(false);
  const [downloadSpinner, showDownloadSpinner] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);
  const [showDetails, setShowDetails] = useState(false);
  const [uploadCvs, setUploadCvs] = useState<string[]>([]);
  const [uploadCvSubId, setUploadCvSubId] = useState<string | number>("");
  const [resetKey, setResetKey] = useState("");
  const [uploadCvModal, showUploadCvModal] = useState(false);
  const [declarationCheck, setDeclarationCheck] = useState(false);
  const { updateVendorSubmissionCvs, isLoading: cvUploadLoading } =
    useVendorAddMultipleCv({
      onSuccess: () => {
        toast.success("CV's added successfully!");
      },
      onError: () => {
        toast.error(error?.message);
      },
    });
  const { updateVendorCv, isLoading, error } = useVendorEditCv({
    onSuccess: () => {
      toast.success("CV Updated Successfully");
    },
    onError: () => {
      toast.error("Something went wrong please try again");
    },
  });
  const getSmartScore = (match_score: string) => {
    const percenttage = parseInt(match_score);

    const textColor =
      !isNaN(percenttage) && percenttage <= 50
        ? themeColors.DoubleRed
        : percenttage > 50 && percenttage <= 80
          ? themeColors.DoubleBrown
          : themeColors.shadowDarkGreen;
    return <p style={{ color: textColor }}>{match_score}% Match</p>;
  };

  const headers = [
    {
      title: "CV Id",
      filter: true,
      filterType: "number",
      cellWidth: "200px",
      name: "cv_id",
    },
    {
      title: "Status",
      filter: true,
      filterType: "select",
      options: ["Liked", "Disliked", "Pending", "On hold"],
      name: "status",
      max: new Date().toISOString().split("T")[0],
      customComponent: (row: SubmissionDetailCvList["allCvs"][number]) => (
        <ToolsContainer>
          <ToolBadge
            color={
              row.status === "Disliked"
                ? themeColors.error
                : row.status === "Liked"
                  ? themeColors.success
                  : row.status === "On hold"
                    ? themeColors?.purpleDark
                    : themeColors.warning
            }
            background={
              row.status === "Disliked"
                ? themeColors.badgeError
                : row.status === "Liked"
                  ? themeColors.badgeSuccess
                  : row.status === "On hold"
                    ? themeColors?.purpleLight
                    : themeColors.badgeWarning
            }
          >
            {row.status === "Liked" ? (
              <StyledStatus>
                <img src="/Assets/Images/like.svg" alt="like" />
                {row.status}
              </StyledStatus>
            ) : row.status === "Disliked" ? (
              <StyledStatus>
                <img src="/Assets/Images/disLike.svg" alt="disLike" />
                {row.status}
              </StyledStatus>
            ) : (
              <StyledStatus>
                <img src="/Assets/Images/cvPending.svg" alt="" />
                {row.status}
              </StyledStatus>
            )}
          </ToolBadge>
        </ToolsContainer>
      ),
    },
    {
      title: "Smart Score",
      filter: true,
      filterType: "text",
      name: "match_score",
      cellWidth: "200px",
      customComponent: (row: SubmissionDetailCvList["allCvs"][number]) => (
        <SmartScoreCard>
          <CircularProgressBar
            fillPercentage={parseInt(row.match_score, 0)}
            size="28px"
            smartScore={row.match_score}
          />
          {getSmartScore(row.match_score)}
          <SmartScoreIcon />
        </SmartScoreCard>
      ),
    },
    {
      title: "Contact Viewed",
      filter: true,
      filterType: "select",
      options: ["Not Viewed", "Viewed"],
      name: "contact_viewed",
      cellWidth: "200px",
      customComponent: (row: SubmissionDetailCvList["allCvs"][number]) => (
        <ToolsContainer>
          <ToolBadge
            color={
              row.contact_viewed === "Not Viewed"
                ? themeColors.error
                : row.contact_viewed === "Viewed"
                  ? themeColors.success
                  : themeColors.warning
            }
            background={
              row.contact_viewed === "Not Viewed"
                ? themeColors.badgeError
                : row.contact_viewed === "Viewed"
                  ? themeColors.badgeSuccess
                  : themeColors.badgeWarning
            }
          >
            {row.contact_viewed === "Viewed" ? (
              <StyledStatus>
                <img src="/Assets/Images/viewed.svg" alt="viewed" />
                {row.contact_viewed}
              </StyledStatus>
            ) : row.contact_viewed === "Not Viewed" ? (
              <StyledStatus>
                <img src="/Assets/Images/notViewedLock.svg" alt="notViewed" />
                {row.contact_viewed}
              </StyledStatus>
            ) : (
              <StyledStatus>
                <img src="/Assets/Images/cvPending.svg" alt="" />
                {row.contact_viewed}
              </StyledStatus>
            )}
          </ToolBadge>
        </ToolsContainer>
      ),
    },
    { title: "Job Seeker Name", filter: true, filterType: "text", name: "cv_name" },
    { title: "CV Upload Date", filter: true, filterType: "date", name: "created_at", max: new Date().toISOString().split("T")[0] },
    { title: "Phone Number", filter: true, filterType: "text", name: "phone_number" },
    {
      title: "Email", filter: true, filterType: "text", name: "email", cellWidth: "250px",
      customComponent: (row: SubmissionDetailCvList["allCvs"][number]) => (
        <Tooltip title={row.email}>
          <span>
            {row.email?.length > 25 ? `${row.email?.slice(0, 25)}...` : row.email || "--"}
          </span>
        </Tooltip>
      ),
    },
    {
      title: "Last Job title", filter: true, filterType: "text", name: "last_job_title", cellWidth: "250px",
      customComponent: (row: SubmissionDetailCvList["allCvs"][number]) =>
        row.last_job_title ?
          <Tooltip title={row.last_job_title}>
            <span>
              {row.last_job_title?.length > 20 ? `${row.last_job_title?.slice(0, 20)}...` : row.last_job_title}
            </span>
          </Tooltip> : <span>--</span>
    },
    {
      title: "Qualification", filter: true, filterType: "text", name: "higher_qualification", cellWidth: "250px",
      customComponent: (row: SubmissionDetailCvList["allCvs"][number]) => (
        <Tooltip title={row.higher_qualification}>
          <span>
            {row.higher_qualification?.length > 20 ? `${row.higher_qualification?.slice(0, 20)}...` : row.higher_qualification}
          </span>
        </Tooltip>
      ),
    },
  ];

  const actionItmes = [
    {
      type: "img", alt: "change_cv", actionTooltip: "change CV", name: "preview", src: "/Assets/Images/cv.svg",
      handleClick: (action: string, item: SubmissionDetailCvList["allCvs"][number]) => { showCvModal(true); setEditCvId(item.cv_id) },
    },
    {
      type: "img", alt: "preview", actionTooltip: "view Cv", name: "preview", src: "/Assets/Images/eye_open_vendor.svg",
      handleClick: (action: string, item: SubmissionDetailCvList["allCvs"][number]) => { showModal(true); setCvDetails(item) },
    },
  ];

  const handleUploadMoreCvs = async () => {
    try {
      if (uploadCvs?.length > 0 && uploadCvs?.length < 11) {
        const payload = { sub_id: uploadCvSubId || "", cv_File: uploadCvs || null, };
        await updateVendorSubmissionCvs(payload);
        handleCallCvApi(true);
      } else {
        toast.warning("Can not upload more than 10 CVs at a time");
      }
    } catch (error) {
      toast.error("something went wrong please try again");
    } finally {
      setUploadCvs([]);
      setUploadCvSubId("");
      showUploadCvModal(false);
      setDeclarationCheck(false);
    }
  };

  const getBase64 = (file: File): Promise<string> => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        const base64String = (reader.result as string).split("base64,")[1];
        resolve(base64String || "");
      };
      reader.onerror = (error) => reject(error);
    });
  };

  const handleEditCv = async () => {
    showTableSpinner(true);
    try {
      if (editCvFile?.length > 0 && declarationCheck) {
        await updateVendorCv({ cvId: editCvId, base64: editCvFile });
        toast.success("CV Updated Successfully");
      }
    } catch (err) {
      toast.error("Something went wrong please try again");
    } finally {
      handleCallCvApi(true);
      showCvModal(false);
      showModal(false);
      showTableSpinner(false);
      setEditCvFile("");
      setEditCvId("");
      setDeclarationCheck(false);
    }
  };

  useEffect(() => {
    if (submissionCvData && submissionCvData?.length > 0) {
      const sorted = [...submissionCvData].sort((a, b) => b.created_at - a.created_at) || [];
      setSortedCvs(sorted);
    } else {
      setSortedCvs([]);
    }
  }, [submissionCvData]);

  const handleDownloadCv = () => {
    showDownloadSpinner(true);
    const cvUrl = cvDetails?.cv;
    if (cvUrl) {
      fetch(cvUrl).then((response) => response.blob()).then((blob) => {
        const url = URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = `${cvDetails?.cv_name}.pdf`;
        document.body.appendChild(a);
        a.click();
        a.remove();
        URL.revokeObjectURL(url);
        showDownloadSpinner(false);
      }).catch(() => {
        toast.error("Failed to download CV");
        showDownloadSpinner(false);
      });
    }
  };

  const formatDate = (timestamp: number) => {
    try {
      const date = new Date(timestamp);
      const day = String(date.getDate()).padStart(2, "0");
      const month = String(date.getMonth() + 1).padStart(2, "0");
      const year = date.getFullYear();
      const timeString = date.toLocaleTimeString("en-GB", { hour: "2-digit", minute: "2-digit", });

      const dateString = `${day}-${month}-${year}`;
      return `${dateString}, ${timeString}`;
    } catch (err) {
      return "";
    }
  };

  return (
    <React.Fragment>
      {submissionDetails && (
        <Typography align="right">
          <Button sx={{ marginRight: "10px" }} size="small" variant="contained" onClick={() => { showUploadCvModal(!uploadCvModal); setUploadCvSubId(submissionDetails?.C2C_Vendor_Bid_Id); }}>
            + Add more Cvs
          </Button>
          <Button variant="outlined" size="small" onClick={() => { setShowDetails(!showDetails); setIsExpanded(!isExpanded) }}>
            View Submission Details
          </Button>
        </Typography>
      )}
      <ReusableTable
        headers={headers}
        data={sortedCvs}
        itemsPerPage={5}
        spinner={spinner || tableSpinner}
        actions={actionItmes}
        actionsCellWidth={"170px"}
        headerColor={themeColors.purpleLight}
        inputBorder={themeColors.purpleDark}
      />
      {showDetails && (
        <div style={{ width: "500px" }}>
          <CardContainer isExpanded={isExpanded}>
            <CardHeader>
              <span>Submission Details</span>
              <div>
                <ExpandButton
                  onClick={() => setIsExpanded((prevState) => !prevState)}
                >
                  {!isExpanded ? <CheckBoxOutlineBlankIcon /> : <RemoveIcon />}
                </ExpandButton>
                <ExpandButton onClick={() => setShowDetails(!showDetails)}>
                  <CloseIcon />
                </ExpandButton>
              </div>
            </CardHeader>
            <CardContent isExpanded={isExpanded}>
              <SubmissionDetailsCard>
                <Typography>
                  <b>Submission Date: </b>{formatDate(Number(submissionDetails?.created_at) || 0)}
                </Typography>
                <Typography>
                  <b>Rate Card:</b> {submissionDetails?.Bill_Rate ?? "N/A"}{" "}
                  {submissionDetails?.Charge_Type?.hourly
                    ? "/hour"
                    : submissionDetails?.Charge_Type?.weekly
                      ? "/week"
                      : "/month"}
                </Typography>
                <Typography>
                  <b>Employer Level:</b> {submissionDetails?.Employer_Level}
                </Typography>
                <Typography>
                  <b>No of Profiles:</b> {submissionDetails?.No_of_Profiles}
                </Typography>
                <Typography>
                  <b>Commission:</b> $ {submissionDetails?.commission}
                </Typography>
                <Typography>
                  <b>Locations:</b> {submissionDetails?.locations?.join(", ")}
                </Typography>
              </SubmissionDetailsCard>
            </CardContent>
          </CardContainer>
        </div>
      )}
      <Modal
        open={modal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <StyledModalBody>
          <Box sx={{ ...modalStyle }}>
            <PdfContainer>
              {cvDetails?.cv?.slice(-5) === ".docx" ? (
                <iframe
                  src={`https://docs.google.com/gview?url=${cvDetails?.cv}&embedded=true`}
                  title="Document Viewer"
                  width="100%"
                  height="500px"
                />
              ) : (
                <PdfView src={cvDetails?.cv} type="application/pdf" />
              )}
            </PdfContainer>
            <Typography align="right">
              <Button
                onClick={() => {
                  showModal(false);
                  setCvDetails(null);
                }}
                size="small"
                variant="outlined"
              >
                Close
              </Button>
            </Typography>
          </Box>
          <StyledModalIcons>
            <Stack alignItems={"center"} justifyContent={"center"}>
              <ToolBadge
                color={
                  cvDetails?.status === "Disliked"
                    ? themeColors.error
                    : cvDetails?.status === "Liked"
                      ? themeColors?.success
                      : themeColors.warning
                }
                background={
                  cvDetails?.status === "Disliked"
                    ? themeColors.badgeError
                    : cvDetails?.status === "Liked"
                      ? themeColors?.badgeSuccess
                      : themeColors.badgeWarning
                }
              >
                {cvDetails?.status === "Liked" ? (
                  <React.Fragment>
                    <img src="/Assets/Images/like.svg" alt="like" />
                    <br />
                    {cvDetails?.status}
                  </React.Fragment>
                ) : cvDetails?.status === "Disliked" ? (
                  <React.Fragment>
                    <img src="/Assets/Images/disLike.svg" alt="disLike" />
                    <br />
                    {cvDetails?.status}
                  </React.Fragment>
                ) : (
                  <React.Fragment>
                    <img src="/Assets/Images/cvPending.svg" alt="" />
                    <br />
                    {cvDetails?.status}
                  </React.Fragment>
                )}
              </ToolBadge>
              <Box sx={{ margin: "5px 0" }}>
                <img
                  src="/Assets/Images/cv.svg"
                  style={{ cursor: "pointer" }}
                  width={25}
                  onClick={() => {
                    setEditCvId(cvDetails?.cv_id || "");
                    showCvModal(true);
                  }}
                  alt="Edit Cv"
                />
              </Box>
              <Typography>
                <Tooltip arrow title={"Download"}>
                  <Spinner show={downloadSpinner}>
                    {cvDetails?.cv?.slice(-5) === ".docx" ? (
                      <a href={cvDetails?.cv} target="_blank">
                        <img
                          src="/Assets/Images/downloadPurple.svg"
                          alt="Download CV"
                          width={25}
                          style={{ cursor: "pointer", marginRight: "8px" }}
                        />
                      </a>
                    ) : (
                      <img
                        src="/Assets/Images/downloadPurple.svg"
                        alt="Download CV"
                        width={25}
                        onClick={handleDownloadCv}
                        style={{ cursor: "pointer", marginRight: "8px" }}
                      />
                    )}
                  </Spinner>
                </Tooltip>
              </Typography>
            </Stack>
          </StyledModalIcons>
        </StyledModalBody>
      </Modal>

      <Modal
        open={cvModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <StyledModalBody>
          <Box sx={{ ...modalStyle }}>
            <FileuploadBox topMargin="16px">
              <CustomFileUpload
                onFileChange={async (file: File | null) => {
                  if (file) {
                    setEditCvFile(await getBase64(file));
                  } else {
                    setEditCvFile("");
                  }
                  setDeclarationCheck(false);
                }}
              />
            </FileuploadBox>
            {editCvFile?.length === 0 && (
              <Typography color={themeColors.error}>
                File is required to edit the existing file
              </Typography>
            )}
            <Checkbox
              sx={{ margin: "0" }}
              checked={declarationCheck}
              onClick={() => setDeclarationCheck(!declarationCheck)}
            />
            I declare that I have full rights and ownership over the resumes
            submitted above
            {!declarationCheck && uploadCvs?.length > 0 && (
              <Typography color={themeColors.error}>
                Please check the box to confirm you have rights to the resume.
              </Typography>
            )}
            <Spinner show={isLoading}>
              <Typography sx={{ marginTop: "10px" }} align={"right"}>
                <Button
                  sx={{ marginRight: "10px" }}
                  variant="contained"
                  size="small"
                  disabled={editCvFile?.length === 0 || !declarationCheck}
                  onClick={handleEditCv}
                >
                  Edit Cv
                </Button>
                <Button
                  variant="outlined"
                  size="small"
                  onClick={() => {
                    showCvModal(false);
                    setDeclarationCheck(false);
                  }}
                >
                  cancel
                </Button>
              </Typography>
            </Spinner>
          </Box>
        </StyledModalBody>
      </Modal>

      <Modal
        open={uploadCvModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <StyledModalBody>
          <Box sx={{ ...modalStyle }}>
            <StyledUploadFile>
              Upload file here
              <Tooltip
                arrow
                title="you can upload Minimum 1 file Maximum 10 files in a single stretch"
              >
                <StyledInfoOutlinedIcon />
              </Tooltip>
              <Typography color={themeColors?.error}> *</Typography>
            </StyledUploadFile>
            <FileuploadBox>
              <MultipleFileUpload
                noUploadName="No File Uploaded"
                onFileChange={async (fileBase64Array: string[] | null) => {
                  if (fileBase64Array) {
                    setUploadCvs(fileBase64Array);
                  }
                  setDeclarationCheck(false);
                }}
                resetKey={resetKey}
              />
            </FileuploadBox>
            <Checkbox
              sx={{ margin: "0" }}
              checked={declarationCheck}
              onClick={() => setDeclarationCheck(!declarationCheck)}
            />
            I declare that I have full rights and ownership over the resumes
            submitted above
            {uploadCvs?.length === 0 ? (
              <Typography color={themeColors.error}>
                Atleast one File is required to upload more CVs
              </Typography>
            ) : (
              uploadCvs?.length > 10 && (
                <Typography color={themeColors.error}>
                  you can upload 10 files in a single stretch
                </Typography>
              )
            )}
            {!declarationCheck && uploadCvs?.length > 0 && (
              <Typography color={themeColors.error}>
                Please check the box to confirm you have rights to the resume.
              </Typography>
            )}
            <Spinner show={cvUploadLoading}>
              <Typography sx={{ marginTop: "10px" }} align={"right"}>
                <Button
                  sx={{ marginRight: "10px" }}
                  variant="contained"
                  size="small"
                  disabled={uploadCvs?.length === 0 || !declarationCheck}
                  onClick={handleUploadMoreCvs}
                >
                  Upload Cvs
                </Button>
                <Button
                  variant="outlined"
                  size="small"
                  onClick={() => {
                    showUploadCvModal(false);
                    setDeclarationCheck(false);
                  }}
                >
                  cancel
                </Button>
              </Typography>
            </Spinner>
          </Box>
        </StyledModalBody>
      </Modal>
    </React.Fragment>
  );
};

const StyledInfoOutlinedIcon = styled(InfoOutlinedIcon)({
  color: themeColors?.grayBlack,
  cursor: "pointer",
  height: "18px",
});

const StyledUploadFile = styled("div")({
  marginTop: "16px",
  display: "flex",
  alignItems: "center",
});

const ToolsContainer = styled(Box)({
  display: "flex",
  flexWrap: "wrap",
  margin: "2px 0",
  alignItems: "center",
  justifyContent: "center",
});

const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 700,
  bgcolor: "background.paper",
  boxShadow: 24,
  borderRadius: "15px",
  padding: "5px 20px 20px 20px",
};

const StyledModalIcons = styled(Box)({
  position: "relative",
  height: "23vh",
  width: "100px",
  padding: "8px",
  borderRadius: "8px",
  background:
    "linear-gradient(rgba(255, 255, 255, 0.95), rgba(255, 255, 255, 0.95))",
  top: "133px",
  right: "-410px",
});

const PdfContainer = styled(Box)({
  padding: "30px",
  width: "100%",
});

const StyledStatus = styled("span")({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  gap: "3px",
});

const PdfView = styled(`embed`)({
  width: "100%",
  height: "500px",
  borderRadius: "10px",
});

const StyledModalBody = styled(Box)(({ theme }) => ({
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  backgroundColor: theme.palette.background.paper,
  border: "2px solid #000",
}));

const ToolBadge = styled(Paper)<{ background: string; color: string }>(
  ({ background, color }) => ({
    background,
    color,
    textAlign: "center",
    paddingTop: "2px",
    paddingLeft: "20px",
    paddingRight: "20px",
    paddingBottom: "2px",
    fontWeight: "bold",
  })
);

const FileuploadBox = styled(Box)<{ topMargin?: string }>(({ topMargin }) => ({
  marginTop: topMargin || "0px",
  textAlign: "center",
  borderRadius: "10px",
  border: `1px dashed ${themeColors.emptyStar}`,
}));

const CardContainer = styled.div<{ isExpanded: boolean }>`
  position: fixed;
  bottom: 0;
  right: 0;
  background-color: #ffffff;
  border: 1px solid #ddd;
  box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.1);
  transition: height 0.3s ease;
  height: ${(props) => (props.isExpanded ? "300px" : "60px")};
  width: 300px; /* Adjust width here */
  max-width: 100vw; /* Ensure it doesn't overflow the viewport */
  overflow: hidden;
  border-radius: 8px 8px 0 0; /* Rounded corners for a more appealing look */
`;

const CardHeader = styled.div`
  padding: 15px;
  background-color: ${themeColors?.purpleDark};
  color: white;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const CardContent = styled.div<{ isExpanded: boolean }>`
  padding: 15px;
  display: ${(props) => (props.isExpanded ? "block" : "none")};
`;

const ExpandButton = styled.button`
  background: none;
  border: none;
  color: white;
  font-size: 18px;
  cursor: pointer;
`;

const SubmissionDetailsCard = styled(Box)({
  display: "flex",
  flexDirection: "column",
  gap: "10px",
});

export default SubmissionDetails;
