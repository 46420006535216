import styled from "@emotion/styled";
import { Box, Button, Typography } from "@mui/material"
import PageHeader from "components/pageHeaders/PageHeader";
import { themeColors } from "core/Theming/theme";
import { useNavigate } from "react-router-dom";

const VendorChats = () => {
    const navigate = useNavigate()

    return (
        <MainContainer>
            <PageHeader heading="Chats" backgroundColor={themeColors.purpleLight || ''} backButton={false} />
            <Container>
                <img height={350} src="/Assets/comingSoonImages/chats.png" alt="" />
                <Styletextcontainer>
                    <Styletext variant="h4">Coming Soon...</Styletext>
                </Styletextcontainer>
            </Container>
            <Footer>
                <StyledButton
                    variant="contained"
                    onClick={() => navigate("/vendor/dashboard")}
                >
                    Go back to Dashboard
                </StyledButton>
            </Footer>

        </MainContainer>
    )
}

const MainContainer = styled(Box)`
width: 100%;,
height:100%,
background-color: ${themeColors.white};
padding: 46px;
`;

const Footer = styled(Box)({
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginTop: "16px",
});

const Styletext = styled(Typography)({
    color: themeColors.blackGray,
});

const StyledButton = styled(Button)({
    borderRadius: "10px",
    width: "350px",
    height: "44px",
    fontSize: "12px",
});

const Styletextcontainer = styled(Box)({
    position: "absolute",
    width: "351px",
    height: "56px",
    backgroundColor: themeColors.white,
    color: themeColors.blackGray,
});

const Container = styled(Box)({
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
});


export default VendorChats