import React from "react";
import { styled } from "@mui/system";
import Typography from "@mui/material/Typography";
import { useJourney } from "pages/hooks";
import { ReactComponent as SubmissionRecieved } from "../../../../../assets/Widgets/Submission recieved.svg";
import { ReactComponent as AllRequisationIcon } from "../../../../../assets/Widgets/All Requisitions Icon.svg";
import { ReactComponent as Pending } from "../../../../../assets/Widgets/Submission ti be reviewd.svg";
import { ReactComponent as Requisation } from "../../../../../assets/Widgets/Requisation create so far.svg";
import { ReactComponent as Accepted } from "../../../../../assets/Widgets/SubmissionAccepted.svg";
import { ReactComponent as Rejected } from "../../../../../assets/Widgets/Submission rejected.svg";

import { themeColors } from "core/Theming/theme";
import { RootState } from "core/store";
import { useSelector } from "react-redux";
import { Box } from "@mui/material";


const YourJourneySoFarCard: React.FC = () =>{

  const userInfo = useSelector((state: RootState) => state.auth?.userInfo);
  const userID =  userInfo?.user_id;

  const { journey, isLoading } = useJourney(userID as string);
  if (isLoading) {
    return <div>Loading...</div>;
  }

  const journeyData = journey?.reduce((acc, item) => {
    return { ...acc, ...item };
  }, {});

 

return (
  <>
        <JourneyCard>
        <MainContainer>
          <TextContainer >
          <StyledSubmissionjourneyText>Currently Active</StyledSubmissionjourneyText>
          <StyledSubmissionjourneyText>Requisitions</StyledSubmissionjourneyText>
            <JourneyReviewText>{journeyData?.Total_rfq?.toString() || "0"}</JourneyReviewText>
          </TextContainer>
          <JourneyBox>
            <AllRequisationIcon/>
          </JourneyBox>

          </MainContainer>
        </JourneyCard>
        <Submissionreview>
          <MainContainer >
          <TextContainer >
          <StyledSubmissionrecievedText>Submissions</StyledSubmissionrecievedText>
          <StyledSubmissionrecievedText> Received</StyledSubmissionrecievedText>
            <ReviewText>{journeyData?.Total_Bids?.toString() || "0"}</ReviewText>
          </TextContainer>
          <ReviewBox>
            <SubmissionRecieved/>
          </ReviewBox>

          </MainContainer>
        </Submissionreview>
        <Submissionpending>
        <MainContainer >
          <TextContainer >
          <StyledSubmissionreviewText>Submissions To Be Reviewed</StyledSubmissionreviewText>
            <PendingText>{journeyData?.Bids_reviewed?.toString() || "0"}</PendingText>
          </TextContainer>
          <ReviewBox>
            <Pending/>
          </ReviewBox>

          </MainContainer>
        </Submissionpending>
        <RequisationCreatesoFar>
        <MainContainer>
          <TextContainer>
          <StyledRequisationreviewText>Requisitions</StyledRequisationreviewText>
          <StyledRequisationreviewText>Created So Far</StyledRequisationreviewText>
            <RequisationText>{journeyData?.totalRequisitions?.toString() || "0"}</RequisationText>
          </TextContainer>
          <ReviewBox>
            <Requisation/>
          </ReviewBox>

          </MainContainer>
        </RequisationCreatesoFar>
        <SubmissionAccept>
        <MainContainer>
          <TextContainer>
          <StyledActiveviewText>Submissions</StyledActiveviewText>
          <StyledActiveviewText> Accepted</StyledActiveviewText>
          <AcceptText>{journeyData?.Bids_accepted?.toString() || "0"}</AcceptText>
          </TextContainer>
          <ReviewBox>
            <Accepted/>
          </ReviewBox>

          </MainContainer>
        </SubmissionAccept>
        <SubmissionReject>
        <MainContainer>
          <TextContainer >
          <StyledRejectText>Submissions</StyledRejectText>
          <StyledRejectText> Rejected</StyledRejectText>
          <RejectText>{journeyData?.Bids_rejected?.toString() || "0"}</RejectText>
          </TextContainer>
          <ReviewBox>
            <Rejected/>
          </ReviewBox>

          </MainContainer>
        </SubmissionReject>
    </>
)};
const JourneyBox=styled(Box)({
  marginLeft:'16px'
});

const MainContainer=styled(Box)({
  display:'flex',
  justifyContent:'space-between'
});

const ReviewBox=styled(Box)({
  marginLeft:'16px'
})
const JourneyCard = styled('div')`
width: 230px;
margin-bottom: 32px;
margin-right: 24px;
border-radius: 8px;
padding: 20px;
background: ${themeColors.shadowlightBlue};
cursor: pointer;
transition: box-shadow 0.3s ease;
display: flex;
flex-direction: row;
align-items: center;
border: 1px solid  ${themeColors.shadowlightBorderBlue};
`;

const Submissionreview=styled('div')`
width: 230px;
margin-bottom: 32px;
margin-right: 24px;
border-radius: 8px;
padding: 20px;
background: ${themeColors.shadowlightBlue};
cursor: pointer;
transition: box-shadow 0.3s ease;
display: flex;
flex-direction: row;
align-items: center;
border: 1px solid  ${themeColors.shadowlightBorderBlue};
`;

const Submissionpending=styled('div')`
width: 230px;
margin-bottom: 32px;
margin-right: 24px;
border-radius: 8px;
padding: 20px;
background:  ${themeColors.shadowlightYellow};
cursor: pointer;
transition: box-shadow 0.3s ease;
display: flex;
flex-direction: row;
align-items: center;
border: 1px solid  ${themeColors.shadowlightBorderYellow};
`;
const SubmissionAccept=styled('div')`
width: 230px;
margin-bottom: 32px;
margin-right: 24px;
border-radius: 8px;
padding: 20px;
background:  ${themeColors.shadowlightGreen};
cursor: pointer;
transition: box-shadow 0.3s ease;
display: flex;
flex-direction: row;
align-items: center;
border:  1px solid ${themeColors.shadowlightBorderGreen};
`;

const RequisationCreatesoFar=styled('div')`
width: 230px;
margin-bottom: 32px;
margin-right: 24px;
border-radius: 8px;
padding: 20px;
background: ${themeColors.shadowlightPurple};
cursor: pointer;
transition: box-shadow 0.3s ease;
display: flex;
flex-direction: row;
align-items: center;
border:1px solid  ${themeColors.shadowlightBorderPurple};
`;

const AcceptText = styled(Typography)`
  color: ${themeColors.shadowDarkGreen};
  font-family: Roboto;
  font-size: 32px !important;
  font-weight: 600;
`;

const SubmissionReject=styled('div')`
width: 230px;
margin-bottom: 32px;
margin-right: 24px;
border-radius: 8px;
padding: 20px;
background: ${themeColors.shadowlightRed};
cursor: pointer;
transition: box-shadow 0.3s ease;
display: flex;
flex-direction: row;
align-items: center;
border: 1px solid  ${themeColors.shadowlightBorderRed};
`;
const RejectText = styled(Typography)`
  color: ${themeColors.shadowDarkRed};
  font-family: Roboto;
  font-size: 32px !important;
  font-weight: 600;
`;
const PendingText = styled(Typography)`
  color: ${themeColors.darkYellows};
  font-family: Roboto;
  font-size: 32px !important;
  font-weight: 600;
`;

const RequisationText = styled(Typography)`
  color:${themeColors.shadowDarkPurple};
  font-family: Roboto;
  font-size: 32px !important;
  font-weight: 600;
`;
const ReviewText = styled(Typography)`
  color: ${themeColors.ShadowDarkBlue};
  font-family: Roboto;
  font-size: 32px !important;
  font-weight: 600;
`;
const JourneyReviewText = styled(Typography)`
  color: ${themeColors.shadowDarkBlue};
  font-family: Roboto;
  font-size: 32px !important;
  font-weight: 600;
`;

const TextContainer = styled('div')`
  display: flex;
  flex-direction: column;
  width:130px;
`;

const StyledSubmissionrecievedText = styled(Typography)`
  color:  ${themeColors.ShadowDarkBlue};
  font-family: Roboto;
  font-size: 16px;
  font-weight:500;
`;
const StyledSubmissionjourneyText = styled(Typography)`
  color: ${themeColors.shadowDarkBlue};
  font-family: Roboto;
  font-size: 16px;
  font-weight:500;
`;
const StyledSubmissionreviewText = styled(Typography)`
  color: ${themeColors.shadowDarkYellow};
  font-family: Roboto;
  font-size: 16px;
  font-weight:500;
`;
const StyledRequisationreviewText = styled(Typography)`
  color:  ${themeColors.shadowDarkPurple};
  font-family: Roboto;
  font-size: 16px;
  font-weight:500;
`;
const StyledActiveviewText = styled(Typography)`
  color: ${themeColors.shadowDarkGreen};
  font-family: Roboto;
  font-size: 16px;
  font-weight:500;
`;
const StyledRejectText = styled(Typography)`
  color:  ${themeColors.shadowDarkRed};
  font-family: Roboto;
  font-size: 16px;
  font-weight:500;
`;

export default YourJourneySoFarCard;
