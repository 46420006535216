import React from 'react'
import { Box, Typography, Grid } from '@mui/material';
import Imag2 from '../../assets/images/LandingPage/Vas/UI CARD.svg';
import Imag1 from '../../assets/images/LandingPage/Vas/UI CARD 2.svg';
import Imag3 from '../../assets/images/LandingPage/Vas/UI CARD 3.svg';
import Imag6 from '../../assets/images/LandingPage/Vas/Assessment_as_service.svg';
import Imag5 from '../../assets/images/LandingPage/Vas/Background_Verification_Check_as_service.svg';
import Imag4 from '../../assets/images/LandingPage/Vas/Interview_as_service.svg';
import styled from '@emotion/styled';

const VAS: React.FC = () => {
    return (
        <div className='page2background'>
            <Box sx={{ flexGrow: 1 }}>
                <Typography
                    variant="h1"
                    component="h2"
                    className="mainp3"
                    sx={{ paddingTop: '60px' }}
                >
                    Value added Services
                </Typography>
                <TextvasStyle>
                    Gain Access to Multiple 3rd-Party Service Providers on a Single Platform, Enhancing the Accuracy and Efficiency of Your Requisitions
                </TextvasStyle>
                <p className='comngsoon'>Coming soon..</p>

                <Grid className='cardgridvas'>
                    {/* Card1  */}
                    <Box sx={{ maxWidth: 320, marginBottom: '25px' }}
                        component="img"
                        src={Imag4}
                        alt="Imag4"
                    />
                    {/* Card2  */}

                    <Box sx={{ maxWidth: 320, marginBottom: '25px' }}
                        component="img"
                        src={Imag6}
                        alt="Imag6"
                    />
                    {/* Card3  */}


                    <Box sx={{ maxWidth: 320, marginBottom: '25px' }}
                        component="img"
                        src={Imag5}
                        alt="Imag5"
                    />
                </Grid>
                <Typography
                    variant="h1"
                    component="h2"
                    className="testimonyvas"
                >
                    Testimonials
                </Typography>
                <Grid className='cardgridvas'>
                    {/* Card1  */}
                    <Box sx={{ maxWidth: 300, marginBottom: '25px', marginRight:'25px'}}
                        component="img"
                        src={Imag3}
                        alt="Imag3"
                    />
                     <Box sx={{ maxWidth: 300, marginBottom: '25px', marginRight:'25px' }}
                        component="img"
                        src={Imag2}
                        alt="Imag2"
                    />
                     <Box sx={{ maxWidth: 300, marginBottom: '25px' }}
                        component="img"
                        src={Imag1}
                        alt="Imag1"
                    />
                </Grid>
            </Box>
        </div>
    )
}
export default VAS;

const TextvasStyle = styled(Typography)`
 color: #FFF;
    text-align: center;
    word-wrap: break-word;
    text-shadow: 13px 4px 6.3px rgba(0, 0, 0, 0.40);
    font-family: "DM Sans", sans-serif;
    font-size: 20px;
    font-weight: 400;
    width: 650px;
    margin: auto !important;
    margin-top: -10px !important;
    padding-top: 9vh !important;
    text-transform: capitalize !important;
    line-height: normal;
    letter-spacing: -0.52px;`