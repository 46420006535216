import styled from "@emotion/styled";
import { Box, Tabs, Typography } from "@mui/material";
import { themeColors } from "core/Theming/theme";
import React from "react";

function RfrDetailsSub() {
  return <div></div>;
}

export default RfrDetailsSub;

export const InterestedBidsTableBox = styled(Box)({
  border: `1px solid ${themeColors.badgeGray}`,
  padding: "20px",
  display: "flex",
  flexDirection: "column",
});

export const TabButtonsContainer = styled(Box)({
  border: `1px solid ${themeColors.badgeGray}`,
  padding: "10px 12px",
  boxSizing: "border-box",
  textAlign: "right",
  marginLeft: "auto",
  boxShadow: `0px 0px 4px 0px ${themeColors.primaryColorLight}`,
  borderRadius: "18px",
  height: "64px",
  display: "inline-block",
});

export const FullDiv = styled(Box)({
  width: "auto",
  backgroundColor: themeColors.white,
  marginTop: "32px",
});
export const ScrollableTabs = styled(Tabs)({
  overflowX: "auto",
  whiteSpace: "nowrap",
  "& .MuiTabs-flexContainer": {
    flexWrap: "nowrap",
  },
});
export const ChartContainer = styled("div")`
  padding: 32px;
`;
export const StyledHr = styled("hr")`
  border: 1px solid ${themeColors.primaryDark};
`;
export const MainContainer = styled(Box)({
  width: "100%",
  height: "100%",
  overflow: "hidden",
  backgroundColor: themeColors.white,
  padding: "46px",
});
export const StyledBox = styled(Box)({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  width: "100%",
  borderRadius: "8px",
  background: themeColors.primaryLight,
  boxShadow: "0px 0px 4px 0px rgba(0, 0, 0, 0.08)",
  padding: "10px 20px",
});
export const StyledTypography = styled(Typography)({
  color: themeColors.primaryDark,
  fontSize: "24px",
  fontStyle: "normal",
  fontWeight: 600,
});
