import React, { useEffect, useState } from "react";
import {
  AppBar,
  Tabs,
  Tab,
  Box,
  Button,
  Typography,
  Stack,
  Chip,
  Modal,
  Tooltip,
  InputBase,
  Container,
} from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import { ReactComponent as jobsIcon } from "../../../assets/icons/RequisationIcon.svg";
import { styled } from "@mui/system";
import AddIcon from "@mui/icons-material/Add";
import { ReactComponent as Trash } from "../../../assets/icons/trash.svg";
import { ReactComponent as Redtrash } from "../../../assets/icons/redTrash.svg";
import { ReactComponent as Calender } from "../../../assets/icons/Calendar.svg";
import { ReactComponent as Edit } from "../../../assets/icons/Edit-Post-Comment.svg";
import { ReactComponent as Share } from "../../../assets/icons/Share.svg";
import { ReactComponent as EyeOpen } from "../../../assets/icons/Eye Open.svg";
import { ReactComponent as Clock } from "../../../assets/icons/clock.svg";
import { ReactComponent as Publish } from "../../../assets/icons/publish.svg";
import { ReactComponent as CloseIcon } from "../../../assets/icons/Closed.svg";
import { ReactComponent as Menuarrow } from "../../../assets/icons/Group 33.svg";
import { ReactComponent as Edits } from "../../../assets/icons/RfrIcon/Edit-Post-Comment.svg";
import { ReactComponent as Shares } from "../../../assets/icons/RfrIcon/Share.svg";
import { ReactComponent as EyeOpens } from "../../../assets/icons/RfrIcon/Eye Open.svg";
import { ReactComponent as Restore } from "../../../assets/icons/RfrIcon/history.svg";
import { ReactComponent as Publishs } from "../../../assets/icons/RfrIcon/publish.svg";
import { ReactComponent as Trashs } from "../../../assets/icons/RfrIcon/Icon.svg";
import { ReactComponent as Calenders } from "../../../assets/icons/RfrIcon/Calendar.svg";
import { ReactComponent as SmartfilterIcon } from "../../../assets/icons/TableviewShapeicon.svg";
import { ReactComponent as Smartmatch } from "../../../assets/requ-submission/SmartMatch.svg";

import SearchIcon from "@mui/icons-material/Search";
import {
  useChangeDateRequisition,
  useDeleteRequisition,
  usePublishRequisition,
  useRestoreRequisition,
  useRfrListAll,
} from "pages/hooks";
import { RFRdataListModal } from "api/resources/modals";
import { useSelector } from "react-redux";
import { RootState, useAppDispatch, useAppSelector } from "core/store";
import Spinner from "components/spinner/Spinner";
import { toast } from "react-toastify";
import { DeleteConfirmModal } from "./Modals/Deleteconfirmmodal";
import { ChangedateModal } from "./Modals/Changedatemodal";
import dayjs, { Dayjs } from "dayjs";
import { Multiselectdeleterows } from "./Modals/Multiselectrowsdelete";
import { ShareModal } from "./Modals/Sharemodal";
import { RestoreModal } from "./Modals/Restoremodal";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import { themeColors } from "core/Theming/theme";
import ReusableTable from "components/reusableFIlterTable/ReusableTable";
import { Multiselectrestorerows } from "./Modals/Multiselectrestore";
import { setRFR } from "core/store/auth";
import { PublishModal } from "./Modals/Publishmodal";
import { Multiselectpublish } from "./Modals/Multiselectpublish";

const Rfrlisting: React.FC = () => {
  const { isVendor } = useAppSelector((store) => store.auth);
  const navigate = useNavigate();
  const userInfo = useSelector((state: RootState) => state.auth?.userInfo);
  const userId = userInfo?.user_id;
  const dispatch = useAppDispatch();
  const IsRfr = useSelector((state: RootState) => state.auth?.isRfr);
  const {
    rfrListAll = [],
    isLoading,
    refetch: refetchAllList,
  } = useRfrListAll(userId as string);

  const { deleteRequisition } = useDeleteRequisition({
    onError: () => toast.error("Error deleting requisition:"),
    onSuccess: () => refetchAllList(),
  });

  const { publishRequisition } = usePublishRequisition({
    onError: () => toast.error("Error publish requisition:"),
    onSuccess: () => refetchAllList(),
  });

  const { postChangeDateRequisition } = useChangeDateRequisition({
    onError: () => toast.error("Error Date requisition:"),
    onSuccess: () => {
      toast.success("Requisition Date update successfully");
      refetchAllList();
    },
  });

  const { restoreRequisition } = useRestoreRequisition({
    onError: () => toast.error("Error  requisition:"),
    onSuccess: () => refetchAllList(),
  });

  const [selectedTab, setSelectedTab] = useState(0);

  const [selectionModels, setSelectionModels] = useState<RFRdataListModal[]>(
    []
  );

  const [open, setOpen] = useState(false);
  const [calenderopen, setCalendaropen] = useState(false);
  const [deleteRfqId, setDeleteRfqId] = useState<string | null>(null);
  const [successMessage, setSuccessMessage] = useState("");
  const [rfqClosingDate, setRfqClosingDate] = useState<Dayjs | null>(null);
  const [sharemodalopen, setShareModalOpen] = useState(false);
  const [restoremodalopen, setRestoreModalOpen] = useState(false);
  const [publish, setPublishopen] = useState(false);
  const [openrows, setOpenrows] = useState(false);
  const [deleteRowsRfqId, setDeleteRowsRfqId] = useState<string[]>([]);
  const [openRestore, setOpenRestore] = useState(false);
  const [openPublish, setOpenPublish] = useState(false);
  const [bulkRestoreRowsRfqId, setBulkRestoreRowsRfqId] = useState<string[]>(
    []
  );
  const [bulkPublishRowsRfqId, setBulkPublishRowsRfqId] = useState<string[]>(
    []
  );
  const [shareRfqId, setShareRfqId] = useState<string | null>(null);
  const [spinner, setSpinner] = useState(false);

  const headers = [
    {
      title: "id",
      name: "id",
      cellWidth: "230px",
      show: false,
    },
    {
      title: "Job Title",
      name: "jobTitle",
      cellWidth: "250px",
      filterType: "text",
      filter: true,
      cellAlign: 'left' as "center" | "left" | "right",

      customComponent: (row: RFRdataListModal) => {
        return (
          <Jobtitle>
            {row.status === "Draft" ? (
              <Link to={""}>{row.jobTitle}</Link>
            ) : (
              <Link to={`/employer/myrequisitions/${row.requisitionId}`}>
                {row.jobTitle}
              </Link>
            )}
          </Jobtitle>
        );
      },
    },
    ...(selectedTab === 0
      ? [
          {
            title: "Status",
            name: "status",
            cellWidth: "250px",
            filterType: "select",
            filter: true,
            options: ["Expired", "Draft", "Delete", "Active"],

            customComponent: (row: RFRdataListModal) => {
              return (
                <>
                  {row.status === "Expired" ? (
                    <StyledButtonExpired>Expired</StyledButtonExpired>
                  ) : row.status === "Delete" ? (
                    <StyledButtonRejected>Deleted</StyledButtonRejected>
                  ) : row.status === "Active" ? (
                    <StyledButtonAccept>Active</StyledButtonAccept>
                  ) : row.status === "Draft" ? (
                    <StyledButtonPending>Draft</StyledButtonPending>
                  ) : (
                    ""
                  )}
                </>
              );
            },
          },
        ]
      : []),
    {
      title: "Job Type",
      name: "jobType",
      cellWidth: "230px",
      filterType: "text",
      filter: true,
      cellAlign: 'left' as "center" | "left" | "right",

      customComponent: (row: RFRdataListModal) => {
        return (
          <Jobtype direction="row" spacing={1} mt={0} >
            <Typeofjobs>{row.jobType}</Typeofjobs>
          </Jobtype>
        );
      },
    },
    {
      title: "No. of Positions",
      name: "noOfPositions",
      cellWidth: "200px",
      filterType: "text",
      filter: true,

      customComponent: (row: RFRdataListModal) => {
        return (
          <Stacks direction="row" spacing={1} mt={0}>
            <p>{row.noOfPositions === 0 ? "-" : row.noOfPositions}</p>
          </Stacks>
        );
      },
    },
    {
      title: "Primary Skills",
      name: "primarySkills",
      cellWidth: "300px",
      filterType: "text",
      filter: true,
      cellAlign: 'left' as "center" | "left" | "right",

      customComponent: (row: RFRdataListModal) => {
        const maxSkillsToShow = 1;
        const { primarySkills = [] } = row;
        const visibleSkills = primarySkills.slice(0, maxSkillsToShow);
        const hiddenSkills = primarySkills.slice(maxSkillsToShow);
        return (
          <Jobtype direction="row" spacing={1} mt={1} ml={1}>
            {visibleSkills.map((skill, index) => (
              <Tooltip key={index} title={skill}>
                <Skil label={skill} />
              </Tooltip>
            ))}

            {hiddenSkills.length > 0 && (
              <Tooltip title={hiddenSkills.join(", ")}>
                <Skil label="..." />
              </Tooltip>
            )}
          </Jobtype>
        );
      },
    },
    {
      title: "Secondary Skills",
      name: "secondarySkills",
      cellWidth: "300px",
      filterType: "text",
      filter: true,
      cellAlign: 'left' as "center" | "left" | "right",

      customComponent: (row: RFRdataListModal) => {
        const maxSkillsToShow = 1;
        const { secondarySkills = [] } = row;
        const visibleSkills = secondarySkills.slice(0, maxSkillsToShow);
        const hiddenSkills = secondarySkills.slice(maxSkillsToShow);
        return (
          <Jobtype direction="row" spacing={1} mt={1} ml={1}>
            {visibleSkills.map((skill, index) => (
              <Tooltip key={index} title={skill}>
                <Secondaryskill label={skill} />
              </Tooltip>
            ))}

            {hiddenSkills.length > 0 && (
              <Tooltip title={hiddenSkills.join(", ")}>
                <Secondaryskill label="..." />
              </Tooltip>
            )}
          </Jobtype>
        );
      },
    },
    {
      title: "Requisition Expiry Date",
      name: "created_at",
      cellWidth: "250px",
      filterType: "date",
      filter: true,
      max: new Date().toISOString().split("T")[0],
      customComponent: (row: RFRdataListModal) => {
        const normalizeToMidnight = (date: Date) => {
          const newDate = new Date(date);
          newDate.setHours(0, 0, 0, 0);
          return newDate;
        };

        const currentDate = normalizeToMidnight(new Date());
        const cellValue = row.expiryDate;

        let displayText;
        let cellStyle = { color: themeColors.success };

        if (cellValue === null || cellValue === "") {
          displayText = "-";
        } else if (cellValue === "expired") {
          displayText = "Expired";
          cellStyle.color = themeColors.lightGreen;
        } else {
          const expiringDate = normalizeToMidnight(new Date(cellValue));
          const isExpired = expiringDate < currentDate;
          if (isExpired) {
            displayText = "Expired";
            cellStyle.color = themeColors.redColor;
          } else {
            displayText = cellValue;
          }
        }
        return (
          <Stacks direction="row" spacing={1} mt={0}>
            <p style={cellStyle}>{displayText}</p>
          </Stacks>
        );
      },
    },
    {
      title: "Rate Card",
      name: "billRate",
      cellWidth: "190px",
      filterType: "text",
      filter: true,
      customComponent: (row: RFRdataListModal) => {
        return (
          <Stacks direction="row" spacing={1} mt={0}>
            <p>{!row.billRate ? "-" : `${row.billRate}`}</p>
          </Stacks>
        );
      },
    },
    ...(selectedTab === 0 || selectedTab === 1 || selectedTab === 4
      ? [
          {
            title: "Interested Vendors",
            name: "interestedVendors",
            cellWidth: "200px",
            filterType: "text",
            filter: true,

            customComponent: (row: RFRdataListModal) => {
              return (
                <Stacks direction="row" spacing={1} mt={0}>
                  <p>{row.interestedVendors}</p>
                </Stacks>
              );
            },
          },
        ]
      : []),
    ...(selectedTab === 0 ||
    selectedTab === 1 ||
    selectedTab === 4 ||
    selectedTab === 2
      ? [
          {
            title: "No. of Submissions got",
            name: "noOfSubmissionsGot",
            cellWidth: "220px",
            filterType: "text",
            filter: true,

            customComponent: (row: RFRdataListModal) => {
              return (
                <Stacks direction="row" spacing={1} mt={0}>
                  <p>{row.noOfSubmissionsGot}</p>
                </Stacks>
              );
            },
          },
        ]
      : []),
    ...(selectedTab === 0 ||
    selectedTab === 1 ||
    selectedTab === 4 ||
    selectedTab === 2
      ? [
          {
            title: "Accepted Submissions",
            name: "acceptedSubmissions",
            cellWidth: "230px",
            filterType: "text",
            filter: true,

            customComponent: (row: RFRdataListModal) => {
              return (
                <Stacks direction="row" spacing={1} mt={0}>
                  <p>{row.acceptedSubmissions}</p>
                </Stacks>
              );
            },
          },
        ]
      : []),
    ...(selectedTab === 0 ||
    selectedTab === 1 ||
    selectedTab === 4 ||
    selectedTab === 2
      ? [
          {
            title: "Rejected Submissions",
            name: "rejectedSubmissions",
            cellWidth: "220px",
            filterType: "text",
            filter: true,

            customComponent: (row: RFRdataListModal) => {
              return (
                <Stacks direction="row" spacing={1} mt={0}>
                  <p>{row.rejectedSubmissions}</p>
                </Stacks>
              );
            },
          },
        ]
      : []),
    ...(selectedTab === 0 || selectedTab === 4 || selectedTab === 1
      ? [
          {
            title: "Pending Submissions to Review",
            name: "PendingBidstoreview",
            cellWidth: "290px",
            filter: true,
            type: "text",

            customComponent: (row: RFRdataListModal) => {
              return (
                <>
                  {row.status === "Active" ? (
                    <Pendingdiv>
                      <p>{row?.pendingSubmissions}</p>
                      <ReviewButon>Review Submission</ReviewButon>
                    </Pendingdiv>
                  ) : row.status === "Delete" ? (
                    <p>{row?.pendingSubmissions}</p>
                  ) : (
                    ""
                  )}
                </>
              );
            },
          },
        ]
      : []),
  ];
  const actionItmes = [
    {
      type: "button",
      name: "actions",
      handleClick: (action: string, item: RFRdataListModal) => {},
      customActionComponent: (row: RFRdataListModal) => {
        return (
          <>
            {selectedTab === 0 ? (
              <div>
                {row.status === "Active" ? (
                  <Iconcontainers>
                    <Eyeicon
                      onClick={() =>
                        navigate(
                          `/employer/myrequisitions/${row.requisitionId}`
                        )
                      }
                    />
                    <EditIcon
                      color="primary"
                      onClick={() =>
                        navigate(`/edit-requisition/${row.requisitionId}`)
                      }
                    />
                    <Activeclock />
                    <Activepublish />
                    <RedtrashIcon
                      onClick={() => handleClickOpen(row?.requisitionId)}
                    />

                    <CalendarIcon
                      color="action"
                      onClick={() =>
                        handleClickOpenclock(
                          row?.requisitionId,
                          row?.expiryDate || ""
                        )
                      }
                    />
                    <ShareIcon
                      color="primary"
                      onClick={() => handleShare(row?.requisitionId)}
                    />
                    {/* <SmartMatch><SmartIcon/>View Matches</SmartMatch> */}
                  </Iconcontainers>
                ) : row.status === "Draft" ? (
                  <>
                    <EyeOpens />
                    <EditIcon
                      color="primary"
                      onClick={() =>
                        navigate(`/edit-requisition/${row.requisitionId}`)
                      }
                    />
                    <Restore />
                    {row.mandatoryCheck === true ? (
                  <PublishIcon
                    onClick={() => handlePublishClick(row?.requisitionId)}
                  />
                ) : (
                  <UncheckPublish />
                )}

                    <RedtrashIcon
                      onClick={() => handleClickOpen(row?.requisitionId)}
                    />
                    <DraftCalender />
                    <Shares />
                    {/* <DisableSmartMatch><SmartIcon/>View Matches</DisableSmartMatch> */}
                  </>
                ) : row.status === "Expired" ? (
                  <Expire>
                    <ExpireviewIcon />
                    <ExpireEditIcon />
                    <ExpireRestore />
                    <Expirepublish />
                    <ExpireRedtrashIcon />
                    <ExpireCalenderIcon />
                    <ExpireShareIcon />
                    {/* <DisableSmartMatch><SmartIcon/>View Matches</DisableSmartMatch> */}
                  </Expire>
                ) : (
                  <DeleteContainer>
                    <EyeOpens />
                    <EditIcon
                      color="primary"
                      onClick={() =>
                        navigate(`/edit-requisition/${row.requisitionId}`)
                      }
                    />
                    <DeleteRestoredIcon
                      onClick={() => handleRestoreClick(row?.requisitionId)}
                    />
                    <Deletepublish />
                    <ExpireRedtrashIcon />
                    <ExpireCalenderIcon />
                    <ExpireShareIcon />
                    {/* <DisableSmartMatch><SmartIcon/>View Matches</DisableSmartMatch> */}
                  </DeleteContainer>
                )}
              </div>
            ) : selectedTab === 1 ? (
              <Iconcontainers>
                <Eyeicon
                  onClick={() =>
                    navigate(`/employer/myrequisitions/${row.requisitionId}`)
                  }
                />
                <EditIcon
                  color="primary"
                  onClick={() =>
                    navigate(`/edit-requisition/${row.requisitionId}`)
                  }
                />
                <RedtrashIcon
                  onClick={() => handleClickOpen(row?.requisitionId)}
                />
                {row?.status !== "Draft" ? (
                  <CalendarIcon
                    color="action"
                    onClick={() =>
                      handleClickOpenclock(
                        row?.requisitionId,
                        row?.expiryDate || ""
                      )
                    }
                  />
                ) : (
                  ""
                )}
                {row?.status !== "Draft" ? (
                  <ShareIcon
                    color="primary"
                    onClick={() => handleShare(row?.requisitionId)}
                  />
                ) : (
                  ""
                )}
                {/* <SmartMatch><SmartIcon/>View Matches</SmartMatch> */}
              </Iconcontainers>
            ) : selectedTab === 4 ? (
              <div>
                <RestoredIcon
                  onClick={() => handleRestoreClick(row?.requisitionId)}
                />
              </div>
            ) : selectedTab === 3 ? (
              <Restorecontainer>
                <EditIcon
                  color="primary"
                  onClick={() =>
                    navigate(`/edit-requisition/${row.requisitionId}`)
                  }
                />
                {row.mandatoryCheck === true ? (
                  <PublisCheck
                    onClick={() => handlePublishClick(row?.requisitionId)}
                  />
                ) : (
                  <Disable/>
                )}
                <RedtrashIcon
                  onClick={() => handleClickOpen(row?.requisitionId)}
                />
              </Restorecontainer>
            ) : null}
          </>
        );
      },
    },
  ];

  useEffect(() => {
    setSpinner(true);
    const handleRefetch = async () => {
      await refetchAllList();
      setSpinner(false);
    };
    handleRefetch();
  }, [refetchAllList, selectedTab]);

  const handleDeleteIconClick = () => {
    const Ids = selectionModels.map((id) => id.requisitionId);
    if (Ids.length > 0) {
      setDeleteRowsRfqId(Ids);
      setOpenrows(true);
    }
  };

  const handleBulkRestoreClick = () => {
    const Ids = selectionModels.map((id) => id.requisitionId);
    if (Ids.length > 0) {
      setBulkRestoreRowsRfqId(Ids);
      setOpenRestore(true);
    }
  };
  const handleClosePopup = () => {
    setOpenRestore(false);
    setBulkRestoreRowsRfqId([]);
  };

  const handleConfirmRestore = () => {
    if (bulkRestoreRowsRfqId.length > 0) {
      bulkRestoreRowsRfqId.forEach((requisitionId) =>
        restoreRequisition(requisitionId)
      );
      toast.success("Requisition  update successfully");
      handleClosePopup();
    }
  };

  const handleBulkPublishClick = () => {
    const Ids = selectionModels.map((id) => id.requisitionId);
    if (Ids.length > 0) {
      setBulkPublishRowsRfqId(Ids);
      setOpenPublish(true);
    }
  };
  const handleClosePublishPopup = () => {
    setOpenPublish(false);
    setBulkPublishRowsRfqId([]);
  };

  const handleConfirmPublish = () => {
    const unfilledMandatoryFields = bulkPublishRowsRfqId.filter(
      (requisitionId) => {
        const requisition = selectionModels.find((item) => item.requisitionId === requisitionId);
        return requisition?.mandatoryCheck !== true;
      }
    );
  
    if (unfilledMandatoryFields.length > 0) {
      toast.error("Some Requisitions have not filled mandatory fields.");
    } else {
      bulkPublishRowsRfqId.forEach((requisitionId) => publishRequisition(requisitionId));
      toast.success("Requisition updated successfully.");
      handleClosePublishPopup();
    }
  };

  const handleShare = (requisitionId: string) => {
    setShareRfqId(requisitionId);
    setShareModalOpen(true);
  };

  const handleShareclose = () => {
    setShareModalOpen(false);
  };
  const handleCloserows = () => {
    setOpenrows(false);
    setDeleteRowsRfqId([]);
  };

  const handleConfirmDeleterows = () => {
    if (deleteRowsRfqId.length > 0) {
      deleteRowsRfqId.forEach((requisitionId) =>
        deleteRequisition(requisitionId)
      );
      toast.success("Requisition deleted successfully");
      handleCloserows();
    }
  };

  const handleClickOpen = (rfqId: string) => {
    setDeleteRfqId(rfqId);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setDeleteRfqId(null);
  };

  const handleClickOpenclock = (rfqId: string, previousDate: string) => {
    setDeleteRfqId(rfqId);
    setRfqClosingDate(dayjs(previousDate));
    setCalendaropen(true);
  };

  const handleRestoreClick = (rfqId: string) => {
    setDeleteRfqId(rfqId);
    setRestoreModalOpen(true);
  };

  const handlePublishClick = (rfqId: string) => {
    setDeleteRfqId(rfqId);
    setPublishopen(true);
  };
  const handleDateClose = () => {
    setCalendaropen(false);
    setDeleteRfqId(null);
  };

  const handleConfirmDelete = () => {
    if (deleteRfqId) {
      deleteRequisition(deleteRfqId);
      toast.success("Requisition deleted successfully");
      setSuccessMessage("Requisitions Deleted Successfully!");
      handleClose();
    }
  };
  const handleDatechange = () => {
    if (deleteRfqId && rfqClosingDate) {
      dayjs.extend(utc);
      dayjs.extend(timezone);
      const rfqClosingData = dayjs(rfqClosingDate).local().format("YYYY-MM-DD");
      postChangeDateRequisition({
        rfqId: deleteRfqId,
        rfqClosingData: rfqClosingData,
      });
      setSuccessMessage("Expiry date changed  Successfully!");
      handleDateClose();
    }
  };

  const handleRestoreClose = () => {
    setRestoreModalOpen(false);
    setDeleteRfqId(null);
  };

  const handleRestore = () => {
    if (deleteRfqId) {
      restoreRequisition(deleteRfqId);
      toast.success("Requisition  update successfully");
      setSuccessMessage("Requisitions Restored Successfully!");
      handleRestoreClose();
    }
  };

  const handlePublishClose = () => {
    setPublishopen(false);
    setDeleteRfqId(null);
  };

  const handlePublish = () => {
    if (deleteRfqId) {
      publishRequisition(deleteRfqId);
      toast.success("Requisition  update successfully");
      setSuccessMessage("Requisitions publish Successfully!");
      handlePublishClose();
    }
  };

  const jobsList = selectedTab === 0 ? rfrListAll : rfrListAll;
  const jobsData: RFRdataListModal[] = Array.isArray(jobsList) ? jobsList : [];

  const filteredJobs = Array.isArray(jobsList)
    ? jobsList.filter((job: { status: string }) => {
        switch (selectedTab) {
          case 1:
            return job.status === "Active";
          case 2:
            return job.status === "Expired";
          case 3:
            return job.status === "Draft";
          case 4:
            return job.status === "Delete";
          default:
            return (
              job.status === "Active" ||
              job.status === "Expired" ||
              job.status === "Draft" ||
              job.status === "Delete"
            );
        }
      })
    : [];

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setSelectedTab(newValue);
  };

  const handleDoBulk = (selectedItems: RFRdataListModal[]) => {
    setSelectionModels(selectedItems);
  };

  const actionsCellWidth =
    selectedTab === 0
      ? "300px"
      : selectedTab === 1
      ? "300px"
      : selectedTab === 3
      ? "100px"
      : selectedTab === 4
      ? "80px"
      : "";

  return (
    <Spinner show={isLoading}>
      <MainContent>
        <Headingcontainer>
          <Headingtesx variant="h4">
            <HeadingIcon />
            My Requisitions
          </Headingtesx>
          <StyledButton
            variant="contained"
            onClick={() => {
              navigate(
                isVendor
                  ? "/vendor/create-requisition"
                  : "/employer/myrequisitions/create-requisition"
              );
              dispatch(setRFR(!IsRfr));
            }}
          >
            Create a Requisition
            <Plusicon />
          </StyledButton>
        </Headingcontainer>
        <Middlecontainer>
          <Box>
            <MainGridbox>
              <MainAppbar position="static">
                <Tabs
                  value={selectedTab}
                  onChange={handleChange}
                  aria-label="job tabs"
                >
                  <Tab label="All Requisitions" />
                  <Tab label="Active Requisitions" />
                  <Tab label="Expired Requisitions" />
                  <Tab label="Draft Requisitions" />
                  <Tab label="Deleted Requisitions" />
                </Tabs>
              </MainAppbar>
              <MainGridcontainer>
                <LeftsiteContainer>
                  <Leftsitecontent>
                    <LeftcontentText>
                      {selectionModels.length} of {filteredJobs.length} Selected
                    </LeftcontentText>
                    {selectedTab === 0 ? (
                      <>
                        <Delete onClick={handleDeleteIconClick} />
                        {/* <PublishIcon onClick={handleBulkPublishClick} /> */}
                      </>
                    ) : selectedTab === 1 ? (
                      <>
                        <Delete onClick={handleDeleteIconClick} />
                      </>
                    ) : selectedTab === 3 ? (
                      <>
                        <PublishIcon onClick={handleBulkPublishClick} />
                        <Delete onClick={handleDeleteIconClick} />
                      </>
                    ) : selectedTab === 4 ? (
                      <>
                        <ClockIcon onClick={handleBulkRestoreClick} />
                      </>
                    ) : (
                      <>
                        <Delete onClick={handleDeleteIconClick} />
                      </>
                    )}
                  </Leftsitecontent>
                  <RightSiteICon>
                    {/* <Search id="search">
                      <SearchIconWrapper>
                        <SearchLogo />
                      </SearchIconWrapper>
                      <StyledInputBase
                        placeholder="Search…"
                        inputProps={{ "aria-label": "search" }}
                      />
                    </Search>
                    <SmartfilterIcons /> */}
                  </RightSiteICon>
                </LeftsiteContainer>
                <Maingridtable>
                  <CustomTable>
                    <ReusableTable
                      headers={headers}
                      data={filteredJobs}
                      itemsPerPage={10}
                      spinner={isLoading || spinner}
                      handleDoBulk={handleDoBulk}
                      checkBox={true}
                      headerColor={themeColors.primaryLight}
                      inputBorder={themeColors.primaryDark}
                      actions={
                        selectedTab === 0 ||
                        selectedTab === 1 ||
                        selectedTab === 3 ||
                        selectedTab === 4
                          ? actionItmes
                          : undefined
                      }
                      actionsCellWidth={actionsCellWidth}
                    />
                  </CustomTable>
                </Maingridtable>
              </MainGridcontainer>
            </MainGridbox>
          </Box>
        </Middlecontainer>
        <Multiselectdeleterows
          open={openrows}
          handleClose={handleCloserows}
          handleConfirmDelete={handleConfirmDeleterows}
          jobTitle={
            jobsData.find((job) => job.requisitionId === deleteRfqId)
              ?.jobTitle || ""
          }
        />
        <Multiselectrestorerows
          open={openRestore}
          handleClose={handleClosePopup}
          handleConfirmDelete={handleConfirmRestore}
          jobTitle={
            jobsData.find((job) => job.requisitionId === deleteRfqId)
              ?.jobTitle || ""
          }
        />

        <Multiselectpublish
          open={openPublish}
          handleClose={handleClosePublishPopup}
          handleConfirmDelete={handleConfirmPublish}
          jobTitle={
            jobsData.find((job) => job.requisitionId === deleteRfqId)
              ?.jobTitle || ""
          }
        />
        <DeleteConfirmModal
          open={open}
          handleClose={handleClose}
          handleConfirmDelete={handleConfirmDelete}
          jobTitle={
            jobsData.find((job) => job.requisitionId === deleteRfqId)
              ?.jobTitle || ""
          }
        />
        <RestoreModal
          open={restoremodalopen}
          handleClose={handleRestoreClose}
          handleConfirmRestore={handleRestore}
          jobTitle={
            jobsData.find((job) => job.requisitionId === deleteRfqId)
              ?.jobTitle || ""
          }
        />
        <PublishModal
          open={publish}
          handleClose={handlePublishClose}
          handleConfirmRestore={handlePublish}
          jobTitle={
            jobsData.find((job) => job.requisitionId === deleteRfqId)
              ?.jobTitle || ""
          }
        />
        <ChangedateModal
          open={calenderopen}
          handleDateClose={handleDateClose}
          handleDatechange={handleDatechange}
          previousDate={
            jobsData.find((job) => job.requisitionId === deleteRfqId)
              ?.expiryDate || ""
          }
          rfqClosingDate={rfqClosingDate}
          setRfqClosingDate={setRfqClosingDate}
        />

        <ShareModal
          open={sharemodalopen}
          handleClose={handleShareclose}
          shareRfqId={shareRfqId}
        />
        {successMessage && (
          <Modal
            open={!!successMessage}
            onClose={() => setSuccessMessage("")}
            aria-labelledby="success-modal-title"
            aria-describedby="success-modal-description"
          >
            <Box sx={{ ...modalStyle }}>
            <Modalheader>
              <Close onClick={() => setSuccessMessage("")} />
            </Modalheader>
              <CenteredColumn>
                <Questionmark>
                  <Menu />
                </Questionmark>
                <CenteredTypography id="success-modal-description" variant="h3">
                  {successMessage}
                </CenteredTypography>
              </CenteredColumn>
            </Box>
          </Modal>
        )}
      </MainContent>
    </Spinner>
  );
};

const DeleteContainer = styled(Box)({
  marginTop: "8px",
});

const Modalheader = styled(Container)({
  display: "flex",
  justifyContent: "flex-end",
  backgroundColor: themeColors.primaryLight,
  Padding: "8px",
  borderTopLeftRadius: "14px",
  borderTopRightRadius: "14px",
  borderBottomRadius: "3px",
  padding: "8px",
});

const Iconcontainers = styled(Box)({
  marginTop: "10px",
});
export const Stacks = styled(Stack)({
  alignItems: "center",
  display: "flex",
  justifyContent: "center",
});

 const Jobtype = styled(Stack)({
  alignItems: "center",
  display: "flex",
  justifyContent: "flex-start",
  marginLeft:'15px'
});
const CustomTable = styled(Box)({
  "& .MuiTableBody-root ": {
    "& .MuiTableCell-root": {
      padding: "8px !important",
    },
  },
  marginTop: "10px",
  overflow: "hidden",
});
const DraftCalender = styled(Calenders)({
  marginRight: "16px",
});
const Pendingdiv = styled(Box)({
  display: "flex",
  flexDirection: "row",
});

const Delete = styled(Redtrash)({
  cursor: "pointer",
});

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  color: themeColors.black,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  marginRight: "0px",
  marginLeft: 0,
  width: "100%",
  background: themeColors.white,
  color: themeColors.black,
  marginTop: "10px",

  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(3),
    width: "200px",
    borderRadius: "30px",
    border: `1px solid ${themeColors.primaryLightDark}`,
  },
}));

const Restorecontainer = styled(`div`)({
  display: "inline-flex",
});

const PublisCheck = styled(Publish)({
  cursor: "pointer",
  margin: "14px 14px 10px 14px",
});

const SmartfilterIcons = styled(SmartfilterIcon)({
  cursor: "pointer",
  marginTop: "19px",
  marginLeft: "16px",
  marginRight: "16px",
});
const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1.5, 1, 1, 0),
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      width: "12ch",
      "&:focus": {
        width: "20ch",
      },
    },
  },
}));

const SearchLogo = styled(SearchIcon)({
  color: themeColors.primaryDark,
});
export const StyledButtonAccept = styled(Button)({
  color: themeColors.success,
  width: "102px",
  borderRadius: "30px",
  backgroundColor: themeColors.successLight,
  border: "none",
  "&:hover": {
    backgroundColor: themeColors.successLight,
    color: themeColors.success,
    border: "none",
  },
  fontSize: "12px",
  marginLeft: "16px",
});

export const StyledButtonExpired = styled(Button)({
  color: themeColors.white,
  width: "102px",
  borderRadius: "30px",
  backgroundColor: themeColors.mediumGrey,
  border: "none",
  "&:hover": {
    backgroundColor: themeColors.mediumGrey,
    color: themeColors.white,
    border: "none",
  },
  fontSize: "12px",
  marginLeft: "16px",
});

export const StyledButtonPending = styled(Button)({
  color: themeColors.darkYellow,
  width: "82px",
  borderRadius: "30px",
  backgroundColor: themeColors.lightYellow,
  border: "none",
  "&:hover": {
    backgroundColor: themeColors.lightYellow,
    color: themeColors.darkYellow,
    border: "none",
  },
  fontSize: "12px",
  marginLeft: "16px",
});

export const StyledButtonRejected = styled(Button)({
  color: themeColors.darkRed,
  width: "102px",
  borderRadius: "30px",
  backgroundColor: themeColors.lightRed,
  border: "none",
  "&:hover": {
    backgroundColor: themeColors.lightRed,
    color: themeColors.darkRed,
    border: "none",
  },
  fontSize: "12px",
  marginLeft: "16px",
});

const CenteredTypography = styled(Typography)({
  marginTop: "20px",
  alignItems: "center",
  textAlign: "center",
  color: themeColors.black,
});
// const Container = styled(`div`)({
//   display: "flex",
//   justifyContent: "flex-end",
  
// });

const Questionmark = styled(`div`)({
  display: "flex",
  justifyContent: "center",
  marginTop:'14px',
});

const Expirepublish = styled(Publishs)({
  marginRight: "16px",
});

const UncheckPublish = styled(Publishs)({
  marginLeft: "16px",
  marginRight:'16px',
  marginTop:'13px',
});

const Disable = styled(Publishs)({
  marginLeft: "14px",
  marginRight:'16px',
  marginTop:'15px'
});
const ExpireviewIcon = styled(EyeOpens)({
  marginRight: "9px",
});
const ExpireRedtrashIcon = styled(Trashs)({
  marginRight: "9px",
  cursor: "pointer",
});
const ExpireEditIcon = styled(Edits)({
  marginRight: "9px",
});
const ExpireCalenderIcon = styled(Calenders)({
  marginRight: "9px",
});
const ExpireRestore = styled(Restore)({
  marginRight: "9px",
});

const ExpireShareIcon = styled(Shares)({
  marginRight: "16px",
});
const Activepublish = styled(Publishs)({
  marginRight: "16px",
  marginTop: "11px",
});

const Deletepublish = styled(Publishs)({
  marginRight: "16px",
  marginLeft: "16px",
});

const Expire = styled(`div`)({
  paddingTop: "12px",
});

const Menu = styled(Menuarrow)({
  height: "80px",
  width: "80px",
  cursor: "pointer",
});

const CenteredColumn = styled(`div`)({
  display: "flex",
  justifyContent: "center",
  flexDirection: "column",
});

const Close = styled(CloseIcon)({
  height: "30px",
  width: "30px",
  cursor: "pointer",
});

const Activeclock = styled(Restore)({
  marginRight: "10px",
  marginTop: "11px",
});

const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  bgcolor: "background.paper",
  boxShadow: 24,
  borderRadius: "14px",
  height: "304px",
};

const Jobtitle = styled(Box)({
  display: "flex",
  justifyContent: "left",
  alignItems: "center",
  textAlign: "center",
  marginLeft:'15px'
});
const Typeofjobs = styled(`p`)({
  textTransform: "capitalize",
});
const PublishIcon = styled(Publish)({
  marginRight: "10px",
  marginLeft: "10px",
  marginTop: "0px",
  cursor: "pointer",
});
const ClockIcon = styled(Clock)({
  alignItems: "center",
  textAlign: "center",
  cursor: "pointer",
  marginLeft: "16px",
});

const RestoredIcon = styled(Clock)({
  marginTop: "5px",
  alignItems: "center",
  textAlign: "center",
  cursor: "pointer",
});

const DeleteRestoredIcon = styled(Clock)({
  alignItems: "center",
  textAlign: "center",
  cursor: "pointer",
  marginBottom:'3px',
});
const ReviewButon = styled(Button)({
  color: themeColors.white,
  background: themeColors.primaryDark,
  marginLeft: "16px",
  borderRadius: "8px",
  width: "200px",
  height: "39px",
  "&:hover": {
    backgroundColor: themeColors.primaryDark,
    color: themeColors.white,
    border: "none",
  },
});
const SmartIcon=styled(Smartmatch)({
  marginRight:'14px'
})
const SmartMatch = styled(Button)({
  color: themeColors.white,
  background: themeColors.primaryDark,
  marginLeft: "16px",
  borderRadius: "8px",
  width: "167px",
  height: "39px",
  marginBottom:'14px',
  "&:hover": {
    backgroundColor: themeColors.primaryDark,
    color: themeColors.white,
    border: "none",
  },
});

const DisableSmartMatch = styled(Button)({
  color: themeColors.white,
  background: themeColors.mediumGrey,
  marginLeft: "16px",
  borderRadius: "8px",
  width: "167px",
  height: "39px",
  marginBottom:'14px',
  "&:hover": {
    backgroundColor: themeColors.mediumGrey,
    color: themeColors.white,
    border: "none",
  },
});
const EditIcon = styled(Edit)({
  marginRight: "10px",
  cursor: "pointer",
  marginTop: "12px",
  marginLeft: "10px",
});
const Eyeicon = styled(EyeOpen)({
  cursor: "pointer",
  marginTop: "11px",
});
const CalendarIcon = styled(Calender)({
  marginRight: "10px",
  cursor: "pointer",
  marginTop: "12px",
});

const Shareclick = styled(Share)({
  cursor: "pointer",
});
const RedtrashIcon = styled(Redtrash)({
  marginRight: "10px",
  cursor: "pointer",
  marginTop: "12px",
});

const ShareIcon = styled(Share)({
  cursor: "pointer",
  marginTop: "12px",
});

const TrashIcon = styled(Trash)({
  marginRight: "15px",
  cursor: "pointer",
});

const LeftcontentText = styled(`p`)({
  marginLeft: "15px",
  color: themeColors.blueShade,
  marginRight: "15px",
});

const LeftsiteContainer = styled(Box)({
  margin: "0px 7px",
  display: "flex",
  alignItems: "center",
});

const Leftsitecontent = styled(Box)({
  display: "flex",
  justifyContent: "flex-start",
  alignItems: "center",
  width: "100%",
  height: "42px",
});

const RightSiteICon = styled(Box)({
  display: "flex",
  flexDirection: "row",
});

const MainGridcontainer = styled(Box)({
  border: `1px solid ${themeColors.primaryLightDark} !important`,
  borderRadius: "8px",
  borderBottomRightRadius: "1px",
  borderBottomLeftRadius: "1px",
  boxShadow: `0px 0px 4px 0px  ${themeColors.shadowColor}`,
});

const Maingridtable = styled(Box)({
  display: "grid",
  gridAutoFlow: "column",
  gridAutoColumns: "100%",
});

export const Skil = styled(Chip)({
  background: themeColors.primaryLight,
  color: themeColors.primaryDark,
  fontWeight: "500",
});

export const Secondaryskill = styled(Chip)({
  background: themeColors.purpleLight,
  color: themeColors.purpleDark,
  fontWeight: "500",
});

const MainGridbox = styled(Box)({
  width: "100%",
  marginTop: "16px",
});

const MainAppbar = styled(AppBar)({
  background: "none",
  width: "870px",
  boxShadow: "none",
  fontWeight: "400",
  "& .MuiTabs-flexContainer ": {
    display: "flex",
    justifyContent: "flex-start",
  },
  marginLeft: "65px",
});

const Middlecontainer = styled(Box)({
  display: "flex",
  justifyContent: "center",
});

const MainContent = styled(Box)({
  width: "100%",
  padding: "20px",
});

const Headingcontainer = styled(Box)({
  display: "flex",
  justifyContent: "space-between",
  padding: "10px 20px",
  border: `1px solid ${themeColors.emptyStar}`,
  borderRadius: "8px",
  marginBottom: "16px",
  background: themeColors.primaryLight,
});

const Headingtesx = styled(Typography)({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
});

const HeadingIcon = styled(jobsIcon)({
  marginRight: "10px",
});

const Plusicon = styled(AddIcon)({
  marginLeft: "5px",
});

const StyledButton = styled(Button)({
  borderRadius: "5px",
  width: "210px",
  height: "44px",
  fontSize: "12px",
});

export default Rfrlisting;
