import React, { useState, useCallback, useRef, useEffect } from 'react';
import { Box, Grid, Typography, Button, Collapse } from '@mui/material';
import { styled } from '@mui/system';
import image1 from '../../../assets/images/LandingPage/ExPage/Frame 48096518.svg'
import image2 from '../../../assets/images/LandingPage/ExPage/Frame 48096519.svg'
import image3 from '../../../assets/images/LandingPage/ExPage/Frame 48096520.svg'
import image4 from '../../../assets/images/LandingPage/ExPage/Frame 48096521.svg'
import image5 from '../../../assets/images/LandingPage/ExPage/Frame 48096522.svg'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const titles = [
  "Resume Parser",
  "Resume Redactor",
  "Talk-to-Resume",
  "Role Mapper",
  "Ranking Algorithm"
];

const descriptions = [
  "Effortlessly parse job descriptions and CVs for quick, clear insights",
  "Redact CVs for privacy and transparency, with full details unlocked upon employer approval",
  "Upload and query CVs in bulk with ease",
  "We provide a revised JD for your review, making sure everything is spot-on",
  "Match Vendors based on your requirements and priorities."
];
const images = [image1, image2, image3, image4, image5]
const primarydes = [
  "Our platform automatically breaks down job descriptions as you create them and organizes CVs as soon as they’re uploaded...",
  "Once you submit the fields, Redactor will upload CVs and show with sensitive details hidden...",
  "With the Talk to CV add-on, you can upload CVs in bulk and query them effortlessly...",
  "With our Job Description Role Mapping, employers can upload JDs and have relevant and irrelevant lines automatically identified...",
  "When you create a requisition, our smart algorithm helps you find the best Vendor by evaluating six key factors..."
];

const Expage1: React.FC = () => {
  const [activeIndex, setActiveIndex] = useState<number | null>(0);
  const [progress, setProgress] = useState(0);
  const progressInterval = useRef<number | undefined>(undefined);
  const autoChangeDelay = 5000;

  const startProgress = useCallback(() => {
    clearInterval(progressInterval.current!);
    setProgress(0);
    progressInterval.current = window.setInterval(() => {
      setProgress((prev) => {
        if (prev >= 100) {
          clearInterval(progressInterval.current!);
          handleNext();
          return 0;
        }
        return prev + 1;
      });
    }, autoChangeDelay / 100);
  }, []);

  useEffect(() => {
    startProgress();
    return () => clearInterval(progressInterval.current!);
  }, [activeIndex, startProgress]);

  const handleNext = () => {
    setActiveIndex((prev) => (prev !== null ? (prev + 1) % titles.length : 0));
  };




  const handleDropdownClick = (index: number) => {
    setActiveIndex(activeIndex === index ? null : index);
  };


  return (
    <>
      <BoxStyledPage>
        <Box sx={{ flexGrow: 1, padding: 2 }}>
          <TypographyStyled variant="h4" gutterBottom>
            Experience Enhanced Features with our Platform
          </TypographyStyled>
          <Grid container spacing={2} >
            <Grid item xs={12} sm={4}>
              <div style={{ marginLeft: '80px' }}>

                {titles.map((title, index) => (
                  <Box key={index} sx={{ mb: 2, padding: 0 }}>
                    <ButtonWrapper active={activeIndex === index}>
                      <StyledButton
                        active={activeIndex === index}
                        progress={progress}

                        onClick={() => handleDropdownClick(index)} sx={{ borderRadius: '1px' }}
                      >
                        <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                          {title}
                          <ExpandMoreIconstyle />
                        </Box>
                      </StyledButton>
                    </ButtonWrapper>
                    <Collapse in={activeIndex === index}>
                      <DescriptionBoxAc sx={{ padding: 2 }}>
                        {descriptions[index]}
                      </DescriptionBoxAc>
                    </Collapse>
                  </Box>
                ))}
              </div>

            </Grid>
            <Grid item xs={12} sm={8}>
              {activeIndex !== null && (
                <Box style={{ padding: '15px', width: '70%', height: '70%', marginLeft: '110px', marginTop: '-20px', marginBottom: '70px' }}>
                  <img
                    src={images[activeIndex]}
                    alt={titles[activeIndex]}
                    style={{ width: '100%', height: '100%', objectFit: 'cover' }}
                  />
                  <Box component={DescriptionBox} >
                    <Typography variant="body1">
                      {descriptions[activeIndex]}
                    </Typography>
                    <Typography variant="body2" sx={{ mt: 1 }}>
                      {primarydes[activeIndex]}
                    </Typography>
                  </Box>
                </Box>
              )}
            </Grid>
          </Grid>
        </Box>
      </BoxStyledPage>
    </>
  )
}
export default Expage1;
const BoxStyledPage = styled(Box)`
  background-color: #160B30;
    color: #FFFFFF;
    width: 100%;
    height: 100%;
`
const StyledButton = styled(Button) <{ active: boolean; progress: number }>`
  position: relative;
  background: ${({ active }) =>
    active
      ? 'linear-gradient(169deg, #4D6CD9 0%, rgba(103, 43, 159, 0.70) 97.46%)'
      : 'linear-gradient(113deg, rgba(255, 255, 255, 0.47) 0%, rgba(255, 255, 255, 0.00) 110.84%)'};
  color: #fff;
  width: 100%;
  text-align: left;
  border: none;
  box-shadow: ${({ active }) =>
    active
      ? '35.654px 35.654px 89.135px 0px rgba(24, 48, 63, 0.20)'
      : '0px 3px 49px 9px rgba(0, 0, 0, 0.06)'};
  &::before {
    content: ${({ active }) => (active ? "''" : 'none')};
    position: absolute;
    top: 0;
    left: 0;
    width: ${({ progress }) => progress}%;
    height: 7px; 
    background-color: ${({ active }) => (active ? '#45F28D' : 'transparent')};
    transition: background-color 0.3s ease;
  }
`;

const DescriptionBoxAc = styled(Box)`
  text-align: left;
  border-radius: 0px 0px 14.262px 14.262px;
  background: linear-gradient(169deg, #4D6CD9 0%, rgba(103, 43, 159, 0.70) 97.46%);
  box-shadow: 35.654px 35.654px 89.135px 0px rgba(24, 48, 63, 0.20);
  padding: 16px;
  color: #fff;
  word-wrap: break-word;
  white-space: normal;
`;
const TypographyStyled = styled(Typography)`
  width: 750px;
  color: #0ff06d;
  text-align: left;
  font-size: 40px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
  letter-spacing: -1.2px;
  margin-left: 100px;
  margin-bottom:50px;
  padding-top:52px;
`;
const ButtonWrapper = styled(Box) <{ active: boolean }>`
  border-radius: 10px;
  background: ${({ active }) => (active ? 'rgba(103, 43, 159, 0.70)' : 'rgba(66, 66, 66, 0.50)')};
  box-shadow: ${({ active }) =>
    active
      ? '35.654px 35.654px 89.135px 0px rgba(24, 48, 63, 0.20)'
      : '0px 3px 49px 9px rgba(0, 0, 0, 0.06)'};
`;
const DescriptionBox = styled(Box)`
    width: 580px;
  padding: 16px;  
  background: linear-gradient(113deg, rgba(255, 255, 255, 0.47) 0%, rgba(255, 255, 255, 0.00) 110.84%);  
  backdrop-filter: blur(5px);           
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);  
  color: #fff;  
  word-wrap: break-word;
  white-space: normal;
  margin-left:75px;
  &::before {
    content: '';
    position: absolute;
    left: -10px;
    top: 0;
    width: 6px;
    height: 100%;
    background-color: #45F28D; 
`;
const ExpandMoreIconstyle = styled(ExpandMoreIcon)({
  color: 'white'
})