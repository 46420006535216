import styled from "@emotion/styled";
import { Box, Button, Card, CardActions, CardContent, Container, Grid, IconButton, Modal, Paper, Tooltip, Typography } from "@mui/material";
import React, { useState } from "react"
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import MonetizationOnOutlinedIcon from '@mui/icons-material/MonetizationOnOutlined';
import MobiledataOffRoundedIcon from '@mui/icons-material/MobiledataOffRounded';
import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import MenuIcon from "@mui/icons-material/Menu";
import { themeColors } from "core/Theming/theme";
import { useMoveRequesitionSingle, useVendorDashboardRequesitions } from "pages/hooks";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import Spinner from "components/spinner/Spinner";
import { Close } from "@mui/icons-material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";

interface OpenRequisitionProps {
  vendorId: string
}

const VendorOpenRequisition: React.FC<OpenRequisitionProps> = ({ vendorId }) => {
  const navigate = useNavigate()
  const [modal, showModal] = useState(false)
  const { DashboardOpenRequisitions = [], error, refetch, isLoading } = useVendorDashboardRequesitions(vendorId || '');
  const [spinner, showSpinner] = useState(false);
  const [moveType, setMoveType] = useState('')
  const [rfqId, setRfqId] = useState('')
  const moveRequisition = useMoveRequesitionSingle({
    onError: () => { },
    onSuccess: () => { refetch(); toast.success(`Requesition moved to ${moveType} successfully`) },
  });

  const handelMove = async () => {
    showSpinner(true);
    try {
      if (moveType === 'interested') {
        await moveRequisition.mutateAsync({
          userID: vendorId || '',
          rfq_id: rfqId || '',
          status: 'interested',
        });
      } else {
        await moveRequisition.mutateAsync({
          userID: vendorId || '',
          rfq_id: rfqId || '',
          status: 'not-interested',
        });
        refetch()
      }
    } catch (err) {
      toast.error('Something went wrong');
    } finally {
      showSpinner(false);
      setRfqId('');
      showModal(false)
    }
  };

  return (
    <MainBox>
      <StyledVendorBox>
        <Typography variant="h3">Open Requisitions</Typography>
        <Typography sx={{ cursor: 'pointer' }} color={themeColors?.purpleDark} onClick={() => navigate('/vendor/rmp')}><b><u>View more</u></b></Typography>
      </StyledVendorBox>
      <Cardbox>
        {isLoading || spinner ? (
          <React.Fragment>Loading...</React.Fragment>
        ) : (
          <React.Fragment>
            <ScrollContainer>
              <Grid container spacing={1} wrap="nowrap">
                {Array.isArray(DashboardOpenRequisitions) && DashboardOpenRequisitions.length > 0 ? (
                  DashboardOpenRequisitions.map((item, index) => (
                    <Grid item xs={12} sm={6} md={9} key={index}>
                      <StyledCard>
                        <CardContent>
                          <CardHeader>
                            <StyledTypography variant='h4'>{item?.rfq_details?.job_title}</StyledTypography>
                          </CardHeader>
                          <StyledDetails>
                            <FlexBox>
                              <img src="/Assets/Images/job.png" alt="job" />
                              {item?.rfq_details?.experience[0]?.split(',')[0] + ' to ' + item?.rfq_details?.experience[0]?.split(',')[1] || ''}
                            </FlexBox>
                            <FlexBox sx={{ padding: '5px 0' }}>
                              <img src="/Assets/Images/location.png" alt="location" />
                              {item?.rfq_details?.locations?.join(', ')?.length > 20 ? (
                                <Tooltip title={item?.rfq_details?.locations?.join(', ')}>
                                  <span>{item?.rfq_details?.locations?.join(', ').slice(0, 20)}...</span>
                                </Tooltip>
                              ) : (
                                item?.rfq_details?.locations?.join(', ')
                              )}
                            </FlexBox>
                            <FlexBox>
                              <img src="/Assets/Images/userLevel.png" alt="userLevel" />
                              {item?.rfq_details?.employee_level.length > 20 ? (
                                <Tooltip title={item?.rfq_details?.employee_level}>
                                  <span>{item?.rfq_details?.employee_level.slice(0, 20)}...</span>
                                </Tooltip>
                              ) : (
                                item?.rfq_details?.employee_level
                              )}
                            </FlexBox>
                          </StyledDetails>
                          <CardItems>
                            <ToolsContainer>
                              {Array.isArray(item?.rfq_details?.primary_skills) && (
                                <React.Fragment>
                                  {item?.rfq_details?.primary_skills?.slice(0, 3).map((tool, index) => (<ToolBadge key={index}>{tool}</ToolBadge>))}
                                  {item?.rfq_details?.primary_skills?.length > 3 && (
                                    <Tooltip arrow PopperProps={{ style: { zIndex: 0 } }} title={item?.rfq_details?.primary_skills?.slice(3).join(', ')}>
                                      <ToolBadge sx={{ cursor: 'pointer' }}>...</ToolBadge>
                                    </Tooltip>
                                  )}
                                </React.Fragment>
                              )}
                            </ToolsContainer>
                          </CardItems>
                          <StyledDiv>
                            <div>
                              <FlexDiv>
                                <StyledCharge /> Charge/Rate: {item?.rfq_details?.rate_info?.bill_rate}
                              </FlexDiv>
                              <FlexDiv>
                                <img src="/Assets/Images/vendorDashboardChargeType.svg" alt="" /> Commission to Vendor: {item?.rfq_details?.rate_info?.commission}
                              </FlexDiv>
                            </div>
                            <div>
                              <StyledSuccess size="small" onClick={() => { setMoveType('interested'); setRfqId(item?.rfq_details?.rfq_id || ''); showModal(true) }}>
                                <CheckOutlinedIcon />
                              </StyledSuccess>
                              <StyledError size="small" onClick={() => { setMoveType('not interested'); setRfqId(item?.rfq_details?.rfq_id || ''); showModal(true) }}>
                                <CloseOutlinedIcon />
                              </StyledError>
                            </div>
                          </StyledDiv>
                        </CardContent>
                        <CardFooter>
                        </CardFooter>
                      </StyledCard>
                    </Grid>
                  ))
                ) : (
                  <p>No requisitions available.</p>
                )}
              </Grid>
            </ScrollContainer>
          </React.Fragment>
        )}
      </Cardbox>
      <Modal
        open={modal}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Box sx={{ ...modalStyle }}>
          <Typography align="right">
            <Close color="primary" sx={{ margin: '5px', cursor: 'pointer' }} onClick={() => { showModal(false); setRfqId('') }} />
          </Typography>
          <CenteredColumn>
            <MiddleIconBig>
              {moveType === "interested" ? <TickIconBig /> : <CancleIconBig />}
            </MiddleIconBig>
            <CenteredTypography id="modal-description" variant="h4">
              Do you want to move the selected requisitions to the
              {moveType === "interested" ? (
                <span style={{ color: themeColors.success }}>
                  “Interested Requisition”
                </span>
              ) : (
                <span style={{ color: themeColors.error }}>
                  “Not interested Requisition”
                </span>
              )}
              tab
            </CenteredTypography>
            <Spinner show={spinner}>
              <StyledContainer>
                <Stylebutton variant="contained" color="primary" onClick={handelMove}>
                  Yes
                </Stylebutton>
                <StylebuttonDelete onClick={() => { showModal(false); setRfqId('') }}>No</StylebuttonDelete>
              </StyledContainer>
            </Spinner>
          </CenteredColumn>
        </Box>
      </Modal>
    </MainBox >
  );
};

const StyledDetails = styled(Box)({
  display: 'flex',
  gap: '5px'
})

const MiddleIconBig = styled(`div`)({
  display: "flex",
  justifyContent: "center",
});

const StyledContainer = styled(Box)({
  marginTop: "20px",
  display: "flex",
  justifyContent: "center",
});

const CancleIconBig = styled(CancelIcon)({
  height: "80px",
  width: "80px",
  color: themeColors.error,
  cursor: "pointer",
});

const TickIconBig = styled(CheckCircleIcon)({
  height: "80px",
  width: "80px",
  color: themeColors.success,
  cursor: "pointer",
});

const CenteredTypography = styled(Typography)({
  marginTop: "20px",
  alignItems: "center",
  textAlign: "center",
  color: themeColors.grayBlack,
});

const CenteredColumn = styled(`div`)({
  display: "flex",
  justifyContent: "center",
  flexDirection: "column",
});

const StylebuttonDelete = styled(`div`)({
  cursor: "pointer",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  fontWeight: "bold",
  color: themeColors.purpleDark,
  width: "100px",
  height: "40px",
  padding: "12px, 47px, 12px, 47px",
  border: `2px solid ${themeColors.purpleDark}`,
  borderRadius: "8px",
});

const Stylebutton = styled(Button)({
  backgroundColor: themeColors.purpleDark,
  color: themeColors.white,
  width: "100px",
  height: "40px",
  padding: "12px, 47px, 12px, 47px",
  borderRadius: "8px",
  marginRight: "37px",
});

const StyledVendorBox = styled(Box)({
  padding: '15px 30px 0 30px',
  display: 'flex',
  justifyContent: 'space-between'
})

const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  borderRadius: "15px",
  padding: '5px 20px 20px 20px'
};

const StyledModalBody = styled(Box)(({ theme }) => ({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  backgroundColor: theme.palette.background.paper,
  border: '2px solid #000',
}));

const StyledTypography = styled(Typography)({
  marginBottom: '0',
  fontSize: '18px'
})

const MainBox = styled(Box)({
  borderRadius: "8px",
  border: `1px solid ${themeColors.emptyStar}`,
  marginTop: "20px",
});

const Cardbox = styled(Box)({
  display: 'grid',
  gridAutoFlow: 'column',
  gridAutoColumns: '100%',
  minHeight: '20vh'
});

const ScrollContainer = styled(Box)`
    min-height: 20vh;
    display: flex;
    overflow-x: auto;
    white-space: nowrap;
    padding: 20px;
  
    & > * {
      flex-shrink: 0;
    }
  
    &::-webkit-scrollbar {
      height: 8px;
    }
  
    &::-webkit-scrollbar-thumb {
      background-color: ${themeColors.badgeGray};
      border-radius: 10px;
    }
  
    &::-webkit-scrollbar-thumb:hover {
      background-color: ${themeColors.grey};
    }
  
    &::-webkit-scrollbar-track {
      background-color: ${themeColors.white};
    }
  `;

const StyledSuccess = styled(Button)({
  backgroundColor: themeColors.success,
  borderRadius: "50%",
  color: 'white',
  marginRight: '2px',
  minWidth: '41px',
  width: '10px !important',
  ":hover": ({
    backgroundColor: themeColors.success,
    color: 'white',
  })
})

const StyledError = styled(Button)({
  backgroundColor: themeColors.error,
  color: 'white',
  borderRadius: '50%',
  minWidth: '41px',
  ":hover": ({
    backgroundColor: themeColors.error,
    color: 'white'
  })
})

const StyledCharge = styled(MonetizationOnOutlinedIcon)({
  color: themeColors.purpleDark
})

const StyledComission = styled(MobiledataOffRoundedIcon)({
  color: themeColors.purpleDark
})

const StyledDiv = styled('div')({
  marginTop: '10px',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'end',
})

const FlexDiv = styled('div')({
  display: 'flex',
  justifyContent: 'start',
  alignItems: 'center',
  gap: '3px',
  letterSpacing: '0.5px',
  fontWeight: 'bold'
  // fontSize: '15px'
})

const FlexBox = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  fontSize: '12px',
  wordSpacing: '2px',
  marginTop: '5px',
  gap: '5px',
  marginBottom: '5px'
})

const CardHeader = styled("div")({
  color: themeColors.grey,
});

const CardFooter = styled(CardActions)({
  display: "flex",
  justifyContent: "flex-start",
  padding: "0px",
});

const ToolsContainer = styled(Box)({
  display: "flex",
  flexWrap: "wrap",
  gap: "8px",
  margin: "8px 0",
});

const ToolBadge = styled(Paper)({
  backgroundColor: themeColors.purpleLight,
  color: "black",
  boxShadow: "none",
  padding: "4px 8px",
  borderRadius: "16px",
  fontSize: "12px",
  display: "inline-block",
});

const CardItems = styled(Box)({
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  padding: "0px",
});

const StyledCard = styled(Card)`
  margin: 8px;
  padding: 12px 18px;
  width: 450px;
  height: 40vh;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: column;
`;

export default VendorOpenRequisition