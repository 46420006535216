import React, { ReactEventHandler, useState } from "react";
import {
  GridColDef,
  GridCellParams,
  GridRowSelectionModel,
  GridRenderCellParams,
} from "@mui/x-data-grid";

import { ReactComponent as AceeptIcon } from "../../../../../assets/images/icons/acceptIcon.svg";
import { ReactComponent as RejectIcon } from "../../../../../assets/images/icons/rejectIcon.svg";
import CustomTabPanel from "../CustomTabPanel";
import styled from "@emotion/styled";
import {
  IconButton,
  Stack,
  Tooltip,
  tooltipClasses,
  TooltipProps,
} from "@mui/material";
import { themeColors } from "core/Theming/theme";
import {
  AcceptOrRejectSubmissionModal,
  VendorModal,
} from "api/resources/modals";
import InterestedRfrModal from "../InterestedVendorsRfrTab/InterestedRfrModal";
import { SubmissionId } from "../AcceptedSubmissionsTab/AcceptedSubmissionsTab";
import { toast } from "react-toastify";
import { setSumissionId } from "core/store/auth";
import { useAppDispatch, useAppSelector } from "core/store";
import {
  UsePostAcceptorRejectSubmission,
  useFetchPendingfRFq,
} from "pages/hooks";
import { useParams } from "react-router-dom";
import { Skil } from "pages/MyRFRs/Components/RFRlistingpage";

type PendingSubmissionsTabProps = {
  dataRows: VendorModal[];
  loading: boolean;
  addNewTab: (tabId: string | number) => void;
};
export enum StatusType {
  Accepted = "accepted",
  Rejected = "rejected",
}

const PendingSubmissionsTab: React.FC<PendingSubmissionsTabProps> = ({
  dataRows,
  loading,
  addNewTab,
}) => {
  const [selectionModel, setSelectionModel] = useState<GridRowSelectionModel>(
    []
  );
  const selectedRows = dataRows.filter((row) =>
    selectionModel.includes(row.id)
  );
  const [open, setOpen] = useState(false);
  const dispatch = useAppDispatch();
  const { "rfq-id": rfqId } = useParams();
  const { refetch: fetchingPending } = useFetchPendingfRFq(rfqId || " ");
  const { postAcceptOrRejectSubmissionData } = UsePostAcceptorRejectSubmission({
    onSuccess: () => {
 if(StatusType.Accepted){
  
 }
        toast.success("Request made Successfully.")
      
    },
    onError: () => toast.error(" Please select a single row and try again"),
  });

  const renderPendingActionItems = () => (
    <StyledDiv>
      <AceeptIcon style={{ marginRight: "8px" }} />
      <RejectIcon />
    </StyledDiv>
  );
  const handleSubmissionClick = (id: string) => {
    toast.success("Submission Details Tab Added at the right end");
    dispatch(setSumissionId(id));
    addNewTab(`SubmissionDetailsTab-${id}`);
  };
  const pendingColumns: GridColDef[] = [
    { field: "vendor_id", headerName: "Vendor Id", width: 180 },
    {
      field: "actionItems",
      headerName: "Actions",
      width: 120,
      renderCell: renderPendingActionItems,
    },
    {
      field: "C2C_Vendor_Bid_Id",
      headerName: "Submission Id",
      width: 180,
      renderCell: (params: GridRenderCellParams<VendorModal>) => (
        <SubmissionId
          onClick={() => handleSubmissionClick("1725286936859696015")}
        >
          {params.row.C2C_Vendor_Bid_Id}
        </SubmissionId>
      ),
    },

    { field: "name", headerName: "Vendor Name", sortable: true, width: 160 },
    {
      field: "startDate",
      headerName: "Submission Date",
      width: 150,
      renderCell: (params: GridRenderCellParams<VendorModal>) => {
        const cellValue = params.value;
        return (
          <>
            <Stack direction="row" spacing={1} mt={0}>
              <p>{cellValue}</p>
            </Stack>
            ;
          </>
        );
      },
    },
    {
      field: "Visa_Type",
      headerName: "Candidate Visa",
      width: 320,
      renderCell: (params: GridRenderCellParams<string[]>) => (
        <Stack direction="row" spacing={1} mt={1}>
          {Array.isArray(params.value) && params.value.length > 0
            ? params.value.map((Visa: string) => (
                <Skil key={Visa} label={Visa} />
              ))
            : null}
        </Stack>
      ),
    },

    {
      field: "Bill_Rate",
      headerName: "Rate info",
      width: 150,
    },
    {
      field: "Employer_Level",
      headerName: "Employee Level",
      width: 200,
    },
    {
      field: "commission",
      headerName: "Commissions asked",
      width: 180,
    },
    {
      field: "cvs_mentioned",
      headerName: "CVs mentioned",
      type: "number",
      width: 180,
    },
    {
      field: "cvs_submitted",
      headerName: "CVs Submitted",
      type: "number",
      width: 180,
    },
  ];

  const handleModelButton = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleAcceptClick = async (
    event: React.MouseEvent<SVGSVGElement>,
    params: GridRenderCellParams<VendorModal>
  ) => {
    event.stopPropagation();
    await dispatch(setSumissionId(params.row.C2C_Vendor_Bid_Id));

    const updatedSubmissionId = params.row.C2C_Vendor_Bid_Id;

    const payload: AcceptOrRejectSubmissionModal = {
      status: StatusType.Accepted,
      submmissionID: updatedSubmissionId,
    };

    postAcceptOrRejectSubmissionData(payload);
  };

  const handleRejectClick = async (
    event: React.MouseEvent<SVGSVGElement>,
    params: GridRenderCellParams<VendorModal>
  ) => {
    event.stopPropagation();
    await dispatch(setSumissionId(params.row.C2C_Vendor_Bid_Id));

    const updatedSubmissionId = params.row.C2C_Vendor_Bid_Id;

    const payload: AcceptOrRejectSubmissionModal = {
      status: StatusType.Rejected,
      submmissionID: updatedSubmissionId,
    };

    postAcceptOrRejectSubmissionData(payload);
  };

  const renderActionItems = (params: GridCellParams) => (
    <StyledDiv>
      <AcceptTooltip title={"Accept"} placement="bottom" arrow>
        <IconButton >
          <AceeptIcon
            onClick={(e) =>
              handleAcceptClick(e, params as GridRenderCellParams<VendorModal>)
            }
          />
        </IconButton>
      </AcceptTooltip>
      <RejectTooltip title={"Reject"} placement="bottom" arrow>
        <IconButton>
          <RejectIcon
            onClick={(e) =>
              handleRejectClick(e, params as GridRenderCellParams<VendorModal>)
            }
          />
        </IconButton>
      </RejectTooltip>
      <InterestedRfrModal
        open={open}
        handleClose={handleClose}
        selectedDataRows={selectedRows}
      />
    </StyledDiv>
  );

  const updatedColumns: GridColDef[] = pendingColumns.map(
    (column: GridColDef) =>
      column.field === "actionItems"
        ? { ...column, renderCell: renderActionItems }
        : column
  );

  return (
    <CustomTabPanel
      value={3}
      selectionModel={selectionModel}
      dataRows={dataRows}
      columns={updatedColumns}
      tabLabel="Request for a Submission"
      onSelectionChange={setSelectionModel}
      onActionButtonClick={handleModelButton}
      isLoading={loading}
      index={3}
    />
  );
};

export const AcceptTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.arrow}`]: {
    color: themeColors.white,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: themeColors.white,
    color: themeColors.success,
    borderRadius: "6px",
    boxShadow: `0 0 0.45rem ${themeColors.successLight}`,
  },
});
export const RejectTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.arrow}`]: {
    color: themeColors.white,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: themeColors.white,
    color: themeColors.redColor,
    borderRadius: "6px",
    boxShadow: `0 0 0.45rem ${themeColors.successLight}`,
  },
});
export const ViewCvTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.arrow}`]: {
    color: themeColors.white,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: themeColors.white,
    color: themeColors.purpleDark,
    borderRadius: "6px",
    boxShadow: `0 0 0.45rem ${themeColors.purpleLight}`,
  },
});
const StyledDiv = styled("div")({
  display: "inline-flex",
  alignItems: "center",
  verticalAlign: "middle",
});

export default PendingSubmissionsTab;
function dispatch(arg0: any) {
  throw new Error("Function not implemented.");
}
