export const vendorWidgetOptions = [
    // { label: "Tell us your requirement", value: "option1" },
    // { label: "Your Journey so far", value: "option2" },
    // { label: "My Submissions", value: "option3" },
    { label: "Quick Submission", value: "option4" },
    { label: "Open requisition", value: "option5" },
];

export const VendorActions = [
    {
        "icon": "/Assets/icons/vendor/Add.svg",
        "text": "Create Submission",
        "url": "/vendor/rmp"
    },
    {
       "icon": "/Assets/icons/vendor/Group.svg",
        "text": "Review Submissions",
        "url": "/vendor/my-submissions"
    },
    {
        "icon": "/Assets/icons/vendor/Group.svg",
        "text": "See all Vendors",
        "url": "/vendor/my-network"
    },
    {
        "icon": "/Assets/icons/vendor/Chat.svg",
        "text": "Chat",
        "url": "/vendor/chats"
    },
    // {
    //     "icon": "/Assets/icons/vendor/Simplification.svg",
    //     "text": "Manage your Team",
    //     "url": ""
    // }
]

export const vendorJourney = [
    { text: "Requisitions Pending", numberText: "200" },
    { text: "Submissions Accepted", numberText: "162" },
    { text: "Submissions Declined", numberText: "132" },
    { text: "Submissions Expired", numberText: "21" },
    { text: "Interest recieved for Submission", numberText: "44" },
]

// Date should be month date year
export const VendorQuickSubmissions = [
    { title: "Python Developer", expiryDate: "08/20/2024", para: "You have related CV to this. Do you want to send a quick submission for the request" },
    { title: "PHP Developer", expiryDate: "10/09/2024", para: "233 submission recived till date" },
    { title: "Frontend Developer", expiryDate: "12/08/2024", para: "233 submission recived till date" },
    { title: "SQL Developer", expiryDate: "09/08/2024", para: "233 submission recived till date" },
    { title: "Java Developer", expiryDate: "09/08/2024", para: "233 submission recived till date" },
    { title: "React Developer", expiryDate: "11/08/2024", para: "233 submission recived till date" },
    { title: "Flutter Developer", expiryDate: "11/08/2024", para: "233 submission recived till date" },
    { title: "Dotnet Developer", expiryDate: "11/08/2024", para: "233 submission recived till date" },
    { title: "React Native Developer", expiryDate: "12/29/2024", para: "233 submission recived till date" },
    { title: "Wordpress Developer", expiryDate: "08/30/2024", para: "233 submission recived till date" },
    { title: "Next.js Developer", expiryDate: "09/08/2024", para: "233 submission recived till date" },
]

export const OpenRequsition = [
    {
        jobTitle: "UI/UX designer",
        exp: "5-10 Years",
        location: "New York City",
        level: "Level 1 or Level 2",
        tools: ['Design Thinking', 'figma', 'Wirefriming', 'Prototyping', 'User Research',
            'Responsive Design', 'Information Archictecture'
        ],
        rate: '45$',
        commission: '5%'
    },
    {
        jobTitle: "UI/UX designer",
        exp: "5-10 Years",
        location: "New York City",
        level: "Level 1 or Level 2",
        tools: ['Design Thinking', 'figma', 'Wirefriming', 'Prototyping', 'User Research',
            'Responsive Design', 'Information Archictecture'
        ],
        rate: '45$',
        commission: '5%'
    },
    {
        jobTitle: "UI/UX designer",
        exp: "5-10 Years",
        location: "New York City",
        level: "Level 1 or Level 2",
        tools: ['Design Thinking', 'figma', 'Wirefriming', 'Prototyping', 'User Research',
            'Responsive Design', 'Information Archictecture'
        ],
        rate: '45$',
        commission: '5%'
    },
    {
        jobTitle: "UI/UX designer",
        exp: "5-10 Years",
        location: "New York City",
        level: "Level 1 or Level 2",
        tools: ['Design Thinking', 'figma', 'Wirefriming', 'Prototyping', 'User Research',
            'Responsive Design', 'Information Archictecture'
        ],
        rate: '45$',
        commission: '5%'
    },
    {
        jobTitle: "UI/UX designer",
        exp: "5-10 Years",
        location: "New York City",
        level: "Level 1 or Level 2",
        tools: ['Design Thinking', 'figma', 'Wirefriming', 'Prototyping', 'User Research',
            'Responsive Design', 'Information Archictecture'
        ],
        rate: '45$',
        commission: '5%'
    },
    {
        jobTitle: "UI/UX designer",
        exp: "5-10 Years",
        location: "New York City",
        level: "Level 1 or Level 2",
        tools: ['Design Thinking', 'figma', 'Wirefriming', 'Prototyping', 'User Research',
            'Responsive Design', 'Information Archictecture'
        ],
        rate: '45$',
        commission: '5%'
    },
    {
        jobTitle: "UI/UX designer",
        exp: "5-10 Years",
        location: "New York City",
        level: "Level 1 or Level 2",
        tools: ['Design Thinking', 'figma', 'Wirefriming', 'Prototyping', 'User Research',
            'Responsive Design', 'Information Archictecture'
        ],
        rate: '45$',
        commission: '5%'
    },
];

export const VendorSubmissionData = [
    {
        name: 'Phanindra Uppala',
        id: 'T0077254',
        tools: ['Java', 'Javascript', 'React JS', 'Node Js'],
        visaType: 'Green Card Holder',
        chargePerRate: '$500',
        commission: '16%',
        category: 'level 1',
        status: 'Accepted'
    },
    {
        name: 'Rama Vuppala',
        id: 'T0077254',
        tools: ['Java', 'Javascript', 'React JS', 'Node Js'],
        visaType: 'White Card Holder',
        chargePerRate: '$500',
        commission: '16%',
        category: 'level 1',
        status: 'Declined'
    },
    {
        name: 'Sangeetha Jonna',
        id: 'T0077254',
        tools: ['Java', 'Javascript', 'React JS', 'Node Js'],
        visaType: 'Business visa',
        chargePerRate: '$500',
        commission: '16%',
        category: 'level 1',
        status: 'Pending'
    },
    {
        name: 'Anil Varma',
        id: 'T0077254',
        tools: ['Java', 'Javascript', 'React JS', 'Node Js'],
        visaType: 'Diplomat visa',
        chargePerRate: '$700',
        commission: '16%',
        category: 'level 1',
        status: 'Expired'
    },
    {
        name: 'Vijay Varma',
        id: 'T0077254',
        tools: ['Java', 'Javascript', 'React JS', 'Node Js'],
        visaType: 'Green Card Holder',
        chargePerRate: '$900',
        commission: '16%',
        category: 'level 1',
        status: 'Declined'
    },
    {
        name: 'Joy',
        id: 'T0077254',
        tools: ['Java', 'Javascript', 'React JS', 'Node Js'],
        visaType: 'Diplomat visa',
        chargePerRate: '$300',
        commission: '16%',
        category: 'level 1',
        status: 'Declined'
    }
]