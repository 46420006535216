import React, { MouseEventHandler, ReactNode, useState } from "react";
import {
  GridColDef,
  GridCellParams,
  GridRowSelectionModel,
  GridRenderCellParams,
} from "@mui/x-data-grid";
import styled from "@emotion/styled";
import { Button, IconButton, Stack, Typography } from "@mui/material";
import { ReactComponent as ChatIcon } from "../../../../../assets/images/icons/Chat.svg";
import { ReactComponent as RejectIcon } from "../../../../../assets/images/icons/rejectIcon.svg";
import CustomTabPanel from "../CustomTabPanel";
import { themeColors } from "core/Theming/theme";
import {
  AcceptOrRejectSubmissionModal,
  VendorModal,
} from "api/resources/modals";
import { ReactComponent as CloseIcon } from "../../../../../assets/images/icons/Frame 48095805.svg";
import { useAppDispatch } from "core/store";
import { setSumissionId } from "core/store/auth";
import {
  RejectTooltip,
  StatusType,
} from "../PendingSubmissions/PendingSubmissions";
import { useFetchAcceptedRFq, UsePostAcceptorRejectSubmission} from "pages/hooks";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";
import { Skil } from "pages/MyRFRs/Components/RFRlistingpage";

type SubmissionIdProps = {
  onClick: MouseEventHandler<HTMLSpanElement>;
  children: ReactNode;
};

type AcceptedSubmissionsTabProps = {
  dataRows: VendorModal[];
  loading: boolean;
  addNewTab: (tabId: string | number) => void;
};

const AcceptedSubmissionsTab: React.FC<AcceptedSubmissionsTabProps> = ({
  dataRows,
  loading,
  addNewTab,
}) => {
  const [selectionModel, setSelectionModel] = useState<GridRowSelectionModel>(
    []
  );
  const dispatch = useAppDispatch();

  const handleSubmissionClick = (id: string) => {
    dispatch(setSumissionId(id));
    addNewTab(`SubmissionDetailsTab-${id}`);
  };

  const renderAcceptedActionItems = (params: GridCellParams) => {
      const { "rfq-id": rfqId } = useParams();
    const [requestAgain, setRequestAgain] = useState(false);
    const handleRequestClick = () => {
      setRequestAgain(true);
    };
    const cvsSubmitted = params.row.cvs_submitted;
      const {
        refetch: fetchingAccepted,
      } = useFetchAcceptedRFq(rfqId);
    const { postAcceptOrRejectSubmissionData } =
      UsePostAcceptorRejectSubmission({
        onSuccess: () => {
          fetchingAccepted().then(() => {
            toast.success("Request made Successfully.");
          });
        },
        onError: () => toast.error(" Please select a single row and try again"),
      });

    const handleRejectClick = async (
      event: React.MouseEvent<SVGSVGElement>,
      params: GridRenderCellParams<VendorModal>
    ) => {
      event.stopPropagation();
      await dispatch(setSumissionId(params.row.C2C_Vendor_Bid_Id));

      const updatedSubmissionId = params.row.C2C_Vendor_Bid_Id;

      const payload: AcceptOrRejectSubmissionModal = {
        status: StatusType.Rejected,
        submmissionID: updatedSubmissionId,
      };

      postAcceptOrRejectSubmissionData(payload);
    };
    return (
      <StyledDiv>
        <RejectTooltip title={"Reject"} placement="bottom" arrow>
          <IconButton>
            <RejectIcon
              onClick={(e) =>
                handleRejectClick(
                  e,
                  params as GridRenderCellParams<VendorModal>
                )
              }
            />
          </IconButton>
        </RejectTooltip>
        {params.value === 4 && <CloseIcon style={{ marginRight: "8px" }} />}

        {cvsSubmitted === 0 ? (
          requestAgain ? (
            <></>
          ) : (
            // <StyledButton href="#" onClick={handleRequestClick}>
            //   Request Again
            // </StyledButton>
            <></>
            // <StyledButtonAccepted href="#" onClick={handleRequestClick}>
            //   Request for CV
            // </StyledButtonAccepted>
          )
        ) : (
          <></>
          // <StyledButtonAccepted href="#" onClick={handleRequestClick}>
          //   Request for CV
          // </StyledButtonAccepted>
        )}
      </StyledDiv>
    );
  };

  const acceptedSubmissionsColumns: GridColDef[] = [
    { field: "vendor_id", headerName: "Vendor Id", width: 180 },
    {
      field: "actionItems",
      headerName: "Actions",
      width: 280,
      renderCell: renderAcceptedActionItems,
    },
    {
      field: "C2C_Vendor_Bid_Id",
      headerName: "Submission Id",
      width: 180,
      renderCell: (params: GridRenderCellParams<VendorModal>) => (
        <SubmissionId
          onClick={() => handleSubmissionClick(params.row.C2C_Vendor_Bid_Id)}
        >
          {params.row.C2C_Vendor_Bid_Id}
        </SubmissionId>
      ),
    },

    { field: "name", headerName: "Vendor Name", sortable: true, width: 160 },
    {
      field: "startDate",
      headerName: "Submission Date",
      width: 150,
      renderCell: (params: GridRenderCellParams<VendorModal>) => {
        const cellValue = params.value;
        return (
          <Stack direction="row" spacing={1} mt={0}>
            <p>{cellValue}</p>
          </Stack>
        );
      },
    },
    {
      field: "Visa_Type",
      headerName: "Candidate Visa",
      sortable: true,
      width: 220,
      renderCell: (params: GridRenderCellParams<string[]>) => (
        <Stack direction="row" spacing={1} mt={1}>
          {Array.isArray(params.value) && params.value.length > 0
            ? params.value.map((Visa: string) => (
                <Skil key={Visa} label={Visa} />
              ))
            : null}
        </Stack>
      ),
    },
    {
      field: "Bill_Rate",
      headerName: "Rate info",
      width: 150,
    },
    {
      field: "Employer_Level",
      headerName: "Employee Level",
      width: 200,
    },
    {
      field: "commission",
      headerName: "Commissions asked",
      width: 180,
    },
    {
      field: "cvs_mentioned",
      headerName: "CVs mentioned",
      type: "number",
      width: 180,
    },
    {
      field: "cvs_submitted",
      headerName: "CVs Submitted",
      type: "number",
      width: 180,
    },
  ];

  const updatedColumns: GridColDef[] = acceptedSubmissionsColumns.map(
    (column: GridColDef) =>
      column.field === "actionItems"
        ? { ...column, renderCell: renderAcceptedActionItems }
        : column
  );

  return (
    <CustomTabPanel
      value={4}
      selectionModel={selectionModel}
      dataRows={dataRows}
      columns={updatedColumns}
      tabLabel="Request for CV"
      onSelectionChange={setSelectionModel}
      isLoading={loading}
      index={4}
      onActionButtonClick={() => {}}
    />
  );
};

export const SubmissionId = styled("p")({}).withComponent(
  ({ onClick, children }: SubmissionIdProps) => (
    <Typography
      onClick={onClick}
      style={{
        textDecoration: "underline",
        cursor: "pointer",
        color:themeColors.primaryDark,
      }}
    >
      {children}
    </Typography>
  )
);

const StyledDiv = styled("div")({
  display: "inline-flex",
  alignItems: "center",
  verticalAlign: "middle",
});

const StyledButton = styled(Button)({
  padding: "5px 20px",
  borderRadius: "8px",
  backgroundColor: themeColors.primaryLight,
  color: themeColors.primaryDark,
  textDecoration: "underline",
});

const DisabledButton = styled(Button)({
  padding: "5px 20px",
  borderRadius: "8px",
  backgroundColor: themeColors.disabled,
  color: themeColors.disabedDark,
  textDecoration: "underline",
});

export const StyledButtonAccepted = styled(Button)({
  color: themeColors.success,
  padding: "5px 20px",
  borderRadius: "8px",
  backgroundColor: themeColors.successLight,
  textDecoration: "underline",
  border: "none",
  "&:hover": {
    backgroundColor: themeColors.successLight,
    color: themeColors.success,
    border: "none",
  },
});

export default AcceptedSubmissionsTab;
