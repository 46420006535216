import { themeColors } from "core/Theming/theme";
import { useEffect, useState } from "react";
import { useAppSelector } from "core/store";
import {
  HeadingContainer,
  HeadingTextSx,
  MainContent,
  MiddleContainer,
} from "../rmp";
import { ReactComponent as RmpHeadericon } from "../../../assets/icons/rmpHeaderIcon.svg";
import { CustomTabPanel } from "../CommonComponents/Tabs";
import { ALL_CVS_TABS } from "./constants/VendorRmpDetails";
import Spinner from "components/spinner/Spinner";
import CvDataBase from "./CvDataBaseTab/CvDataBaseTab";
import { SharedTabPannel } from "components/SharedTabPannel";
import SmartScore from "./SmartScoreTab/SmartScoreTab";
import { useGetVendorCvData } from "pages/hooks";

const MyCvs = () => {
  const [selectedTab, setSelectedTab] = useState(0);
  const userInfo = useAppSelector((store) => store?.auth?.userInfo);
  const [spinner, showSpinner] = useState(false);
  const {
    vendorCvsData = [],
    isLoading: cvDataLoading,
    refetch,
  } = useGetVendorCvData(userInfo?.user_id || "");

  const refechData = async () => {
    showSpinner(true);
    await refetch();
    showSpinner(false);
  };

  const handleTabChange = async (
    event: React.ChangeEvent<{}>,
    newValue: number
  ) => {
    setSelectedTab(newValue);
    refechData();
  };

  useEffect(() => {
    refechData();
  }, []);

  return (
    <MainContent>
      <HeadingContainer>
        <HeadingTextSx variant="h4" color={themeColors.purpleDark}>
          <RmpHeadericon /> &nbsp; All CV's
        </HeadingTextSx>
      </HeadingContainer>
      <MiddleContainer>
        <CustomTabPanel
          selectedTab={selectedTab}
          changeTabs={handleTabChange}
          labels={ALL_CVS_TABS}
        />
      </MiddleContainer>
      <Spinner show={spinner}>
        <SharedTabPannel value={selectedTab} index={0}>
          <CvDataBase
            spinner={cvDataLoading}
            data={vendorCvsData}
            selectedTab={selectedTab}
            refechData={refechData}
          />
        </SharedTabPannel>
        <SharedTabPannel value={selectedTab} index={1}>
          <SmartScore
            spinner={cvDataLoading}
            data={vendorCvsData}
            selectedTab={selectedTab}
            refechData={refechData}
          />
        </SharedTabPannel>
      </Spinner>
    </MainContent>
  );
};

export default MyCvs;
