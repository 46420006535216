import { useEffect, useState } from "react";
import styled from "@emotion/styled";
import { Box } from "@mui/material";
import Spinner from "components/spinner/Spinner";
import { useAppSelector, useAppDispatch, RootState } from "core/store";
import { Header } from "layouts/Headerfile";
import Sidenavbar from "layouts/Sidenavbar";
import { Outlet, useNavigate } from "react-router-dom";
import { checkUserType } from "core/store/auth";
import { useSelector } from "react-redux";
import { themeColors } from "core/Theming/theme";

const AdminLayout = () => {
    const navigate = useNavigate()
    const dispatch = useAppDispatch();
    const { isLoading, isVendor } = useAppSelector((state) => state.auth);
    const [completionPercentage, setCompletionPercentage] = useState(0);
    const userInfo = useSelector((state: RootState) => state.auth?.userInfo);
    const [spinner, showSpinner] = useState(true)
    useEffect(() => {
        if (isVendor === null) {
            const storedData = JSON.parse(localStorage.getItem('c2c') || '{}');
            if (storedData && typeof storedData.isVendor === 'boolean') {
                dispatch(checkUserType(storedData.isVendor));
            }
        }
    }, []);

    useEffect(() => {
        const calculateCompletionPercentage = () => {
            let totalChecks = 3;
            let completedChecks = 0;
            if (userInfo?.about_company?.company_headquarters !== "")
                completedChecks++;
            if (userInfo?.general_profile?.first_name != null) completedChecks++;
            if (
                Array.isArray(userInfo?.role_specifications?.role_hiring_for) &&
                (userInfo?.role_specifications?.role_hiring_for?.length ?? 0) > 0
            )
                completedChecks++;
            const percentage = (completedChecks / totalChecks) * 100;
            setCompletionPercentage(percentage);
        };
        if (userInfo) {
            calculateCompletionPercentage();
        }
    }, [userInfo]);

    useEffect(() => {
        if (isVendor !== null) {
            dispatch(checkUserType(isVendor));
            setTimeout(() => {
                // navigating according to user Type and existing location
                const path = (isVendor ? `/vendor${window.location.pathname.split('/vendor')[1] || '/dashboard'}` : `/employer${window.location.pathname.split('/employer')[1] || '/dashboard'}`)
                navigate(path)
                setTimeout(() => {
                    showSpinner(false)
                }, 500)
            }, 500);
        }
    }, [isVendor]);

    return (
        <section>
            <StyledBox component="main">
                <StickyHeader>
                    <Header completionPercentage={completionPercentage} />
                </StickyHeader>
                <Spinner show={isLoading || spinner}>
                    <Maincontent>
                        <Sidenavbar />
                        <PageContent>
                            <Outlet context={{ setCompletionPercentage }} />
                        </PageContent>
                    </Maincontent>
                </Spinner>
            </StyledBox>
        </section>
    )
}

export default AdminLayout;

const PageContent = styled('div')({
    marginTop: '12vh',
    overflow: 'hidden',
    width: '100%',
    minHeight: '100vh'
})

const StickyHeader = styled('div')({
    position: 'fixed',
    top: 0,
    zIndex: 6,
    width: '100%'
})

const StyledBox = styled(Box)({
    flexGrow: 1,
    backgroundColor: themeColors.white,
});

const Maincontent = styled(Box)({
    display: "flex",
    position: "relative",
});
