import React from "react";
import { Button, AppBar as MuiAppBar } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import MarkEmailUnreadIcon from "@mui/icons-material/MarkEmailUnread";
import NotificationAddIcon from "@mui/icons-material/NotificationAdd";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import { ReactComponent as Logo } from "../../../../assets/icons/TalentXchange_White_Icon.svg";
import styled from "@emotion/styled";
import { themeColors } from "core/Theming/theme";

const Navbar: React.FC = () => {
  return (
    <div>
      <CustomAppBar position="static">
        <Header>
          <div>
            <Logo/>
          </div>
{/* 
          <ButtonContainer>
            <Button color="inherit">Home</Button>
            <Button color="inherit">Jobs</Button>
          </ButtonContainer>
          <IconContainer>
            <SearchIcon />
            <MarkEmailUnreadIcon />
            <NotificationAddIcon />
            <AccountCircleOutlinedIcon />
          </IconContainer> */}
        </Header>
      </CustomAppBar>
    </div>
  );
};
const CustomAppBar = styled(MuiAppBar)`
  background-color: ${themeColors.primaryDark} !important;
  height: 80px;
`;
const Header = styled.div`
  padding: 10px 80px;
  display: flex;
  justify-content: flex-start;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
`;

const IconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  & > *:not(:last-child) {
    margin-right: 20px;
  }
`;

export default Navbar;
