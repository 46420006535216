import { Typography, Box } from '@mui/material';
import styled from '@emotion/styled';
import Spinner from 'components/spinner/Spinner';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { checkUserType } from 'core/store/auth';
import { useNavigate, useParams } from 'react-router-dom';
import { useRFQDescription } from 'pages/hooks';
import { toast } from 'react-toastify';
import { useAppSelector } from 'core/store';

const CheckUserType = () => {
  const params = useParams()
  const rfqId = params.id
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = useAppSelector(store => store.auth.userInfo)
  const { rfqDescription, error, refetch, isLoading } = useRFQDescription(rfqId ? rfqId : '');

  // What ever user do like logn/signup/postsign any user check conditions and all the other auth conditions are checking in this page and according we are navigating to particular components according to the conditions

  const handleGetUserType = () => {
    if (user && user.general_profile?.first_name) {
      dispatch(checkUserType(user.general_profile.recruiter));
      if (user.general_profile.recruiter) {
        navigate('/vendor/dashboard');
      } else {
        navigate('/employer/dashboard');
      }
    }
  };


  useEffect(() => {
    if (!rfqId) {
      handleGetUserType()
    }
  }, [])

  useEffect(() => {
    // share URL navigation
    if (rfqDescription?.details?.id) {
      dispatch(checkUserType(true))
      if (rfqDescription?.details?.rfq_details?.rfq_closing_date) {
        if (new Date(rfqDescription?.details?.rfq_details?.rfq_closing_date || '') > new Date())
          navigate(`/vendor/rmp/${rfqId}/submission-details`)
        else {
          toast.warning('JD was expired please go through other jobs')
          navigate('/vendor/rmp')
        }
      }
      else {
        toast.warning('JD was not exist please go through other jobs')
        navigate('/vendor/rmp')
      }
    }
  }, [rfqDescription])


  useEffect(() => {
    // share URL navigation
    if (rfqDescription?.details?.id) {
      dispatch(checkUserType(true))
      if (rfqDescription?.details?.rfq_details?.rfq_closing_date) {
        new Date(rfqDescription?.details?.rfq_details?.rfq_closing_date || '') > new Date() ?
          navigate(`/vendor/rmp/${rfqId}/submission-details`) : navigate('/vendor/rmp')
      }
      else {
        navigate('/vendor/rmp')
      }
    }
  }, [rfqDescription])

  return (
    <StyledBox>
      <StyledTypography variant="h2">
        Loading please wait...
      </StyledTypography>
      <Spinner show={true} />
    </StyledBox>
  );
};

const StyledTypography = styled(Typography)({
  marginBottom: '40px'
})

const StyledBox = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  height: '100vh'
});

export default CheckUserType;
