import {
  Box,
  Button,
  IconButton,
  Modal,
  styled,
  Typography,
} from "@mui/material";
import { themeColors } from "core/Theming/theme";
import { ReactComponent as ClosedcircleIcon } from "../../../../../assets/images/closedCircleIcon.svg";
import { ReactComponent as Question } from "../../../../../assets/icons/Question.svg";
import { ReactComponent as SuccessTickIcon } from "../../../../../assets/icons/SuccessTickIcon.svg";
import React, { useState } from "react";
import { VendorModal } from "api/resources/modals";
import { useParams } from "react-router-dom";
import { useGetRFRDetails } from "pages/hooks";
import CheckIcon from "@mui/icons-material/Check";
import CancelIcon from "@mui/icons-material/Cancel";

export interface InterestedRfrModalProps {
  open: boolean;
  handleClose: () => void;
  modalContent?: React.ReactNode;
  selectedDataRows: VendorModal[]; 
}

type StyledButtonProps = {
  colortype: "yes" | "no";
};

const InterestedRfrModal: React.FC<InterestedRfrModalProps> = ({
  open,
  handleClose,
  modalContent,
  selectedDataRows,
}) => {
  const { "rfq-id": rfqId } = useParams();
  const { rfrDetails } = useGetRFRDetails(rfqId);
  const data = rfrDetails?.rfq_details;
  const [yesClicked, setYesClicked] = useState<boolean>(false);

  const handleModalClose = () => {
    setYesClicked(false);
    handleClose();
  };

  if (!selectedDataRows.length) {
    return null; 
  }

  return (
    <Modal
      open={open}
      onClose={handleModalClose}
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
    >
      <ModalBox>
        <CustomBox>
          <ModalIconButton onClick={handleModalClose}>
            <ClosedcircleIcon height={"24px"} width={"24px"} />
          </ModalIconButton>
        </CustomBox>

        {yesClicked ? (
          <Box p={4} sx={{ textAlign: "center" }}>
            <SuccessTickIcon
              height={"104px"}
              style={{ marginBottom: "24px" }}
            />
            <StyledTypography variant="h6" sx={{ marginBottom: "32px" }}>
              Request for Submissions (RFS) created successfully for
              {selectedDataRows.length} Vendor
              {selectedDataRows.length > 1 ? "s" : ""}.
            </StyledTypography>
          </Box>
        ) : (
          <Box p={4} pt={1} sx={{ textAlign: "center" }}>
            <Question height={"104px"} />
            <StyledTypographyYes variant="h6">
              Hi {selectedDataRows[0].name}, do you want to request a CV from{" "}
              {selectedDataRows.length} accepted vendor
              {selectedDataRows.length > 1 ? "s" : ""} for
              <b> {data?.job_title}?</b>
            </StyledTypographyYes>

            <Box
              style={{
                display: "inline-flex",
                marginTop: "16px",
              }}
            >
              <StyledAcceptButton
                colortype="yes"
                onClick={() => setYesClicked(!yesClicked)}
                endIcon={<CheckIcon />}
              >
                Yes
              </StyledAcceptButton>
              <StyledAcceptButton
                colortype="no"
                onClick={handleModalClose}
                endIcon={<CancelIcon />}
              >
                No
              </StyledAcceptButton>
            </Box>
          </Box>
        )}
        {modalContent && <Box sx={{ mt: 2 }}>{modalContent}</Box>}
      </ModalBox>
    </Modal>
  );
};

const StyledAcceptButton = styled(Button)<StyledButtonProps>(
  ({ colortype }) => ({
    borderRadius: "10px",
    boxShadow: `0px 0px 4px 0px ${
      colortype === "yes" ? "rgba(52, 168, 83, 0.75)" : "rgba(188, 1, 1, 0.75)"
    }`,
    backgroundColor: colortype === "yes" ? "#34A853" : "rgba(188, 0, 0, 1.00)",
    color: "white",
    "&:hover": {
      backgroundColor: colortype === "yes" ? "#2c8f4b" : "rgba(188, 0, 0, 0.9)",
    },
    display: "flex",
    alignItems: "center",
    marginLeft: colortype === "yes" ? 0 : "10px",
  })
);

const StyledTypographyYes = styled(Typography)({
  fontSize: "16px",
});

const StyledTypography = styled(Typography)({
  fontSize: "18px",
});

const CustomBox = styled(Box)({
  background: themeColors.primaryLight,
  width: "100%",
  height: "54px",
  borderTopRightRadius: "20px",
  borderTopLeftRadius: "20px",
  position: "relative",
});

const ModalIconButton = styled(IconButton)({
  position: "absolute",
  top: 10,
  right: 10,
  color: themeColors.black,
});

const ModalBox = styled(Box)({
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 480,
  backgroundColor: themeColors.white,
  borderRadius: "20px",
  boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.25)",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
});

export default InterestedRfrModal;
