import * as Yup from "yup";

export const marks = [
  {
    value: 0,
    label: "0",
  },
  {
    value: 5,
    label: "5",
  },
  {
    value: 10,
    label: "10",
  },
  {
    value: 15,
    label: "15",
  },
  {
    value: 20,
    label: "20+",
  },
];

export const validationSchema = Yup.object({
  jobTitle: Yup.string()
    .matches(
      /^[a-zA-Z0-9\s-]*$/,
      "Job Title can only contain letters, numbers, spaces, and hyphens"
    )
    .required("Job Title is required"),
  initialExperience: Yup.number()
    .min(0, "Experience cannot be less than 0")
    .required("Experience is required"),
  maxExperience: Yup.number()
    .min(
      Yup.ref("initialExperience"),
      "Max Experience must be greater than Initial Experience"
    )
    .required("Experience is required"),
  noOfPositions: Yup.number()
    .min(1, "At least 1 position is required")
    .required("Number of Positions is required"),
  Qualifications: Yup.array().min(1, "At least one qualification is required"),
  Locations: Yup.array().min(1, "At least one location is required"),
  primarySkills: Yup.array().min(1, "At least one primary skill is required"),
  secondarySkills: Yup.array().min(
    1,
    "At least one secondary skill is required"
  ),
  rfqClosingDate: Yup.date().required("Requisitions Closing Date is required"),
  jobType: Yup.string().required("Job Type is required"),
  jobCategory: Yup.string().required("Job Category is required"),
  employeeLevel: Yup.string().required("Employee Level is required"),
  billRateFrom: Yup.number()
    .min(1, "Bill rate must be greater than 0")
    .required("Bill rate is required"),
  billRateTo: Yup.number()
    .min(Yup.ref("billRateFrom"), "Bill rate 'to' must be greater than 'from'")
    .required("Bill rate is required"),
    jobDescriptionText: Yup.string()
    .test(
      'maxWords',
      'Job description cannot exceed 100 words',
      (value) => {
        if (!value) return true;
        return value.trim().split(/\s+/).length <= 100;
      }
    ),

});


