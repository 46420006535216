import { AnalysisModal } from "api/resources/modals";
import { themeColors } from "core/Theming/theme";
import React from "react";
import FunnelChart from "./Components/FunnelChart";
import PieChart from "./Components/PieChart";
import { useFetchAnalysisRFq } from "pages/hooks";
import { useParams } from "react-router-dom";
import styled from "@emotion/styled";

export const Charts = () => {
  const { "rfq-id": rfqId } = useParams();
  const {
    analysisFunnelData = [],
    isLoading: loadingAnalytics,
    refetch: fetchingFunnelAnalytics,
  } = useFetchAnalysisRFq(rfqId);
  const dataItem = analysisFunnelData[0] || {};

  const isOnlyPoolOfVendors =
    dataItem.interestedVendors === 0 &&
    dataItem.vendorsWhoRaisedBids === 0 &&
    dataItem.acceptedBids === 0 &&
    dataItem.submittedCVs === 0;

  const pieData = {
    labels: [
      "Uninterested Vendors",
      "Interested Vendors",
      "Rejected Submissions",
      "Accepted Submissions",
    ],
    datasets: [
      {
        data: [
          dataItem?.uninterestedVendorsPercentage || 0,
          dataItem?.interestedVendorsPercentage || 0,
          dataItem?.rejectedBidsPercentage || 0,
          dataItem?.acceptedBidsPercentage || 0,
        ],
        backgroundColor: [
          themeColors.uninterestedVendorsPieBgColor,
          themeColors.interestedVendorsPieBgColor,
          themeColors.rejectedBidsPieBgColor,
          themeColors.acceptedBidsPieBgColor,
        ],
        colors: [
          themeColors.uninterestedVendorsPieTextColor,
          themeColors.interestedVendorsPieTextColor,
          themeColors.rejectedBidsPieTextColor,
          themeColors.acceptedBidsPieTextColor,
        ],
      },
    ],
  };

  const pieOptions = {
    plugins: {
      legend: {
        display: true,
        position: "right",
        labels: {
          boxWidth: 50,
          boxHeight: 20,
          padding: 30,
          font: {
            size: 14,
            family: "Roboto",
          },
          color: (context: any) => {
            const { dataIndex } = context;
            const colors = context.chart.data.datasets[0].colors;
            return colors && colors[dataIndex]
              ? colors[dataIndex]
              : themeColors.blackGray;
          },
        },
        generateLabels: (chart: any) => {
          const dataset = chart.data.datasets[0];
          return chart.data.labels.map((label: string, index: number) => ({
            text: label,
            fillStyle: dataset.colors[index],
            strokeStyle: dataset.colors[index],
            lineWidth: 2,
            hidden: false,
            index: index,
          }));
        },
      },
      datalabels: {
        color: (context: any) => {
          return pieData.datasets[0].colors[context.dataIndex];
        },
        formatter: (value: number, context: any) => {
          const { chart } = context;
          const { labels, datasets } = chart.data;
          const dataset = datasets[0];

          const total = dataset.data.reduce(
            (acc: number, val: number) => acc + val,
            0
          );
          const percentage = ((value / total) * 100).toFixed(1);
          const label = labels[context.dataIndex];

          return `${percentage}%\n${label}`;
        },
        font: {
          size: 12,
          weight: "bold",
        },
        position: "center",
        align: "center",
        anchor: "center",
      },
      tooltip: {
        callbacks: {
          label: function (tooltipItem: any) {
            const label = tooltipItem.label ?? "";
            const value = tooltipItem.raw ?? 0;
            return `${label}: ${value}`;
          },
        },
      },
    },
  };

  const funnelDataArray = [
    {
      label: "Pool of Vendors",
      data: dataItem.poolOfVendors || 0,
      backgroundColor: themeColors.poolOfVendorsBgColor,
      textColor: themeColors.poolOfVendorsFunnelColor,
    },
    {
      label: "Interested Vendors",
      data: dataItem.interestedVendors || 0,
      backgroundColor: themeColors.interestedVendorsBgColor,
      textColor: themeColors.interestedVendorsFunnelColor,
    },
    {
      label: "Vendors who raised Submissions",
      data: dataItem.vendorsWhoRaisedBids || 0,
      backgroundColor: themeColors.vendorsWhoRaisedBidsBgColor,
      textColor: themeColors.vendorsWhoRaisedBidsFunnelColor,
    },
    {
      label: "Accepted Submissions",
      data: dataItem.acceptedBids || 0,
      backgroundColor: themeColors.acceptedBidsBgColor,
      textColor: themeColors.acceptedBidsFunnelColor,
    },
    {
      label: "Submitted CVs",
      data: dataItem.submittedCVs || 0,
      backgroundColor: themeColors.submittedCVsBgColor,
      textColor: themeColors.submittedCVsFunnelColor,
    },
  ];

  funnelDataArray.sort((a, b) => b.data - a.data);

  const funnelData = {
    labels: funnelDataArray.map((item) => item.label),
    datasets: [
      {
        data: funnelDataArray.map((item) => item.data),
        backgroundColor: funnelDataArray.map((item) => item.backgroundColor),
        textColor: funnelDataArray.map((item) => item.textColor),
      },
    ],
  };

  const funnelChartOptions = {
    indexAxis: "y" as const,
    scales: {
      x: {
        display: false,
      },
    },
    plugins: {
      legend: {
        display: false,
      },
      datalabels: {
        color: (context: any) => {
          return funnelData.datasets[0].textColor[context.dataIndex];
        },
        font: {
          size: 12,
          weight: "bold" as const,
        },
        formatter: (value: number, context: any) => {
          const labels = context.chart.data.labels;
          if (labels && context.dataIndex != null) {
            const label = labels[context.dataIndex];
            return label ? `${label}` : "";
          }
          return "";
        },
        align: "center" as const,
        anchor: "center" as const,
        clamp: true,
        offset: 0,
        display: (context: any) => {
          const labels = context.chart.data.labels;
          const dataIndex = context.dataIndex;
          return !!(
            labels &&
            dataIndex != null &&
            typeof labels[dataIndex] === "string"
          );
        },
      },
    },
  };

  const filteredPieData = {
    labels: pieData.labels.filter((_, index) => pieData.datasets[0].data[index] > 0),
    datasets: [
      {
        ...pieData.datasets[0],
        data: pieData.datasets[0].data.filter((value) => value > 0),
        backgroundColor: pieData.datasets[0].backgroundColor.filter(
          (_, index) => pieData.datasets[0].data[index] > 0
        ),
        colors: pieData.datasets[0].colors.filter(
          (_, index) => pieData.datasets[0].data[index] > 0
        ),
      },
    ],
  };

  return (
    <>
      <div style={{ filter: isOnlyPoolOfVendors ? "blur(4px)" : "none" }}>
        <h3>Lead Funnel:</h3>
        <StyledHr />
        <FunnelContainer>
          <div >
            <FunnelChart data={funnelData} options={funnelChartOptions} />
          </div>

        </FunnelContainer>
        <h3>Submissions:</h3>
        <StyledHr />

        <PieChart data={filteredPieData} options={pieOptions} />
      </div>
      {isOnlyPoolOfVendors && <NoDataMessage>No data available</NoDataMessage>}
    </>
  );
};

const StyledHr = styled("hr")`
  border: 1px solid ${themeColors.primaryDark};
`;

const FunnelContainer = styled("div")`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top:32px;
  height: 300px;
`;

const NoDataMessage = styled("div")`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: ${themeColors.primaryDark};
  font-size: 18px;
  font-weight: bold;
  text-align: center;
`;

export default Charts;
