import React from 'react';
import styled from '@emotion/styled';
import { themeColors } from 'core/Theming/theme';

interface JobDetail {
    icon: string;
    label: string;
    value: string | null | number | string[];
}

interface JobDetailsProps {
    jobDetails: JobDetail;
}

const JobDetailsComponent: React.FC<JobDetailsProps> = ({ jobDetails }) => {
    return (
        <section>
            <StyledTypography>
                <img src={jobDetails?.icon} height={20} width={20} alt={jobDetails?.label} /> <StyledSpan>{jobDetails?.label}: </StyledSpan><StyledValueSpan>{jobDetails?.value}</StyledValueSpan>
            </StyledTypography>
        </section>
    );
};

const StyledTypography = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 10px;
`;

const StyledSpan = styled.span`
  margin-left: 6px;
  font-size: 18px;
  font-weight: 450;
  color: ${themeColors.purpleDark}  
`;

const StyledValueSpan = styled('span')({
    fontSize: '17px',
    marginLeft: '5px'
})

export default JobDetailsComponent;
