import React, { ReactNode } from "react";
import { styled } from "@mui/material/styles";
import { Box, Typography } from "@mui/material";
import { useAppSelector } from "core/store";
import { themeColors } from "core/Theming/theme";

interface EmptyWidgetsProps {
  button: ReactNode;
}

const EmptyWidgets: React.FC<EmptyWidgetsProps> = ({ button }) => {
  const { isVendor } = useAppSelector(store => store.auth)

  return (
<>
    {
      isVendor?(
        <EmptyWidgetsContainers>
   
   <img src={`${!isVendor ? '/Assets/Images/emptywidgets.svg' : '/Assets/Images/emptywidgets1.svg'}`} alt="empty widgets" />
   <MainContainer>
     <TextContainer>
     <TextStyle>You haven't added any custom widgets yet. Enhance your </TextStyle>
     <TextStyle_second >dashboard with additional widgets</TextStyle_second>  
     </TextContainer>
   {button}
   </MainContainer>
        </EmptyWidgetsContainers>

      ):(
        <>
          <EmptyWidgetsContainer>
   
   <img src={`${!isVendor ? '/Assets/Images/emptywidgets.svg' : '/Assets/Images/emptywidgets1.svg'}`} alt="empty widgets" />
   <MainContainer>
     <TextContainer>
     <TextStyle>You haven't added any custom widgets yet. Enhance your </TextStyle>
     <TextStyle_second >dashboard with additional widgets</TextStyle_second>  
     </TextContainer>
   {button}
   </MainContainer>
 </EmptyWidgetsContainer >
        
        </>
      )
    }
</>
)};


const EmptyWidgetsContainers = styled(Box)({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "flex-start",
  textAlign: "center",
});
const EmptyWidgetsContainer = styled(Box)({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "center",
  textAlign: "center",
});

const TextContainer=styled(Box)({
  marginBottom:'16px'
});

const MainContainer=styled(Box)({
display:'flex',
flexDirection:'column',
marginLeft:'50px'
});

const TextStyle=styled(Typography)({
  color:themeColors.darkGrey,
  fontSize:'22px',
  fontWeight:'400',
});

const TextStyle_second=styled(Typography)({
  color:themeColors.darkGrey,
  fontSize:'22px',
  fontWeight:'400',
  textAlign:'justify'
});


export default EmptyWidgets;
