import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { User } from "api/resources/modals";

export interface AuthState {
  isAuthenticated: boolean;
  userInfo: User | null;
  isLoading: boolean;
  isVendor: boolean | null;
  submissionId:string | null;
  isView:boolean;
  isRfr:boolean;
}

const initialAuthState: AuthState = {
  isLoading: true,
  isAuthenticated: false,
  userInfo: null,
  isVendor: null,
  submissionId: null,
  isView:false,
  isRfr:false,
};

const storeInSession = (data: { isVendor?: boolean; vendorWidgets?: Record<string, any>; employeeWidgets?: Record<string, any> }) => {
  const existingData = JSON.parse(localStorage.getItem('c2c') || '{}');
  const updatedData = { ...existingData, ...data };
  localStorage.setItem('c2c', JSON.stringify(updatedData));
};

const slice = createSlice({
  name: "auth",
  initialState: initialAuthState,
  reducers: {
    userLoggedin: (state, action: PayloadAction<User>) => {
      return {
        ...state,
        isLoading: false,
        isAuthenticated: true,
        userInfo: action.payload,
      };
    },
    userLoginFailed: (state) => {
      return {
        ...state,
        userInfo: null,
        isLoading: false,
        isAuthenticated: false,
      };
    },
    userLoggedout: (state) => {
      return {
        ...state,
        userInfo: null,
        isLoading: false,
        isAuthenticated: false,
      };
    },
    userLoginInitiated: (state) => {
      return { ...state, isLoading: true };
    },
    checkUserType: (state, action: PayloadAction<boolean | null>) => {
      state.isVendor = action.payload;
      state.isVendor !== null && storeInSession({ isVendor: state.isVendor });
    },
    setSumissionId: (state, action: PayloadAction<string | null>) => {
     state.submissionId= action.payload;
    },
    setView:(state, action: PayloadAction<boolean>) => {
      state.isView= action.payload;
     },
     setRFR:(state, action: PayloadAction<boolean>) => {
      state.isRfr= action.payload;
     },
  },
});

export default slice.reducer;

export const {
  userLoggedin,
  userLoginFailed,
  userLoggedout,
  userLoginInitiated,
  checkUserType,
  setSumissionId,
  setView,
  setRFR
} = slice.actions;
