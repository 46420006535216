import styled from '@emotion/styled';
import { Box, Button, Checkbox, FormControlLabel, Menu, MenuItem, Paper, Table, TableBody, TableCell, TableHead, TableRow, Tooltip, Typography } from '@mui/material';
import PaginationComponent from 'components/paginationComponent/Pagination';
import { themeColors } from 'core/Theming/theme';
import React, { useEffect, useMemo, useState } from 'react';
import { BadgeBackgrounds, BadgeColors } from './statusColors';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import { useAppSelector } from 'core/store';
import TableLoader from 'components/TableLoader/TableLoader';

interface TableHeader {
    title: string;
    filter?: boolean;
    filterType?: string;
    options?: string[];
    min?: string;
    max?: string;
    name?: string;
    cellWidth?: string;
    show?: boolean;
    cellAlign?: "center" | "left" | "right";
    customComponent?: (row: any) => JSX.Element;
    customFilter?: (row: any, data: any, type?: string | '') => any;
    handleFieldClick?: (name: any, item?: any) => void;
}

interface ActionItem {
    type: string,
    alt?: string;
    src?: string;
    handleClick: (name: any, item?: any) => void;
    btnName?: string;
    name: string;
    actionTooltip?: string;
    customActionComponent?: (row: any) => JSX.Element;
}

interface ReusableTableProps {
    headers: TableHeader[];
    data: any[];
    spinner: boolean;
    actions?: ActionItem[];
    itemsPerPage?: number;
    actionsCellWidth?: string;
    headerColor?: string;
    checkBox?: boolean | null;
    inputBorder?: string;
    handleDoBulk?: (item: any) => void;
}

const ReusableTable: React.FC<ReusableTableProps> = ({ headers, data, spinner, actions, itemsPerPage, actionsCellWidth, headerColor, checkBox, handleDoBulk, inputBorder }) => {
    const isVendor = useAppSelector(store => store?.auth?.isVendor)
    const [totalItemPerPage, setTotalItemPerPage] = useState(itemsPerPage)
    const [currentPage, setCurrentPage] = useState(1);
    const [totalItems, setTotalItems] = useState(0);
    const [filterCriteria, setFilterCriteria] = useState<any>({});
    const [filteredData, setFilteredData] = useState<any[]>([]);
    const [selectedValues, setSelectedValues] = useState<Set<any>>(new Set()); // Manage selected rows based on value
    const [selectedField, setSelectedField] = useState<string | null>(null); // Field for selecting rows
    const [columnVisibility, setColumnVisibility] = useState<Record<string, boolean>>(
        headers.reduce((acc, header) => {
            if (header.name) acc[header.name] = header.show !== false;
            return acc;
        }, {} as Record<string, boolean>)
    );
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    const handleDropdownClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleDropdownClose = () => {
        setAnchorEl(null);
    };

    const handleColumnVisibilityChange = (headerName: string) => {
        setColumnVisibility(prev => ({
            ...prev,
            [headerName]: !prev[headerName]
        }));
    }

    const hasActiveFilters = Object.values(filterCriteria).some((value) => value !== '' && value !== null);

    const applyFilters = useMemo(() => {
        return () => {
            return data.filter((item) => {
                return headers.every((header) => {
                    const filterValue = filterCriteria[header.name || ''];
                    if (!filterValue) return true;
                    const itemValue = item[header.name || ''];
                    const filterValueString = filterValue.toString().toLowerCase();
                    const itemValueString = itemValue ? itemValue.toString().toLowerCase() : '';
                    if (header.filterType === 'date') {
                        const normalizeDate = (date: string | number) => {
                            let parsedDate: Date;
                            if (typeof date === 'number') {
                                parsedDate = new Date(date);
                            } else if (typeof date === 'string') {
                                parsedDate = new Date(date);
                            } else {
                                return '';
                            }
                            if (isNaN(parsedDate.getTime())) {
                                return '';
                            }
                            return parsedDate.toISOString().split('T')[0];
                        };
                        const normalizedItemDate = itemValueString && normalizeDate((!itemValueString.includes('-') || !itemValueString.includes('/')) ? JSON.parse(itemValueString) : itemValueString);
                        const normalizedFilterDate = normalizeDate(filterValueString);
                        return normalizedItemDate === normalizedFilterDate;
                    } else if (header.filterType === 'number' || header.filterType === 'text') {
                        return itemValueString.includes(filterValueString);
                    } else if (header.filterType === 'select') {
                        return itemValueString === filterValueString;
                    } else {
                        return true;
                    }
                });
            });
        };
    }, [data, headers, filterCriteria]);


    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>, header: TableHeader) => {
        const { value } = e.target;
        const filterName = header.name || '';

        setFilterCriteria((prev: any) => ({
            ...prev,
            [filterName]: value,
        }));
    };

    const handleRowCheckboxClick = (value: any) => {
        setSelectedValues((prevSelectedValues) => {
            const newSelectedValues = new Set(prevSelectedValues);
            if (newSelectedValues.has(value)) {
                newSelectedValues.delete(value);
            } else {
                newSelectedValues.add(value);
            }
            return newSelectedValues;
        });
    };

    const handleHeaderCheckboxClick = () => {
        if (selectedField && filteredData.length) {
            const allValues = new Set(filteredData.map(item => item[selectedField || '']));
            // Case: If some items are selected but not all
            if (selectedValues.size > 0 && selectedValues.size < allValues.size) {
                // Clear selection
                setSelectedValues(new Set());
            } else if (selectedValues.size === allValues.size) {
                // Case: All items are currently selected
                // Clear selection
                setSelectedValues(new Set());
            } else {
                // Case: No items are selected, or not all items are selected
                // Select all items
                setSelectedValues(allValues);
            }
        } [0]
    };

    const onItemPerChange = (perPageItems: number) => {
        setTotalItemPerPage(perPageItems)
    }

    const getSelectedData = () => {
        return filteredData.filter(item => selectedValues.has(item[selectedField || '']));
    };

    const formatDate = (timestamp: number) => {
        try {
            const date = new Date(timestamp);
            const day = String(date.getDate()).padStart(2, '0');
            const month = String(date.getMonth() + 1).padStart(2, '0');
            const year = date.getFullYear();
            const timeString = date.toLocaleTimeString('en-GB', { hour: '2-digit', minute: '2-digit' });

            const dateString = `${day}-${month}-${year}`;
            return `${dateString}, ${timeString}`;
        } catch (err) {
            return '';
        }
    };

    const handleClearFilter = () => {
        setFilterCriteria({});
    };

    const paginatedData = useMemo(() => {
        return filteredData.slice(
            (currentPage - 1) * (totalItemPerPage || 5),
            currentPage * (totalItemPerPage || 5)
        );
    }, [filteredData, currentPage, totalItemPerPage]);

    const handlePageChange = (page: number) => {
        setCurrentPage(page);
    };

    const toTitleCase = (str: string) => {
        return str
            .split(' ')
            .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
            .join(' ');
    };

    useEffect(() => {
        setTotalItems(filteredData.length);
    }, [filteredData]);

    useEffect(() => {
        setFilteredData(applyFilters);
    }, [filterCriteria, data]);

    useEffect(() => {
        setTotalItems(data.length);
    }, [data]);

    useEffect(() => {
        setCurrentPage(1);
    }, [totalItems]);

    useEffect(() => {
        if (handleDoBulk) {
            handleDoBulk(getSelectedData());
        }
    }, [selectedValues]);

    useEffect(() => {
        // Set the selectedField to the first header with a name when checkBox is true
        if (checkBox && headers.length > 0) {
            setSelectedField(headers[0].name || null);
        }
    }, [headers, checkBox]);

    return (
        <React.Fragment>
            <TableLoader show={spinner}>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Box>
                        <Button onClick={handleDropdownClick} sx={{ mr: "auto" }}>Manage Columns</Button>
                        {hasActiveFilters && <Button variant='outlined' onClick={handleClearFilter}>Clear Filter</Button>}
                    </Box>
                    {!checkBox && <Typography sx={{ margin: '0 15px' }}>showing {paginatedData?.length} of {data?.length}</Typography>}
                </Box>
                <Menu
                    anchorEl={anchorEl}
                    open={Boolean(anchorEl)}
                    onClose={handleDropdownClose}
                >
                    {headers.map((header, index) => (
                        header?.show !== false &&
                        <MenuItem key={index}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={columnVisibility[header.name || '']}
                                        onChange={() => handleColumnVisibilityChange(header.name || '')}
                                    />
                                }
                                label={header.title}
                            />
                        </MenuItem>
                    ))}
                </Menu>
                <StyledTableContainer style={{}}>
                    <Table aria-label="simple table" style={{ tableLayout: 'fixed', width: '100%' }}>
                        <StyledTableHead headerColor={headerColor}>
                            <TableRow>
                                {checkBox && selectedField && (
                                    <TableCell width={'100px'} align='center'>
                                        <StyledCheckbox
                                            indeterminate={selectedValues.size > 0 && selectedValues.size < new Set(filteredData.map(item => item[selectedField])).size}
                                            checked={selectedValues.size === new Set(filteredData.map(item => item[selectedField])).size}
                                            onChange={handleHeaderCheckboxClick}
                                        />
                                    </TableCell>
                                )}
                                {actions && (
                                    <TableCell width={actionsCellWidth} align="center">Actions</TableCell>
                                )}
                                {headers.map((header, index) => (
                                    header?.show !== false && columnVisibility[header.name || ''] && (
                                        <StyledHeaderTableCell cellWidth={header?.cellWidth} align={header?.cellAlign || "center"} key={index}>
                                            {toTitleCase(header.title)}
                                            {header.filter && (
                                                <InputWrapper>
                                                    {header.filterType === 'select' ? (
                                                        <StyledSelect
                                                            border_color={inputBorder}
                                                            value={filterCriteria[header.name || ''] as string || ''}
                                                            name={header.name}
                                                            onChange={(e: React.ChangeEvent<HTMLSelectElement>) => handleInputChange(e, header)}
                                                        >
                                                            <option value="">Select</option>
                                                            {header.options && header.options.map((option, idx) => (
                                                                <option key={idx} value={option}>
                                                                    {option}
                                                                </option>
                                                            ))}
                                                        </StyledSelect>
                                                    ) : (
                                                        <StyledInput
                                                            border_color={inputBorder}
                                                            type={header.filterType}
                                                            min={header.filterType === 'number' ? 0 : ''}
                                                            value={filterCriteria[header.name || ''] as string || ''}
                                                            name={header.name}
                                                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleInputChange(e, header)}
                                                        />
                                                    )}
                                                    <FilterAltOutlinedIcon color='primary' />
                                                </InputWrapper>
                                            )}
                                        </StyledHeaderTableCell>
                                    )
                                ))}
                            </TableRow>
                        </StyledTableHead>
                        <TableBody>
                            {paginatedData.length > 0 ? (
                                paginatedData.map((item: any, index: number) => (
                                    <StyledTableRow key={index} hoverColor={headerColor}>
                                        {checkBox && selectedField && (
                                            <TableCell align='center'>
                                                <StyledCheckboxs type='checkbox'
                                                    isVendor={isVendor}
                                                    checked={selectedValues.has(item[selectedField || ''])}
                                                    onChange={() => handleRowCheckboxClick(item[selectedField || ''])}
                                                />
                                            </TableCell>
                                        )}
                                        {actions && (
                                            <TableCell align="center">
                                                <FlexTableCell>
                                                    {actions.map((action, idx) => (
                                                        !action?.customActionComponent ? (
                                                            <React.Fragment key={idx}>
                                                                {action.type === 'img' ? (
                                                                    <Tooltip title={action.actionTooltip} arrow>
                                                                        <img
                                                                            src={action.src}
                                                                            alt={action.alt}
                                                                            onClick={() => action.handleClick(action.name, item)}
                                                                            style={{ cursor: 'pointer', marginRight: '8px' }}
                                                                        />
                                                                    </Tooltip>
                                                                ) : (
                                                                    <Button
                                                                        size='small'
                                                                        variant="contained"
                                                                        onClick={() => action.handleClick(action.name, item)}
                                                                    >
                                                                        <Typography fontSize={'small'}>{action.btnName}</Typography>
                                                                    </Button>
                                                                )}
                                                            </React.Fragment>
                                                        ) : (
                                                            <span onClick={() => action.handleClick(action.name, item)} key={idx}>
                                                                {action?.customActionComponent(item)}
                                                            </span>
                                                        )
                                                    ))}
                                                </FlexTableCell>
                                            </TableCell>
                                        )}
                                        {headers.map((header, idx) => (
                                            header?.show !== false && columnVisibility[header.name || ''] && (
                                                <TableCell align={header?.cellAlign || "center"} key={idx} onClick={header.handleFieldClick ? () => header.handleFieldClick!(header.name, item) : undefined}>
                                                    {!header.customComponent ? (
                                                        header.name === 'created_at' ? formatDate(item[header.name]) :
                                                            header.name === 'status' ? (
                                                                <ToolsContainer>
                                                                    <ToolBadge
                                                                        color={BadgeColors[item[header.name] as keyof typeof BadgeColors] || themeColors.grayBlack}
                                                                        background={BadgeBackgrounds[item[header.name] as keyof typeof BadgeBackgrounds] || themeColors.badgeGray}
                                                                    >
                                                                        {item.status}
                                                                    </ToolBadge>
                                                                </ToolsContainer>
                                                            ) : (header?.handleFieldClick ? (<u style={{ cursor: 'pointer' }}>{item[header.name || '']}</u>) : item[header.name || ''] || '--')
                                                    ) : (
                                                        header.customComponent(item)
                                                    )}
                                                </TableCell>
                                            )
                                        ))}
                                    </StyledTableRow>
                                ))
                            ) : (
                                <TableRow>
                                    <TableCell colSpan={headers.length + (actions ? 1 : 0)} align="center">
                                        <Typography variant="h3">No Data</Typography>
                                    </TableCell>
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </StyledTableContainer>

                <PaginationComponent
                    itemsPerPage={totalItemPerPage || 5}
                    initialItemsPerPage={itemsPerPage || 5}
                    totalItems={totalItems}
                    currentPage={currentPage}
                    onPageChange={handlePageChange}
                    onItemPerChange={onItemPerChange}
                />
            </TableLoader>
        </React.Fragment>
    );
};


const StyledCheckbox = styled(Checkbox)({
    padding: '0'
})

const StyledSelect = styled.select<{ border_color?: string }>`
  border: 1px solid ${({ border_color }) => border_color || themeColors?.primaryAlt};
  outline: none;
  padding: 3px;
  width: 100%;
  border-radius: 3px;
    z-inex:0 !important;
  &:focus {
    border-color: none; 
  }
`;

const StyledCheckboxs = styled.input < { isVendor?: boolean | null } > `
  width: 18px; 
  height: 18px; 
  cursor: pointer;
  appearance: none; 
  background-color: ${themeColors.white}; 
  border: 2px solid ${themeColors.darkGrey}; 
  border-radius: 3px;

  &:checked {
    background-color: ${props => props?.isVendor ? themeColors?.purpleDark : themeColors?.primaryDark};
    border-color: ${props => props?.isVendor ? themeColors?.purpleDark : themeColors?.primaryDark};
  }
    
  &:checked::after {
    content: '';
    display: block;
    width: 6px;
    height: 9px;
    border: solid white;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
    margin-left: 4px;
    margin-top: 2px;
  }
`;

const StyledHeaderTableCell = styled(TableCell)<{ cellWidth?: string }>(({ cellWidth }) => ({
    width: cellWidth || '150px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
}))

const StyledTableRow = styled(TableRow)<{ hoverColor?: string }>(({ hoverColor }) => ({
    overflowY: 'auto',
    cursor: 'pointer',
    ':hover': {
        backgroundColor: hoverColor
    }
}))

const StyledTableContainer = styled(Box)({
    overflowX: 'auto',
    maxWidth: '100%',
    boxShadow: 'none',
    paddingRight: '10px',
    minHeight: '20vh',
});

const StyledTable = styled(Table)({
    width: '100%',
    tableLayout: 'fixed', // Ensure columns are of fixed size based on content
    minWidth: '650px', // Adjust the minimum width to ensure the table doesn't collapse too much
});

const ToolsContainer = styled(Box)({
    display: "flex",
    flexWrap: "wrap",
    margin: "2px 0",
    alignItems: 'center',
    justifyContent: 'center',
});

const ToolBadge = styled(Paper)<{ background: string, color: string }>(({ background, color }) => ({
    background,
    color,
    textAlign: 'center',
    paddingTop: '2px',
    paddingLeft: '20px',
    paddingRight: '20px',
    paddingBottom: '2px',
    fontWeight: 'bold',
}));


const StyledTableHead = styled(TableHead)<{ headerColor?: string }>(({ headerColor }) =>
({
    backgroundColor: headerColor ? headerColor : themeColors.badgeGray,
    '& th': {
        textTransform: 'none',
        fontSize: '15.5px'
    },
}))

const InputWrapper = styled("div")({
    display: "flex",
    alignItems: "center",
    width: "20% !inportent",
});

const StyledInput = styled.input<{ border_color?: string }>`
  width: 90%;
  border: 1px solid ${({ border_color }) => border_color ? border_color : themeColors.primaryAlt};
  outline: none;
  padding: 3px;
  border-radius: 3px;

  &:focus {
    border-color: none; 
  }
`;

const FlexTableCell = styled(Box)`
  display: flex ;
  align-items: center;
  justify-content:center
`;


export default ReusableTable;
