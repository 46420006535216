import {
  CreateUserCV,
  getActiveRequisition,
  getAllSubmissionsList,
  getCvDetails,
  getEmployerWidgets,
  getRaisedSubmissions,
  getRfqAnalysis,
  getRfqRequestedSubmissions,
  getRFRShare,
  getSubmissionDetails,
  getSubmissionsDetails,
  GetVendorMySubmissions,
  GetVendorSubmissionCvs,
  getVendorWidgets,
  postCreateVendorSubmission,
  postEmployerWidgets,
  postVendorWidgets,
  VendorAddMultipleCv,
  postRequestForSubmissionData,
  postAcceptOrRejectSubmissionData,
  moveRequesitionSingle,
  editRequesitionSingle,
  postCvLikeOrDislike,
  VendorEditCv,
  getVendorSubmissionDetails,
  GetVendorEmployerRequests,
  getCvContactDetails,
  postCvNotes,
  getCvNotes,
  getVendorYourJourney,
  getVendorDashboardRequesitions,
  GetVendorsSubmissionCvs,
  getVendorDashboardSubmissions,
  getVendorCreateQuickSubmission,
  postNotifyVendor,
  postPublishRequisition,
  getVendorNotifications,
  getEnpCvContactDetails,
  postCvsOnHold,
  postVendorReadNotification,
  postJdFile,
  getLocationDetails,
  GetVendorCvsData,
  resendMail,
} from "api/resources/Auth";
import "react-toastify/dist/ReactToastify.css";
import { AxiosError, AxiosResponse } from "axios";
import { useMutation, useQuery } from "react-query";
import {
  createQuickRfrDetails,
  getAboutToExpireyData,
  getJourney,
  getQuickRfqSkills,
  getQuickRfqVisatype,
  getRecentsubmissions,
  getRfqAccepted,
  getRFQDescription,
  getRFqEmployeeLevel,
  getRfqInterested,
  getRFqJobType,
  getRFQlocations,
  getRfqNotInterested,
  getRfqPending,
  getRfqpositon,
  getRFQqualification,
  getRfqRejected,
  getRfqRequestedCVs,
  getRFQsecodaryskills,
  getRFRDetails,
  getRFReditdetails,
  getRFRJobCategory,
  getRfrListAll,
  getRFRVisaType,
  getRoles,
  getUserIDDetails,
  getUserLocation,
  postAboutCompanyData,
  postChangeDateRequisition,
  postCreateRFRData,
  postDeleteRequisition,
  postDraftRFRData,
  postEditRfrDetails,
  postJDDetails,
  postRestoreRequisition,
  postRolesData,
  postUserIDDetails,
  signIn,
  signInVerifyOTP,
  signUp,
  signUpVerifyOTP,
  socialSignIN,
  socialSignUp,
  getRmpDataAll,
  getRmpIntrestedData,
  getRmpNotIntrestedData,
} from "api/resources/Auth";
import {
  RolesDataModal,
  PostCompanyDataModal,
  PostUserIDdetailsModal,
  SignupModal,
  VerifyOTPModal,
  User,
  QuickRFRformModal,
  CreateRFRModal,
  RFRDraftModal,
  RFRDetailsModal,
  EditRFRModal,
  ChangeDateRequisitionDataModal,
  RFQDescription,
  CreateRfqVendorSubmission,
  VendorRaisedSubmissions,
  CreateCV,
  MoveRequesitionmodel,
  VendorMySubmissionProps,
  SubmissionDetailsModal,
  CvDetailsModal,
  RFRdataListModal,
  VenderAllRequisitionModel,
  RMPdataTableModel,
  ActiveRequisitionsModal,
  Widgets,
  SelectedWidgetOptions,
  SubmissionCvModal,
  VendorSubmitMultipleCvs,
  SubmissionDetailCvList,
  AcceptOrRejectSubmissionModal,
  RequestForSubmissionModal,
  VendorEditCvProps,
  VendorJobSubmission,
  EditOrMoveIntOrNotInterestedReqResponceModel,
  VendorMysubmitionsEmployer,
  CvContactDetailsModal,
  CvNotesDetails,
  VendorYourJournetSoFar,
  VendorDashBoardOpenRequisitionsProps,
  SubmissionDetailsCvList,
  VendorDashboardSubmissionsProps,
  VendorCreateQuickSubmissionProps,
  VendorModal,
  NotifyAgainModal,
  VendorNotifacation,
  CvEmpContactDetailsModal,
  VendorReadNotification,
  JdFileProps,
  JobDetailsModalProps,
  ZipCodeDetailsModalProps,
  VendorCvsDatabaseTableModel,
  VendorCvsDatabaseApiModel,
} from "api/resources/modals";
import { userLoggedin } from "core/store/auth";
import { useAppDispatch } from "core/store";
import {
  getEditrfrCachekey,
  getJouneryCacheKey,
  getQuickRfqSkillsCacheKey,
  getQuickRfqVisatypeCacheKey,
  getRecentSubmissionsCacheKey,
  getRequisitionsAboutToExpireCacheKey,
  getRfqAcceptedCacheKey,
  getRFqEmployeeLevelCacheKey,
  getRfqInterestedCacheKey,
  getRFqjobTypeCacheKey,
  getRFQlocationCacheKey,
  getRfqNotInterestedCacheKey,
  getRfqPendingCacheKey,
  getRfqpositonCacheKey,
  getRFQqualificationCacheKey,
  getRfqRejectdCacheKey,
  getRfqRequestedCVsCacheKey,
  getRFQsecodaryskillsCacheKey,
  getRFRDetailsCacheKey,
  getRFRJobCategoryCacheKey,
  getRfrlistAllCachekey,
  getRFRVisaTypeCacheKey,
  getRolesCacheKey,
  getUserIDDetailsCachekey,
  getUserLocationCacheKey,
  getRmpDataAllCacheKey,
  getRmpIntrestedDataCacheKey,
  getRmpNotIntrestedDataCacheKey,
  getRfqAnalysisCacheKey,
  getRfqRequestedSubmissionsCacheKey,
  getAllSubmissionsListCacheKey,
  getShareRfrCachekey,
  getVendorSubmissionCacheKey,
  getSubmissionDetailsCacheKey,
  getCvDetailsCacheKey,
  getActiveRequisitionsCacheKey,
  getVendorWidgetCacheKey,
  getEmployeeWidgetCacheKey,
  getVedorSubmissionCacheKey,
  getVendorSubmissionEmployerRequestsKey,
  generateCvDetailsCacheKey,
  getCvNotesCacheKey,
  getVedorSubmissionCvsCacheKey,
  getVedorNotificationCacheKey,
  generateEmpCvDetailsCacheKey,
  generateLocationDetailsCacheKey,
  getVendorCvsDataCacheKey,
} from "api/cacheKeys";
import { useEffect, useState } from "react";
import { queryClient } from "api/client";
interface UseSignInOrSignUpProps {
  onError: (err: AxiosError) => void;
  onSuccess: (data: User) => void;
}

interface ErrorResponse {
  message?: string;
}
interface UseVerifyOTPProps {
  onError: (error: AxiosError<ErrorResponse>) => void;
  onSuccess: (data: User) => void;
}
export const useSignUp = ({ onError, onSuccess }: UseSignInOrSignUpProps) => {
  const dispatch = useAppDispatch();
  const { mutateAsync, isLoading, error } = useMutation(
    (data: SignupModal) => signUp(data),
    {
      onError: (error: AxiosError) => {
        onError(error);
      },
      onSuccess: (data: any) => {
        localStorage.setItem("user", JSON.stringify(data));
        dispatch(userLoggedin(data));
        onSuccess(data);
      },
    }
  );
  return { signUp: mutateAsync, isLogging: isLoading, error };
};
export const useSignUpVerifyOTP = ({
  onError,
  onSuccess,
}: UseVerifyOTPProps) => {
  const dispatch = useAppDispatch();
  const { mutateAsync, isLoading, error } = useMutation(
    (data: VerifyOTPModal) => signUpVerifyOTP(data),
    {
      onError: (error: AxiosError<ErrorResponse, any>) => {
        onError(error);
      },
      onSuccess: (data) => {
        localStorage.setItem("user", JSON.stringify(data));
        dispatch(userLoggedin(data));
        onSuccess(data);
      },
    }
  );
  return { signUpVerifyOTP: mutateAsync, isVerifying: isLoading, error };
};
export const useSignIn = ({ onError, onSuccess }: UseSignInOrSignUpProps) => {
  const dispatch = useAppDispatch();
  const { mutateAsync, isLoading, error } = useMutation(
    (data: SignupModal) => signIn(data),
    {
      onError: (error: AxiosError) => {
        onError(error);
      },
      onSuccess: (data) => {
        localStorage.setItem("user", JSON.stringify(data));
        dispatch(userLoggedin(data));
        onSuccess(data);
      },
    }
  );
  return { signIn: mutateAsync, isSigning: isLoading, error };
};
export const useSignInVerifyOTP = ({
  onError,
  onSuccess,
}: UseVerifyOTPProps) => {
  const dispatch = useAppDispatch();
  const { mutateAsync, isLoading, error } = useMutation(
    (data: VerifyOTPModal) => signInVerifyOTP(data),
    {
      onError: (error: AxiosError<ErrorResponse, any>) => {
        onError(error);
      },
      onSuccess: (data) => {
        localStorage.setItem("user", JSON.stringify(data));
        dispatch(userLoggedin(data));
        onSuccess(data);
      },
    }
  );
  return { signInVerifyOTP: mutateAsync, isSignInVerifying: isLoading, error };
};
export const useSocialSignIn = ({
  onError,
  onSuccess,
}: UseSignInOrSignUpProps) => {
  const dispatch = useAppDispatch();
  const { mutateAsync, isLoading, error } = useMutation(
    (data: SignupModal) => socialSignIN(data),
    {
      onError: (error: AxiosError) => onError(error),
      onSuccess: (data) => {
        localStorage.setItem("user", JSON.stringify(data));
        dispatch(userLoggedin(data));
        onSuccess && onSuccess(data);
      },
    }
  );
  return { socialSignIn: mutateAsync, isSigningIn: isLoading, error };
};
export const useSocialSignUp = ({
  onError,
  onSuccess,
}: UseSignInOrSignUpProps) => {
  const dispatch = useAppDispatch();
  const { mutateAsync, isLoading, error } = useMutation(
    (data: SignupModal) => socialSignUp(data),
    {
      onError: (error: AxiosError) => onError(error),
      onSuccess: (data) => {
        localStorage.setItem("user", JSON.stringify(data));
        dispatch(userLoggedin(data));
        onSuccess && onSuccess(data);
      },
    }
  );
  return { socialSignUp: mutateAsync, isSigningUp: isLoading, error };
};
export const useGetUserIDDetails = (userId: string | null) => {
  const { data, isLoading, refetch } = useQuery<User, Error>(
    getUserIDDetailsCachekey(userId),
    () => getUserIDDetails(userId)
  );
  return { userdata: data, isLoading, refetch };
};

export const useGetRFRDetails = (rfqId?: string) => {
  const { data, isLoading, refetch } = useQuery<RFRDetailsModal, Error>(
    getRFRDetailsCacheKey(rfqId),
    () => getRFRDetails(rfqId)
  );
  return { rfrDetails: data, isLoading, refetch };
};

export const usePostSignData = ({
  onError,
  onSuccess,
}: UseSignInOrSignUpProps) => {
  const dispatch = useAppDispatch();
  const { mutateAsync, isLoading, error } = useMutation(
    (data: PostUserIDdetailsModal) => postUserIDDetails(data),
    {
      onError: (error: AxiosError) => onError(error),
      onSuccess: (data) => {
        localStorage.setItem("user", JSON.stringify(data));
        dispatch(userLoggedin(data));
        onSuccess && onSuccess(data);
      },
    }
  );
  return { postSignData: mutateAsync, isSigningIn: isLoading, error };
};

export const usePostJobDescription = ({
  onError,
  onSuccess,
}: UseSignInOrSignUpProps) => {
  const { mutateAsync, isLoading, error } = useMutation(
    (formData: FormData) => postJDDetails(formData),
    {
      onError: (error: AxiosError) => onError(error),
      onSuccess: (data) => {
        onSuccess(data);
      },
    }
  );

  return { postJDDetails: mutateAsync, isLoading, error };
};

export const useFetchUserLocations = () => {
  const { data, isLoading, isFetching } = useQuery(
    getUserLocationCacheKey,
    getUserLocation
  );
  return { locations: data, isLoading: isLoading || isFetching };
};
export const usePostCompanyData = ({
  onError,
  onSuccess,
}: UseSignInOrSignUpProps) => {
  const dispatch = useAppDispatch();
  const { mutateAsync, isLoading, error } = useMutation(
    (data: PostCompanyDataModal) => postAboutCompanyData(data),
    {
      onError: (error: AxiosError) => onError(error),
      onSuccess: (data) => {
        localStorage.setItem("user", JSON.stringify(data));
        dispatch(userLoggedin(data));
        onSuccess && onSuccess(data);
      },
    }
  );
  return { postAboutCompanyData: mutateAsync, isSigningIn: isLoading, error };
};
export const useFetchGetRoles = () => {
  const { data, isLoading, isFetching } = useQuery(getRolesCacheKey, getRoles);
  return { roles: data, isLoading: isLoading || isFetching };
};
export const useRolesData = ({
  onError,
  onSuccess,
}: UseSignInOrSignUpProps) => {
  const dispatch = useAppDispatch();
  const { mutateAsync, isLoading, error } = useMutation(
    (data: RolesDataModal) => postRolesData(data),
    {
      onError: (error: AxiosError) => onError(error),
      onSuccess: (data) => {
        localStorage.setItem("user", JSON.stringify(data));
        dispatch(userLoggedin(data));
        onSuccess && onSuccess(data);
      },
    }
  );
  return { postRolesData: mutateAsync, isSigningIn: isLoading, error };
};
export const useRfrListAll = (userId: string) => {
  const { data, isLoading, refetch } = useQuery<User, Error>(
    getRfrlistAllCachekey(userId),
    () => getRfrListAll(userId)
  );
  return { rfrListAll: data, isLoading, refetch };
};

export const useCreateRFRData = ({
  onError,
  onSuccess,
}: UseSignInOrSignUpProps) => {
  const { mutateAsync, isLoading, error } = useMutation(
    (data: CreateRFRModal) => postCreateRFRData(data),
    {
      onError: (error: AxiosError) => onError(error),
      onSuccess: (data) => {
        onSuccess && onSuccess(data);
      },
    }
  );
  return { postCreateRFRData: mutateAsync, isSigningIn: isLoading, error };
};

export const useDraftRFRData = ({
  onError,
  onSuccess,
}: UseSignInOrSignUpProps) => {
  const { mutateAsync, isLoading, error } = useMutation(
    (data: RFRDraftModal) => postDraftRFRData(data),
    {
      onError: (error: AxiosError) => onError(error),
      onSuccess: (data) => {
        onSuccess && onSuccess(data);
      },
    }
  );
  return { postDraftRFRData: mutateAsync, isLoading: isLoading, error };
};

export const useFetchRFRJobCategory = () => {
  const { data, isLoading, isFetching } = useQuery(
    getRFRJobCategoryCacheKey,
    getRFRJobCategory
  );
  return { jobCategory: data, isLoading: isLoading || isFetching };
};
export const useFecthRFQlocations = () => {
  const { data, isLoading, isFetching } = useQuery(
    getRFQlocationCacheKey,
    getRFQlocations
  );
  return { location: data, isLoading: isLoading || isFetching };
};
export const useFetchRFQsecodaryskills = () => {
  const { data, isLoading, isFetching } = useQuery(
    getRFQsecodaryskillsCacheKey,
    getRFQsecodaryskills
  );
  return { secondaryskills: data, isLoading: isLoading || isFetching };
};
export const useFetchRFQqualification = () => {
  const { data, isLoading, isFetching } = useQuery(
    getRFQqualificationCacheKey,
    getRFQqualification
  );
  return { qualifications: data, isLoading: isLoading || isFetching };
};
export const useFetchRfqpositon = () => {
  const { data, isLoading, isFetching } = useQuery(
    getRfqpositonCacheKey,
    getRfqpositon
  );
  return { positions: data, isLoading: isLoading || isFetching };
};
export const useFetchNotInterested = (rfqId?: string) => {
  const { data, isLoading, isFetching, refetch } = useQuery(
    getRfqNotInterestedCacheKey(rfqId),
    () => getRfqNotInterested(rfqId),
    { enabled: !!rfqId }
  );
  return {
    notInterestedVendors: data,
    isLoading: isLoading || isFetching,
    refetch,
  };
};

export const useFetchInterested = (rfqId?: string) => {
  const { data, isLoading, isFetching, refetch } = useQuery(
    getRfqInterestedCacheKey(rfqId),
    () => getRfqInterested(rfqId),
    { enabled: !!rfqId }
  );

  return {
    interestedVendors: data,
    isLoading: isLoading || isFetching,
    refetch,
  };
};

export const useFetchSubmissionDetails = (SubmissionId?: string) => {
  const { data, isLoading, isFetching, refetch } = useQuery(
    getSubmissionDetailsCacheKey(SubmissionId),
    () => getSubmissionDetails(SubmissionId),
    { enabled: !!SubmissionId }
  );

  return {
    submissionDetails: data,
    isLoading: isLoading || isFetching,
    refetch,
  };
};

export const useFetchAcceptedRFq = (rfqId?: string) => {
  const { data, isLoading, isFetching, refetch } = useQuery(
    getRfqAcceptedCacheKey(rfqId),
    () => getRfqAccepted(rfqId),
    { enabled: !!rfqId }
  );
  return { acceptedVendors: data, isLoading: isLoading || isFetching, refetch };
};

export const useFetchRequestedCVsRFq = (rfqId?: string) => {
  const { data, isLoading, isFetching, refetch } = useQuery(
    getRfqRequestedCVsCacheKey(rfqId),
    () => getRfqRequestedCVs(rfqId),
    { enabled: !!rfqId }
  );
  return { requestedCVs: data, isLoading: isLoading || isFetching, refetch };
};

export const useFetchRequestedSubmissionsRFq = (rfqId?: string) => {
  const { data, isLoading, isFetching, refetch } = useQuery(
    getRfqRequestedSubmissionsCacheKey(rfqId),
    () => getRfqRequestedSubmissions(rfqId),
    { enabled: !!rfqId }
  );
  return {
    requestedSubmissions: data,
    isLoading: isLoading || isFetching,
    refetch,
  };
};

export const useFetchRejectedRFq = (rfqId?: string) => {
  const { data, isLoading, isFetching, refetch } = useQuery(
    getRfqRejectdCacheKey(rfqId),
    () => getRfqRejected(rfqId),
    { enabled: !!rfqId }
  );
  return { rejectedVendors: data, isLoading: isLoading || isFetching, refetch };
};

export const useFetchPendingfRFq = (rfqId?: string) => {
  const { data, isLoading, isFetching, refetch } = useQuery(
    getRfqPendingCacheKey(rfqId),
    () => getRfqPending(rfqId),
    { enabled: !!rfqId }
  );
  return { pendingVendors: data, isLoading: isLoading || isFetching, refetch };
};

export const useFetchAnalysisRFq = (rfqId?: string) => {
  const { data, isLoading, isFetching, refetch } = useQuery(
    getRfqAnalysisCacheKey(rfqId),
    () => getRfqAnalysis(rfqId),
    { enabled: !!rfqId }
  );
  return {
    analysisFunnelData: data,
    isLoading: isLoading || isFetching,
    refetch,
  };
};

export const useFetchRFqjobType = () => {
  const { data, isLoading, isFetching } = useQuery(
    getRFqjobTypeCacheKey,
    getRFqJobType
  );
  return { jobs: data, isLoading: isLoading || isFetching };
};
export const useFetchRFqEmployeeLevel = () => {
  const { data, isLoading, isFetching } = useQuery(
    getRFqEmployeeLevelCacheKey,
    getRFqEmployeeLevel
  );
  return { levels: data, isLoading: isLoading || isFetching };
};

export const useQuickRfqSkills = () => {
  const { data, isLoading, isFetching } = useQuery(
    getQuickRfqSkillsCacheKey,
    getQuickRfqSkills
  );

  return { Skills: data, isLoading: isLoading || isFetching };
};
export const useQuickRfqVisatype = () => {
  const { data, isLoading, isFetching } = useQuery(
    getQuickRfqVisatypeCacheKey,
    getQuickRfqVisatype
  );

  return { Visa: data, isLoading: isLoading || isFetching };
};

export const useQuickRfrDetails = ({
  onError,
  onSuccess,
}: UseSignInOrSignUpProps) => {
  const { mutateAsync, isLoading, error } = useMutation(
    (data: QuickRFRformModal) => createQuickRfrDetails(data),
    {
      onError: (error: AxiosError) => onError(error),
      onSuccess: (data) => {
        onSuccess && onSuccess(data);
      },
    }
  );
  return { createQuickRfrDetails: mutateAsync, isSigningIn: isLoading, error };
};

export const useDeleteRequisition = ({
  onError,
  onSuccess,
}: UseSignInOrSignUpProps) => {
  const { mutateAsync, isLoading, error } = useMutation(
    (rfqId: string) => postDeleteRequisition(rfqId),
    {
      onError: (error: AxiosError) => onError(error),
      onSuccess: (data: User) => onSuccess(data),
    }
  );

  return { deleteRequisition: mutateAsync, isDeleting: isLoading, error };
};

export const useChangeDateRequisition = ({
  onError,
  onSuccess,
}: UseSignInOrSignUpProps) => {
  const { mutateAsync, isLoading, error } = useMutation(
    (data: ChangeDateRequisitionDataModal) => postChangeDateRequisition(data),
    {
      onError: (error: AxiosError) => onError(error),
      onSuccess: (data) => {
        onSuccess && onSuccess(data);
      },
    }
  );
  return {
    postChangeDateRequisition: mutateAsync,
    isSigningIn: isLoading,
    error,
  };
};

export const useEditRFR = (rfqId: string) => {
  const { data, isLoading, refetch } = useQuery<void, Error>(
    getEditrfrCachekey(rfqId),
    () => getRFReditdetails(rfqId)
  );
  return { editRfrDetails: data, isLoading, refetch };
};

export const editRfrallDetails = ({
  onError,
  onSuccess,
}: UseSignInOrSignUpProps) => {
  const { mutateAsync, isLoading, error } = useMutation(
    (data: EditRFRModal) => postEditRfrDetails(data),
    {
      onError: (error: AxiosError) => onError(error),
      onSuccess: (data) => {
        onSuccess && onSuccess(data);
      },
    }
  );
  return { postEditRfrDetails: mutateAsync, isSigningIn: isLoading, error };
};

export const useRecentSubmissions = (userID: string) => {
  const { data, isLoading, isFetching, refetch } = useQuery(
    getRecentSubmissionsCacheKey(userID),
    () => getRecentsubmissions(userID)
  );

  return { recentSubmissions: data, isLoading: isLoading || isFetching, refetch };
};

export const useJourney = (userID: string) => {
  const { data, isLoading, isFetching } = useQuery(
    getJouneryCacheKey(userID),
    () => getJourney(userID)
  );

  return { journey: data, isLoading: isLoading || isFetching };
};

export const useRequisitionsAboutToExpire = (userID: string) => {
  const { data, isLoading, isFetching, refetch } = useQuery(
    getRequisitionsAboutToExpireCacheKey(userID),
    () => getAboutToExpireyData(userID)
  );
  return { aboutExpireData: data, isLoading: isLoading || isFetching, refetch };
};

export const useRFQDescription = (rfqId: string) => {
  const { data, isLoading, refetch, error } = useQuery<RFQDescription, Error>(
    ["rfqDescription", rfqId],
    () => getRFQDescription(rfqId)
  );

  return { rfqDescription: data, isLoading, refetch, error };
};

export const useCreateVendorSubmission = ({
  onError,
  onSuccess,
}: UseSignInOrSignUpProps) => {
  const { mutateAsync, isLoading, error } = useMutation(
    (data: CreateRfqVendorSubmission) => postCreateVendorSubmission(data),
    {
      onError: (error: AxiosError) => onError(error),
      onSuccess: (data) => {
        onSuccess && onSuccess(data);
      },
    }
  );
  return { createVendorSubmission: mutateAsync, isLoading, error };
};

export const useRaisedSubmissions = (rfqId: string, userId: string) => {
  const { data, isLoading, refetch, error } = useQuery<
    VendorRaisedSubmissions[],
    Error
  >(["raisedSubmissions", rfqId], () => getRaisedSubmissions(rfqId, userId));

  return { raisedSubmissions: data, isLoading, refetch, error };
};
export const useRestoreRequisition = ({
  onError,
  onSuccess,
}: UseSignInOrSignUpProps) => {
  const { mutateAsync, isLoading, error } = useMutation(
    (rfqId: string) => postRestoreRequisition(rfqId),
    {
      onError: (error: AxiosError) => onError(error),
      onSuccess: (data: User) => onSuccess(data),
    }
  );

  return { restoreRequisition: mutateAsync, isRestoring: isLoading, error };
};

export const useRmpDataAll = (vendorID: string) => {
  const { data, isLoading, refetch, isSuccess } = useQuery<
    VenderAllRequisitionModel[],
    Error
  >(getRmpDataAllCacheKey(vendorID), () => getRmpDataAll(vendorID));
  return { rmpAllData: data, isLoading, refetch, isSuccess };
};

export const useRmpIntrestedData = (vendorId: string) => {
  const { data, isLoading, refetch, isSuccess } = useQuery<
    VenderAllRequisitionModel[],
    Error
  >(getRmpIntrestedDataCacheKey(vendorId), () => getRmpIntrestedData(vendorId));
  return { rmpIntrestedData: data, isLoading, refetch, isSuccess };
};

export const useRmpNotIntrestedData = (vendorId: string) => {
  const { data, isLoading, refetch, isSuccess } = useQuery<
    VenderAllRequisitionModel[],
    Error
  >(getRmpNotIntrestedDataCacheKey(vendorId), () =>
    getRmpNotIntrestedData(vendorId)
  );
  return { rmpNotIntrestedData: data, isLoading, refetch, isSuccess };
};

export const useAllSubmissionList = (empId: string) => {
  const { data, isLoading, refetch } = useQuery<User, Error>(
    getAllSubmissionsListCacheKey(empId),
    () => getAllSubmissionsList(empId)
  );
  return { allSubmissionsList: data, isLoading, refetch };
};

export const useCreateCVSubmission = ({
  onError,
  onSuccess,
}: UseSignInOrSignUpProps) => {
  const { mutateAsync, isLoading, error } = useMutation(
    (data: CreateCV) => CreateUserCV(data),
    {
      onError: (error: AxiosError) => onError(error),
      onSuccess: (data) => {
        onSuccess && onSuccess(data);
      },
    }
  );
  return { createMyCV: mutateAsync, isLoading, error };
};

export const useShareRfr = (rfqID: string) => {
  const { data, isLoading, refetch } = useQuery<void, Error>(
    getShareRfrCachekey(rfqID),
    () => getRFRShare(rfqID)
  );
  return { shareLink: data, isLoading, refetch };
};

export const useMoveRequesitionSingle = ({
  onError,
  onSuccess,
}: {
  onError: (error: AxiosError) => void;
  onSuccess: (data: EditOrMoveIntOrNotInterestedReqResponceModel) => void;
}) => {
  const { mutateAsync, isLoading, error, isSuccess } = useMutation(
    (data: MoveRequesitionmodel) => moveRequesitionSingle({ ...data }),
    {
      onError: (error: AxiosError) => onError(error),
      onSuccess: (data) => {
        const { vendor_id } = data || {};
        if (vendor_id) {
          queryClient.removeQueries(getRmpDataAllCacheKey(vendor_id));
          queryClient.removeQueries(getRmpIntrestedDataCacheKey(vendor_id));
          queryClient.removeQueries(getRmpNotIntrestedDataCacheKey(vendor_id));
        }
        onSuccess(data);
      },
    }
  );
  return { mutateAsync, isLoading, error, isSuccess };
};

export const useEditRequesitionSingle = ({
  onError,
  onSuccess,
}: {
  onError: (error: AxiosError) => void;
  onSuccess: (data: EditOrMoveIntOrNotInterestedReqResponceModel) => void;
}) => {
  const { mutateAsync, isLoading, error, isSuccess } = useMutation(
    (data: MoveRequesitionmodel) => editRequesitionSingle({ ...data }),
    {
      onError: (error: AxiosError) => onError(error),
      onSuccess: (data) => {
        const { vendor_id } = data || {};
        if (vendor_id) {
          queryClient.removeQueries(getRmpDataAllCacheKey(vendor_id));
          queryClient.removeQueries(getRmpIntrestedDataCacheKey(vendor_id));
          queryClient.removeQueries(getRmpNotIntrestedDataCacheKey(vendor_id));
        }
        onSuccess(data);
      },
    }
  );
  return { mutateAsync, isLoading, error, isSuccess };
};

type PaginationModel = {
  page: number;
  pageSize: number;
};

export const usePaginatedRequesitionData = (
  requesitionsData: RMPdataTableModel[],
  paginationModel: PaginationModel
) => {
  const [paginatedData, setPaginatedData] = useState<RMPdataTableModel[]>([]);
  const handlePaginatedRequesitionData = () => {
    const paginatedData = requesitionsData.slice(
      paginationModel.page * paginationModel.pageSize,
      (paginationModel.page + 1) * paginationModel.pageSize
    );
    setPaginatedData(paginatedData);
  };

  useEffect(() => {
    handlePaginatedRequesitionData();
  }, [requesitionsData, paginationModel]);

  return paginatedData;
};

export const UseGetVendorSubmission = (userId?: string) => {
  const { data, isLoading, isSuccess, refetch } = useQuery<
    VendorMySubmissionProps[],
    Error
  >(getVendorSubmissionCacheKey(userId), () => GetVendorMySubmissions(userId), {
    enabled: !!userId,
  });
  return { vendorSubmissions: data, isLoading, isSuccess, refetch };
};

export const UseGetVendorEmployerRequests = (userId?: string) => {
  const { data, isLoading, isSuccess, refetch } = useQuery<
    VendorMysubmitionsEmployer[],
    Error
  >(
    getVendorSubmissionEmployerRequestsKey(userId),
    () => GetVendorEmployerRequests(userId),
    {
      enabled: !!userId,
    }
  );
  return { vendorEmployerRequest: data, isLoading, isSuccess, refetch };
};

export const useGetActiveRequisitions = (userID: string) => {
  const { data, isLoading, refetch } = useQuery<ActiveRequisitionsModal, Error>(
    getActiveRequisitionsCacheKey(userID),
    () => getActiveRequisition(userID)
  );
  return { activeRequisitions: data, isLoading, refetch };
};

export const usePostVendorWidgets = ({
  onError,
  onSuccess,
}: UseSignInOrSignUpProps) => {
  const { mutateAsync, isLoading, error } = useMutation(
    (data: Widgets) => postVendorWidgets(data),
    {
      onError: (error: AxiosError) => onError(error),
      onSuccess: (data) => {
        onSuccess && onSuccess(data);
      },
    }
  );
  return { updateVendoeWidgets: mutateAsync, isLoading, error };
};

export const useGetVendorWidgetOptions = (userId: string) => {
  const { data, isLoading, refetch } = useQuery<SelectedWidgetOptions, Error>(
    getVendorWidgetCacheKey(userId),
    () => getVendorWidgets(userId),
    { enabled: !!userId }
  );
  return { activeVendorWidgets: data, isLoading, refetch };
};

export const useGetEmployeeWidgetOptions = (userId: string) => {
  const { data, isLoading, refetch } = useQuery<SelectedWidgetOptions, Error>(
    getEmployeeWidgetCacheKey(userId),
    () => getEmployerWidgets(userId),
    { enabled: !!userId }
  );
  return { activeEmployeeWidgets: data, isLoading, refetch };
};

export const usePostEmployerWidgets = ({
  onError,
  onSuccess,
}: UseSignInOrSignUpProps) => {
  const { mutateAsync, isLoading, error } = useMutation(
    (data: Widgets) => postEmployerWidgets(data),
    {
      onError: (error: AxiosError) => onError(error),
      onSuccess: (data) => {
        onSuccess && onSuccess(data);
      },
    }
  );
  return { updateEmployerWidgets: mutateAsync, isLoading, error };
};

export const useGetSubmissionDetails = (submissionID?: string) => {
  const { data, isLoading, refetch } = useQuery<SubmissionDetailsModal, Error>(
    getSubmissionDetailsCacheKey(submissionID),
    () => getSubmissionsDetails(submissionID)
  );
  return { submissionsDetails: data, isLoading, refetch };
};

export const useGetCvDetails = (submmissionID?: string) => {
  const { data, isLoading, refetch } = useQuery<SubmissionCvModal, Error>(
    getCvDetailsCacheKey(submmissionID),
    () => getCvDetails(submmissionID)
  );
  return { cvDetails: data, isLoading, refetch };
};

export const useVendorAddMultipleCv = ({
  onError,
  onSuccess,
}: UseSignInOrSignUpProps) => {
  const { mutateAsync, isLoading, error } = useMutation(
    (data: VendorSubmitMultipleCvs) => VendorAddMultipleCv(data),
    {
      onError: (error: AxiosError) => onError(error),
      onSuccess: (data) => {
        onSuccess && onSuccess(data);
      },
    }
  );
  return { updateVendorSubmissionCvs: mutateAsync, isLoading, error };
};

export const useGetVendorSubmissionCvs = (
  submissionId: string | number | null
) => {
  const { data, isLoading, refetch } = useQuery<
    SubmissionDetailCvList,
    Error
  >(
    getVedorSubmissionCacheKey(submissionId),
    () => GetVendorSubmissionCvs(submissionId),
    { enabled: !!submissionId }
  );
  return { submissonCvList: data, isLoading, refetch };
};

export const postAcceptorRejectSubmission = ({
  onError,
  onSuccess,
}: UseSignInOrSignUpProps) => {
  const { mutateAsync, isLoading, error } = useMutation(
    (data: AcceptOrRejectSubmissionModal) =>
      postAcceptOrRejectSubmissionData(data),
    {
      onError: (error: AxiosError) => onError(error),
      onSuccess: (data) => {
        onSuccess && onSuccess(data);
      },
    }
  );
  return {
    postAcceptOrRejectSubmissionData: mutateAsync,
    isLoading: isLoading,
    error,
  };
};

export const useFetchVisaType = () => {
  const { data, isLoading, isFetching } = useQuery(
    getRFRVisaTypeCacheKey,
    getRFRVisaType
  );
  return { visaType: data, isLoading: isLoading || isFetching };
};

export const UsePostRequestForSubmission = ({
  onError,
  onSuccess,
}: UseSignInOrSignUpProps) => {
  const { mutateAsync, isLoading, error } = useMutation(
    (data: RequestForSubmissionModal) => postRequestForSubmissionData(data),
    {
      onError: (error: AxiosError) => onError(error),
      onSuccess: (data, _, context?: { rfqId?: string }) => {
        const { rfqId } = context || {};
        if (rfqId) {
          queryClient.refetchQueries(getRfqInterestedCacheKey(rfqId));
        }

        onSuccess(data);
      },
    }
  );

  return {
    requestedSubmissionData: mutateAsync,
    isLoading: isLoading,
    error,
  };
};

export const UsePostAcceptorRejectSubmission = ({
  onError,
  onSuccess,
}: UseSignInOrSignUpProps) => {
  const { mutateAsync, isLoading, error } = useMutation(
    (data: AcceptOrRejectSubmissionModal) =>
      postAcceptOrRejectSubmissionData(data),
    {
      onError: (error: AxiosError) => onError(error),
      onSuccess: (data) => {
        onSuccess && onSuccess(data);
      },
    }
  );
  return {
    postAcceptOrRejectSubmissionData: mutateAsync,
    isLoading: isLoading,
    error,
  };
};



export const useVendorEditCv = ({
  onError,
  onSuccess,
}: UseSignInOrSignUpProps) => {
  const { mutateAsync, isLoading, error } = useMutation(
    (data: VendorEditCvProps) => VendorEditCv(data),
    {
      onError: (error: AxiosError) => onError(error),
      onSuccess: (data) => {
        onSuccess && onSuccess(data);
      },
    }
  );
  return { updateVendorCv: mutateAsync, isLoading, error };
};

export const useCvLikeOrDislike = ({
  onError,
  onSuccess,
}: UseSignInOrSignUpProps) => {
  const { mutateAsync, isLoading, error } = useMutation(
    ({ cvID, status }: { cvID: string; status: string }) =>
      postCvLikeOrDislike(cvID, status),
    {
      onError: (error: AxiosError) => onError(error),
      onSuccess: (data: User) => onSuccess(data),
    }
  );

  return { cvLikeOrDislike: mutateAsync, isRestoring: isLoading, error };
};

export const useVendorSubmissionDetails = (submissionId: string) => {
  const { data, isLoading, refetch, error } = useQuery<
    VendorJobSubmission,
    Error
  >(["submissionId", submissionId], () =>
    getVendorSubmissionDetails(submissionId)
  );

  return { vendorMySubmissionDetails: data, isLoading, refetch, error };
};


export const useCvContactDetails = (cvId: string, vendorId: string, enabled: boolean) => {
  const { data, isLoading, refetch, error } = useQuery<CvContactDetailsModal, Error>(
    generateCvDetailsCacheKey(cvId, vendorId),
    () => getCvContactDetails(cvId, vendorId),
    { enabled }
  );

  return { CvContactDetails: data, isLoading, refetch, error };
};

export const useCvNotes = ({
  onError,
  onSuccess,
}: UseSignInOrSignUpProps) => {
  const { mutateAsync, isLoading, error } = useMutation(
    ({ cvId, notes, notesImage }: { cvId: string; notes: string, notesImage: string }) => postCvNotes(cvId, notes, notesImage),
    {
      onError: (error: AxiosError) => onError(error),
      onSuccess: (data: User) => onSuccess(data),
    }
  );

  return { cvNotes: mutateAsync, isRestoring: isLoading, error };
};

export const useCvDetailsNotes = (cvId: string, enabled: boolean) => {
  const { data, isLoading, refetch, error } = useQuery<CvNotesDetails, Error>(
    getCvNotesCacheKey(cvId),
    () => getCvNotes(cvId),
    { enabled }
  );

  return { notesCvDetails: data, isLoading, refetch, error };
};

export const useVendorDashboardMySubmissions = (userID: string) => {
  const { data, isLoading, refetch, error } = useQuery<VendorDashboardSubmissionsProps[], Error>(
    ['vendorDashboardMySubmissions', userID],
    () => getVendorDashboardSubmissions(userID),
    {
      enabled: !!userID
    }
  );
  return { vendorDashboardMySubmissions: data, isLoading, refetch, error };
};

export const useVendorDashboardSubmissions = (vendorID: string) => {
  const { data, isLoading, refetch, error } = useQuery<VendorCreateQuickSubmissionProps[], Error>(
    ['vendorDashboardSubmissions', vendorID],
    () => getVendorCreateQuickSubmission(vendorID),
    {
      enabled: !!vendorID
    }
  );
  return { vendorDashboardSubmissions: data, isLoading, refetch, error };
};

export const useVendorYourJourney = (vendorId: string) => {
  const { data, isLoading, refetch, error } = useQuery<VendorYourJournetSoFar, Error>(
    ['vendorYourJourney', vendorId],
    () => getVendorYourJourney(vendorId),
    {
      enabled: !!vendorId
    }
  );
  return { VendorYourJourney: data, isLoading, refetch, error };
};

export const useVendorDashboardRequesitions = (vendorID: string) => {
  const { data, isLoading, refetch, error } = useQuery<VendorDashBoardOpenRequisitionsProps[], Error>(
    ['vendoropenReq', vendorID],
    () => getVendorDashboardRequesitions(vendorID),
    {
      enabled: !!vendorID
    }
  );
  return { DashboardOpenRequisitions: data, isLoading, refetch, error };
};

export const useGetVendorsSubmissionCvs = (
  submissionId: string | number | null
) => {
  const { data, isLoading, refetch } = useQuery<
    SubmissionDetailsCvList[],
    Error
  >(
    getVedorSubmissionCvsCacheKey(submissionId),
    () => GetVendorsSubmissionCvs(submissionId),
    { enabled: !!submissionId }
  );
  return { submissonCvsList: data, isLoading, refetch };
};


export const usePublishRequisition = ({
  onError,
  onSuccess,
}: UseSignInOrSignUpProps) => {
  const { mutateAsync, isLoading, error } = useMutation(
    (rfqId: string) => postPublishRequisition(rfqId),
    {
      onError: (error: AxiosError) => onError(error),
      onSuccess: (data: User) => onSuccess(data),
    }
  );

  return { publishRequisition: mutateAsync, isPublish: isLoading, error };
};

export const UsePostNotifyVendor = ({
  onError,
  onSuccess,
}: UseSignInOrSignUpProps) => {
  const { mutateAsync, isLoading, error } = useMutation(
    (data: NotifyAgainModal) => postNotifyVendor(data),
    {
      onError: (error: AxiosError) => onError(error),
      onSuccess: (data, _, context?: { rfqId?: string }) => {
        onSuccess(data);
      },
    }
  );

  return {
    requestedNotifyVendor: mutateAsync,
    isLoading: isLoading,
    error,
  };
};

export const useGetVendorNotifications = (vendorID: string) => {
  const { data, isLoading, refetch, error } = useQuery<VendorNotifacation[], Error>(
    ['vendorDashboardSubmissions', vendorID],
    () => getVendorNotifications(vendorID),
    {
      enabled: !!vendorID
    }
  );
  return { vendorNotificatios: data, isLoading, refetch, error };
};

export const useEmpCvContactDetails = (submissionId: string, vendorId: string) => {
  const { data, isLoading, refetch, error } = useQuery<CvEmpContactDetailsModal, Error>(
    generateEmpCvDetailsCacheKey(submissionId, vendorId),
    () => getEnpCvContactDetails(submissionId, vendorId),
  );

  return { CvEmpContactDetails: data, isLoading, refetch, error };
};

export const useCvOnHold = ({
  onError,
  onSuccess,
}: UseSignInOrSignUpProps) => {
  const { mutateAsync, isLoading, error } = useMutation(
    ({ cvID, status }: { cvID: string; status: string }) =>
      postCvsOnHold(cvID, status),
    {
      onError: (error: AxiosError) => onError(error),
      onSuccess: (data: User) => onSuccess(data),
    }
  );

  return { cvOnHold: mutateAsync, isRestoring: isLoading, error };
};

export const UsepostVendorReadNotification = ({ onError, onSuccess }: UseSignInOrSignUpProps) => {
  const { mutateAsync, isLoading, error } = useMutation((data: VendorReadNotification) => postVendorReadNotification(data),
    {
      onError: (error: AxiosError) => onError(error), 
      onSuccess: (data: User) => {
        onSuccess(data); 
      },
    }
  );

  return { vendorReadNotification: mutateAsync, isLoading, error };
};


export const usePostJd = ({
  onError,
  onSuccess,
}: {
  onError: (err: AxiosError) => void;
  onSuccess: (data: JobDetailsModalProps) => void;
}) => {
  const { mutateAsync, isLoading, error } = useMutation(
    (job_desription: File) => postJdFile(job_desription),
    {
      onError: (error: AxiosError) => onError(error),
      onSuccess: (data: JobDetailsModalProps) => onSuccess(data),
    }
  );

  return { postJdDetails: mutateAsync, isLoading, error };
};

export const useGetLocationDetails = (pin?: string) => {
  const { data, isLoading, refetch, error } = useQuery<ZipCodeDetailsModalProps, Error>(
    generateLocationDetailsCacheKey(pin),
    () => getLocationDetails(pin),
  );

  return {locationDetails: data, isLoading, refetch, error };
};
export const useGetVendorCvData = (userId?: string) => {
  const { data, isLoading, isSuccess, refetch } = useQuery<
    VendorCvsDatabaseApiModel[],
    Error
  >(getVendorCvsDataCacheKey(userId), () => GetVendorCvsData(userId), {
    enabled: !!userId,
  });
  return { vendorCvsData: data, isLoading, isSuccess, refetch };
};

export const useResendMail = ({
  onError,
  onSuccess,
}: {
  onError: (error: AxiosError) => void;
  onSuccess: (data: string) => void;
}) => {
  const { mutateAsync, isLoading, error, isSuccess } = useMutation(
    (cvId:string) => resendMail(cvId),
    {
      onError: (error: AxiosError) => onError(error),
      onSuccess: (data) => {
        const message = data;
        onSuccess(message);
      },
    }
  );
  return { mutateAsync, isLoading, error, isSuccess };
};