import { Box, Grid, styled, TextField, Typography, } from "@mui/material";
import TalentLogo from "../../assets/icons/TalentXchange_White_Icon (1).svg";
import XIcon from '@mui/icons-material/X';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import CopyrightIcon from '@mui/icons-material/Copyright';


type FooterSection = 'Company' | 'Product' | 'Legal';
const FooterDetails: Record<FooterSection, string[]> = {
    Company: ['About', 'Contact Us'],
    Product: ['Features', 'Subscription', 'Support'],
    Legal: ['Privacy Policy', 'Terms & Conditions'],
};

const Footer: React.FC = () => {
    const footerlist: FooterSection[] = ['Company', 'Product', 'Legal'];
    return (
        <>
            <Box sx={{ flexGrow: 1 }}>

                <GridStyled container spacing={2}>
                    <Grid item xs={4}>
                        <Grid sx={{ display: 'Grid', margin: '20px', paddingLeft: '90px' }}>
                            <Box
                                component="img"
                                sx={{
                                    height: 55, flexGrow: 0
                                }}
                                alt="talentXchangeLogo"
                                src={TalentLogo}
                            />
                            <TypographyStyled>
                                Designed as a one-stop hub for both recruiters and vendors, TalentXchange provides a seamless space to post requirements and share hotlists.
                            </TypographyStyled>
                        </Grid>

                    </Grid>
                    <Grid item xs={8} sx={{ right: '15px' }}>
                        <Grid sx={{ display: 'flex', justifyContent: 'space-around', marginRight: '12px', marginTop: '40px', padding: '15px', }}>
                            {footerlist.map((item) => (
                                <Box key={item} >
                                    <TypographyStyled sx={{ fontWeight: '500', fontSize: '20px' }}>{item}</TypographyStyled>
                                    <Grid sx={{ display: 'grid', marginTop: '3px' }}>

                                        {FooterDetails[item].map((details) => (
                                            <TypographyStyled sx={{ marginBottom: '5px' }}>{details}</TypographyStyled>
                                        ))}

                                    </Grid>
                                </Box>

                            )
                            )}
                        </Grid>
                        <Grid >

                        </Grid>
                    </Grid>
                    <Box sx={{ height: '2px', width: '90%', backgroundColor: '#7A7A7A', marginLeft: '75px' }} />
                   
                </GridStyled>
                <GridStyled>
                <Grid sx={{display:'flex',justifyContent:'center',paddingTop:'15px'}}>
                    <XIcon sx={{margin:'10px'}}/>
                    <FacebookIcon sx={{margin:'10px'}} />
                    <InstagramIcon sx={{margin:'10px'}}/>
                    <LinkedInIcon sx={{margin:'10px'}} />
                   </Grid>
                   <Grid sx={{display:'flex', justifyContent:'center', marginLeft:'60px', paddingBottom:'20px'}}>
                   <CopyrightIcon />
                   <TypographyStyled sx={{marginTop:'2px', fontSize:'15px'}}>Copyright. All rights reserved</TypographyStyled>
                   </Grid>         
                </GridStyled>          
            </Box>
        </>
    )
}
const GridStyled = styled(Grid)({
    backgroundColor: '#0D061C',
    color: '#fff',
    fontFamily: '"DM Sans", sans-serif',

})
const TypographyStyled = styled(Typography)({
    width: '250px',
    color: '#fff',
    fontFamily: '"DM Sans", sans-serif',
    fontSize: '16px',
    fontWeight: '200',
    marginTop: '12px',
    letterSpacing: '-0.2px'

})
export default Footer;