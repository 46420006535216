import styled from "@emotion/styled";
import { Box, Tooltip, tooltipClasses, Typography } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined';
import { themeColors } from "core/Theming/theme";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

interface HeaderProps {
    heading: string;
    navigationUrl?: string | -1;
    backButton: boolean;
    backgroundColor: string;
    icon?: string;
    tooltip?: string
}

const PageHeader: React.FC<HeaderProps> = ({ heading, icon, navigationUrl, backButton, backgroundColor, tooltip }) => {
    const navigate = useNavigate();

    const handleBackButtonClick = () => {
        if (typeof navigationUrl === 'string') {
            navigate(navigationUrl);
        } else {
            navigate(-1)
        }
    };

    return (
        <StyledHeader bg={backgroundColor}>
            <StyledBox>
                {backButton && <StyledIcon onClick={handleBackButtonClick} />}
                {icon && <img src={icon} alt={icon} />}
                <Typography variant="h3" color="primary">
                    {heading}
                </Typography>
                {tooltip &&
                    <StyledTooltip PopperProps={{ style: { zIndex: 0 } }} arrow title={tooltip}>
                        <InfoOutlinedIcon color="primary" />
                    </StyledTooltip>}
            </StyledBox>
        </StyledHeader>
    );
};

const StyledTooltip = styled(Tooltip)({
    background: themeColors.white,
    cursor: 'pointer',
    [`& .${tooltipClasses.tooltip}`]: {
        maxWidth: 680,
        left: -50,
        backgroundColor: themeColors.white,
        color: themeColors.black,
        fontSize: 12,
        border: `1px solid ${themeColors.white}`,
        borderRadius: "6px",
        boxShadow: `0 0 0.25rem ${themeColors.black}`,
      }
})

const StyledHeader = styled(Box)<{ bg: string }>(({ bg }) => ({
    display: 'flex',
    alignItems: 'center',
    backgroundColor: bg,
    padding: '15px',
    borderRadius: '8px',
    justifyContent: 'space-between',
}))


const StyledIcon = styled(ArrowBackOutlinedIcon)({
    color: themeColors.purpleDark,
    cursor: 'pointer',
});

const StyledBox = styled(Box)({
    display: "flex",
    alignItems: "center",
    gap: "10px"
});

export default PageHeader;
