import React, { useEffect, useRef, useState, } from 'react';
import { Box, IconButton, Typography, Button } from '@mui/material';

import styled from '@emotion/styled';
import { ReactComponent as JobsIcon } from "../../../assets/images/LandingPage/requ-submission/Settings.svg";

import { ReactComponent as Glass } from "../../../assets/images/LandingPage/requ-submission/glass.svg";
import { ReactComponent as Leftarrow } from "../../../assets/images/LandingPage/requ-submission/Leftarrow.svg";
import { ReactComponent as Rightarrow } from "../../../assets/images/LandingPage/requ-submission/Rightarrow.svg";
import { ReactComponent as Icon1 } from "../../../assets/images/LandingPage/requ-submission/Icon1.svg";
import { ReactComponent as Icon2 } from "../../../assets/images/LandingPage/requ-submission/Icon2.svg";
import { ReactComponent as Icon3 } from "../../../assets/images/LandingPage/requ-submission/Icon3.svg";
import { ReactComponent as Icon4 } from "../../../assets/images/LandingPage/requ-submission/Icon4.svg";
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

const CarouselContainer = styled(Box)`
width:90%;
  display: flex;
  align-items: center;
  overflow: hidden;
  position: relative;
  margin-top:10px;
  background: #160B30;
  margin:auto;
`;

const CarouselWrapper = styled(Box)`
  display: flex;
  overflow-x: scroll;
  scroll-behavior: smooth;
  &::-webkit-scrollbar {
display: none; /* Hide scrollbar */
  }
`;

const CardContainer = styled(Box)`
//   min-width: 300px;
//   height: 200px;
//   margin: 0 10px;
//   background-color: #3b3b98;
//   border-radius: 10px;
//   box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
//   border: 2px solid rgba(255, 255, 255, 0.3); /* Outer Border */
//   padding: 20px;
//   color: white;
  position: relative;
`;

const OuterBorder = styled(Box)`
  position: absolute;
  top: -10px;
  bottom: -10px;
  left: -10px;
  right: -10px;
  border: 2px solid rgba(255, 255, 255, 0.5);
  border-radius: 12px;
`;

const Tag = styled(Box)`
  background-color: #CED9FF;
  border-radius: 20px;
  padding: 3px 6px;
  margin-right: 5px;
`;

const ArrowButton = styled(IconButton)`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;
`;
interface RateInfo {
    bill_rate: string
}
interface RfqDetails {
    experience: string[]; 
    primary_skills: string[]; 
    job_title: string;
    locations: string[]; 
    job_type: string;
    rfq_closing_date: string;
    rate_info: RateInfo;

}
interface RequisitionData {
    rfq_details: RfqDetails;
}

export const Cards = () => {
    const navigate = useNavigate();

    const carouselRef = useRef<HTMLDivElement>(null);
    const [data, setData] = useState<RequisitionData[]>([]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get<RequisitionData[]>(
                    'https://xak9-hweo-h6q9.n7.xano.io/api:ceD068cG:C2C-Backend/c2cLandingPageRequisitionsData'
                );
                setData(response.data);
            } catch (err) {
                
            }
        }
        fetchData();
    }, [])
    const scrollLeft = () => {
        if (carouselRef.current) {
            carouselRef.current.scrollBy({ left: -300, behavior: 'smooth' });
        }
    };
    const handleSignupClick = () => {
        navigate('/signup'); 
    };
    const scrollRight = () => {
        if (carouselRef.current) {
            carouselRef.current.scrollBy({ left: 300, behavior: 'smooth' });
        }
    };
    const formatJobTitle = (jobType: string) => {
        if (!jobType) {
          return "---";
        }
      
        const jobTypeParts = jobType.split(' ').map(part => part.trim());
        
      
        if (jobTypeParts.length <= 1) {
          return jobTypeParts.join(' ');
        } else {
          return `${jobTypeParts.slice(0, 1).join(' ')}...`;
        }
      };
    const formatExperience = (experience: string[]) => {
        if (experience.length === 0) return '0';
        if (experience.length === 2 && !experience[0].includes(',')) {
            const minExp = experience[0].trim();
            const maxExp = experience[1].trim();
            return `${minExp}-${maxExp}`;
        }
        if (experience.length === 1 && experience[0].includes(',')) {
            const expRange = experience[0].split(',');
            const minExp = expRange[0].trim();
            const maxExp = expRange[1].trim();
            return `${minExp}-${maxExp}`;
        }     
        return `${experience[0][0]}-${experience[0][2]}`;
    };
   

    const formatLocations = (locations: string[]) => {
        if (!locations || locations.length === 0 || !locations[0]) {
            return "---";
        }

        const locationParts = locations[0].split(',').map(part => part.trim());

        if (locationParts.length <= 1) {
            return locationParts.join(', ');
        } else {
            return `${locationParts.slice(0, 1).join(', ')}...`;
        }
    };
    const formatJobType = (jobType: string) => {
        if (!jobType) {
          return "---";
        }
      
        const jobTypeParts = jobType.split(' ').map(part => part.trim());
        
      
        if (jobTypeParts.length <= 1) {
          return jobTypeParts.join(' ');
        } else {
          return `${jobTypeParts.slice(0, 2).join(' ')}...`;
        }
      };
      
    const formatDate = (dateString: string) => {
        if (!dateString) return "---";

        const [year, month, day] = dateString.split('-');
        return `${day}-${month}-${year}`;

    };

    const formatBillRate = (billRate: string) => {
        const [rate, frequency] = billRate.split('/'); 
        return { rate: rate.trim(), frequency: frequency ? frequency.trim() : '' };
    };


    return (

        <CarouselContainer>
            <ArrowButton onClick={scrollLeft} style={{ left: '-11px', top: '224px' }}>
                <Rightarrow />
            </ArrowButton>

            <CarouselWrapper ref={carouselRef}>
                {data.map((item, index) => {
                    const { rate, frequency } = formatBillRate(item.rfq_details.rate_info.bill_rate);
                    return (
                        <CardContainer key={index}>
                            <Box sx={{ margin: '0', padding: '0' }}>

                                <JobsIcon width='500px' height='500px' />

                            </Box>
                            {/* <OuterBorder /> */}
                            <Box>
                                <Box sx={{ position: 'absolute', top: '137px', left: '80px' }}>
                                    <Box sx={{ display: 'flex' }}>
                                        <Box sx={{ fontFamily: '"DM Sans", sans-serif' }}>
                                            <Typography color="#fff" variant="h4" textAlign='center' fontWeight='500' fontSize='20px' marginRight="16px"> {formatJobTitle(item.rfq_details.job_title)}</Typography>
                                            <Typography color="#fff" fontWeight='200'> <Icon3 style={{ marginRight: '6px' }} />Experience: {formatExperience(item.rfq_details.experience)} years</Typography>
                                            <Typography color="#fff" fontWeight='200'> <Icon2 style={{ marginRight: '6px' }} />Location: {formatLocations(item.rfq_details.locations)}</Typography>
                                            <Typography color="#fff" fontWeight='200'> <Icon1 style={{ marginRight: '6px'}} />Job Type: {formatJobType(item.rfq_details.job_type)}</Typography>
                                            <Typography color="#fff" fontWeight='200'> <Icon4 style={{ marginRight: '6px' }} />Closing Date:  {formatDate(item.rfq_details.rfq_closing_date)}</Typography>
                                            <Box display="flex" sx={{ marginTop: '16px', width: '200px', overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>
                                                {item.rfq_details.primary_skills.map((skill, idx) => (
                                                    <Tag key={idx}>{skill}</Tag>
                                                ))}
                                            </Box>
                                        </Box>
                                        <Box>
                                            <Box sx={{ position: 'sticky' }}>
                                                <Box sx={{ marginLeft: '6px', marginTop: '37px' }}>
                                                    <Glass width='95' height='90' />
                                                </Box>

                                                <Box sx={{ position: 'absolute', top: '14px', left: '20px', textAlign:'center', justifyContent:'center' }}>
                                                    <Typography variant='h5' fontWeight='400' fontSize='16px' color='#fff'>{rate}
                                                    </Typography>
                                                    <Typography fontWeight='200' textAlign='center' color='#fff'>per {frequency}</Typography>
                                                </Box>
                                            </Box>

                                            <Button variant="contained" style={{ position: 'sticky', backgroundColor: '#5b5bff', width: '90px', height: '30px', margin: '0', padding: '0', bottom: '5px', right: '5px' }} onClick={handleSignupClick}>
                                                View More
                                            </Button>

                                        </Box>
                                    </Box>
                                </Box>
                            </Box>
                        </CardContainer>
                    )
                })}
            </CarouselWrapper>

            <ArrowButton onClick={scrollRight} style={{ right: '-10px', top: '224px' }}>
                <Leftarrow />
            </ArrowButton>
        </CarouselContainer>
    )
}

export default Cards
