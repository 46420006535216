import * as React from "react";
import { styled } from "@mui/material/styles";
import Tooltip, { TooltipProps, tooltipClasses } from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { themeColors } from "core/Theming/theme";
import { useEffect } from "react";

const copyToClipboard = (
  text: string,
  setTooltipOpen: React.Dispatch<React.SetStateAction<boolean>>
) => {
  navigator.clipboard.writeText(text).then(() => {
    setTooltipOpen(true);
    setTimeout(() => {
      setTooltipOpen(false);
    }, 1000);
  });
};

interface ContactDetailsTooltipProps {
  open: boolean;
  onClose: () => void;
  data: {
    employerId: string;
    employerName: string;
    contactNumber: string;
    email: string;
    website: string;
  };
}

const ContactDetailsTooltip: React.FC<ContactDetailsTooltipProps> = ({
  open,
  onClose,
  data,
}) => {
  const [emailCopied, setEmailCopied] = React.useState(false);
  const [websiteCopied, setWebsiteCopied] = React.useState(false);
  const tooltipRef = React.useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        tooltipRef.current &&
        !tooltipRef.current.contains(event.target as Node)
      ) 
        onClose();
    };

    if (open) {
      document.addEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [open, onClose]);

  return (
    <span ref={tooltipRef}>
      <DetailsToolTip
        PopperProps={{
          disablePortal: true,
        }}
        onClose={onClose}
        open={open}
        disableFocusListener
        disableHoverListener
        disableTouchListener
        arrow
        title={
          <React.Fragment>
            <ContactDetailsHeader>
              <p>Employer contact details</p>
            </ContactDetailsHeader>
            <ContactDetailsContent>
              <p>Employer ID: {data.employerId}</p>
              <p>Employer Name: {data.employerName}</p>
              <p>Contact Number: {data.contactNumber}</p>
              <p>
                Email ID:
                <a href={`mailto:${data.email}`} style={{ marginLeft: "5px" }}>
                  {data.email}
                </a>
                <Tooltip
                  title="Copied to clipboard!"
                  open={emailCopied}
                  disableHoverListener
                  disableFocusListener
                  arrow
                >
                  <IconButton
                    size="small"
                    onClick={() => copyToClipboard(data.email, setEmailCopied)}
                    style={{
                      marginLeft: "5px",
                      color: themeColors.DoubleDarkBlue,
                    }}
                  >
                    <ContentCopyIcon fontSize="small" />
                  </IconButton>
                </Tooltip>
              </p>
              <p>
                Website:
                <a
                  href={data.website}
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ marginLeft: "5px" }}
                >
                  {data.website}
                </a>
                <Tooltip
                  title="Copied to clipboard!"
                  open={websiteCopied}
                  disableHoverListener
                  disableFocusListener
                  arrow
                >
                  <IconButton
                    size="small"
                    onClick={() =>
                      copyToClipboard(data.website, setWebsiteCopied)
                    }
                    style={{
                      marginLeft: "5px",
                      color: themeColors.DoubleDarkBlue,
                    }}
                  >
                    <ContentCopyIcon fontSize="small" />
                  </IconButton>
                </Tooltip>
              </p>
            </ContactDetailsContent>
          </React.Fragment>
        }
      >
        <span></span>
      </DetailsToolTip>
    </span>
  );
};

const DetailsToolTip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: themeColors.white,
    padding: 0,
    color: themeColors.grey,
    maxWidth: 400,
    fontSize: theme.typography.pxToRem(12),
    border: `1px solid ${themeColors.purpleLight}`,
    boxShadow: `0px 0px 4px 0px ${themeColors.lightgrey}`,
  },

  [`& .MuiTooltip-arrow`]: {
    left: "8px !important",
    color: themeColors.purpleLight,
    fontSize: "2em",
  },
}));

const ContactDetailsHeader = styled("div")({
  width: "100%",
  borderRadius: "8px 8px 0px 0px",
  height: "28px",
  backgroundColor: themeColors.purpleLight,
  display: "flex",
  alignItems: "center",
  paddingLeft: "3%",
  fontWeight: "800",
  fontSize: "14px",
});

const ContactDetailsContent = styled("div")({
  width: "315px",
  padding: "7px 14px",
  display: "flex",
  alignItems: "baseline",
  flexDirection: "column",
  gap: "8px",
});

export default ContactDetailsTooltip;
