export const ROUTES = {
  root: '/',
  default: '*',
  signin: "/signin",
  signup: "/signup",
  rfrDetails:"/:userType/:routeName/:rfq-id",
  dashboard: '/:userType/dashboard',
  postsignup: '/postsignup',
  myRfrs: '/:userType/myrequisitions',
  profile: '/:userType/profile',
  createRfr: '/:userType/:routeName/create-requisition',
  bidMarketPlace: '/employer/submission-market-place',
  myNetwork: '/:userType/my-network',
  analytics: '/:userType/analytics',
  chats: '/:userType/chats',
  settings: '/:userType/settings',
  authUserCheck: '/auth-user',
  vendorMyNetwork: '/vendor/my-network',
  rpm: '/vendor/rmp',
  mySubmission: '/vendor/my-submissions',
  myCvs: '/vendor/my-cv',
  editRFRDetails: "/edit-requisition/:rfq-id",
  vendorJobDetailPage: '/vendor/rmp/:rmp_id/submission-details',
  submissionDetails: '/employer/submission-market-place/:sumission_id',
  vendorSubmissionDetails: '/vendor/my-submissions/:id/submission-details',
  vendorChats: '/vendor/chats',
  analytic: '/employer/analytics',
};