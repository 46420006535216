import React from "react";
import {
  Box,
  Button,
  Typography,
  Modal,
  styled,
  IconButton,
  Alert,
} from "@mui/material";
import { ReactComponent as Question } from "../../../../../assets/icons/Question.svg";
import { ReactComponent as ClosedCircleIcon } from "../../../../../assets/images/closedCircleIcon.svg";
import CheckIcon from "@mui/icons-material/Check";
import CancelIcon from "@mui/icons-material/Cancel";
import { themeColors } from "core/Theming/theme";
import { VendorModal } from "api/resources/modals";
import { StatusType } from "../PendingSubmissions/PendingSubmissions";

interface InitialModalProps {
  open: boolean;
  handleClose: () => void;
  handleYesClick: () => void;
  status?: string;
  jobTitle?: string;
  vendorName?:string;
  btnName?:string;
  key:string;
  multiModal?:boolean;
}

const InitialModal: React.FC<InitialModalProps> = ({
  open,
  handleClose,
  handleYesClick,
  status,
  jobTitle,
  vendorName,
  btnName,
  key,
  multiModal,
}) => {
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="initial-modal-title"
      key={key}
    >
      <ModalBox>
        <CustomBox>
          <ModalIconButton onClick={handleClose}>
            <ClosedCircleIcon height={"24px"} width={"24px"} />
          </ModalIconButton>
        </CustomBox>
        <Box p={4} pt={1} sx={{ textAlign: "center" }}>
          <Question height={"104px"} />
          <StyledTypographyYes variant="h6">
            Hi, do you want to <b> {btnName}</b>
            <Typography></Typography>
            <b>{status}</b>
            {multiModal ?  "Submission / s from" :""}  <b>{vendorName}</b>
            <br />
            for
            <b> {jobTitle}?</b>
          </StyledTypographyYes>
          <Box style={{ display: "inline-flex", marginTop: "16px" }}>
            <StyledAcceptButton
              colortype="yes"
              onClick={handleYesClick}
              endIcon={<CheckIcon />}
            >
              Yes
            </StyledAcceptButton>
            <StyledAcceptButton
              colortype="no"
              onClick={handleClose}
              endIcon={<CancelIcon />}
            >
              No
            </StyledAcceptButton>
          </Box>
        </Box>
      </ModalBox>
    </Modal>
  );
};



const CustomBox = styled(Box)({
  background: themeColors.primaryLight,
  width: "100%",
  height: "54px",
  borderTopRightRadius: "20px",
  borderTopLeftRadius: "20px",
  position: "relative",
});

const ModalIconButton = styled(IconButton)({
  position: "absolute",
  top: 10,
  right: 10,
  color: themeColors.black,
});

const ModalBox = styled(Box)({
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 480,
  backgroundColor: themeColors.white,
  borderRadius: "20px",
  boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.25)",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
});

const StyledAcceptButton = styled(Button)<{ colortype: "yes" | "no" }>(
  ({ colortype }) => ({
    borderRadius: "10px",
    boxShadow: `0px 0px 4px 0px ${
      colortype === "yes" ? "rgba(52, 168, 83, 0.75)" : "rgba(188, 1, 1, 0.75)"
    }`,
    backgroundColor: colortype === "yes" ? "#34A853" : "rgba(188, 0, 0, 1.00)",
    color: "white",
    "&:hover": {
      backgroundColor: colortype === "yes" ? "#2c8f4b" : "rgba(188, 0, 0, 0.9)",
    },
    display: "flex",
    alignItems: "center",
    marginLeft: colortype === "yes" ? 0 : "10px",
  })
);

const StyledTypographyYes = styled(Typography)({
  fontSize: "16px",
});

export default InitialModal;
