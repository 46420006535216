import React from "react";
import { Box, Grid, Typography } from "@mui/material";
import BidCard from "./BidCard";
import styled from "@emotion/styled";
import MenuIcon from "@mui/icons-material/Menu";
import { useRecentSubmissions } from "pages/hooks";
import { RecentSubmissionModal } from "api/resources/modals";
import { themeColors } from "core/Theming/theme";
import { useSelector } from "react-redux";
import { RootState } from "core/store";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "core/routes";
import Spinner from "components/spinner/Spinner";


const Bidscards: React.FC = () => {

  const navigate=useNavigate()
  const userInfo = useSelector((state: RootState) => state.auth?.userInfo);
  const userID =  userInfo?.user_id;
  const { recentSubmissions, isLoading } = useRecentSubmissions(userID as string);


  if (isLoading) {
    return <Spinner show={true} />;
  }

  return (
    <MainBox>
      <StyledBox>
        <Heading variant="h4"  gutterBottom>
          Recent Submissions
        </Heading>
        <StyledMenuIcon />
      </StyledBox>
      <Linkdiv>
      <Typography variant="h4" onClick={() => navigate(ROUTES.bidMarketPlace)}> <u>ViewAll</u></Typography>
      </Linkdiv>
      <Cardbox>
        <ScrollContainer>
          <Grid container spacing={1} wrap="nowrap">
          {recentSubmissions?.map((result: RecentSubmissionModal, index: number) => {
            
              return (
                <Grid item xs={12} sm={6} md={4} key={index}>
                  <BidCard
                    name={`${result?.result?.general_profile?.first_name?result?.result?.general_profile?.first_name:''} ${result?.result?.general_profile?.last_name?result?.result?.general_profile?.last_name:''}`}
                    bidId={result?.result?.C2C_Vendor_Bid_Id}
                    jobTitle={result?.result?.rfq_details?.job_title}
                    visaCategory={result?.result?.rfq_details?.visa_type}
                    rate={result?.result?.rfq_details?.rate_info?.bill_rate}
                    commission={result?.result?.rfq_details?.rate_info?.commission}
                    rfq_id={result?.result?.rfq_id}
                    status={result?.result?.status}
                  />
                </Grid>
              );
            })}
          </Grid>
        </ScrollContainer>
      </Cardbox>
    </MainBox>
  );
};

const Heading = styled(Typography)({
  color: themeColors.darksecondBlue,
  fontSize: '22px',
});

const StyledMenuIcon = styled(MenuIcon)({
  color: themeColors.primaryDark,
});

const Cardbox = styled(Box)({
  display:'grid',
  gridAutoFlow:'column',
  gridAutoColumns:'100%'
});

const MainBox = styled(Box)({
  borderRadius: "8px",
  border: `1px solid ${themeColors.emptyStar}`,
  marginTop: "20px",
});

const StyledBox = styled(Box)({
  display: "flex",
  justifyContent: "space-between",
  padding: "15px 30px",
  boxShadow:
    "0px 9px 16px rgba(159, 162, 191, .18), 0px 2px 2px rgba(159, 162, 191, 0.32)",
});
const ScrollContainer = styled(Box)`
  display: flex;
  overflow-x: auto;
  white-space: nowrap;
  padding: 20px;

  & > * {
    flex-shrink: 0;
  }

  &::-webkit-scrollbar {
    height: 8px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #ccc;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background-color: ${themeColors.darkGrey};
  }

  &::-webkit-scrollbar-track {
    background-color: ${themeColors.white};
  }
`;

const Linkdiv=styled(Box)({
  display:'flex',
  justifyContent:"flex-end",
  marginRight:"28px",
  marginTop:"10px",
  cursor:'pointer'
})
export default Bidscards;
