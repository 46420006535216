import styled from "@emotion/styled";
import { themeColors } from "core/Theming/theme";

interface ProgressBarProps {
  fillPercentage: number;
  size?: string;
  className?: string;
  smartScore: string;
}

const getScoreColor = (smartScore: string): string => {
  const numericScore = parseInt(smartScore, 10);

  if (isNaN(numericScore)) {
    return themeColors.lightgrey;
  }

  if (numericScore < 50) {
    return themeColors.DoubleRed;
  } else if (numericScore <= 80) {
    return themeColors.DoubleBrown;
  } else {
    return themeColors.shadowDarkGreen;
  }
};

export const CircularProgressBar = styled(
  ({
    className,
    fillPercentage,
    size = "100px",
    smartScore,
    ...props
  }: ProgressBarProps) => {
    const strokeDashOffset = 100 - fillPercentage;
    const strokeWidth = 4;
    const color = getScoreColor(smartScore);
    return (
      <div className={className} {...props}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="-3 -3 38 38"
          width={size}
          height={size}
        >
          <circle
            cx="16"
            cy="16"
            r="15.9155"
            className="circle-background"
            strokeWidth={strokeWidth}
          />
          <circle
            cx="16"
            cy="16"
            r="15.9155"
            className="circle-progress"
            strokeWidth={strokeWidth}
            strokeDasharray="100 100"
            strokeDashoffset={strokeDashOffset}
            strokeLinecap="round"
            stroke={color}
          />
        </svg>
      </div>
    );
  }
)(({}) => ({
  width: "auto",
  height: "auto",
  transform: "rotate(-90deg)",
  [`& .circle-background`]: {
    fill: "none",
    stroke: themeColors.lightgrey,
  },
  [`& .circle-progress`]: {
    fill: "none",
    transition: "stroke-dashoffset 1s ease-in-out",
  },
}));
