import React from 'react';
import { Navigate, Outlet, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from '../core/store/index';
import { ROUTES } from 'core/routes';
import Spinner from 'components/spinner/Spinner';
import CheckUserType from './Registration/authUser/CheckUserType';

export const ProtectedRoute: React.FC = () => {
  const { isAuthenticated, isLoading, userInfo } = useSelector(
    (state: RootState) => state.auth
  );


  const { userType } = useParams<{ userType: string }>();
  const { id } = useParams<{ id: string }>()

  if (isLoading) return <Spinner show={isLoading} />;

  if (!isAuthenticated) {
    return <Navigate to={ROUTES.signin} />;
  }

  if (!userInfo?.general_profile?.first_name && !userInfo?.general_profile?.last_name) {
    return (
      !id ? <Navigate to={ROUTES.postsignup} /> : <Navigate to={`${ROUTES.postsignup}/${id}`} />
    );
  }

  if (userType && userType !== 'employer' && userType !== 'vendor') {
    return <Navigate to={ROUTES.signin} />;
  }

  return <Outlet />;
};
