import React, { useState, ChangeEvent, useRef, useEffect } from "react";
import { Box, Typography, IconButton, LinearProgress, styled, } from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";
import { ReactComponent as PdfIcon } from "../../assets/images/pdfIcon.svg";
import ArticleIcon from '@mui/icons-material/Article';

interface MultipleFileUploadProps {
    onFileChange: (files: string[] | null) => void;
    resetKey?: any;
    noUploadName: string;
}
const MultipleFileUpload: React.FC<MultipleFileUploadProps> = ({ onFileChange, resetKey, noUploadName }) => {
    const [files, setFiles] = useState<File[]>([]);
    const [error, setError] = useState<string | null>(null);
    const [uploadProgress, setUploadProgress] = useState<number[]>([]);
    const [uploading, setUploading] = useState<boolean>(false);
    const fileInputRef = useRef<HTMLInputElement | null>(null);
    const getBase64 = (file: File): Promise<string> => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => {
                const base64String = (reader.result as string).split('base64,')[1];
                resolve(base64String || '');
            };
            reader.onerror = (error) => reject(error);
        })
    };
    const simulateUploadProgress = (index: number) => {
        setUploading(true);
        let progress = 0;
        const interval = setInterval(() => {
            progress += 10;
            setUploadProgress(prev => {
                const newProgress = [...prev];
                newProgress[index] = progress;
                return newProgress;
            });
            if (progress >= 100) {
                clearInterval(interval);
                setUploading(false);
            }
        }, 500);
    };
    const handleFileChange = async (event: ChangeEvent<HTMLInputElement>) => {
        const selectedFiles = event.target.files;
        if (selectedFiles) {
            const newFiles = Array.from(selectedFiles).filter(file => {
                return (
                    file.type === "application/pdf" ||
                    file.type === "application/msword" ||
                    file.type === "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                );
            });
            if (newFiles.length === 0) {
                setError("Please upload PDF or Doc files only.");
                return;
            }
            const newFileBase64Array = await Promise.all(newFiles.map(file => getBase64(file)));
            setFiles(prevFiles => [...prevFiles, ...newFiles]);
            setError(null);
            onFileChange([...newFileBase64Array]);
            newFiles.forEach((_, index) => simulateUploadProgress(files.length + index));
        }
    };
    const handleDrop = async (event: React.DragEvent<HTMLDivElement>) => {
        event.preventDefault();
        const droppedFiles = event.dataTransfer.files;
        if (droppedFiles) {
            const newFiles = Array.from(droppedFiles).filter(file => file.type === "application/pdf");
            if (newFiles.length === 0) {
                setError("Only PDF documents are allowed.");
                return;
            }
            const newFileBase64Array = await Promise.all(newFiles.map(file => getBase64(file)));
            setFiles(prevFiles => [...prevFiles, ...newFiles]);
            setError(null);
            onFileChange([...newFileBase64Array]);
            newFiles.forEach((_, index) => simulateUploadProgress(files.length + index));
        }
    };
    const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
        event.preventDefault();
    };
    const handleRemoveFile = async (index: number) => {
        const updatedFiles = files.filter((_, i) => i !== index);
        setFiles(updatedFiles);
        setUploadProgress(progress => progress.filter((_, i) => i !== index));
        const fileBase64Array = await Promise.all(updatedFiles.map(file => getBase64(file)));
        onFileChange(fileBase64Array);
    };
    useEffect(() => {
        if (resetKey) {
            setFiles([]);
            setUploadProgress([]);
            setUploading(false);
            setError(null);
            if (fileInputRef.current) {
                fileInputRef.current.value = '';
            }
        }
    }, [resetKey]);
    return (
        <MainBox onDrop={handleDrop} onDragOver={handleDragOver}>
            <>
                <Typography variant="body1" mb={2}>
                    <input
                        type="file"
                        accept="application/pdf,application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                        onChange={handleFileChange}
                        style={{ display: "none" }}
                        id="file-upload"
                        ref={fileInputRef}
                        multiple
                    />
                    <label htmlFor="file-upload">
                        <IconButton component="span" color="primary">
                            <img src="/Assets/Images/fileUpload.svg" alt="Upload" />
                        </IconButton>
                    </label>
                    {files.length === 0 && !uploading ? (
                        <Box>
                            <Typography variant="h3">{noUploadName}</Typography>
                            <Typography>Supported format: PDF or Docs of size less than or equal to 2MB</Typography>
                        </Box>
                    ) : (
                        <Box>
                            <Typography variant="h3">Upload more files</Typography>
                        </Box>
                    )}
                </Typography>
            </>
            <>
                {uploading ? (
                    files.map((file, index) => (
                        <UploadingBox key={index}>
                            <UploadingSubBox>
                                {file?.type === "application/pdf" ? <PdfIcon /> : <ArticleIcon color="primary" />}
                                <Typography variant="body2" ml={1}>
                                    {file?.name}
                                </Typography>
                            </UploadingSubBox>
                            <Box flexGrow={1} ml={2}>
                                <LinearProgress
                                    variant="determinate"
                                    value={uploadProgress[index] || 0}
                                />
                            </Box>
                        </UploadingBox>
                    ))
                ) : (
                    files.map((file, index) => (
                        <UploadedBox key={index}>
                            {file.type === "application/pdf" ? <PdfIcon /> : <ArticleIcon color="primary" />}
                            <Typography variant="body1" ml={1}>
                                <a href={URL.createObjectURL(file)} target="_blank" rel="noopener noreferrer">
                                    {file.name}
                                </a>
                            </Typography>
                            <IconButton
                                color="error"
                                onClick={() => handleRemoveFile(index)}
                                sx={{ ml: 1 }}
                            >
                                <CancelIcon />
                            </IconButton>
                        </UploadedBox>
                    ))
                )}
                {error && (
                    <Typography color="error" variant="body2" mt={2}>
                        {error}
                    </Typography>
                )}
            </>
        </MainBox>
    );
};
const MainBox = styled(Box)({
    border: `1px solid grey.400`,
    borderRadius: "8px",
    padding: "16px",
    textAlign: "center",
});
const UploadingBox = styled(Box)({
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
});
const UploadingSubBox = styled(Box)({
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
});
const UploadedBox = styled(Box)({
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
});
export default MultipleFileUpload