import { themeColors } from "core/Theming/theme";
import { HeadingContainer, HeadingTextSx, MainContent, MiddleContainer, } from "../rmp";
import { ReactComponent as RmpHeadericon } from "../../../assets/icons/rmpHeaderIcon.svg";
import { CustomTabPanel } from "../CommonComponents/Tabs";
import { useEffect, useState, useMemo } from "react";
import { ALL_SUBMISSIONS_TAB } from "./constants/VendorRmpDetails";
import MySubmissionTab from "./MySubmissionTab/MySubmissionTab";
import EmployerRequestTab from "./EmployerRequestTab/EmployerRequestTab";
import { SharedTabPannel } from "components/SharedTabPannel";
import { useAppSelector } from "core/store";
import { UseGetVendorEmployerRequests, UseGetVendorSubmission } from "pages/hooks";
import Spinner from "components/spinner/Spinner";

const MySubmissions = () => {
  const [selectedTab, setSelectedTab] = useState(0);
  const userInfo = useAppSelector(store => store?.auth?.userInfo);
  const { vendorSubmissions = [], isLoading: submissionLoading, refetch } = UseGetVendorSubmission(userInfo?.user_id || "");
  const { vendorEmployerRequest = [], isLoading: employerRequestLoading, refetch: refetchEmployer } = UseGetVendorEmployerRequests(userInfo?.user_id || "");
  const [spinner, showSpinner] = useState(false)

  const employerData = useMemo(() => {
    if (selectedTab === 1) {
      const sorted = vendorEmployerRequest?.length > 0 && [...vendorEmployerRequest]?.sort((a, b) => b?.bidStatus?.created_at - a?.bidStatus?.created_at);
      return sorted || [];
    }
    return [];
  }, [selectedTab, vendorEmployerRequest, employerRequestLoading]);

  const submissionData = useMemo(() => {
    if (selectedTab === 0) {
      const sorted = vendorSubmissions?.length > 0 && [...vendorSubmissions]?.sort((a, b) => b?.subimissionDateAndMore?.created_at - a?.subimissionDateAndMore?.created_at);
      return sorted || [];
    }
    return [];
  }, [selectedTab, vendorSubmissions, submissionLoading]);

  const handleTabChange = async (event: React.ChangeEvent<{}>, newValue: number) => {
    setSelectedTab(newValue);
    if (newValue === 0) {
      await refetch();
    } else {
      await refetchEmployer();
    }
  };

  useEffect(() => {
    showSpinner(true)
    employerData
    if (selectedTab === 0) refetch();
    else refetchEmployer();
    setTimeout(() => showSpinner(false), 500)
  }, [selectedTab])

  return (
    <MainContent>
      <HeadingContainer>
        <HeadingTextSx variant="h4" color={themeColors.purpleDark}>
          <RmpHeadericon /> &nbsp; All Submissions
        </HeadingTextSx>
      </HeadingContainer>

      <MiddleContainer>
        <CustomTabPanel
          selectedTab={selectedTab}
          changeTabs={handleTabChange}
          labels={ALL_SUBMISSIONS_TAB}
        />
      </MiddleContainer>
      <Spinner show={spinner}>
        <SharedTabPannel value={selectedTab} index={0}>
          <MySubmissionTab
            key={submissionData?.length}
            spinner={submissionLoading}
            data={submissionData}
            selectedTab={selectedTab}
          />
        </SharedTabPannel>
        <SharedTabPannel value={selectedTab} index={1}>
          <EmployerRequestTab
            key={employerData?.length}
            spinner={employerRequestLoading}
            data={employerData}
            selectedTab={selectedTab}
          />
        </SharedTabPannel>
      </Spinner>
    </MainContent>
  );
};




export default MySubmissions;
