import React, { useEffect, useState } from "react";
import {
  Container,
  Box,
  Tabs as MuiTabs,
  Tab as MuiTab,
  Typography,
  Button,
} from "@mui/material";
import { styled } from "@mui/system";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { TabPanel, a11yProps } from "./components/tabs/Tabspanel";
import Generalprofile from "./components/tabs/Generalprofile";
import { useGetUserIDDetails, usePostCompanyData, useRolesData } from "pages/hooks";
import { PostCompanyDataModal, RolesDataModal, RoleSpecificationModal } from "api/resources/modals";
import { AboutCompany } from "./components/tabs/Companydetails";
import { toast } from "react-toastify";
import { RoleSpecificationComponent } from "./components/tabs/Rolescomponent";
import { useOutletContext } from "react-router-dom";
import { useAppSelector } from "core/store";
import { themeColors } from "core/Theming/theme";

export interface StyledTabProps {
  completed: boolean;
  label: React.ReactNode;
  sx?: any;
  value: any;
  isNextTab: boolean;
}

export const getLabelText = (
  index: number,
  completed: boolean,
  isNextTab: boolean,
  isSelected: boolean,
) => {
  const labels = [
    "General Information",
    "About Company",
    "Role Specifications",
    "KYC Documents",
  ];
  return (
    <>
      {completed ? (
        <StyledCheckCircleIcon />
      ) : (
        <StyledSpan isNextTab={isNextTab} isSelected={isSelected}>
          {isNextTab ? `0${index + 1}` : `0${index + 1}`}
        </StyledSpan>
      )}
      {labels[index]}
    </>
  );
};

const ProfileTab = () => {
  const storedUser = localStorage.getItem("user");
  const user = storedUser ? JSON.parse(storedUser) : null;
  const userId = user?.user_id;

  const {
    userdata,
    refetch: refetchUserData,
    isLoading,
  } = useGetUserIDDetails(userId);

  const [tabStatus, setTabStatus] = useState<{ [key: number]: boolean }>({
    0: false,
    1: false,
    2: false,
  });

  const { postAboutCompanyData } = usePostCompanyData({
    onSuccess: () => {
      toast.success("Submitted Successfully");
      refetchUserData();
    },
    onError: () => toast.error("Something went wrong, please try again"),
  });

  const { postRolesData } = useRolesData({
    onSuccess: () => {
      toast.success("Submitted Successfully");
      refetchUserData();
    },
    onError: () => toast.error("Something went wrong, please try again"),
  });

  const [value, setValue] = React.useState(0);
  const { setCompletionPercentage } = useOutletContext<{ setCompletionPercentage: React.Dispatch<React.SetStateAction<number>> }>();

  useEffect(() => {
    refetchUserData();
  }, []);

  useEffect(() => {
    setTabStatus({
      0: userdata?.general_profile?.first_name !== "",
      1: userdata?.general_profile?.first_name != null,
      2: userdata?.about_company?.company_headquarters !== "" && userdata?.general_profile?.first_name != null,
    });
  }, [userdata]);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    if (tabStatus[newValue]) {
      setValue(newValue);
    }
  };

  const handleNext = () => {
    if (value < 2)
      setValue((prevValue) => {
        const newValue = prevValue + 1;
        return newValue;
      });
  };

  return (
    <Container sx={{ marginTop: "20px" }}>
      <StyledTabBox>
        <Tabs value={value} onChange={handleChange} aria-label="profile tabs">
          {[0, 1, 2].map((index) => (
            <StyledTab
              key={index}
              value={index}
              sx={{ margin: index !== 0 ? "0px 10px" : "0px" }}
              label={getLabelText(
                index,
                index < value,
                index === value + 1,
                index === value
              )}
              {...a11yProps(index)}
              completed={index < value}
              isNextTab={index === value + 1}
              disabled={!tabStatus[index]}
            />
          ))}
        </Tabs>
      </StyledTabBox>
      <StyleButtonBox>
        <Typography variant="h3" component="div" gutterBottom>
          Complete Your Profile
        </Typography>
        <Typography variant="subtitle1">
          {
            value===2?'': <ButtonNext variant="outlined" onClick={handleNext}>
            Next
          </ButtonNext>
          }
        </Typography>
      </StyleButtonBox>
      <TabPanel value={value} index={0}>
        <Generalprofile />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <AboutCompany
          userdata={userdata}
          handleSave={(values) => {
            const payload: PostCompanyDataModal = {
              userID: userId,
              headQuarters: values.headquarters,
              revenue: values.revenue,
              description: values.description,
              size: values.size,
            };
            postAboutCompanyData(payload);
          }}
        />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <RoleSpecificationComponent
          userdata={userdata}
          handleSubmit={(values: RoleSpecificationModal) => {
            const payload: RolesDataModal = {
              userID: userId,
              Aerospace_and_Defense: values.aerospace,
              Agriculture_Forestry_and_Fishing: values.agriculture,
              Automotive_and_Transportation: values.automotive,
              Banking_and_Finance: values.banking,
              Business_and_Management_Services: values.business,
              IT_Computer_and_High_Tech: values.it,
              Education: values.education,
              Professional_Services: values.professionalServices,
              Entertainment_Travel_and_Leisure: values.entertainment,
              Consumer_Services: values.consumerServices,
              other: values.other,
              the_roles_you_are_hiring_for: values.selectedRole,
            };
            postRolesData(payload);
          }}
        />
      </TabPanel>
    </Container>
  );
};

const StyledCheckCircleIcon = styled(CheckCircleIcon)({
  marginRight: "8px",
  backgroundColor: "white",
  borderRadius: "50%",
  color: "green",
});
const StyledTabBox = styled(Box)`
  padding: 10px 28px;
  border: 1px solid ${themeColors.badgeGray};
  border-radius: 12px;
`;

const StyleButtonBox = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 36px 23px 10px 23px;
`;

const ButtonNext = styled(Button)`
  cursor: pointer;
`;

const StyledSpan = styled("span")<{
  isNextTab: boolean;
  isSelected: boolean;
}>(({ isNextTab, isSelected, theme }) => {
  const { isVendor } = useAppSelector((store) => store.auth);
  return {
    display: "inline-block",
    width: "34px",
    height: "32px",
    borderRadius: "50%",
    textAlign: "center",
    lineHeight: "22px",
    marginRight: "8px",
    border: `1px solid ${themeColors.badgeGray}`,
    padding: "5px",
    fontFamily: "Roboto",
    fontWeight: "500",
    backgroundColor: isSelected ? (isVendor ? themeColors.purpleDark : themeColors.primaryDark) : isNextTab ? "" : themeColors.white,
    color: isSelected ? themeColors.white : themeColors.grey,
  };
});

const StyledTab = styled(
  (props: StyledTabProps & React.ComponentProps<typeof MuiTab>) => (
    <MuiTab {...props} />
  )
)(({ completed }) => ({
  minWidth: "120px",
  height: "56px",
  textTransform: "none",
  padding: "15px 12px",
  borderRadius: "8px",
  border: `1px solid ${themeColors.badgeGray}`,
  display: "flex",
  flexDirection: "row",
  backgroundColor: completed ? "" : themeColors.white,
  color: completed ? themeColors.success : themeColors.grey,
  fontFamily: "Roboto",
  fontWeight: "500",
  "& .tab-number": {
    display: "inline-block",
    width: "34px",
    height: "32px",
    borderRadius: "50%",
    color: completed ? themeColors.white : themeColors.grey,
    textAlign: "center",
    lineHeight: "22px",
    marginRight: "8px",
    border: `1px solid ${themeColors.darkGrey}`,
    padding: "5px",
    fontFamily: "Roboto",
    fontWeight: "500",
  },
  "&.Mui-selected": {
    colors: themeColors.white,
    fontFamily: "Roboto",
    fontWeight: "500",
    border: "none",
  },
  "& .MuiTab-wrapper": {
    flexDirection: "row",
    alignItems: "center",
  },
}));

const Tabs = styled(MuiTabs)({
  display: "flex",
  height: "56px",
  "& .MuiTabs-flexContainer": {
    display: "flex",
    height: "56px",
  },
});

export default ProfileTab;
