import styled from "@emotion/styled";
import { Container, Divider, Paper, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { themeColors } from "core/Theming/theme";
import React from "react";

interface VendorDescriptionProps {
    jobTitle: string,
    quickMessage?: string,
    jdIntro?: string,
    jdRoles?: string,
    primarySkills?: string[],
    secondrySkills?: string[],
    minExp?: string,
    maxExp?: string,
    qualifications?: string[]
}

const VendorDescription: React.FC<VendorDescriptionProps> = (props) => {
    const { jobTitle, quickMessage, jdIntro, jdRoles, primarySkills, secondrySkills, minExp, maxExp, qualifications } = props

    return (
        <Container>
            <StyledBox>
                <Typography variant="h3">{jobTitle}</Typography>
                {qualifications?.map((item, index) => (
                    <ToolBadge key={index}>{item}</ToolBadge>
                ))}
                <ToolBadge>{(minExp && maxExp) ? `${minExp} to ${maxExp} Years` : (minExp) ? `${minExp}` : '0'}</ToolBadge>
            </StyledBox>
            <Divider />
            <StyledQuickMessage>
                <StyledHeading>Quick Message for Vendor</StyledHeading>
                <StyledMessageCard height="15vh">
                    <p>{quickMessage || <NoDataFound />}</p>
                </StyledMessageCard>
            </StyledQuickMessage>

            <StyledQuickMessage>
                <StyledHeading>Job Description</StyledHeading>
                <StyledMessageCard height="50vh">
                    <StyledIntroHeading><u>Introduction</u></StyledIntroHeading>
                    <p>{jdIntro || <NoDataFound />}</p>
                    <br />
                    <StyledIntroHeading><u>Roles and Responsibilities</u></StyledIntroHeading>
                    <p>{jdRoles || <NoDataFound />}</p>
                </StyledMessageCard>
            </StyledQuickMessage>
        </Container>
    );
}

const NoDataFound = () => {
    return (
        <StyledNoData>
            <img src="/Assets/Images/sadEmoji.svg" alt="no_data" />
            <Typography>DATA NOT AVAILABLE</Typography>
        </StyledNoData>
    )
}

const StyledNoData = styled('div')({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    gap: '20px'
})

const StyledIntroHeading = styled(Typography)({
    fontSize: '20px',
    marginBottom: '10px'
})

const StyledQuickMessage = styled(Box)({
    marginTop: '25px',
    marginBottom: '20px'
})

const StyledHeading = styled(Typography)({
    color: themeColors.purpleDark,
    fontSize: '20px',
    fontWeight: '450'
})

const StyledMessageCard = styled('div') <{ height?: string }>`
    margin-top: 15px;
    padding: 15px;
    height: ${(props) => props.height || '15px'};
    overflow-y: scroll;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    font-size: 16px;
    border-radius: 10px;
    overflow-x: hidden;
    width: 100%;

    @supports (scrollbar-color: auto) {
        scrollbar-color: ${themeColors.purpleDark} ${themeColors.purpleLight};
    }

    @supports selector(::-webkit-scrollbar) {
        ::-webkit-scrollbar {
            width: 8px;
            height: 8px;
            background: ${themeColors.purpleLight};
        }

        ::-webkit-scrollbar-thumb {
            background: ${themeColors.purpleDark};
            border-radius: 8px;
        }

        ::-webkit-scrollbar-track {
            background: ${themeColors.purpleLight};
        }

        ::-webkit-scrollbar-button {
            background: ${themeColors.black};
            height: 15px;
            width: 15px;
        }

        ::-webkit-scrollbar-button:increment,
        ::-webkit-scrollbar-button:decrement {
            display: block;
            height: 15px;
            width: 15px;
        }

        ::-webkit-scrollbar-button:increment {
            background: ${themeColors.black};
        }

        ::-webkit-scrollbar-button:decrement {
            background: ${themeColors.black}; 
        }

        ::-webkit-scrollbar-button:increment:before,
        ::-webkit-scrollbar-button:decrement:before {
            content: '';
            display: block;
            width: 0;
            height: 0;
            border-style: solid;
        }

        ::-webkit-scrollbar-button:increment:before {
            border-width: 0 5px 5px 5px;
            border-color: ${themeColors.purpleLight} transparent transparent transparent;
            margin-top: 2px;
        }

        ::-webkit-scrollbar-button:decrement:before {
            border-width: 5px 5px 0 5px;
            border-color: transparent transparent ${themeColors.purpleLight} transparent;
            margin-bottom: 2px;
        }
    }
`;

const StyledBox = styled(Box)({
    display: 'flex',
    paddingTop: '5px',
    paddingBottom: '5px',
    flexWrap: 'wrap'
});

const ToolBadge = styled(Paper)({
    backgroundColor: themeColors.badgeGray,
    color: "black",
    boxShadow: "none",
    padding: "4px 8px",
    borderRadius: "9px",
    fontSize: "12px",
    display: "inline-block",
    margin: "4px",
});

export default VendorDescription;
