import store from "core/store";
import {
  checkUserType,
  userLoggedin,
  userLoggedout,
  userLoginInitiated,
} from "core/store/auth";

export const loadUserFromStorage = () => {
  store.dispatch(userLoginInitiated());
  const user = localStorage.getItem("user");
  if (user) store.dispatch(userLoggedin(JSON.parse(user)));
  else store.dispatch(userLoggedout());
};

export const logout = () => {
  localStorage.clear();
  window.location.href = '/'
  store.dispatch(checkUserType(null))
  store.dispatch(userLoggedout());
};
