import React from "react";
import {
  Grid,
  Typography,
  Autocomplete,
  TextField,
  Chip,
  Box,
  Checkbox,
} from "@mui/material";
import { useField, useFormikContext } from "formik";
import CloseIcon from "@mui/icons-material/Close";
import styled from "@emotion/styled";
import { useAppSelector } from "core/store";
import { themeColors } from "core/Theming/theme";

interface DropdownSelectorProps {
  name: string;
  label?: string;
  options: string[];
  placeholder?: string;
  required?: boolean;
}

interface DropdownStyleProps {
  isVendor: boolean | null;
}

const DropdownSelector: React.FC<DropdownSelectorProps> = ({
  name,
  label,
  options,
  placeholder,
  required = false,
}) => {
  const { isVendor } = useAppSelector((store) => store.auth);
  const { setFieldValue } = useFormikContext<string[]>();
  const [field, meta] = useField<string[]>(name);

  const showError = meta.touched && Boolean(meta.error);

  return (
    <Grid item md={12}>
      <Typography variant="h6" mb={1}>
        {label} {required && <RedIcon>*</RedIcon>}
      </Typography>
      <Autocomplete
        multiple
        freeSolo
        id={`${name}-autocomplete`}
        options={options}
        disableCloseOnSelect
        value={Array.isArray(field.value) ? field.value : []} 
        getOptionLabel={(option) => option}
        onChange={(event: React.SyntheticEvent, newValue: string[]) => {
          const filteredValue = newValue.filter((item) => item.trim() !== "");
          setFieldValue(name, filteredValue);
        }}
        filterSelectedOptions={false}
        renderOption={(props, option) => {
          const isSelected = field.value.includes(option);
          return (
            <li {...props}>
              <Checkbox
                checked={isSelected}
                sx={{
                  color: isVendor ? themeColors.purpleDark : themeColors.primaryDark,
                }}
              />
              {option}
            </li>
          );
        }}
        renderTags={(value, getTagProps) => {
          return (
            (Array.isArray(value) ? value : [])
              .filter((option) => option.trim() !== "")
              .map((option, index) => {
                const { key, onDelete, ...otherProps } = getTagProps({ index });

                return (
                  <Chip
                    key={option}
                    label={option}
                    deleteIcon={
                      <CloseIcon sx={{ color: isVendor ? themeColors.purpleDark : themeColors.primaryDark }} />
                    }
                    onDelete={() => {
                      const newValues = field.value.filter((val) => val !== option);
                      setFieldValue(name, newValues);
                    }}
                    {...otherProps}
                    sx={{
                      mr: 1,
                      mt: 1,
                      backgroundColor: isVendor ? themeColors.purpleLight : themeColors.primaryLight,
                      color: isVendor ? themeColors.purpleDark : themeColors.primaryDark,
                    }}
                  />
                );
              })
          );
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            variant="outlined"
            placeholder={placeholder}
            error={showError}
            helperText={showError ? meta.error : ""}
          />
        )}
      />
    </Grid>
  );
};

const RedIcon = styled("span")({
  color: themeColors.error,
});

const DropdownStyle = styled(Box, {
  shouldForwardProp: (prop) => prop !== "isVendor",
})<DropdownStyleProps>(({ isVendor }) => {
  const backgroundColor = isVendor ? themeColors.purpleLight : themeColors.primaryLight;
  const chipColor = isVendor ? themeColors.purpleDark : themeColors.primaryDark;
  return {
    marginTop: "18px",
    width: "100%",
    "& .MuiChip-root": {
      width: "173px",
      borderRadius: "5px",
      marginRight: "5px",
      background: backgroundColor,
      color: chipColor,
      cursor: "pointer",
    },
    "& .MuiChip-deleteIcon": {
      color: chipColor,
    },
  };
});

export default DropdownSelector;
