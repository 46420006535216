import styled from "@emotion/styled";
import { Box } from "@mui/material"
import Allsubmissions from "./Allsubmissions";
import { themeColors } from "core/Theming/theme";

const BidMarketPlace = () => {
    return (
        <MainContainer>
            <Allsubmissions/>
        </MainContainer>
    )
}

const MainContainer = styled(Box)`
width: 100%;,
height:100%,
background-color: ${themeColors.white};
`;

export default BidMarketPlace