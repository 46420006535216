import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import styled from '@emotion/styled';
import { Card, Divider, Grid, Paper, Tooltip } from '@mui/material';
import { themeColors } from 'core/Theming/theme';
import { useVendorDashboardMySubmissions } from 'pages/hooks';
import { useNavigate } from 'react-router-dom';

interface VendorMySubmissionsProps {
  userId: string
}

const VendorMySubmissions: React.FC<VendorMySubmissionsProps> = ({ userId }) => {
  const navigate = useNavigate()
  const { vendorDashboardMySubmissions, error, refetch, isLoading } = useVendorDashboardMySubmissions(userId ? userId : '');

  return (
    <MainContainer>
      <StyledVendorBox>
        <Typography sx={{ fontSize: '24px', fontWeight: 600 }} color={themeColors?.purpleDark}>My Submissions</Typography>
        <Typography sx={{ cursor: 'pointer' }} color={themeColors?.purpleDark} onClick={() => navigate('/vendor/my-submissions')}><b><u>View more</u></b></Typography>
      </StyledVendorBox>
      <ScrollContainer>
        <Grid container spacing={2} wrap="nowrap">
          {isLoading ? <React.Fragment><b>Loading...</b></React.Fragment> :
            <React.Fragment>
              {vendorDashboardMySubmissions?.map((item, index) => (
                <Grid item xs={12} sm={6} md={4} key={index}>
                  <StyledCard>
                    <Typography variant="h3">
                      <Tooltip arrow title={item?.rfq_details?.job_title}>
                        <span style={{ cursor: 'pointer' }}>{item?.rfq_details?.job_title?.length > 20 ? item?.rfq_details?.job_title?.slice(0, 20) + '...' : item?.rfq_details?.job_title}</span>
                      </Tooltip>
                      <Typography color={themeColors?.grayBlack}><b>{item?.subimissionDateAndMore?.C2C_Vendor_Bid_Id}</b></Typography>
                    </Typography>

                    <Typography sx={{ marginBottom: '5px' }} align='center'>
                      <ToolsContainer>
                        <ToolBadge
                          color={item?.subimissionDateAndMore?.status?.toLocaleLowerCase() === 'pending' ? "#845511" : item?.subimissionDateAndMore?.status?.toLocaleLowerCase() === 'accepted' ? "#106430" : "#9E1717"}
                          background={item?.subimissionDateAndMore?.status?.toLocaleLowerCase() === 'pending' ? "#FBFAED" : item?.subimissionDateAndMore?.status?.toLocaleLowerCase() === 'accepted' ? "#EFF9F2" : "#FFF8F8"}
                        >
                          {item?.subimissionDateAndMore?.status?.toLocaleLowerCase() === 'pending' ? <img src="/Assets/Images/vendorDashboardPending.svg" alt="" /> : item?.subimissionDateAndMore?.status?.toLocaleLowerCase() === 'accepted' ? <img src="/Assets/Images/vendorDashboardAccepted.svg" alt="" /> : <img src="/Assets/Images/vendorDashboardRejected.svg" alt="" />}
                          Submission {item?.subimissionDateAndMore?.status &&
                            item.subimissionDateAndMore.status.charAt(0).toUpperCase() + item.subimissionDateAndMore.status.slice(1)
                          }
                        </ToolBadge>
                      </ToolsContainer>
                    </Typography>
                    <Divider />
                    <Box>
                      <StyledSubmissionBox>
                        <Typography><b>Visa Type:</b></Typography>
                        <Typography>
                          {item?.subimissionDateAndMore?.Visa_Type?.join(', ')?.length > 20 ? (
                            <Tooltip title={item?.subimissionDateAndMore?.Visa_Type?.join(', ')}>
                              <span>{item?.subimissionDateAndMore?.Visa_Type?.join(', ').slice(0, 20)}...</span>
                            </Tooltip>
                          ) : (
                            item?.subimissionDateAndMore?.Visa_Type?.join(', ')
                          )}
                        </Typography>
                      </StyledSubmissionBox>
                      <StyledSubmissionBox>
                        <Typography><b>Rate Card:</b></Typography>
                        <Typography>{item?.subimissionDateAndMore?.Bill_Rate}</Typography>
                      </StyledSubmissionBox>
                      <StyledSubmissionBox>
                        <Typography><b>Commission to vendor:</b></Typography>
                        <Typography>{item?.subimissionDateAndMore?.commission}</Typography>
                      </StyledSubmissionBox>
                      <StyledSubmissionBox>
                        <Typography><b>Category:</b></Typography>
                        <Typography>{item?.subimissionDateAndMore?.Employer_Level}</Typography>
                      </StyledSubmissionBox>
                      <StyledSubmissionBox>
                        <Typography><b>Primary Skills:</b></Typography>
                        {item?.rfq_details?.primary_skills?.slice(0, 2).map((tool, index) => (
                          <SkillsToolsContainer>
                            <Tooltip arrow PopperProps={{ style: { zIndex: 0 } }} title={item?.rfq_details?.primary_skills?.slice(2).join(', ')}>
                              <SkillsBadge key={index}
                                color={themeColors?.purpleDark}
                                background={"#F7F3FE"}
                              >
                                {tool}
                              </SkillsBadge>
                            </Tooltip>
                          </SkillsToolsContainer>))}
                        {item?.rfq_details?.primary_skills?.length > 2 && (
                          <Tooltip arrow PopperProps={{ style: { zIndex: 0 } }} title={item?.rfq_details?.primary_skills?.slice(2).join(', ')}>
                            <SkillsBadge sx={{ cursor: 'pointer' }} color={themeColors?.purpleDark}
                              background={"#F7F3FE"}>...</SkillsBadge>
                          </Tooltip>
                        )}
                      </StyledSubmissionBox>
                    </Box>
                  </StyledCard>
                </Grid>
              ))}
            </React.Fragment>
          }
        </Grid>
      </ScrollContainer>
    </MainContainer>
  );
};

const StyledSubmissionBox = styled(Box)({
  display: 'flex',
  flexWrap: 'wrap',
  alignItems: 'flex-start',
  padding: '2px',
  gap: '10px'
})

const StyledVendorBox = styled(Box)({
  padding: '0 38px 0 38px',
  display: 'flex',
  justifyContent: 'space-between'
})

const ScrollContainer = styled(Box)`
  display: flex;
  overflow-x: auto;
  white-space: nowrap;
  padding: 20px;

  & > * {
    flex-shrink: 0;
  }

  &::-webkit-scrollbar {
    height: 8px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: ${themeColors.badgeGray};
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background-color: ${themeColors.grey};
  }

  &::-webkit-scrollbar-track {
    background-color: ${themeColors.white};
  }
`;

const StyledCard = styled(Card)`
  margin: 10px;
  padding-left: 15px;
  padding-right: 16px;
  padding-top: 15px;
  padding-bottom: 20px;
  width: 300px;
  height: 330px;
  border-radius: 10px;
  background-color: white;
  border: 1px solid ${themeColors.purpleLight};
  display: flex;
  flex-direction: column;
  overflow: hidden;
`;

const SkillsToolsContainer = styled(Box)({
  display: "flex",
  flexWrap: "wrap",
  margin: "2px 0",
});

const ToolsContainer = styled(Box)({
  display: "flex",
  flexWrap: "wrap",
  justifyContent: 'center',
  margin: "2px 0",
});

const SkillsBadge = styled(Paper)<{ background: string, color: string }>(({ background, color }) => ({
  background,
  color,
  textAlign: 'center',
  paddingTop: '2px',
  paddingLeft: '20px',
  paddingRight: '20px',
  paddingBottom: '2px',
  fontWeight: 'bold',
}));

const ToolBadge = styled(Paper)<{ background: string, color: string }>(({ background, color }) => ({
  background,
  color,
  textAlign: 'center',
  paddingTop: '2px',
  paddingLeft: '20px',
  paddingRight: '20px',
  paddingBottom: '2px',
  fontWeight: 'bold',
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  gap: '10px'
}));

const MainContainer = styled(Card)({
  flexGrow: 1,
  backgroundColor: themeColors.white,
  width: '100%',
  height: 430,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  overflow: 'hidden',
  position: 'relative',
});

export default VendorMySubmissions