import * as React from 'react';
import { useEffect } from 'react';

import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import "./index.css";
import TalentLogo from "../../assets/icons/TalentXchange_White_Icon (1).svg";
import { useNavigate } from 'react-router-dom';
import Page2 from './Page2';
import Page3 from './page3';
import Contactus from './Navbarcomponents/Contactus'
import VAS from './VAS'
import FAQ from './FAQ'
import TalentXchange from './talentchange'
import Settings from './Navbarcomponents/Index'
import Expage1 from './ExperienceEnhanced/expage1'
import Spinner from 'components/spinner/Spinner';
import Footer from './Footer'


const pages = ['Home', 'Feature', 'Requisitions', "Contact Us"];

export default function Home() {
  const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(null);
  const [currentPage, setCurrentPage] = React.useState<string>('Home');
  const [isLoading, setIsLoading] = React.useState<boolean>(true);

  const homeRef = React.useRef<HTMLDivElement>(null);
  const featureRef = React.useRef<HTMLDivElement>(null);
  const requisitionsRef = React.useRef<HTMLDivElement>(null);
  const contactRef = React.useRef<HTMLDivElement>(null);
  const navigate = useNavigate();
  useEffect(() => {
    const loadData = async () => {
      setIsLoading(true);
      await new Promise((resolve) => setTimeout(resolve, 2000));
      setIsLoading(false);
    };

    loadData();
  }, []);

  if (isLoading) {
    return <Spinner show={isLoading} />;
  }

  const handleLoginClick = () => {
    navigate('/signin');
  };

  const handleSignupClick = () => {
    navigate('/signup');
  };

  const handleNavigate = (page: string) => {
    if (currentPage === page) {
      return;
    }

    setCurrentPage(page);
    switch (page) {
      case 'Home':
        homeRef.current?.scrollIntoView({ behavior: 'smooth' });
        break;
      case 'Feature':
        featureRef.current?.scrollIntoView({ behavior: 'smooth' });
        break;
      case 'Requisitions':
        requisitionsRef.current?.scrollIntoView({ behavior: 'smooth' });
        break;
      case 'Contact Us':
        contactRef.current?.scrollIntoView({ behavior: 'smooth' });
        break;
      default:
        break;
    }
  };
  const getHighlightClass = (page: string) => {
    return currentPage === page ? 'highlight' : '';
  };
  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  return (
    <div>
      <AppBar position="fixed" className='Login-Menu'>
        <Container maxWidth="xl">
          <Toolbar disableGutters >
            <Box
              className='talentxchangelogo'
              component="img"
              sx={{
                height: 55, flexGrow: 0
              }}
              alt="Your logo."
              src={TalentLogo}
            />
            <Box sx={{ flexGrow: 1, display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
              <Box sx={{ display: { xs: 'none', md: 'flex' }, justifyContent: 'flex-end', marginRight: '20px' }}>
                {pages.map((page) => (
                  <Button
                    className={`nav-button ${getHighlightClass(page)}`}
                    key={page}
                    onClick={() => handleNavigate(page)}
                    sx={{ color: 'white', display: 'block', justifyContent: 'right', fontFamily: '"DM Sans", sans-serif', fontSize: '16px', fontWeight: 300 }}
                  >
                    {page}
                  </Button>
                ))}
              </Box>
              <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Button className='loginbutton' onClick={handleLoginClick}>Login</Button>
                <Button className='signup' onClick={handleSignupClick}>SignUp</Button>
              </Box>
            </Box>
          </Toolbar>
        </Container>
      </AppBar>
      <div className="Layout">

        <div ref={homeRef} className={`section ${getHighlightClass('Home')}`}>


          <div className='main-content'>
            <div className='main-text'>
              Transforming <span className='us-talent'>US Talent </span> Engagement,
              Simplified!
            </div>

            <p className='powerdby'>
              Powered by Hiringhood
            </p>
            <h3 className='text'>
              Seamlessly Connect with trusted Vendors
              and get Tailored Requisitions and submissions
            </h3>
          </div>
          <Button className='Getstarted' onClick={handleSignupClick}>Get Started</Button>

        </div>
      </div>
      <Page2 />
      <TalentXchange />
      <div ref={requisitionsRef} className={`section ${getHighlightClass('Requisitions')}`}>
        <Settings />

      </div>
      <Page3 />

      <div ref={featureRef} className={`section ${getHighlightClass('Feature')}`}>
        <Expage1 />

      </div>



      <VAS />

      <div ref={contactRef} className={`section ${getHighlightClass('Contact Us')}`}>
        <Contactus />
      </div>

      <FAQ />
      <Footer />

    </div>
  );

}
