export const EmployerSideNavData = [
  {
    "component": "Dashboard",
    "icon": "/Assets/sidenavIcons/dashboard.svg",
    "url": "/employer/dashboard",
    "selectedIcon": "/Assets/sidenavIcons/ColoredIcons/dashboardBlue.svg"
  },
  {
    "component": "My Requisitions",
    "icon": "/Assets/sidenavIcons/My RFRs.svg",
    "url": "/employer/myrequisitions",
    "selectedIcon": "/Assets/sidenavIcons/ColoredIcons/MyRFRsBlue.svg"
  },
  {
    "component": "All Submissions",
    "icon": "/Assets/sidenavIcons/BidsMarketPlace.svg",
    "url": "/employer/submission-market-place",
    "selectedIcon": "/Assets/sidenavIcons/ColoredIcons/BidsMarketplaceBlue.svg"
  },
  {
    "component": "My Network",
    "icon": "/Assets/sidenavIcons/My Network.svg",
    "url": "/employer/my-network",
    "selectedIcon": "/Assets/sidenavIcons/ColoredIcons/MyNetworkBlue.svg"
  },
  {
    "component": "Analytics",
    "icon": "/Assets/sidenavIcons/Analytics.svg",
    "url": "/employer/analytics",
    "selectedIcon": "/Assets/sidenavIcons/ColoredIcons/AnalyticsBlue.svg"
  },
  {
    "component": "Chats",
    "icon": "/Assets/sidenavIcons/Chats.svg",
    "url": "/employer/chats",
    "selectedIcon": "/Assets/sidenavIcons/ColoredIcons/ChatsBlue.svg"
  },
  {
    "component": "Profile",
    "icon": "/Assets/sidenavIcons/Profile.svg",
    "url": "/employer/profile",
    "selectedIcon": "/Assets/sidenavIcons/ColoredIcons/Profilebarblue.svg"
  },
  {
    "component": "Settings",
    "icon": "/Assets/sidenavIcons/Settings.svg",
    "url": "/employer/settings",
    "selectedIcon": "/Assets/sidenavIcons/ColoredIcons/SettingsBlue.svg"
  }
]