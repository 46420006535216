export const getUserLocationCacheKey = ["locations"];
export const getRolesCacheKey = ["roles"];
export const getRFRVisaTypeCacheKey = ["visatype"];
export const getRFRJobCategoryCacheKey = ["jobCategory"];
export const getRFQlocationCacheKey = ["location"];
export const getRFQsecodaryskillsCacheKey = ["secondaryskills"];
export const getRfqpositonCacheKey = ["positions"];
export const getRFQqualificationCacheKey = ["qualifications"];
export const getRFqjobTypeCacheKey = ["jobs"];
export const getRFqEmployeeLevelCacheKey = ["levels"];
export const getQuickRfqSkillsCacheKey = ["Skills"];
export const getQuickRfqVisatypeCacheKey = ["Visa"];
export const getRecentSubmissionsCacheKey = (userId?: string) => [
  "recentSubmissions",
  userId,
];

export const getJouneryCacheKey = (userId?: string) => [
  "journey",
  userId,
];

export const getRequisitionsAboutToExpireCacheKey = (userId?: string) => [
  "aboutExpireData",
  userId,
];

export const getAllSubmissionsListCacheKey = (empId?: string) => [
  "allSubmissionsList",
  empId,
];

export const getUserIDDetailsCachekey = (userId: string | null) => [
  "userId",
  userId,
];
export const getRfrlistAllCachekey = (userId: string | null) => [
  "userId",
  userId,
];
export const getRFRDetailsCacheKey = (rfqId?: string) => [
  "rfrDetails",
  rfqId,
];
export const getRfqInterestedCacheKey = (rfqId?: string) => [
  "rfrInterested",
  rfqId,
];
export const getRfqNotInterestedCacheKey = (rfqId?: string) => [
  "rfrNotInterested",
  rfqId,
];
export const getRfqAcceptedCacheKey = (rfqId?: string) => [
  "rfrAccepted",
  rfqId,
];
export const getRfqRequestedCVsCacheKey = (rfqId?: string) => [
  "requestedCvs",
  rfqId,
];
export const getRfqRequestedSubmissionsCacheKey = (rfqId?: string) => [
  "requestedSubmissions",
  rfqId,
];
export const getRfqAnalysisCacheKey = (rfqId?: string) => [
  "Analysis",
  rfqId,
];
export const getRfqRejectdCacheKey = (rfqId?: string) => [
  "rfrRejected",
  rfqId,
];
export const getRfqPendingCacheKey = (rfqId?: string) => [
  "rfrPending",
  rfqId,
];
export const getEditrfrCachekey = (rfqId: string | null) => ["editRfr", rfqId];
export const getShareRfrCachekey = (rfqID: string | null) => [
  "shareRfr",
  rfqID,
];

export const getVendorSubmissionCacheKey = (userId?: string | number) => [
  "userId",
  userId,
];

export const getVendorSubmissionEmployerRequestsKey = (userId?: string | number) => [
  "userId",
  userId,
];

export const getCvDetailsCacheKey = (submmissionID?:string) => [
  "cvDetails",
  submmissionID,
];

export const getVendorCvsDataCacheKey = (userId?: string | number) => [
  "userId",
  userId,
];

export const getSubmissionDetailsCacheKey = (SubmissionId?: string) => ["SubmissionDetails",SubmissionId,];
export const getRmpDataAllCacheKey = (vendorId?: string) => ["rmpAll",vendorId,];
export const getRmpIntrestedDataCacheKey = (vendorId?: string) => ["rmpIntrestedData",vendorId,];
export const getRmpNotIntrestedDataCacheKey = (vendorId?: string) => ["rmpNotIntrestedData",vendorId,];
export const getActiveRequisitionsCacheKey = (userId?: string) => ["activeRequisitions",userId,];
export const getVendorWidgetCacheKey = (userId?: string) => ["userId",userId,];
export const getEmployeeWidgetCacheKey = (userId?: string) => ["userId",userId,];
export const getVedorSubmissionCacheKey = (submissionId?: string | number | null) => ["submissionId",submissionId]
export const getVedorSubmissionCvsCacheKey = (submissionId?: string | number | null) => ["submissionId",submissionId]
export const generateCvDetailsCacheKey = (cvId: string, vendorId: string) => ["cvId",cvId,"vendorId",vendorId,];
export const getCvNotesCacheKey = (cvId: string) => ["cvId",cvId]
export const getVendorYourJourneyCacheKey = (vendorId?: string) => ["vendorId",vendorId]
export const getVedorNotificationCacheKey=(vendorID?:string)=>["vendorID",vendorID];
export const generateEmpCvDetailsCacheKey = (submissionId: string, vendorId: string) => ["submissionId",submissionId,"vendorId",vendorId,];
export const generateLocationDetailsCacheKey=(pin?:string)=>["pin",pin];