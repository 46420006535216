import React, { useState } from "react";
import { Modal, Box, Typography } from "@mui/material";
import styled from "@emotion/styled";
import { ReactComponent as CloseIcon } from "../../../../assets/icons/Closed.svg";
import { ReactComponent as Menuarrow } from "../../../../assets/icons/Group 33.svg";
import { ReactComponent as Gmail } from "../../../../assets/icons/E-mail.svg";
import { ReactComponent as Linkdln } from "../../../../assets/icons/Linkdln.svg";
import { ReactComponent as WhatsappIcon } from "../../../../assets/icons/Whatsapp.svg";
import { ReactComponent as More } from "../../../../assets/icons/Moreshare.svg";
import { ReactComponent as Twitter } from "../../../../assets/icons/Twitter.svg";
import { ReactComponent as Facebook } from "../../../../assets/icons/Facebook.svg";
import { ReactComponent as Telegram } from "../../../../assets/icons/Telegram.svg";
import { ReactComponent as Reddit } from "../../../../assets/icons/Reddit.svg";
import { themeColors } from "core/Theming/theme";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import LibraryAddCheckIcon from '@mui/icons-material/LibraryAddCheck';
import {
  LinkedinShareButton,
  WhatsappShareButton,
  EmailShareButton,
  FacebookShareButton,
  TwitterShareButton,
  RedditShareButton,
  TelegramShareButton,
} from "react-share";
import { useShareRfr } from "pages/hooks";
import { toast } from "react-toastify";
import CopyToClipboard from "react-copy-to-clipboard";
import Spinner from "components/spinner/Spinner";

export const ShareModal = ({
  open,
  handleClose,
  shareRfqId,
}: {
  open: boolean;
  handleClose: () => void;
  shareRfqId: string | null;
}) => {
  const { shareLink = { webLink: "" }, isLoading } = useShareRfr(
    shareRfqId as string
  );
  const [openMenu, setOpenMenu] = useState(false);

  const [showCheckIcon,setShowCheckIcon]=useState(false);

  const handleChange = () => {
    setOpenMenu(true);
  };
  const handleInstagramShare = () => {
    setShowCheckIcon(true)
  };

  if (isLoading) {
    return <Spinner show={true} />;
  }

  const handleModalClose = () => {
    setOpenMenu(false); 
    handleClose(); 
    setShowCheckIcon(false)
  };
  return (
    <Modal
      open={open}
      onClose={handleModalClose}
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
    >
      <Box sx={{ ...modalStyle }}>
        <Container>
          <Close onClick={handleModalClose} />
        </Container>
        <CenteredColumn>
          <Questionmark>
            <Menu />
          </Questionmark>
          <CenteredTypography id="modal-description" variant="h4">
            Link Copied Successfully!
          </CenteredTypography>
          <ClipboardUrl>
            <CopyURl variant="h6">{shareLink?.webLink}</CopyURl>

            <CopyToClipboard
              text={shareLink?.webLink}
              onCopy={handleInstagramShare}
            >
             {!showCheckIcon?<CopyIcon />:<CheckCopy/>}
            </CopyToClipboard>
          </ClipboardUrl>
          <CenteredText id="modal-description" variant="h6">
            Share it with your network
          </CenteredText>
          {!openMenu ? (
            <>
              <StyledContainer>
                <EmailShareButton url={shareLink?.webLink}>
                  <GmailIcon />
                </EmailShareButton>
                <LinkedinShareButton url={shareLink?.webLink}>
                  <LinkdlnIcon />
                </LinkedinShareButton>
                <WhatsappShareButton url={shareLink?.webLink}>
                  <Whatsapp />
                </WhatsappShareButton>
                <ShareIcon onClick={handleChange} />
              </StyledContainer>
            </>
          ) : (
            ""
          )}

          {openMenu ? (
            <>
              <StyledContainers>
                <EmailShareButton url={shareLink?.webLink}>
                  <GmailIcon />
                </EmailShareButton>
                <LinkedinShareButton url={shareLink?.webLink}>
                  <LinkdlnIcon />
                </LinkedinShareButton>
                <WhatsappShareButton url={shareLink?.webLink}>
                  <Whatsapp />
                </WhatsappShareButton>
                <FacebookShareButton url={shareLink?.webLink}>
                  <FacebookIcon />
                </FacebookShareButton>
                <TwitterShareButton url={shareLink?.webLink}>
                  <Twitterx />
                </TwitterShareButton>
                <RedditShareButton url={shareLink?.webLink}>
                  <RedditIcon />
                </RedditShareButton>
                <TelegramShareButton url={shareLink?.webLink}>
                  <TelegramIcon />
                </TelegramShareButton>
              </StyledContainers>
            </>
          ) : (
            ""
          )}
        </CenteredColumn>
      </Box>
    </Modal>
  );
};

const ClipboardUrl = styled(Box)({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  border: `1px solid ${themeColors.primaryLightDark}`,
  borderRadius: "8px",
  width: "500px",
  marginTop: "16px",
  padding: "8px",
  marginLeft: "48px",
  boxShadow: `0px 0px 4px 0px ${themeColors.shadowColor}`,
});

const CopyIcon = styled(ContentCopyIcon)({
  cursor: "pointer",
  color: themeColors.blackGray,
  marginLeft: "5px",
});

const CheckCopy=styled(LibraryAddCheckIcon)({
  cursor: "pointer",
  color: themeColors.success,
  marginLeft: "5px",
})
const Twitterx = styled(Twitter)({
  cursor: "pointer",
});

const FacebookIcon = styled(Facebook)({
  cursor: "pointer",
});
const GmailIcon = styled(Gmail)({
  cursor: "pointer",
});

const RedditIcon = styled(Reddit)({
  cursor: "pointer",
});

const TelegramIcon = styled(Telegram)({
  cursor: "pointer",
});
const ShareIcon = styled(More)({
  cursor: "pointer",
});
const LinkdlnIcon = styled(Linkdln)({
  marginRight: "10px",
  marginLeft: "10px",
  cursor: "pointer",
});
const Whatsapp = styled(WhatsappIcon)({
  marginRight: "10px",
  marginLeft: "10px",
  cursor: "pointer",
});

const CenteredTypography = styled(Typography)({
  marginTop: "20px",
  alignItems: "center",
  textAlign: "center",
  color: themeColors.black,
});
const CenteredText = styled(Typography)({
  marginTop: "15px",
  alignItems: "center",
  textAlign: "center",
  color: themeColors.blackGray,
});

const CopyURl = styled(Typography)({
  color: themeColors.blackGray,
});
const StyledContainer = styled(Box)({
  marginTop: "20px",
  display: "flex",
  justifyContent: "center",
  borderRadius: "20px",
  padding: "10px",
});

const StyledContainers = styled(Box)({
  marginTop: "20px",
  display: "flex",
  flexWrap: "wrap",
  justifyContent: "space-evenly",
  gap: "10px",
  borderRadius: "20px",
  padding: "20px",
  maxHeight: "150px",
  overflowY: "auto",
});

const Container = styled(`div`)({
  display: "flex",
  justifyContent: "flex-end",
  backgroundColor: themeColors.primaryLight,
  Padding: "8px",
  borderTopLeftRadius: "14px",
  borderTopRightRadius: "14px",
  borderBottomRadius: "3px",
  padding: "8px",
});

const Questionmark = styled(`div`)({
  display: "flex",
  justifyContent: "center",
  marginTop:'16px'
});

const Menu = styled(Menuarrow)({
  height: "80px",
  width: "80px",
  cursor: "pointer",
});

const CenteredColumn = styled(`div`)({
  display: "flex",
  justifyContent: "center",
  flexDirection: "column",
});

const Close = styled(CloseIcon)({
  height: "30px",
  width: "30px",
  cursor: "pointer",
});

const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  bgcolor: "background.paper",
  boxShadow: 24,
borderRadius: "14px",
  padding:'0px 0px 20px 0px '
};
