import React, { useState } from "react";
import { Modal, Box, Typography } from "@mui/material";
import styled from "@emotion/styled";
import { themeColors } from "core/Theming/theme";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import LibraryAddCheckIcon from "@mui/icons-material/LibraryAddCheck";
import { ReactComponent as CloseIcon } from "../../../assets/icons/Closed.svg";
import { useCvContactDetails, useShareRfr } from "pages/hooks";
import { toast } from "react-toastify";
import CopyToClipboard from "react-copy-to-clipboard";
import Spinner from "components/spinner/Spinner";
import { ReactComponent as Menuarrow } from "../../../assets/icons/Group 33.svg";

export const Vendordetails = ({
  open,
  handleClose,
  cvId,
  vendorId,
}: {
  open: boolean;
  handleClose: () => void;
  cvId?: string | undefined;
  vendorId?: string | undefined;
}) => {
  const { CvContactDetails, isLoading, error } = useCvContactDetails(
    cvId as string,
    vendorId as string,
    open
  );
  const [showCheckIcon, setShowCheckIcon] = useState(false);

  const [showsiteIcon, setSiteIcon] = useState(false);

  const handleInstagramShare = () => {
    setShowCheckIcon(true);
  };

  const handlesiteClick = () => {
    setSiteIcon(true);
  };

  if (isLoading) {
    return <Spinner show={true} />;
  }

  const handleModalClose = () => {
    handleClose();
    setShowCheckIcon(false);
    setSiteIcon(false);
  };

  return (
    <Modal
      open={open}
      onClose={handleModalClose}
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
    >
      <Box sx={{ ...modalStyle }}>
        <Container>
          <Close onClick={handleModalClose} />
        </Container>
        <CenteredColumn>
          <Questionmark>
            <Menu />
          </Questionmark>
          <CenteredTypography id="modal-description" variant="h4">
            You unlocked Vendor contact details!
          </CenteredTypography>
          <FirstContainer>
            <Details>
              <Heading>Vendor ID :</Heading>
              <Texts>{CvContactDetails?.user_id}</Texts>
            </Details>
            <Details>
              <Heading>Company Name :</Heading>
              <Text>{CvContactDetails?.general_profile?.company_name}</Text>
            </Details>
            <Details>
              <Heading>Contact Number :</Heading>
              <Text>{CvContactDetails?.general_profile?.phone_number}</Text>
            </Details>
          </FirstContainer>
          <SeconContainer>
            <Details>
              <Heading>Email ID :</Heading>
              <ClipboardUrl>
                <Link variant="h6">{CvContactDetails?.email}</Link>

                <CopyToClipboard
                  text={CvContactDetails?.email ? CvContactDetails?.email : ""}
                  onCopy={handleInstagramShare}
                >
                  {!showCheckIcon ? <CopyIcon /> : <CheckCopy />}
                </CopyToClipboard>
              </ClipboardUrl>
            </Details>
            <Details>
              <Heading>Website :</Heading>
              <ClipboardUrl>
                <Link variant="h6">
                  {CvContactDetails?.general_profile?.website_link}
                </Link>

                <CopyToClipboard
                  text={
                    CvContactDetails?.general_profile?.website_link
                      ? CvContactDetails?.general_profile?.website_link
                      : ""
                  }
                  onCopy={handlesiteClick}
                >
                  {!showsiteIcon ? <CopyIcon /> : <CheckCopy />}
                </CopyToClipboard>
              </ClipboardUrl>
            </Details>
          </SeconContainer>
        </CenteredColumn>
      </Box>
    </Modal>
  );
};

const Menu = styled(Menuarrow)({
  height: "80px",
  width: "80px",
  cursor: "pointer",
  marginTop: "16px",
});
const FirstContainer = styled(Box)({
  display: "flex",
  justifyContent: "flex-start",
  alignItems: "flex-start",
  flexDirection: "column",
  padding: "0px 100px",
});

const SeconContainer = styled(Box)({
  display: "flex",
  justifyContent: "flex-start",
  alignItems: "flex-start",
  flexDirection: "column",
  padding: "0px 0px 0px 100px",
});

const Link = styled(Typography)({
  color: themeColors.primaryDark,
  textDecoration: "underline",
  fontSize: "14px",
  fontWeight: "400",
});
const Details = styled(Box)({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  textAlign: "center",
  marginBottom: "16px",
});

const Heading = styled(Typography)({
  color: themeColors.black,
  textTransform: "capitalize",
  fontSize: "14px",
  fontWeight: "600",
});

const Text = styled(Typography)({
  color: themeColors.grey,
  marginLeft: "6px",
  fontSize: "14px",
  fontWeight: "400",
  textTransform: "capitalize",
});
const Texts = styled(Typography)({
  color: themeColors.grey,
  marginLeft: "6px",
  fontSize: "14px",
  fontWeight: "400",
  textTransform: "capitalize",
  textDecoration: "underline",
});

const ClipboardUrl = styled(Box)({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
});

const CopyIcon = styled(ContentCopyIcon)({
  cursor: "pointer",
  color: themeColors.blackGray,
  marginLeft: "5px",
});

const CheckCopy = styled(LibraryAddCheckIcon)({
  cursor: "pointer",
  color: themeColors.success,
  marginLeft: "5px",
});

const CenteredTypography = styled(Typography)({
  marginTop: "20px",
  alignItems: "center",
  textAlign: "center",
  color: themeColors.black,
  marginBottom: "25px",
});

const Close = styled(CloseIcon)({
  height: "30px",
  width: "30px",
  cursor: "pointer",
});

const Container = styled(`div`)({
  display: "flex",
  justifyContent: "flex-end",
  alignItems: "center",
  textAlign: "center",
  backgroundColor: `${themeColors.primaryLight} !important`,
  padding: "10px",
  borderTopRightRadius: "10px",
  borderTopLeftRadius: "10px",
});

const Questionmark = styled(`div`)({
  display: "flex",
  justifyContent: "center",
});

const CenteredColumn = styled(`div`)({
  display: "flex",
  justifyContent: "center",
  flexDirection: "column",
});

const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 477,
  bgcolor: "background.paper",
  boxShadow: 24,
  borderRadius: "12px",
};
