import React from 'react';
import { Card, CardContent, Grid, Typography, Button, styled } from '@mui/material';
import { themeColors } from 'core/Theming/theme';
import { ReactComponent as AcceptIcon } from "../../../../assets/images/icons/acceptIcon.svg";
import { ReactComponent as RejectIcon } from "../../../../assets/images/icons/rejectIcon.svg";

type KeyValuePair = {
    key: string;
    value: string | string[];
};

interface ReusableCardProps {
    header: string;
    SubmissionId: string;
    leftKeyValuePairs: KeyValuePair[];
    rightKeyValuePairs: KeyValuePair[];
    leftButtonLabel: string;
    rightButtonLabel: string;
    onLeftButtonClick: () => void;
    onRightButtonClick: () => void;
    onSubmissionIdClick: () => void; // New prop for handling Submission ID click
}

const StyledCard = styled(Card)(({ theme }) => ({
    width: '100%',
    padding: theme.spacing(2),
    paddingBottom: "0px",
    marginBottom: theme.spacing(3),
    marginTop: theme.spacing(3),
    boxShadow: '0px 0px 4px 0px rgba(77, 108, 217, 0.75)',
}));

const Header = styled(Typography)(({ theme }) => ({
    fontWeight: theme.typography.fontWeightBold,
    marginBottom: theme.spacing(2),
}));

const KeyValueGrid = styled(Grid)(({ theme }) => ({
    marginBottom: theme.spacing(2),
}));

const ButtonContainer = styled(Grid)(({ theme }) => ({
    marginTop: theme.spacing(2),
    display: 'flex',
    justifyContent: 'flex-end',
    gap: theme.spacing(2),
}));

const KeyValueColumn: React.FC<{ keyValuePairs: KeyValuePair[] }> = ({ keyValuePairs }) => (
    <Grid item xs={6}>
        {keyValuePairs.map(({ key, value }, index) => (
            <KeyValueGrid container key={index}>
                <Grid item xs={4}>
                    <Typography variant="h4" sx={{ fontSize: "14px" }} color={themeColors.primaryDark}>
                        {key}
                    </Typography>
                </Grid>
                <Grid item xs={8}>
                    <Typography variant="body2">{value}</Typography>
                </Grid>
            </KeyValueGrid>
        ))}
    </Grid>
);

const ReusableCard: React.FC<ReusableCardProps> = ({
    header,
    leftKeyValuePairs,
    rightKeyValuePairs,
    leftButtonLabel,
    rightButtonLabel,
    SubmissionId,
    onLeftButtonClick,
    onRightButtonClick,
    onSubmissionIdClick, 
}) => (
    <StyledCard>
        <CardContent>
            <Header variant="h3">
                {header}:
                <Typography
                    component="span"
                    sx={{ cursor: 'pointer', textDecoration: 'underline', color: themeColors.primaryDark ,marginLeft:"4px"}}
                    onClick={onSubmissionIdClick}
                    variant="h3"
                >
                    {SubmissionId}
                </Typography>
            </Header>
            <Grid container spacing={2}>
                <KeyValueColumn keyValuePairs={leftKeyValuePairs} />
                <KeyValueColumn keyValuePairs={rightKeyValuePairs} />
            </Grid>
            <ButtonContainer container>
                <Button variant="contained" sx={{ backgroundColor: themeColors.lightGreen, color: '#fff' }} onClick={onLeftButtonClick} endIcon={<AcceptIcon />}>
                    {leftButtonLabel}
                </Button>
                <Button variant="contained" sx={{ backgroundColor: themeColors.reject, color: '#fff' }} onClick={onRightButtonClick} endIcon={<RejectIcon />}>
                    {rightButtonLabel}
                </Button>
            </ButtonContainer>
        </CardContent>
    </StyledCard>
);

export default ReusableCard;
