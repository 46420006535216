import React from "react";
import { Card, CardContent, Typography, Button, Box, Tooltip } from "@mui/material";
import styled from "@emotion/styled";
import ClockIcon from "../../../../../assets/images/clockiconforjobscards.png";
import JobsIcon from "../../../../../assets/images/Jobsicon.png";
import TestingIcon from "../../../../../assets/images/Testing.png";
import LocationIcon from "../../../../../assets/images/locationpoint.png";
import Midclock from "../../../../../assets/icons/Time/MidTime.svg";
import Rejectclock from "../../../../../assets/icons/Time/RejectTime.svg";
import Clock from "../../../../../assets/icons/Time/Time.svg";
import { themeColors } from "core/Theming/theme";
import { useNavigate } from "react-router-dom";

interface JobCardProps {
  title: string;
  experience: string[];
  locations: string[];
  skills: string[];
  daysLeft: number;
  newQuotations: number;
  reviewedQuotations: number;
  rfqId:string
}
const JobCard: React.FC<JobCardProps> = ({
  title,
  experience,
  locations,
  skills,
  daysLeft,
  newQuotations,
  reviewedQuotations,
  rfqId
}) => {

  const navigate=useNavigate()

  const calculateDaysLeft = (dateString: string): number => {
    const currentDate = new Date(); 
    const targetDate = new Date(dateString); 
    const differenceInMs = targetDate.getTime() - currentDate.getTime();
    const daysLeft = Math.ceil(differenceInMs / (1000 * 60 * 60 * 24));
    return daysLeft;
  };
  const daysRemaining = calculateDaysLeft(JSON.stringify(daysLeft));
  const cities = locations.map((location) => {
    return location
  })

  const skill=skills.map((skills,index) => {
    return index < skills.length - 1 ? `${skills},`: `${skills}`
  });
  
  return (
    <StyledCard>
      <Header>
      <Tooltip arrow title={title}>
      <span style={{ cursor: 'pointer' }}> <Tilte variant="h4">{title.length > 20 ? title?.slice(0, 20) + '...' : title}</Tilte></span>
      </Tooltip>
        {
          daysRemaining <=20 &&  daysRemaining >=10 ?(
            <Headers>
            {" "}
            <img src={Midclock} alt="clock" /> {daysRemaining} Days left
          </Headers>
          ):daysRemaining <10 ?( <HeaderContainer>
            {" "}
            <img src={Rejectclock} alt="clock" /> {daysRemaining} Days left
          </HeaderContainer>):(
             <HeaderText>
             {" "}
             <img src={Clock} alt="clock" /> {daysRemaining} Days left
           </HeaderText>
          )
        }
      </Header>
      <CardContenter>
        <IconContainer>
          <Infotextstyle>
            <StyledImg src={JobsIcon} /> {experience[0] ? experience[0].replace(",", " to ") : ""} yrs experience
          </Infotextstyle>
        </IconContainer>
        <IconContainer>
          <Infotextstyle>
            <StyledImg src={LocationIcon} /> {cities}
          </Infotextstyle>
        </IconContainer>
        <IconContainer>
          <Infotextstyle>
            <StyledImg src={TestingIcon} /> {skill}
          </Infotextstyle>
        </IconContainer>
      </CardContenter>
      <StyleButton variant="contained" color="primary" fullWidth  
      onClick={() => navigate(`/employer/myrequisitions/${rfqId}`)}>
        View Job
      </StyleButton>
      <Footer>
        <StyledBox>
          <StyledTypography variant="h3">{newQuotations}</StyledTypography>
          <SmallTypography>
            New <br /> Quotations
          </SmallTypography>
        </StyledBox>
        <StyledBox>
          <StyledTypography variant="h3">{reviewedQuotations}</StyledTypography>
          <SmallTypography>
            Reviewed <br /> Quotations
          </SmallTypography>
        </StyledBox>
        <StyledBox>
          <StyledTypography variant="h3">8</StyledTypography>
          <SmallTypography>
            Chats Initiated <br /> with Vendors
          </SmallTypography>
        </StyledBox>
      </Footer>
    </StyledCard>
  );
};

const Tilte=styled(Typography)({
  color:themeColors.black,
  textTransform:'capitalize',
  fontSize:'20px'
});
const Header = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
const HeaderText = styled(Typography)`
  border-radius: 15px;
  background: ${themeColors.primaryLight};
  color: ${themeColors.darksecondBlue};
  padding: 5px 10px;
  align-item: center;
  text-align: center;
  font-weight: 600;
  gap: 8px;
  width: 183px;
  display: flex;
  justify-content: center;
  margin-top: 16px;
`;

const Headers=styled(Typography)`
border-radius: 15px;
background: ${themeColors.lightYellows};
color: ${themeColors.darkYellows};
padding: 5px 10px;
align-item: center;
text-align: center;
font-weight: 600;
gap: 8px;
width: 183px;
display: flex;
justify-content: center;
margin-top: 16px;
`;

const HeaderContainer=styled(Typography)`
border-radius: 15px;
background: ${themeColors.lightReject};
color: ${themeColors.darkReject};
padding: 5px 10px;
align-item: center;
text-align: center;
font-weight: 600;
gap: 8px;
width: 183px;
display: flex;
justify-content: center;
margin-top: 16px;
`;

const IconContainer = styled(Box)`
  display: flex;
  align-items: center;
  margin-top: 8px;
`;

const InfoText = styled(Typography)`
  display: flex;
  align-items: center;
  margin-right: 8px;
  font-size: 14px;
`;

const Footer = styled(Box)`
  display: flex;
`;
const CardContenter = styled(CardContent)`
  display: flex;
  flex-direction: column;
  padding: 10px;
`;
const Infotextstyle = styled(InfoText)`
  font-size: 12px;
  color: ${themeColors.blueShade};
`;
const StyledImg = styled.img`
  margin-right: 5px;
`;

const StyleButton = styled(Button)`
  margin-bottom: 16px;
  border-radius: 4px;
  height: 36px;
`;
const StyledCard = styled(Card)`
  margin: 10px;
  padding: 10px 20px 30px 20px;
  width: 280px;
  height: 340px;
  border-radius: 10px;
  box-shadow: box-shadow: 0px 0px 4px 0px ${themeColors.primaryDark};
  border: 1px solid ${themeColors.primaryDark};
  alignitem: center;
  text-align: center;
`;
const StyledBox = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  border: 1px solid ${themeColors.primaryLight};
  background: ${themeColors.primaryLight};
  color: ${themeColors.primaryDark}
  border-radius: 4px;
  padding: 4px 10px;
  margin-right: 7px;
`;

const StyledTypography = styled(Typography)`
  color: ${themeColors.primaryDark}
`;

const SmallTypography = styled(Typography)`
  font-size: 10px;
  color: ${themeColors.primaryDark}
`;
export default JobCard;
