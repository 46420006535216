import React, { useMemo, useState } from "react";
import {
  Box,
  Button,
  Checkbox,
  Divider as MuiDivider,
  FormControlLabel,
  Grid,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import styled from "@emotion/styled";
import * as Yup from "yup";
import { Formik, Form, FormikHelpers, ErrorMessage } from "formik";
import { useFetchGetRoles } from "pages/hooks";
import { RoleSpecificationModal, User } from "api/resources/modals";
import DropdownSelector from "components/Multiselectdropdowns";
import Spinner from "components/spinner/Spinner";
import { themeColors } from "core/Theming/theme";

interface RoleSpecificationProps {
  userdata?: User;
  handleSubmit: (
    values: RoleSpecificationModal,
    actions: FormikHelpers<RoleSpecificationModal>
  ) => void;
}

const validationSchema = Yup.object()
  .shape({
    aerospace: Yup.boolean(),
    banking: Yup.boolean(),
    education: Yup.boolean(),
    consumerServices: Yup.boolean(),
    agriculture: Yup.boolean(),
    business: Yup.boolean(),
    professionalServices: Yup.boolean(),
    automotive: Yup.boolean(),
    it: Yup.boolean(),
    entertainment: Yup.boolean(),
  })
  .test("at-least-one", "Select at least one industry", (value) =>
    Object.values(value).some(Boolean)
  );
export const RoleSpecificationComponent: React.FC<RoleSpecificationProps> = ({
  userdata,
  handleSubmit,
}) => {
  const { roles = [], isLoading } = useFetchGetRoles();
  const [open, isOpen] = useState<boolean>(false);
  const handleCheck = () => {
    isOpen(!open);
  };

  const rolesData = useMemo(() => {
    return roles.map((role) => role.role);
  }, [roles]);

  return (
    <Spinner show={isLoading}>
      <Formik
        initialValues={{
          aerospace:
            userdata?.role_specifications?.Aerospace_and_Defense || false,
          banking: userdata?.role_specifications?.Banking_and_Finance || false,
          education: userdata?.role_specifications?.Education || false,
          consumerServices:
            userdata?.role_specifications?.Consumer_Services || false,
          agriculture:
            userdata?.role_specifications?.Agriculture_Forestry_and_Fishing ||
            false,
          business:
            userdata?.role_specifications?.Business_and_Management_Services ||
            false,
          professionalServices:
            userdata?.role_specifications?.Professional_Services || false,
          automotive:
            userdata?.role_specifications?.Automotive_and_Transportation ||
            false,
          it: userdata?.role_specifications?.IT_Computer_and_High_Tech || false,
          entertainment:
            userdata?.role_specifications?.Entertainment_Travel_and_Leisure ||
            false,
          other: userdata?.role_specifications?.other || "",
          selectedRole:
            (userdata?.role_specifications?.role_hiring_for as []) ?? [],
        }}
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
      >
        {({ values, handleChange, handleSubmit }) => {
          const isAnyCheckboxChecked = Object.values(values)
            .slice(0, 10)
            .some(Boolean);
          return (
            <>
              <Form onSubmit={handleSubmit}>
                <Box>
                  <StyledPaper variant="outlined">
                    <StyledBox>
                      <Typography variant="h4">
                        {" "}
                        Roles Specifications
                      </Typography>
                    </StyledBox>
                    <Box mt={2}>
                      <Grid container spacing={2}>
                        <Grid item md={10}>
                          <Typography variant="h6" mb={2}>
                            Select all the industries you are hiring for? *
                          </Typography>
                          <Grid container spacing={1}>
                            <SelectCheckbox>
                              <Box>
                                <CheckboxMain>
                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        checked={values.aerospace}
                                        onChange={handleChange}
                                        name="aerospace"
                                      />
                                    }
                                    label="Aerospace and Defence"
                                  />
                                </CheckboxMain>
                                <CheckboxMain>
                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        checked={values.banking}
                                        onChange={handleChange}
                                        name="banking"
                                      />
                                    }
                                    label="Banking and Finance"
                                  />
                                </CheckboxMain>
                                <CheckboxMain>
                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        checked={values.education}
                                        onChange={handleChange}
                                        name="education"
                                      />
                                    }
                                    label="Education"
                                  />
                                </CheckboxMain>
                                <CheckboxMain>
                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        checked={values.consumerServices}
                                        onChange={handleChange}
                                        name="consumerServices"
                                      />
                                    }
                                    label="Consumer Services"
                                  />
                                </CheckboxMain>
                              </Box>
                              <Box>
                                <CheckboxMain>
                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        checked={values.agriculture}
                                        onChange={handleChange}
                                        name="agriculture"
                                      />
                                    }
                                    label="Agriculture, Forestry and Fishing"
                                  />
                                </CheckboxMain>
                                <CheckboxMain>
                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        checked={values.business}
                                        onChange={handleChange}
                                        name="business"
                                      />
                                    }
                                    label="Business and Management Services"
                                  />
                                </CheckboxMain>
                                <CheckboxMain>
                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        checked={values.professionalServices}
                                        onChange={handleChange}
                                        name="professionalServices"
                                      />
                                    }
                                    label="Professional Services"
                                  />
                                </CheckboxMain>
                                <CheckboxMain>
                                  <Box sx={{ marginLeft: "-9px" }}>
                                    <Checkbox
                                      onChange={handleCheck}
                                      value={open}
                                    />
                                    <label>Ohter</label>
                                  </Box>
                                  {open ? (
                                    <>
                                      <TextField
                                        value={values.other}
                                        onChange={handleChange}
                                        name="other"
                                        label="Other (please specify)"
                                        variant="outlined"
                                        size="small"
                                      />
                                      {values?.other === "" ? (
                                        <Alerttext>
                                          please fill the field
                                        </Alerttext>
                                      ) : (
                                        ""
                                      )}{" "}
                                    </>
                                  ) : (
                                    ""
                                  )}
                                </CheckboxMain>
                              </Box>
                              <Box>
                                <CheckboxMain>
                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        checked={values.automotive}
                                        onChange={handleChange}
                                        name="automotive"
                                      />
                                    }
                                    label="Automotive and Transportation"
                                  />
                                </CheckboxMain>
                                <CheckboxMain>
                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        checked={values.it}
                                        onChange={handleChange}
                                        name="it"
                                      />
                                    }
                                    label="IT, Computer and High Tech"
                                  />
                                </CheckboxMain>
                                <CheckboxMain>
                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        checked={values.entertainment}
                                        onChange={handleChange}
                                        name="entertainment"
                                      />
                                    }
                                    label="Entertainment, Travel and Leisure"
                                  />
                                </CheckboxMain>
                              </Box>
                              <Box></Box>
                            </SelectCheckbox>
                            <ErrorMessage
                              name="aerospace"
                              component="div"
                              className="error"
                            />
                          </Grid>
                        </Grid>
                        <DropdownSelector
                          name="selectedRole"
                          label="Select all roles you are hiring for?"
                          options={rolesData}
                          placeholder="select or type and enter Roles"
                        />
                      </Grid>
                    </Box>
                    <StyledDivider />
                    <Boxmodule>
                      <Button
                        variant="contained"
                        color="primary"
                        type="submit"
                        disabled={!isAnyCheckboxChecked}
                      >
                        Save
                      </Button>
                    </Boxmodule>
                  </StyledPaper>
                </Box>
              </Form>
            </>
          );
        }}
      </Formik>
    </Spinner>
  );
};

const Alerttext = styled(`p`)({
  marginLeft: "2px",
  marginTop: "3px",
  color: "red",
});

const StyledBox = styled(Box)({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
});

const StyledPaper = styled(Paper)({
  padding: "24px",
  marginBottom: "16px",
  variant: "outlined",
});

const StyledDivider = styled(MuiDivider)`
  margin: 30px 0px;
  background: ${themeColors.blackGray};
`;

const Boxmodule = styled(Box)`
  display: flex;
  justify-content: flex-end;
`;

const SelectCheckbox = styled(Box)`
  display: flex;
  margin-left: 8px;
`;

const CheckboxMain = styled(Box)`
  display: flex;
  margin-right: 20px;
`;
