import styled from '@emotion/styled';
import { loadUserFromStorage } from 'core/Auth';
import { ROUTES } from 'core/routes';
import StatusComingSoon from 'pages/ComingSoon';
import SignUp from 'pages/Registration';
import Postsignup from 'pages/Registration/postSignup';
import { useEffect } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import { ProtectedRoute } from "pages/ProtectedRoute";
import MyRFRs from "pages/MyRFRs";
import AdminLayout from "layouts/AdminLayout";
import Dashboard from "pages/Dashboard";
import ProfileTab from "pages/profileScene";
import BidMarketPlace from "pages/BidMarketPlace";
import Chats from "pages/Chats";
import Settings from "pages/Settings";
import CreateRFRForm from "pages/MyRFRs/CreateRFR";
import MyNetwork from 'pages/MyNetwork';
import Analytics from 'pages/Vendor/Analytics';
import CheckUserType from 'pages/Registration/authUser/CheckUserType';
import MySubmissions from 'pages/Vendor/mySubmissions/index';
import MyCvs from 'pages/Vendor/myCvs';
import Rmp from 'pages/Vendor/rmp';
import EditRFRForm from 'pages/MyRFRs/EditRFR';
import RmpDetails from 'pages/Vendor/rmp/rmpDetails';
import UserLayout from 'layouts/UserLayout';
import SubmissionsDetails from 'pages/BidMarketPlace/SubmissionDetails';
import { RFRDetails } from 'pages/MyRFRs/RFRDetails';
import VendorSubmissionDetails from 'pages/Vendor/mySubmissions/vendorSubmissionDetail';
import Home from 'pages/home';
import Contactus from './pages/home/Navbarcomponents/Contactus'
import VendorChats from 'pages/Vendor/vendorChats/chats';
import VendorMyNetwork from 'pages/Vendor/myNetwork/MyNetwork';
import { Analytic } from 'pages/Analytics';

export const Main = () => {

  useEffect(() => {
    loadUserFromStorage();
  }, []);

  return (
    <MainContainer>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path={ROUTES.root} element={<UserLayout />}>
          <Route path='signup/:id' element={<SignUp mode='signup' />} />
          <Route path='signin/:id' element={<SignUp mode='signin' />} />
          <Route path={'signup'} element={<SignUp mode='signup' />} />
          <Route path={'signin'} element={<SignUp mode='signin' />} />
          <Route path={'/contact'} element={<Contactus />} />

        </Route>
        <Route element={<ProtectedRoute />}>
          <Route element={<AdminLayout />}>
            <Route path={ROUTES.dashboard} element={<Dashboard />} />
            <Route path={ROUTES.myRfrs} element={<MyRFRs />} />
            <Route path={ROUTES.editRFRDetails} element={<EditRFRForm />} />
            <Route path={ROUTES.rfrDetails} element={<RFRDetails />} />
            <Route path={ROUTES.profile} element={<ProfileTab />} />
            <Route path={ROUTES.bidMarketPlace} element={<BidMarketPlace />} />
            <Route path={ROUTES.myNetwork} element={<MyNetwork />} />
            <Route path={ROUTES.vendorMyNetwork} element={<VendorMyNetwork />} />
            <Route path={ROUTES.analytics} element={<Analytics />} />
            <Route path={ROUTES.analytic} element={<Analytic />} />
            <Route path={ROUTES.chats} element={<Chats />} />
            <Route path={ROUTES.vendorChats} element={<VendorChats />} />
            <Route path={ROUTES.settings} element={<Settings />} />
            <Route path={ROUTES.mySubmission} element={<MySubmissions />} />
            <Route path={ROUTES.myCvs} element={<MyCvs />} />
            <Route path={ROUTES.rpm} element={<Rmp />} />
            <Route path={ROUTES.createRfr} element={<CreateRFRForm />} />
            <Route path={ROUTES.vendorJobDetailPage} element={<RmpDetails />} />
            <Route path={ROUTES.submissionDetails} element={<SubmissionsDetails />} />
            <Route path={ROUTES.vendorSubmissionDetails} element={<VendorSubmissionDetails />} />
          </Route>
          <Route path={ROUTES.authUserCheck} element={<CheckUserType />} />
          <Route path={`${ROUTES.authUserCheck}/:id`} element={<CheckUserType />} />
        </Route>
        <Route path={ROUTES.postsignup} element={<Postsignup />} />
        <Route path={`${ROUTES.postsignup}/:id`} element={<Postsignup />} />
        <Route path={ROUTES.default} element={<StatusComingSoon />} />
      </Routes>
    </MainContainer>
  );
};

const MainContainer = styled('div')`
  padding: 0;
`;