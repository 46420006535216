import WavingHandIcon from "@mui/icons-material/WavingHand";
import { Box, Card, Grid, Typography } from "@mui/material";
import { styled } from "@mui/system";
import { DashboardCardItems, QuickRFRformModal, SelectedWidgetOptions } from "api/resources/modals";
import Spinner from "components/spinner/Spinner";
import { RootState, useAppSelector } from "core/store";
import { themeColors } from "core/Theming/theme";
import { useGetEmployeeWidgetOptions, useGetVendorWidgetOptions, useQuickRfrDetails } from "pages/hooks";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import Bidscards from "./Components/Cardscomponent/Bidscards";
import Countdowncards from "./Components/Cardscomponent/Countdowncards";
import Jobcards from "./Components/Cardscomponent/Jobcards";
import YourJourneySoFarCard from "./Components/Cardscomponent/YourJourneySoFarCards";
import EmptyWidgets from "./Components/EmptyWidgetsSection";
import MultiSelectButton from "./Components/MultiSelectButton";
import QuickActionsCard from "./Components/QuickActionsCard";
import QuickRFRForm from "./Components/QuickRFRform";
import VendorQuickSubmission from "./Components/Vendor/QuickSubmission/QuickSubmission";
import VendorOpenRequisition from "./Components/Vendor/Requsition/OpenRequisition";
import VendorJourneySoFar from "./Components/Vendor/VendorJourney/VendorJourney";
import VendorMySubmissions from "./Components/Vendor/VendorMySubmissions/VendorMySubmissions";
import {
  employeeActions,
  employeeWidgetOptions
} from "./constants/employeeConstants";
import { VendorActions, vendorWidgetOptions } from "./constants/vendorConstants";

interface WidgetOptionsProps {
  label: string,
  value: string
}

interface JourneyCardsProps {
  icon?: React.ElementType,
  text: string,
  numberText: string | number,
  cardColors?: string
}

const Dashboard = () => {
  const { isVendor } = useAppSelector(store => store.auth);
  const [widgetOptions, setWidgetOptions] = useState<WidgetOptionsProps[]>(employeeWidgetOptions);
  const [actions, setActions] = useState<DashboardCardItems[]>([]);
  const [selectedValues, setSelectedValues] = useState<string[]>([]);
  const userInfo = useSelector((state: RootState) => state.auth?.userInfo);
  const userId = userInfo?.user_id ?? "";

  const { activeVendorWidgets, refetch: vendorWidgetRegetch, isLoading: vendorWidgetsLoading } = useGetVendorWidgetOptions(userId);
  const { activeEmployeeWidgets, refetch: employerWidgetRegetch, isLoading: employerWidgetsLoading } = useGetEmployeeWidgetOptions(userId);

  const { createQuickRfrDetails } = useQuickRfrDetails({
    onSuccess: () => toast.success("Submitted Successfully"),
    onError: () => toast.error("Something went wrong, please try again"),
  });

  useEffect(() => {
    const fetchWidgets = async () => {
      try {
        if (isVendor) {
          const { data: vendorData } = await vendorWidgetRegetch();
          if (vendorData) {
            const selectedOptions = Object.keys(vendorData).filter(key =>
              key.startsWith('option') && vendorData[key as keyof SelectedWidgetOptions]
            );
            setSelectedValues(selectedOptions);
          }
        } else {
          const { data: employerData } = await employerWidgetRegetch();
          if (employerData) {
            const selectedOptions = Object.keys(employerData).filter(key =>
              key.startsWith('option') && employerData[key as keyof SelectedWidgetOptions]
            );
            setSelectedValues(selectedOptions);
          }
        }
      } catch (error) {
        toast.error('Failed to fetch widget options');
      }
    };

    fetchWidgets();
  }, [isVendor, vendorWidgetRegetch, employerWidgetRegetch]);

  useEffect(() => {
    if (isVendor) {
      setActions(VendorActions);
      setWidgetOptions(vendorWidgetOptions);
    } else {
      setActions(employeeActions);
      setWidgetOptions(employeeWidgetOptions);
    }
  }, [isVendor]);

  useEffect(() => {
    const handleGetWidgets = async () => {
      if (userId) {
        await vendorWidgetRegetch();
        await employerWidgetRegetch();
      }
    };
    handleGetWidgets();
  }, [userId, vendorWidgetRegetch, employerWidgetRegetch]);

  return (
    <MainContainer key={selectedValues?.length}>
      <Spinner show={vendorWidgetsLoading || employerWidgetsLoading}>
        <StyledBox>
          <LeftContainer>
            <WelcomeText>Welcome!</WelcomeText>
            <TechsopyText>
              {`${userInfo?.general_profile?.first_name} ${userInfo?.general_profile?.last_name}`}
              <HandIcon />
            </TechsopyText>
          </LeftContainer>
          <RightContainer>
            <MultiSelectButton
              options={widgetOptions}
              selectedValues={selectedValues}
              onChange={setSelectedValues} // Update parent state
              buttonText="Manage Widgets"
              isVendor={isVendor}
            />
          </RightContainer>
        </StyledBox>

        <Grid container>
          <Grid item md={3} mt={2} style={{ display: 'flex', flexDirection: 'column', justifyContent: 'stretch' }}>
            <QuickActionsContainer>
              <QuickActionsText textColor={isVendor ? themeColors?.purpleDark : themeColors?.darksecondBlue}>Quick Actions</QuickActionsText>
              {actions.map((action, index) => (
                <React.Fragment key={index}>
                  <QuickActionsCard
                    theme={isVendor}
                    icon={action?.icon}
                    text={action?.text}
                    url={action?.url}
                  />
                </React.Fragment>
              ))}
            </QuickActionsContainer>
          </Grid>

          <Grid item md={9} mt={2}>
            {isVendor && (
              <React.Fragment>
                <YourJourneyVendor>
                  <YourJourneyText textColor={isVendor ? themeColors?.purpleDark : themeColors?.primaryDark}>Your Journey So Far</YourJourneyText>
                  <GridContainer>
                    <VendorJourneySoFar vendorId={userInfo?.user_id || ''} />
                  </GridContainer>
                </YourJourneyVendor>
              </React.Fragment>
            )}

            {((!isVendor)) && (
              <YourJourneyVendor>
                <YourJourneyText textColor={isVendor ? themeColors?.purpleDark : themeColors?.primaryDark}>Your journey so far...</YourJourneyText>
                <GridContainer>
                  <YourJourneySoFarCard />
                </GridContainer>
              </YourJourneyVendor>
            )}
          </Grid>
        </Grid>
        <Box sx={{ marginTop: '10px' }}>
          {(isVendor) && (
            <VendorMySubmissions userId={userInfo?.user_id || ''} />
          )}
        </Box>
        {(!isVendor) && <Jobcards />}
        <ChatBox>
          {/* <ProfileCard /> */}
          {!selectedValues.length && isVendor ? (
            <EmptyWidgets
              button={
                <MultiSelectButton key={selectedValues[0]}
                  options={widgetOptions}
                  selectedValues={selectedValues}
                  onChange={setSelectedValues} // Update directly
                  buttonText="Manage Widgets"
                  isVendor={isVendor}
                />
              }
            />
          ) : null}
        </ChatBox>
        {(selectedValues.includes("option1") && !isVendor) ? (
          <QuickRFRForm
            handleQuickRfrsubmit={(
              values: QuickRFRformModal,
              actions,
              resetForm
            ) => {
              const payload: QuickRFRformModal = {
                userId: userId as String,
                jobTitle: values?.jobTitle,
                Locations: values?.Locations,
                skills: values?.skills,
                visaType: values?.visaType,
                billRateFrom: values?.billRateFrom,
                billRateTo: values?.billRateTo,
                Status: values.Status,
                hourly: values.hourly,
                weekly: values.weekly,
                monthly: values.monthly,
              };
              createQuickRfrDetails(payload).then(() => {
                resetForm();
              });
            }}
          />
        ) :
          (selectedValues.includes("option4") && isVendor) &&
          <VendorQuickSubmission userId={userInfo?.user_id || ''} />
        }
        {(selectedValues.includes("option5") && (!isVendor)) && (
          <Countdowncards />
        )}
        {(selectedValues.includes("option3") && !isVendor) && <Bidscards />}
        {(selectedValues.includes("option5") && isVendor) &&
          <VendorOpenRequisition vendorId={userInfo?.user_id || ''} />
        }
        <StyledSubmissionAndChats item md={12}>
          {!isVendor &&
            !selectedValues.length && (
              <Box>
                <EmptyWidgets
                  button={
                    <MultiSelectButton
                      options={widgetOptions}
                      selectedValues={selectedValues}
                      onChange={setSelectedValues} // Update directly
                      buttonText="Manage Widgets"
                      isVendor={isVendor}
                    />
                  }
                />
              </Box>
            )
          }
        </StyledSubmissionAndChats>
      </Spinner>
    </MainContainer>
  );
};

const StyledSubmissionAndChats = styled(Grid)({
  display: 'flex',
  alignItems: 'center',
  marginTop: '20px',
  gap: '20px'
})

const GridContainer = styled(Box)`
  display: flex;
  width: 100%;
  display: flex;
  flex-wrap:wrap;
  gap: 16px;
`;


const HandIcon = styled(WavingHandIcon)`
  color: ${themeColors.emoji} !important;
  margin-left: 7px;
`;

const MainContainer = styled(Box)`
width: 100%;
height: 100%;
background-color: ${themeColors.white};
padding: 46px;
min-height: 100vh;
overflow: hidden
`;

const StyledBox = styled(Box)`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

const ChatBox = styled(Box)`
  padding: 34px 0px;
  grid-template-columns: 400px 1fr;
`;

const LeftContainer = styled(Box)`
  display: flex;
  flex-direction: column;
`;

const RightContainer = styled(Box)`
  display: flex;
  align-items: center;
`;

const WelcomeText = styled(Typography)`
  color: var(--Grey-60, ${themeColors.darkGrey});
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
  letter-spacing: -0.16px;
`;

const TechsopyText = styled(Typography)`
  color: var(--Black, ${themeColors.black});
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%;
`;

const QuickActionsText = styled(Typography) <{ textColor: string }>`
  color: ${(props) => props?.textColor};
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  width: 100%;
  padding-left: 16px;
  padding-top: 24px
  
`;

const YourJourneyText = styled(Box) <{ textColor: string }>`
  color: ${(props) => props?.textColor};
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  margin-bottom: 15px
`;

const QuickActionsContainer = styled('div')`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 20px;
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.25) !important;
  border-radius: 12px;
  min-height: 100%;
`;

const YourJourneyVendor = styled(Card)`
  display: flex;
  flex-direction: column;
  padding: 20px;
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.25) !important;
  min-height: 100%;
  margin-left: 20px;
`;

export default Dashboard;
