import Rfrlisting from "./Components/RFRlistingpage";


const MyRFRs = () => {
  return (
    <div>
      <Rfrlisting />
    </div>
  );
};

export default MyRFRs;
