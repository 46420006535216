import { keyframes } from "@emotion/react";
import styled from "@emotion/styled";
import React, { useEffect, useState } from "react";

interface LoaderProps {
    show: boolean;
    children?: React.ReactNode;
}

const Spinner: React.FC<LoaderProps> = ({ show, children }) => {
    const [resetKey, setResetKey] = useState(false);

    useEffect(() => {
        if (!show) return; // Return early if 'show' is false and don't run the rest of the effect

        const interval = setInterval(() => {
            console.log(1)
            setResetKey((prevReset) => !prevReset);
        }, 5000);

        return () => clearInterval(interval); // Ensure interval is cleared on unmount or when 'show' becomes false
    }, [show, resetKey]);

    if (!show) return <>{children}</>;

    return (
        <Backdrop>
            <LoaderContainer>
                <ImageLoader key={resetKey?.toString()} src={"/Assets/Images/loader.gif"} alt="Loading..." reset={resetKey} />
                <ProgressBarLabel>
                    <ProgressBarFill reset={resetKey} />
                </ProgressBarLabel>
            </LoaderContainer>
        </Backdrop>
    );
};

const Backdrop = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
`;

const LoaderContainer = styled.div`
  position: relative;
  width: 200px;
  height: 80px;
`;

const moveAnimation = keyframes`
  0% {
    left: -50%;
    opacity: 0;
  }
  10% {
    opacity: 1;
  }
  90% {
    opacity: 1;
  }
  100% {
    left: 100%;
    opacity: 0;
  }
`;

const ImageLoader = styled.img<{ reset: boolean }>`
  position: absolute;
  top: 10px;
  z-index: 1;
  height: 50px;
  animation: ${moveAnimation} 5s linear infinite;
`;

const ProgressBarLabel = styled.div`
  position: absolute;
  width: 200px;
  height: 8px;
  top: 70px;
  border-radius: 5px;
  background-color: #e0e0e0;
  overflow: hidden;
`;

const fillAnimation = keyframes`
  0% {
    width: 0%;
  }
  100% {
    width: 100%;
  }
`;

const ProgressBarFill = styled.div<{ reset: boolean }>`
  height: 100%;
  background: linear-gradient(
    90deg,
    #00c15d 0%,
    #9d42f1 12.5%,
    #7a33bb 25%,
    #1749c7 37.5%,
    #4d6cd9 50%,
    #38f4a4 62.5%,
    #00e777 75%,
    #b580f4 87.5%
  );
  background-size: 200px 8px;
  border-radius: 5px;
  animation: ${fillAnimation} 5s linear infinite; // Synchronize with the resetKey timing
`;

export default Spinner;