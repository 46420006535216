import { Grid, Box, Typography } from "@mui/material"
import Image1 from '../../assets/images/LandingPage/Talentxchange1.svg'
import Image2 from '../../assets/images/LandingPage/For Employer.svg'
import image3 from '../../assets/images/LandingPage/Talentxchange2.svg'
import image4 from '../../assets/images/LandingPage/For Vendor.svg'

const TalentXchange: React.FC = () => {
    return (
        <div className="page2background txch">
            <Grid>
                <Box sx={{ flexGrow: 1 }}>
                    <Typography
                        variant="h1"
                        component="h2"
                        className="mainTX"
                    >
                        Why Choose Talent Xchange
                    </Typography>
                    <Typography variant="body1" className='descriptiontx'>
                        Our marketplace is designed to value all stakeholders,
                        significantly enhancing your requisition and submission
                        outcomes.

                    </Typography>
                    <Grid spacing={2} className="TXimagecontainer">
                        <Grid spacing={6}>
                        <Box className="tximg1"
                            component="img"
                            alt="Image1"
                            src={Image1}
                        
                        />
                        </Grid>
                        <Grid spacing={6}>
                        <Box className="employerimg"
                            component="img"
                            alt="Image2"
                            src={Image2}
                            height={400}

                        />
                        </Grid>

                    </Grid>
                    <Grid spacing={2} className="TXimagecontainer">
                        <Grid spacing={6}>
                        
                        <Box className="vendorimg"
                            component="img"
                            alt="Image1"
                            src={image4}
                            height={400}

                        
                        />
                        </Grid>
                        <Grid spacing={6}>
                        <Box className='txtimg2'
                            component="img"
                            alt="Image2"
                            src={image3}
                        />
                        </Grid>

                    </Grid>
                </Box>
            </Grid>

        </div>
    )

}
export default TalentXchange