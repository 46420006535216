import React, { useState, useEffect } from "react";
import { Card, CardContent, Typography, Button, Box, Modal, Container, Tooltip } from "@mui/material";
import styled from "@emotion/styled";
import { themeColors } from "core/Theming/theme";
import { toast } from "react-toastify";
import { useChangeDateRequisition, useRequisitionsAboutToExpire } from "pages/hooks";
import dayjs, { Dayjs } from "dayjs";
import { ChangedateModal } from "pages/MyRFRs/Components/Modals/Changedatemodal";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import { useSelector } from "react-redux";
import { RootState } from "core/store";
import Spinner from "components/spinner/Spinner";
import { ReactComponent as Menuarrow } from "../../../../../assets/icons/Group 33.svg";
import { ReactComponent as CloseIcons } from "../../../../../assets/icons/Closed.svg";  

interface CountdownCardProps {
  title: string;
  expiryDate: string;
  bids: number;
  rfq_id:string
}

const CountdownCard: React.FC<CountdownCardProps> = ({
  title,
  expiryDate,
  bids,
  rfq_id
}) => {
  const userInfo = useSelector((state: RootState) => state.auth?.userInfo);
  const userID =  userInfo?.user_id;
  const { aboutExpireData, isLoading,refetch } = useRequisitionsAboutToExpire(userID as string);

  const [successMessage, setSuccessMessage] = useState("");  

  const { postChangeDateRequisition } = useChangeDateRequisition({
    onError: () => toast.error("Error Date requisition:"),
    onSuccess: () => {
      toast.success("Requisition Date update successfully");
      refetch();
    },
  });

  const [calenderopen, setCalendaropen] = useState(false);
  const [rfqClosingDate, setRfqClosingDate] = useState<Dayjs | null>(null);
  const [deleteRfqId,setDeleteRfqId]=useState('')

  
 const handleClickOpenclock = (rfq_id: string, previousDate: string) => {
  console.log(rfq_id)
  setDeleteRfqId(rfq_id)
  setRfqClosingDate(dayjs(previousDate));
  setCalendaropen(true);
};

 

const handleDateClose = () => {
  setCalendaropen(false);
};

const handleDatechange = () => {
  if (deleteRfqId && rfqClosingDate) {
    dayjs.extend(utc);
    dayjs.extend(timezone);
    const rfqClosingData = dayjs(rfqClosingDate).local().format("YYYY-MM-DD");
    postChangeDateRequisition({
      rfqId: deleteRfqId,
      rfqClosingData: rfqClosingData,
    });
    setSuccessMessage("Expiry date changed  Successfully!");
    handleDateClose();
  }
};


  const calculateTimeLeft = () => {
    const difference = +new Date(expiryDate) - +new Date();
    let timeLeft = {
      days: 0,
      hours: 0,
      minutes: 0,
      seconds: 0,
    };

    if (difference > 0) {
      timeLeft = {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / 1000 / 60) % 60),
        seconds: Math.floor((difference / 1000) % 60),
      };
    }
    return timeLeft;
  };

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  useEffect(() => {
    const timer = setTimeout(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    return () => clearTimeout(timer);
  }, [timeLeft]);

  if (isLoading) {
    return <Spinner show={true} />;
  }

  return (
    <StyledCard>
      <CardContent>
      <Tooltip arrow title={title}>
      <span style={{ cursor: 'pointer' }}> <Tilte variant="h4">{title.length > 20 ? title?.slice(0, 20) + '...' : title}</Tilte></span>
      </Tooltip>
        <ExpiryTypography variant="body2" color={themeColors.grayBlack}>
          <span>Expiry date:</span> {new Date(expiryDate).toLocaleDateString()}
        </ExpiryTypography>
        <CountdownContainer>
          <TimeBox>
            <TimeTypography variant="h4">{timeLeft.days}</TimeTypography>
            <Typography variant="body2">Days</Typography>
          </TimeBox>
          <TimeBox>
            <TimeTypography variant="h4">{timeLeft.hours}</TimeTypography>
            <Typography variant="body2">Hours</Typography>
          </TimeBox>
          <TimeBox>
            <TimeTypography variant="h4">{timeLeft.minutes}</TimeTypography>
            <Typography variant="body2">Minutes</Typography>
          </TimeBox>
          <TimeBox>
            <TimeTypography variant="h4">{timeLeft.seconds}</TimeTypography>
            <Typography variant="body2">Seconds</Typography>
          </TimeBox>
        </CountdownContainer>
        <BidsTypography variant="h6" color={themeColors.grayBlack}>
          <BidsCount>{bids}</BidsCount> Submissions received <br /> till date
        </BidsTypography>
        <Buttonclass variant="contained" color="primary"  onClick={() => handleClickOpenclock(rfq_id,expiryDate )}>
          Change Requisition Expiry Date
        </Buttonclass>
      </CardContent>

      <ChangedateModal
          open={calenderopen}
          handleDateClose={handleDateClose}
          handleDatechange={handleDatechange}
          previousDate={expiryDate}
          rfqClosingDate={rfqClosingDate}
          setRfqClosingDate={setRfqClosingDate}
        />
         {successMessage && (
          <Modal
            open={!!successMessage}
            onClose={() => setSuccessMessage("")}
            aria-labelledby="success-modal-title"
            aria-describedby="success-modal-description"
          >
            <Box sx={{ ...modalStyle }}>
            <Modalheader>
              <Close onClick={() => setSuccessMessage("")} />
            </Modalheader>
              <CenteredColumn>
                <Questionmark>
                  <Menu />
                </Questionmark>
                <CenteredTypography id="success-modal-description" variant="h3">
                  {successMessage}
                </CenteredTypography>
              </CenteredColumn>
            </Box>
          </Modal>
        )}

    </StyledCard>
  );
};
const CenteredColumn = styled(`div`)({
  display: "flex",
  justifyContent: "center",
  flexDirection: "column",
});
const Modalheader = styled(Container)({
  display: "flex",
  justifyContent: "flex-end",
  backgroundColor: themeColors.primaryLight,
  Padding: "8px",
  borderTopLeftRadius: "14px",
  borderTopRightRadius: "14px",
  borderBottomRadius: "3px",
  padding: "8px",
});


const Close = styled(CloseIcons)({
  height: "30px",
  width: "30px",
  cursor: "pointer",
});

const Questionmark = styled(`div`)({
  display: "flex",
  justifyContent: "center",
  marginTop:'16px'
});


const Menu = styled(Menuarrow)({
  height: "80px",
  width: "80px",
  cursor: "pointer",
});

const CenteredTypography = styled(Typography)({
  marginTop: "20px",
  alignItems: "center",
  textAlign: "center",
  color: themeColors.black,
});

const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  bgcolor: "background.paper",
  boxShadow: 24,
  borderRadius: "15px",
  height: "304px",
};


const Tilte=styled(Typography)({
  color:themeColors.grey,
  textTransform:'capitalize',
  fontSize:'20px',
});
const StyledCard = styled(Card)`
  margin: 10px;
  padding: 16px 20px 30px 20px;
  width: 280px;
  height: 290px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  border: 1px solid ${themeColors.primaryDark};
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
`;

const CountdownContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 10px 0;
  align-items: center;
  border: 1px solid ${themeColors.primaryDark};
  background: ${themeColors.primaryLight};
  color: ${themeColors.primaryDark};
  border-radius: 12px;
  padding: 10px;
  gap: 10px;
`;

const TimeBox = styled.div`
  text-align: center;
`;

const Buttonclass = styled(Button)`
  height: 29px;
  border-radius: 4px;
`;

const TimeTypography = styled(Typography)`
  border: 1px solid ${themeColors.primaryDark};
  padding: 10px;
  border-radius: 4px;
  margin-right: 5px;
`;

const ExpiryTypography = styled(Typography)`
  margin-top: 10px;
  margin-bottom: 10px;
  color: ${themeColors.darkGrey};
  & > span {
    font-weight: bold;
    color: ${themeColors.grey};
  }
`;

const BidsTypography = styled(Typography)`
  display: flex;
  justify-content: center;
  margin: 5px 0px;
`;

const BidsCount = styled.span`
  color: ${themeColors.primaryDark};
  font-weight: 700;
  font-size: 26px;
  margin-right: 10px;
`;

export default CountdownCard;
