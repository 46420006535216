import styled from "@emotion/styled";
import { Box, Button, IconButton, Typography, Modal } from "@mui/material";
import React from "react";
import { ReactComponent as ClosedcircleIcon } from "../../../assets/images/closedCircleIcon.svg";
import { ReactComponent as SuccessIcon } from "../../../assets/images/success.svg";
import { themeColors } from "core/Theming/theme";

interface BidRequestModalProps {
  open: boolean;
  onButtonClick: () => void;
  handleClose: () => void;
}

const BidRequestModal: React.FC<BidRequestModalProps> = ({
  open,
  onButtonClick,
  handleClose,
}) => {
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <ModalBox>
        <CustomBox>
        <ModalIconButton onClick={handleClose}>
          <ClosedcircleIcon height={'24px'} width={'24px'}/>
        </ModalIconButton>
        </CustomBox>
        <SuccessIcon />
        
        <RequestText>
          Your request for resource has been created successfully and
          distributed among our vast vendor network.
        </RequestText>
        <StayTunedText>Stay tuned for more Submissions!</StayTunedText>
        <StyledButton
          type="button"
          variant="contained"
          color="primary"
          onClick={onButtonClick}
        >
          Go to Requisitions Listing
        </StyledButton>
      </ModalBox>
    </Modal>
  );
};


const CustomBox = styled(Box) ({
  background: themeColors.primaryLight, 
  width: '100%',
  height: '61px',
 borderTopRightRadius: '20px',
 borderTopLeftRadius: '20px',
  flexShrink: 0
});
const StayTunedText = styled(Typography)({
  color: themeColors.primaryDark,
  textAlign: "center",
  fontFeatureSettings: "'clig' off, 'liga' off",
  fontFamily: "Roboto",
  fontSize: "26px",
  fontStyle: "normal",
  fontWeight: 500,
  marginTop: 24,
  marginBottom: 24,
});

const RequestText = styled(Typography)({
  color: themeColors.grayBlack,
  textAlign: "center",
  fontFeatureSettings: "'clig' off, 'liga' off",
  fontFamily: "Roboto",
  fontSize: "24px",
  fontStyle: "normal",
  fontWeight: 500,
  lineHeight: "120%",
  marginRight:"24px",
  marginLeft:"24px",
  marginTop:"24px",
});


const ModalIconButton = styled(IconButton)({
  position: "absolute",
  top: 10,
  right: 10,
  color: themeColors.black,
});

const ModalBox = styled(Box)({
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 720,
  backgroundColor: themeColors.white,
  borderRadius: "20px",
  boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.25)",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
});

const StyledButton = styled(Button)({
  width: "auto",
  height: "38px",
  borderRadius: "12px",
  marginBottom: 24,
});

export default BidRequestModal;
