import { Box, Typography, Grid, Button } from '@mui/material'
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import styled from '@emotion/styled';
import { useState } from 'react';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


interface AccordionStyleProps {
  isactive: number;
}
interface Content {
  text?: string;
  list?: string[];
}


const FAQ: React.FC = () => {
  const [query, setQuery] = useState('');
  const [email, setEmail] = useState('');

  const [activeIndex, setActiveIndex] = useState<number | null>(null);
  const handleAccordionChange = (index: number) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  const titles = [
    "What is Requisition?",
    "As a Vendor what can I do?",
    "How can a user switch his role?",
    "As an Employer what can I do?",
  ];
  const contents: Content[] = [
    {
      text: "In TalentXchange, a requisition represents a job posting. Our platform, designed for both vendors and employers, allows employers to create and manage requisitions, track submissions, and review quotations for each requisition."
    },
    {
      list: [
        "Mark a requisition as interested or uninterested",
        "Submit my submission",
        "Add CVs",
        "View employer contact information",
        "Networking with multiple Employers"

      ]
    },
    {
      text: "Users on TalentXchange can switch between vendor and employer roles at any time by toggling between the two, regardless of their initial enrollment."
    },
    {
      list: [
        "In TalentXchange, as a Employer, I can:",
        "Create job requisitions :",
        "Request submissions from interested vendors",
        "Network with multiple vendors"
      ]

    }
  ];

  const handleSendClick = async () => {
    try {
      const response = await axios.post(
        'https://xak9-hweo-h6q9.n7.xano.io/api:ceD068cG:C2C-Backend/c2cLandingPageQuery',
        {
          query: query,
          email: email,
        }
      );
      toast.success('Sent successfully!');

    } catch (error) {
      toast.error('Failed to send');
    }
  };

  // const contents = [
  //   "In TalentXchange, a requisition represents a job posting. Our platform, designed for both vendors and employers, allows employers to create and manage requisitions, track submissions, and review quotations for each requisition.",
  //   ,["Indicate interest or disinterest in a requisition",
  //     "Submit proposals",
  //     "Upload CVs",
  //     "Access employer contact details",
  //     "Network with various employers"
  //   ],

  //   // "As a vendor on TalentXchange, I can:\n1. Indicate interest or disinterest in a requisition\n2. Submit proposals\n3. Upload CVs\n4. Access employer contact details\n5. Network with various employers.",

  //   "Users on TalentXchange can switch between vendor and employer roles at any time by toggling between the two, regardless of their initial enrollment.",
  //   ,
  //   [ "Indicate interest or disinterest in a requisition", "Submit proposals", "Upload CVs", "Access employer contact details", "Network with various employers" ]


  //   // "As an employer on TalentXchange, I can:\n1. Create job requisitions\n2. Request submissions from interested vendors\n3. Network with multiple vendors."
  // ];

  return (
    <div className='page2background'>
      <Box sx={{ flexGrow: 1 }}>
        <Typography
          variant="h1"
          component="h2"
          className="mainp3"
        >
          Frequently Asked Questions
        </Typography>
        <div className='faqform'>
          {titles.map((title, index) => {
            const content = contents[index];

            return (
              <Accordionstyle
                key={index}
                isactive={activeIndex === index ? 1 : 0}
                expanded={activeIndex === index}
                onChange={() => handleAccordionChange(index)}
              >
                <AccordionSummarystyle
                  expandIcon={<ExpandMoreIconstyle />}
                  aria-controls={`panel${index}-content`}
                  id={`panel${index}-header`}
                >
                  <Typography className='accTitle'>{title}</Typography>
                </AccordionSummarystyle>
                <AccordionDetailsStyle>
                  {content?.list ? (
                    <>
                      {/* Render the first item as plain text */}
                      {content.list.length > 0 && (
                        <Typography color='#fff' textAlign='left' marginLeft='-10px' variant="h4" gutterBottom>
                          {content.list[0]}
                        </Typography>
                      )}
                      {/* Render the rest of the list as list items */}
                      {content.list.length > 1 && (
                        <StyledList>
                          {content.list.slice(1).map((item, i) => (
                            <StyledListItem key={i}>{item}</StyledListItem>
                          ))}
                        </StyledList>
                      )}
                    </>
                  ) : (
                    <Typography variant="body1">
                      {content?.text}
                    </Typography>
                  )}
                </AccordionDetailsStyle>
              </Accordionstyle>
            );
          })}


        </div>
        <Grid>
          <div className='p4bottom'>
            <StyledInput type='text' placeholder='Enter your query...' value={query}
              onChange={(e) => setQuery(e.target.value)}

              style={{ width: '450px', height: '40px' }} className='p4bottomtext'></StyledInput >
            <StyledInput type='email' placeholder='Enter your email...'
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              style={{ width: '350px', height: '40px' }} className='p4bottomtext'></StyledInput >
            <SendButton color="success" variant="contained" onClick={handleSendClick}
              type="submit">
              Send
            </SendButton>

          </div>
        </Grid>
        <ToastContainer />
      </Box>


    </div>
  )
}
export default FAQ;
const AccordionSummarystyle = styled(AccordionSummary)({
  color: '#4D6CD9',
  background: 'transparent',
  fontFamily: ' "DM Sans", sans-serif !important',
  fontSize: '45',
  fontWeight: '800',

})
const Accordionstyle = styled(Accordion) <AccordionStyleProps>`
  background: ${(props) =>
    props.isactive
      ? ' var(--GlassFill-Dark-Blue-Teal, linear-gradient(104deg, rgba(135, 255, 225, 0.20) 0%, rgba(92, 109, 255, 0.20) 77.6%), rgba(29, 29, 29, 0.05))'
      : 'transparent'};
  padding: 10px;
  margin-bottom: 0;
  justify-content: center;
`;

const ExpandMoreIconstyle = styled(ExpandMoreIcon)({
  color: 'white'
})
const AccordionDetailsStyle = styled(AccordionDetails)({
  color: '#fff',
  fontSize: '28px',
  fontFamily: ' "DM Sans", sans-serif !important',
  borderRadius: '15px',
  wordWrap: 'break-word',
  wordBreak: 'break-word',
})
const SendButton = styled(Button)`
width:120px;
color:#412269;
border-radius:60px;
fontFamily: ' "DM Sans", sans-serif !important';
font-weight: 500;
line-height: normal;
letter-spacing: -0.44px;
font-size:18px;
background: #0FF06D;
box-shadow: 0px 1px 7.8px 0px rgba(161, 76, 240, 0.45);
&:hover{
background: #0FF06D;
color:#412269;
}

`
const StyledInput = styled.input`
padding:10px;
  &::placeholder {
  fontFamily: ' "DM Sans", sans-serif !important';
font-weight: 400;
line-height: normal;
letter-spacing: -0.44px;
font-size:15px;
    color: white;
    opacity: 1;
    margin:5px;
    padding:12%;
    text-align:left;
   
  }
`;

const StyledList = styled('ol')({
  paddingLeft: '0',
  margin: '0',
});

const StyledListItem = styled('li')({
  color: '#fff',
  fontSize: '15px',
  fontFamily: '"DM Sans", sans-serif !important',
});