import styled from "@emotion/styled";
import { Box, Button, IconButton, Typography, Modal } from "@mui/material";
import React from "react";
import { ReactComponent as CloseIcon } from "../../../../assets/icons/Closed.svg";
import { ReactComponent as Menuarrow } from "../../../../assets/icons/Group 33.svg";
import { themeColors } from "core/Theming/theme";

interface EditModalProps {
  open: boolean;
  handleEditClose: () => void;
  onButtonClick: () => void;
}

const EditModal: React.FC<EditModalProps> = ({
  open,
  onButtonClick,
  handleEditClose,
}) => {
  return (
    <Modal
    open={open}
    onClose={() => handleEditClose()}
    aria-labelledby="success-modal-title"
    aria-describedby="success-modal-description"
  >
    <Box sx={{ ...modalStyle }}>
      <Container>
        <Close onClick={() => handleEditClose()} />
      </Container>
      <CenteredColumn>
        <Questionmark>
          <Menu />
        </Questionmark>
        <CenteredTypography id="success-modal-description" variant="h3">
        Requisitions Updated Successfully!
        </CenteredTypography>
        <ButtonBox>
        <StyledButton
          type="button"
          variant="contained"
          color="primary"
          onClick={onButtonClick}
        >
          Go to Requisitions Listing
        </StyledButton>
        </ButtonBox>
      </CenteredColumn>
    </Box>
  </Modal>
  );
};

const CenteredTypography = styled(Typography)({
    marginTop: "20px",
    alignItems: "center",
    textAlign: "center",
    color:themeColors.black
  });
  const Container = styled(`div`)({
    display: "flex",
    justifyContent: "flex-end",
    backgroundColor: themeColors.primaryLight,
    Padding: "8px",
    borderTopLeftRadius: "14px",
    borderTopRightRadius: "14px",
    borderBottomRadius: "3px",
    padding: "8px",
  });
  
  const Questionmark = styled(`div`)({
    display: "flex",
    justifyContent: "center",
    marginTop:'10px'
  });
  
  const Menu = styled(Menuarrow)({
    height: "80px",
    width: "80px",
    cursor: "pointer",
  });
  
  const CenteredColumn = styled(`div`)({
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
  });
  
  const Close = styled(CloseIcon)({
    height: "30px",
    width: "30px",
    cursor: "pointer",
  });
  
  const modalStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 600,
    bgcolor: "background.paper",
    boxShadow: 24,
    borderRadius: "14px",
    height: "304px"
  };

  const StyledButton = styled(Button)({
    width: "450px",
    height: "38px",
    borderRadius: "12px",
  });
  const ButtonBox=styled(Box)({
    display:'flex',
    justifyContent:"center",
    marginTop:'20px',
    alignItems:"center"
  })

export default EditModal;
