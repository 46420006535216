import {
  VendorMySubmissionProps,
  VendorSubmissionsTableModel,
} from "api/resources/modals";
import { ReactComponent as EyeIcon } from "../../../../assets/icons/vendorSubmitionEyeIcon.svg";
import { ReactComponent as ContactsActive } from "../../../../assets/icons/vendorContactActiveIcon.svg";
import { ReactComponent as ContactsDisable } from "../../../../assets/icons/vendorContactDisableIcon.svg";

import {
  FilterIcon,
  LeftcontentText,
  LeftsiteContainer,
  Leftsitecontent,
  MainGridContainer,
  Maingridtable,
  RightSiteICon,
} from "pages/Vendor/rmp";
import { useEffect, useState } from "react";
import styled from "@emotion/styled";
import { Link, useNavigate } from "react-router-dom";
import {
  ClickAwayListener,
  Stack,
  TableContainer,
  Tooltip,
  Typography,
} from "@mui/material";
import ReusableTable from "components/reusableFIlterTable/ReusableTable";
import { themeColors } from "core/Theming/theme";
import ContactDetailsTooltip from "pages/Vendor/CommonComponents/ContactDetailsTip";
import {
  StyledButtonAccept,
  StyledButtonPending,
  StyledButtonRejected,
} from "pages/BidMarketPlace/Allsubmissions";
import zIndex from "@mui/material/styles/zIndex";

interface Props {
  selectedTab: number;
  data: VendorMySubmissionProps[];
  spinner: boolean;
}

const MySubmissionTab = ({ selectedTab, data, spinner }: Props) => {
  const navigate = useNavigate();
  const [isLoading, setLoading] = useState(false);
  const [selectedSubmissions, setSelectedSubmissions] = useState<VendorSubmissionsTableModel[]>([]);
  const [submissionData, setSubmissionData] = useState<
    VendorSubmissionsTableModel[] | []
  >([]);
  const [openId, setOpenId] = useState<string | null>(null);

  const handleTooltipClose = () => {
    setOpenId(null);
  };

  const handleTooltipToggle = (id: string) => {
    setOpenId((prev) => (prev === id ? null : id));
  };
  const headers = [
    {
      title: "id",
      name: "id",
      cellWidth: "230px",
      show: false,
    },
    {
      title: "Submission ID",
      name: "submissionId",
      cellWidth: "200px",
      filterType: "text",
      filter: true,
      customComponent: (row: VendorSubmissionsTableModel) => {
        return (
          <Link to={`${row.submissionId}/submission-details`}>
            {row.submissionId}
          </Link>
        );
      },
    },
    {
      title: "Employer Name",
      name: "employerName",
      cellWidth: "200px",
      filterType: "text",
      filter: true,
    },
    {
      title: "Employer ID",
      name: "employerId",
      cellWidth: "250px",
      filterType: "text",
      filter: true,
    },
    {
      title: "Submission Date",
      name: "created_at",
      cellWidth: "200px",
      filterType: "date",
      filter: true,
    },
    {
      title: "Requisition Details",
      name: "requisitionDetails",
      cellWidth: "200px",
      filterType: "text",
      filter: true,
      customComponent: (row: VendorSubmissionsTableModel) => {
        return (
          <Stack direction="column" spacing={1} mt={0}>
            <Typography color={themeColors?.purpleDark}>
              {row.requisitionDetails}
            </Typography>
            <p>{row.rfqId}</p>
          </Stack>
        );
      },
    },
    {
      title: "Visa Type",
      name: "visaType",
      cellWidth: "150px",
      filterType: "text",
      filter: true,
      customComponent: (row: VendorSubmissionsTableModel) => {
        return (
          <Tooltip title={row?.visaType} arrow>
            <Typography variant="body2" noWrap>
              {row?.visaType}
            </Typography>
          </Tooltip>
        );
      },
    },
    {
      title: "Employee Level",
      name: "employeeLevel",
      cellWidth: "200px",
      filterType: "text",
      filter: true,
    },
    {
      title: "Noof CV's mentioned in Submission",
      name: "noOfCvsMentionedInSubmission",
      cellWidth: "250px",
      filterType: "text",
      filter: true,
    },
    {
      title: "No of CV's actually Submitted",
      name: "noOfCvsActuallySubmitted",
      cellWidth: "250px",
      filterType: "text",
      filter: true,
    },
    {
      title: "Status",
      name: "status",
      filterType: "select",
      options: ["pending", "accepted", "rejected"],
      cellWidth: "150px",

      filter: true,
      customComponent: (row: VendorSubmissionsTableModel) => {
        return (
          <Stack direction="row" spacing={1} mt={0}>
            {row.status == "pending" ? (
              <StyledButtonPending>
                {row.status.charAt(0).toUpperCase() + row.status.slice(1)}
              </StyledButtonPending>
            ) : row.status == "accepted" ? (
              <StyledButtonAccept>
                {row.status.charAt(0).toUpperCase() + row.status.slice(1)}
              </StyledButtonAccept>
            ) : (
              <StyledButtonRejected>
                {row.status.charAt(0).toUpperCase() + row.status.slice(1)}
              </StyledButtonRejected>
            )}
          </Stack>
        );
      },
    },
  ];

  const actionItmes = [
    {
      type: "button",
      name: "actions",
      handleClick: (action: string, item: VendorSubmissionsTableModel) => {},
      customActionComponent: (row: VendorSubmissionsTableModel) => {
        return (
          <ActinItemDiv>
            <EyeIconAction
              onClick={(e: React.MouseEvent) => {
                navigate(`${row.submissionId}/submission-details`);
              }}
            />
            <div>
              {row.status != "rejected" ? (
                <>
                  <ContactDetailsTooltip
                    open={openId === row.submissionId}
                    onClose={handleTooltipClose}
                    data={{
                      employerId: row.employerId,
                      employerName: row.employerName,
                      contactNumber: row.phoneNumber,
                      email: row.email,
                      website: row.website,
                    }}
                  />
                  <ContactActiveIcon
                    onClick={() => {
                      handleTooltipToggle(row.submissionId);
                    }}
                  />
                </>
              ) : (
                <ContactDisableIcon />
              )}
            </div>
          </ActinItemDiv>
        );
      },
    },
  ];

  const transformData = (
    data: VendorMySubmissionProps[] | []
  ): VendorSubmissionsTableModel[] | [] => {
    const transformedData: VendorSubmissionsTableModel[] | [] = data
      .filter(
        (item: VendorMySubmissionProps) => Object.keys(item)?.length !== 0
      )
      .map((item: VendorMySubmissionProps, index:number) => ({
        id: index,
        submissionId: item?.subimissionDateAndMore?.C2C_Vendor_Bid_Id || "",
        employerName: item?.general_profile?.company_name || "",
        employerId: item?.user_id || "",
        created_at: item?.subimissionDateAndMore.created_at || 0,
        requisitionDetails: item?.rfq_details?.job_title || "",
        visaType: item?.subimissionDateAndMore?.Visa_Type?.join(", ") || "",
        employeeLevel: item?.subimissionDateAndMore?.Employer_Level || "",
        noOfCvsMentionedInSubmission:
          item?.subimissionDateAndMore?.No_of_Profiles || "-",
        noOfCvsActuallySubmitted:
          item?.subimissionDateAndMore?.cv_submitted || "-",
        status: item?.subimissionDateAndMore?.status || "",
        rfqId: item?.rfq_details?.rfq_id || "",
        phoneNumber: item?.general_profile?.phone_number || "-",
        email: item?.email || "-",
        website: item?.general_profile?.website_link || "-",
      }));
    return transformedData;
  };

  const setRmpDataForTable = async () => {
    setLoading(true);
    switch (selectedTab) {
      case 1:
        setSubmissionData(transformData(data || []));
        break;
      default:
        setSubmissionData(transformData(data));
    }
    setLoading(false);
  };

  const handleDoBulk = (selectedItems: VendorSubmissionsTableModel[] ) => {
    setSelectedSubmissions(selectedItems);
  };

  useEffect(() => {
    setRmpDataForTable();
  }, [data, selectedTab]);

  return (
    <MainGridContainer>
      <LeftsiteContainer>
        <Leftsitecontent>
          <LeftcontentText>
            {`${selectedSubmissions?.length || 0} of ${
              submissionData?.length || 0
            } Selected`}
          </LeftcontentText>
        </Leftsitecontent>
        <RightSiteICon>
          <FilterIcon />
        </RightSiteICon>
      </LeftsiteContainer>
      <Maingridtable>
        <TableContainer>
          <ClickAwayListener onClickAway={handleTooltipClose}>
            <ReusableTable
              key={submissionData?.length}
              headers={headers}
              data={submissionData}
              itemsPerPage={10}
              spinner={spinner}
              handleDoBulk={handleDoBulk}
              checkBox={true}
              headerColor={themeColors.purpleLight}
              inputBorder={themeColors.purpleDark}
              actions={actionItmes}
              actionsCellWidth={"150px"}
            />
          </ClickAwayListener>
        </TableContainer>
      </Maingridtable>
    </MainGridContainer>
  );
};

export const EyeIconAction = styled(EyeIcon)({
  cursor: "pointer",
  width: "24px",
  height: "24px",
});

export const ContactActiveIcon = styled(ContactsActive)({
  marginTop: "8px",
  cursor: "pointer",
  width: "24px",
  height: "24px",
});

export const ContactDisableIcon = styled(ContactsDisable)({
  marginTop: "8px",
  cursor: "no-drop",
  width: "24px",
  height: "24px",
});

const ActinItemDiv = styled(`div`)({
  margin: "11px 0px",
  display: "flex",
  gap: "8px",
  alignItems: "center",
});

export default MySubmissionTab;
