import styled from "@emotion/styled";
import { Box, Button, Typography } from "@mui/material";
import { themeColors } from "core/Theming/theme";
import { ReactComponent as jobsIcon } from "../../assets/icons/SettingsBlue.svg";
import { ReactComponent as Logo } from "../../assets/icons/ComingsoonLogo/My-rfr-logo-comingsoon.svg";
import {  useNavigate } from "react-router-dom";

const Settings = () => {
  const navigate = useNavigate();
  return (
    <MainContainer>
      <Headingcontainer>
        <Headingtesx variant="h4">
          <HeadingIcon />
          Settings
        </Headingtesx>
      </Headingcontainer>
      <Container>
        <Logo />
        <Styletextcontainer>
          <Styletext variant="h4">Coming Soon...</Styletext>
        </Styletextcontainer>
      </Container>
      <Footer>
        <StyledButton
          variant="contained"
          onClick={() => navigate("/employer/dashboard")}
        >
          Go back to Dashboard
        </StyledButton>
      </Footer>
    </MainContainer>
  );
};

const Styletextcontainer = styled(Box)({
  position: "absolute",
  width: "351px",
  height: "56px",
  backgroundColor: themeColors.white,
  color: themeColors.blackGray,
});

const Styletext = styled(Typography)({
  color: themeColors.blackGray,
  marginTop: "15px",
});
const Footer = styled(Box)({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  marginTop: "16px",
});

const StyledButton = styled(Button)({
  borderRadius: "10px",
  width: "350px",
  height: "44px",
  fontSize: "12px",
});
const Container = styled(Box)({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  textAlign: "center",
});
const HeadingIcon = styled(jobsIcon)({
  marginRight: "10px",
  color: themeColors.primary,
});

const Headingtesx = styled(Typography)({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
});

const Headingcontainer = styled(Box)({
  display: "flex",
  justifyContent: "space-between",
  padding: "16px 20px",
  border: `1px solid ${themeColors.emptyStar}`,
  borderRadius: "8px",
  marginBottom: "16px",
  background: themeColors.primaryLight,
});

const MainContainer = styled(Box)`
width: 100%;,
height:100%,
background-color: ${themeColors.white};
padding: 16px 20px;
`;
export default Settings;
