import { Modal, Box, Typography, Button } from "@mui/material";
import styled from "@emotion/styled";
import CancelPresentationIcon from "@mui/icons-material/CancelPresentation";
import CancelIcon from "@mui/icons-material/Cancel";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { themeColors } from "core/Theming/theme";
import { requesitionType } from "../rmp/index";

interface props {
  isOpen: boolean;
  modelType: requesitionType;
  handleClose: () => void;
  handleConfirm: () => void;
  id: number | string;
}
export const CommonModel = ({
  isOpen,
  modelType,
  handleClose,
  handleConfirm,
  id,
}: props) => {
  return (
    <Modal
      open={isOpen}
      onClose={handleClose}
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
    >
      <Box sx={{ ...modalStyle }}>
        <Container>
          <Close onClick={handleClose} />
        </Container>
        <CenteredColumn>
          <MiddleIconBig>
            {modelType == "interested" ? <TickIconBig /> : <CancleIconBig />}
          </MiddleIconBig>
          <CenteredTypography id="modal-description" variant="h4">
            Do you want to move the selected requisitions to the
            {modelType == "interested" ? (
              <span style={{ color: themeColors.success }}>
                “Interested Requisition”
              </span>
            ) : (
              <span style={{ color: themeColors.error }}>
                “Not interested Requisition”
              </span>
            )}
            tab
          </CenteredTypography>
          <StyledContainer>
            <Stylebutton
              variant="contained"
              color="primary"
              onClick={handleConfirm}
            >
              Yes
            </Stylebutton>
            <StylebuttonDelete onClick={handleClose}>No</StylebuttonDelete>
          </StyledContainer>
        </CenteredColumn>
      </Box>
    </Modal>
  );
};

const CenteredTypography = styled(Typography)({
  marginTop: "20px",
  alignItems: "center",
  textAlign: "center",
  color: themeColors.grayBlack,
});

const StyledContainer = styled(Box)({
  marginTop: "20px",
  display: "flex",
  justifyContent: "center",
});

const Container = styled(`div`)({
  display: "flex",
  justifyContent: "flex-end",
});

const MiddleIconBig = styled(`div`)({
  display: "flex",
  justifyContent: "center",
});

const CancleIconBig = styled(CancelIcon)({
  height: "80px",
  width: "80px",
  color: themeColors.error,
  cursor: "pointer",
});

const TickIconBig = styled(CheckCircleIcon)({
  height: "80px",
  width: "80px",
  color: themeColors.success,
  cursor: "pointer",
});

const CenteredColumn = styled(`div`)({
  display: "flex",
  justifyContent: "center",
  flexDirection: "column",
});

const Close = styled(CancelPresentationIcon)({
  borderRadius: "12px",
  color: themeColors.purpleDark,
  height: "30px",
  width: "30px",
  cursor: "pointer",
});
const Stylebutton = styled(Button)({
  backgroundColor: themeColors.purpleDark,
  color: themeColors.white,
  width: "100px",
  height: "40px",
  padding: "12px, 47px, 12px, 47px",
  borderRadius: "8px",
  marginRight: "37px",
});
const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "400px",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: "20px 30px",
  borderRadius: "12px",
};

const StylebuttonDelete = styled(`div`)({
  cursor: "pointer",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  fontWeight: "bold",
  color: themeColors.purpleDark,
  width: "100px",
  height: "40px",
  padding: "12px, 47px, 12px, 47px",
  border: `2px solid ${themeColors.purpleDark}`,
  borderRadius: "8px",
});
