import React, { useEffect, useState } from "react";
import {
  Button,
  Menu,
  MenuItem,
  Checkbox,
  FormControlLabel,
} from "@mui/material";
import { ExpandMore, ExpandLess } from "@mui/icons-material";
import styled from "@emotion/styled";
import { usePostEmployerWidgets, usePostVendorWidgets } from "pages/hooks";
import { toast } from "react-toastify";
import { useAppSelector } from "core/store";

interface Option {
  label: string;
  value: string;
}

interface MultiSelectButtonProps {
  options: Option[];
  selectedValues: string[];
  onChange: (selected: string[]) => void;
  buttonText: string;
  isVendor: boolean | null;
}

const MultiSelectButton: React.FC<MultiSelectButtonProps> = ({
  options,
  selectedValues,
  onChange,
  buttonText,
  isVendor,
}) => {
  const user = useAppSelector(store => store.auth);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [tempSelectedValues, setTempSelectedValues] = useState<string[]>(selectedValues);
  const { updateVendoeWidgets, isLoading, error } = usePostVendorWidgets({
    onSuccess: () => toast.success('Widgets updated successfully'),
    onError: () => toast.error('Failed to apply widgets'),
  });
  const { updateEmployerWidgets, isLoading: employerWedgetLoading } = usePostEmployerWidgets({
    onSuccess: () => toast.success('Widgets updated successfully'),
    onError: () => toast.error('Failed to apply widgets'),
  });

  useEffect(() => {
    setTempSelectedValues(selectedValues);
  }, [selectedValues]);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleToggle = (value: string) => {
    const currentIndex = tempSelectedValues.indexOf(value);
    const newSelectedValues = [...tempSelectedValues];

    if (currentIndex === -1) newSelectedValues.push(value);
    else newSelectedValues.splice(currentIndex, 1);

    setTempSelectedValues(newSelectedValues);
  };

  const handleActionButtonClick = async () => {
    const payload = {
      option1: tempSelectedValues.includes("option1"),
      option2: tempSelectedValues.includes("option2"),
      option3: tempSelectedValues.includes("option3"),
      option4: tempSelectedValues.includes("option4"),
      option5: tempSelectedValues.includes("option5"),
      user_id: user?.userInfo?.user_id || '',
    };

    try {
      if (isVendor) {
        await updateVendoeWidgets(payload);
      } else {
        await updateEmployerWidgets(payload);
      }
      onChange(tempSelectedValues); // Ensure this is called after successful update
    } catch (error) {
      toast.error('Failed to update widgets');
    } finally {
      handleClose();
    }
  };

  return (
    <React.Fragment>
      <Button
      sx={{width:'200px'}}
        aria-controls="multi-select-menu"
        aria-haspopup="true"
        onClick={handleClick}
        variant={isVendor ? 'contained' : 'contained'}
        endIcon={anchorEl ? <ExpandLess /> : <ExpandMore />}
      >
        {buttonText}
      </Button>
      <Menu
        id="multi-select-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {options.map((option) => (
          <MenuItem key={option.value} onClick={() => handleToggle(option.value)}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={tempSelectedValues.includes(option.value)}
                />
              }
              label={option.label}
            />
          </MenuItem>
        ))}
        <Button
          variant="contained"
          color="primary"
          onClick={handleActionButtonClick}
          fullWidth
        >
          {isLoading || employerWedgetLoading ? 'Loading please wait...' : 'Apply'}
        </Button>
      </Menu>
    </React.Fragment>
  );
};



const PerformAction = styled(Button)({
  width: '250px'
})
const StyledButton = styled(Button)`
  && {
    margin: 16px;
  }
`;

const StyledMenuItem = styled(MenuItem)`
  && {
    display: flex;
    align-items: center;
  }
`;

export default MultiSelectButton;
