import {
  VendorCvsDatabaseApiModel,
  VendorCvsSmartScoreTableModel,
} from "api/resources/modals";
import ReusableTable from "components/reusableFIlterTable/ReusableTable";
import { themeColors } from "core/Theming/theme";
import {
  FilterIcon,
  LeftcontentText,
  LeftsiteContainer,
  Leftsitecontent,
  LightToolTip,
  MainGridContainer,
  Maingridtable,
  RightSiteICon,
  TableContainer,
} from "pages/Vendor/rmp";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
  StyledButtonAccept,
  StyledButtonPending,
  StyledButtonRejected,
} from "pages/BidMarketPlace/Allsubmissions";
import { useResendMail } from "pages/hooks";
import { toast } from "react-toastify";
import Tooltip from "@mui/material/Tooltip";
import {
  ResendMailDisableIcon,
  ResendMailIcon,
  RightToRepresentDiv,
} from "../CvDataBaseTab/CvDataBaseTab";
import styled from "@emotion/styled";
import { ReactComponent as EyeIcon } from "../../../../assets/icons/vendorEyeIcon.svg";
import { CircularProgressBar } from "pages/Vendor/CommonComponents/CircleProgress";

interface Props {
  selectedTab: number;
  data: VendorCvsDatabaseApiModel[];
  spinner: boolean;
  refechData: () => {};
}

const SmartScore = ({ selectedTab, data, refechData }: Props) => {
  const [isLoading, setLoading] = useState(false);
  const [cvData, setCvData] = useState<VendorCvsSmartScoreTableModel[]>([]);
  const [selectedCvs, setSelectedCvs] = useState<
    VendorCvsSmartScoreTableModel[]
  >([]);

  const resendMailApiCall = useResendMail({
    onError: () => {},
    onSuccess: (message) => {
      refechData();
      if (message == "Email Sent Successfully") toast.success(message);
      else toast.error(message);
    },
  });

  const resendMail = async (cvId: string) => {
    setLoading(true);
    await resendMailApiCall.mutateAsync(cvId);
    setLoading(false);
  };

  const getSmartScore = (match_score: string) => {
    const percenttage = parseInt(match_score);
    const textColor =
      !isNaN(percenttage) && percenttage <= 50
        ? themeColors.DoubleRed
        : percenttage > 50 && percenttage <= 80
        ? themeColors.DoubleBrown
        : themeColors.shadowDarkGreen;
    return <p style={{ color: textColor }}>{match_score}% Match</p>;
  };

  const transformData = (
    apiData: VendorCvsDatabaseApiModel[]
  ): VendorCvsSmartScoreTableModel[] => {
    const transformedData: VendorCvsSmartScoreTableModel[] | [] = apiData
      ?.filter(
        (item: VendorCvsDatabaseApiModel) => Object.keys(item)?.length !== 0
      )
      ?.map((item: VendorCvsDatabaseApiModel, index: number) => ({
        id: index,
        cvId: item?.cvId || "-",
        requisitionId: item?.requisitionId || "-",
        requisitionName: item?.requisitionName || "-",
        jobSeekerName: item?.jobSeekerName || "-",
        rightToRepresent: item?.rightToRepresent || "-",
        noOfRequisitions: item?.noOfRequisitions || "-",
        phone: item?.phone || "-",
        email: item?.email || "-",
        lastJobTitle: item?.lastJobTitle || "-",
        qualification: item?.qualification || "-",
        smartScore: item?.smartScore || "-",
      }));
    return transformedData;
  };

  const headers = [
    {
      title: "id",
      name: "id",
      cellWidth: "230px",
      show: false,
    },
    {
      title: "CV ID",
      name: "cvId",
      cellWidth: "200px",
      filterType: "text",
      filter: true,
      customComponent: (row: VendorCvsSmartScoreTableModel) => {
        return <Link to={`${row.cvId}/cv-details`}>{row.cvId}</Link>;
      },
    },
    {
      title: "Job Seeker Name",
      name: "jobSeekerName",
      cellWidth: "200px",
      filterType: "text",
      filter: true,
    },
    {
      title: "Right To Represent",
      name: "rightToRepresent",
      cellWidth: "250px",
      filterType: "select",
      options: ["pending", "accepted", "rejected"],
      filter: true,
      customComponent: (row: VendorCvsSmartScoreTableModel) => {
        return (
          <RightToRepresentDiv>
            {row.rightToRepresent == "pending" ? (
              <StyledButtonPending>
                {row.rightToRepresent.charAt(0).toUpperCase() +
                  row.rightToRepresent.slice(1)}
              </StyledButtonPending>
            ) : row.rightToRepresent == "accepted" ? (
              <StyledButtonAccept>
                {row.rightToRepresent.charAt(0).toUpperCase() +
                  row.rightToRepresent.slice(1)}
              </StyledButtonAccept>
            ) : (
              <StyledButtonRejected>
                {row.rightToRepresent.charAt(0).toUpperCase() +
                  row.rightToRepresent.slice(1)}
              </StyledButtonRejected>
            )}
            {row.rightToRepresent != "accepted" ? (
              <LightToolTip
                textColor={themeColors.black}
                title="Resend Email To Candidate"
                arrow
              >
                {row.email ? (
                  <ResendMailIcon onClick={() => resendMail(row.cvId)} />
                ) : (
                  <ResendMailDisableIcon />
                )}
              </LightToolTip>
            ) : (
              ""
            )}
          </RightToRepresentDiv>
        );
      },
    },
    {
      title: "Requisition Details",
      name: "re",
      cellWidth: "200px",
      filterType: "number",
      filter: true,
      customComponent: (row: VendorCvsSmartScoreTableModel) => {
        const maxLength = 18;
        const truncatedJobTitle =
          row.requisitionName.length > maxLength
            ? `${row.requisitionName.substring(0, maxLength)}...`
            : row.requisitionName;
        return (
          <RfqFieldData>
            <Tooltip title={row.requisitionName} arrow>
              <Link to={`${row.cvId}/cv-details`}>{truncatedJobTitle}</Link>
            </Tooltip>
            <p>{row.requisitionId}</p>
          </RfqFieldData>
        );
      },
    },
    {
      title: "Smart Score",
      filter: true,
      filterType: "text",
      name: "match_score",
      cellWidth: "200px",
      customComponent: (row: VendorCvsSmartScoreTableModel) => (
        <SmartScoreCard>
          <CircularProgressBar
            fillPercentage={parseInt(row.smartScore, 0)}
            size="28px"
            smartScore={row.smartScore}
          />
          {getSmartScore(row.smartScore)}
          <SmartScoreIcon />
        </SmartScoreCard>
      ),
    },
    {
      title: "Phone",
      name: "phone",
      cellWidth: "200px",
      filterType: "text",
      filter: true,
    },
    {
      title: "Email",
      name: "email",
      cellWidth: "150px",
      filterType: "text",
      filter: true,
      customComponent: (row: VendorCvsSmartScoreTableModel) => {
        const maxLength = 18;
        const truncatedEmail =
          row.email.length > maxLength
            ? `${row.email.substring(0, maxLength)}...`
            : row.email;
        return (
          <Tooltip title={row.email} arrow>
            <div>{truncatedEmail}</div>
          </Tooltip>
        );
      },
    },
    {
      title: "Last Job title",
      name: "lastJobTitle",
      cellWidth: "200px",
      filterType: "text",
      filter: true,
    },
    {
      title: "Qualification",
      name: "qualification",
      cellWidth: "250px",
      filterType: "text",
      filter: true,
    },
  ];

  useEffect(() => {
    setLoading(true);
    setCvData(transformData(data));
    setLoading(false);
  }, [data, selectedTab]);

  return (
    <MainGridContainer>
      <LeftsiteContainer>
        <Leftsitecontent>
          <LeftcontentText>
            {`${selectedCvs?.length || 0} of ${cvData?.length || 0} Selected`}
          </LeftcontentText>
        </Leftsitecontent>
        <RightSiteICon>
          <FilterIcon />
        </RightSiteICon>
      </LeftsiteContainer>
      <Maingridtable>
        <TableContainer>
          <ReusableTable
            key={cvData?.length}
            headers={headers}
            data={cvData}
            itemsPerPage={10}
            spinner={isLoading}
            checkBox={false}
            headerColor={themeColors.purpleLight}
            inputBorder={themeColors.purpleDark}
            actionsCellWidth={"150px"}
          />
        </TableContainer>
      </Maingridtable>
    </MainGridContainer>
  );
};

export const SmartScoreIcon = styled(EyeIcon)({
  cursor: "pointer",
  width: "24px",
  height: "24px",
  marginRight: "24px",
});

export const SmartScoreCard = styled(`div`)({
  display: "flex",
  width: "100%",
  justifyContent: "space-around",
  alignItems: "center",
});

export const RfqFieldData = styled(`div`)({
  display: "flex",
  flexDirection: "column",
  width: "100%",
  justifyContent: "left",
  alignItems: "left",
});

export default SmartScore;
