export const TabValues = [
  {
    label: "Submit Submissions",
    value: 1,
  },
  {
    label: "Chat",
    value: 2,
  },
  {
    label: "Description",
    value: 2,
  },
  {
    label: "Raised Submissions",
    value: 2,
  },
];

export const RMP_TABLE_TABS = [
  "Pending Requisition",
  "Interested Requisitions",
  "Uninterested Requisitions",
];
