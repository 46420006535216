import React, { useEffect } from "react";
import { Modal, Box, Typography, Button, Divider } from "@mui/material";
import styled from "@emotion/styled";
import { ReactComponent as CloseIcon } from "../../../../assets/icons/Closed.svg";
import dayjs, { Dayjs } from "dayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { StaticDatePicker } from '@mui/x-date-pickers/StaticDatePicker';
import { themeColors } from "core/Theming/theme";

export const ChangedateModal = ({
  open,
  handleDateClose,
  handleDatechange,
  previousDate,
  rfqClosingDate,
  setRfqClosingDate
}: {
  open: boolean;
  handleDateClose: () => void;
  handleDatechange: () => void;
  previousDate: string;
  rfqClosingDate: Dayjs | null;
  setRfqClosingDate: (date: Dayjs | null) => void;
}) => {
  useEffect(() => {
    if (previousDate) {
      setRfqClosingDate(dayjs(previousDate));
    }
  }, [previousDate, setRfqClosingDate]);
  return (
    <Modal
      open={open}
      onClose={handleDateClose}
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
    >
      <Box sx={{ ...modalStyle }}>
        <Container>
          <Typography color='primary' variant="h4">Change Expiry Date</Typography>
          <Close onClick={handleDateClose} />
        </Container>
        <CenteredColumn>

          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <StaticDatePicker
              displayStaticWrapperAs="desktop"
              value={rfqClosingDate}
              onChange={(newValue: dayjs.Dayjs | null) => setRfqClosingDate(newValue)}
            />
          </LocalizationProvider>
          <StyledContainer>
            <Stylebutton
              variant="contained"
              color="primary"
              onClick={handleDatechange}
            >
              Change Expiry Date
            </Stylebutton>
          </StyledContainer>
          <Divider color="primary" />
          <CenteredText
            id="modal-description"
            variant="h6"

          >
           Previous Expiry Date:  <Edittime >{previousDate} </Edittime>
          </CenteredText>
        </CenteredColumn>
      </Box>
    </Modal>
  );
};

const Edittime = styled(`span`)({
  fontWeight: '700',
  color: themeColors.blackGray
})

const CenteredText = styled(Typography)({
  marginTop: '10px',
  alignItems: "center",
  textAlign: "center",
  color: themeColors.blackGray,
  marginBottom:'8px'
});

const StyledContainer = styled(Box)({
  display: "flex",
  justifyContent: "center",
  marginBottom: "14px"
});

const Container = styled(`div`)({
  display: "flex",
  justifyContent: "space-between",
  alignItems: 'center',
  textAlign: 'center',
  backgroundColor: `${themeColors.primaryLight} !important`,
  padding: '10px',
  borderRadius: "10px",
  borderBottomLeftRadius:'2px',
  borderBottomRightRadius:'2px',
});

const CenteredColumn = styled(`div`)({
  display: "flex",
  justifyContent: "center",
  flexDirection: "column",
});

const Close = styled(CloseIcon)({
  height: "30px",
  width: "30px",
  cursor: "pointer",
});

const Stylebutton = styled(Button)({
  height: "42px",
  borderRadius: "4px",
  width: '350px'
});

const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  borderRadius: "10px",
};
